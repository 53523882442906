import { css } from '@emotion/react';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { Link, Outlet } from 'react-router';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { MaggieFeatureFlags } from '../../../util/feature-flags';

const mainContainer = css`
  width: 100%;
`;

const tabContentsContainer = css`
  padding-top: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
`;

export function DataDisplay() {
  const { showTearSheets } = useFlags<MaggieFeatureFlags>();
  const [selectedTab, setSelectedTab] = useState(ROUTES.COMPANY_PROFILES);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue as ROUTES);
  };

  return (
    <div css={mainContainer}>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab
          label='Company Summary'
          value={ROUTES.COMPANY_PROFILES}
          to={ROUTES.COMPANY_PROFILES}
          component={Link}
        />
        {showTearSheets && (
          <Tab label='Tearsheets' value={ROUTES.TEAR_SHEETS} to={ROUTES.TEAR_SHEETS} component={Link} />
        )}
      </Tabs>
      <Typography
        color='text.primary'
        component={Stack}
        height='100%'
        width='100%'
        css={tabContentsContainer}
      >
        <Outlet />
      </Typography>
    </div>
  );
}
