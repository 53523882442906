import { Alert, Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { Loader } from '../../../components/Loader/Loader';
import { FundProjectedInvestmentAudit } from '../../../schemas/FundProjectedInvestmentAudit.schema';
import { FundReserves as FundReservesType } from '../../../schemas/FundReserves.schema';
import { getFundProjectedInvestmentHistory } from '../../../services/queries/MaggieFundQueries';
import { useAsync } from '../../../util/hook-utils';
import { usePortCos } from '../../Finance2/hooks/usePortCos';
import {
  forceReservesHistoryUpdateState,
  selectedFundReservesState,
  selectedFundStateFP,
} from '../state/FPState';
import { FundReservesGrid } from './FundReservesGrid';
import { FundReservesSummary } from './FundReservesSummary';
import { ReservesModals } from './ReservesModals';

export function FundReservesLoader() {
  const { dataReady } = usePortCos();
  const fund = useAtomValue(selectedFundStateFP);
  const forceReservesHistoryUpdate = useAtomValue(forceReservesHistoryUpdateState);
  const reserves = useAtomValue(selectedFundReservesState);
  const { data: reservesHistory, error } = useAsync(
    useCallback(() => {
      if (!fund) return Promise.resolve(null);
      return getFundProjectedInvestmentHistory(fund.id);
    }, [fund]),
    {
      id: `${forceReservesHistoryUpdate}-${fund?.id}`,
    }
  );

  if (error) return <Alert severity='error'>{error}</Alert>;
  if (!dataReady || !reserves || !reservesHistory) return <Loader />;
  return <FundReserves reserves={reserves} reservesHistory={reservesHistory} />;
}

interface IFundReservesProps {
  reserves: FundReservesType;
  reservesHistory: FundProjectedInvestmentAudit[];
}
function FundReserves({ reserves, reservesHistory }: IFundReservesProps) {
  const { dataReady } = usePortCos();
  const fund = useAtomValue(selectedFundStateFP);

  if (!reserves || !fund || !dataReady) return null;
  return (
    <>
      <ReservesModals />
      <Box>
        <FundReservesSummary reserves={reserves} />
        <GenericFallbacksWrapper>
          <FundReservesGrid fund={fund} reserves={reserves} reservesHistory={reservesHistory} />
        </GenericFallbacksWrapper>
      </Box>
    </>
  );
}
