import { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { cloneDeep, debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Temporal } from 'temporal-polyfill';
import { IconTypes } from '../../../components/Icon';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { createDeal, dealSort, deleteDeal, updateDeal } from '../../../services/queries/MaggieDealQueries';
import { getErrorMessage } from '../../../services/queryHelpers';
import { companyStateJ } from '../../../services/state/CompanyStateJ';
import { LoadingId, ToastProps } from '../../../types';
import { dateToPlainDateString } from '../../../util/date-utilts';
import { SortedStageIdsState } from '../../CompanyProfiles/state/CompanyDealsState';
import { getNextStageId } from '../../CompanyProfiles/utils/dealActionsUtils';
import { IDealStageDataModel } from '../data-models/dealStage.data-model';
import {
  allDealsState,
  dealStagesByIdMapState,
  dealStagesByNameMapState,
  dealTypesByIdMapState,
} from '../state/DealboardDataState';

export interface IDealActionCallbacks {
  onComplete?: () => void;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

export const useDealActions2 = () => {
  const updateStage = useUpdateStage();
  const stagesMap = useAtomValue(dealStagesByNameMapState);
  const stages = useAtomValue(dealStagesByIdMapState);
  const dealTypes = useAtomValue(dealTypesByIdMapState);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleDelete = useDeleteDeal();

  const handleClose = useCallback(
    async (deal: IDealDataModel) => {
      await updateStage(deal, {
        ...deal,
        stageId: stagesMap?.get('Closed')!.id,
      });
    },
    [stagesMap, updateStage]
  );

  const handlePass = useCallback(
    async (deal: IDealDataModel, reason: string, passComments: string | null) => {
      await updateStage(deal as IDealDataModel, {
        ...deal,
        passComments,
        stageComments: reason,
        stageId: stagesMap?.get('Pass')!.id,
      });
    },
    [stagesMap, updateStage]
  );

  const handleMoveToCurrent = useCallback(
    async (deal: IDealDataModel) => {
      const dealType = dealTypes?.get(deal.dealTypeId);
      const dealStages = dealType?.dealStageIds.map((id) => stages?.get(id));
      (dealStages as IDealStageDataModel[]).sort((a, b) => a.sortOrder - b.sortOrder);

      await updateStage(deal as IDealDataModel, {
        ...deal,
        stageId: (dealStages as IDealStageDataModel[])[0].id,
      });
    },
    [dealTypes, stages, updateStage]
  );

  const handleMissed = useCallback(
    async (deal: IDealDataModel, reason: string, passComments: string | null) => {
      await updateStage(deal as IDealDataModel, {
        ...deal,
        stageComments: reason,
        stageId: stagesMap?.get('Missed')!.id,
        passComments,
      });
    },
    [stagesMap, updateStage]
  );

  const handleTrack = useCallback(
    async (deal: IDealDataModel, reason: string, snoozeDate: Date | undefined) => {
      await updateStage(deal as IDealDataModel, {
        ...deal,
        trackComments: reason,
        snoozeDate: snoozeDate ? dateToPlainDateString(snoozeDate) : undefined,
        stageId: stagesMap?.get('Track')!.id,
      });
    },
    [stagesMap, updateStage]
  );

  return {
    handleDelete,
    showConfirmDelete,
    setShowConfirmDelete,
    handleClose,
    handlePass,
    handleTrack,
    handleMissed,
    handleMoveToCurrent,
  };
};

export function useDeleteDeal() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { deleteDeals } = useDealStateUpdates();

  return useCallback(
    async (dealToDelete: IDealDataModel, callbacks: IDealActionCallbacks = {}) => {
      actions.startLoading(LoadingId.deleteDeal);

      try {
        await deleteDeal(dealToDelete.id);
        deleteDeals([dealToDelete]);
        pushSuccessToast({ message: 'Deal successfully deleted' });
        callbacks.onSuccess?.();
      } catch (err) {
        pushErrorToast({ message: 'Failed to delete deal' });
        callbacks?.onError?.(err);
      } finally {
        actions.stopLoading(LoadingId.deleteDeal);
        callbacks.onComplete?.();
      }
    },
    [actions, deleteDeals, pushErrorToast, pushSuccessToast]
  );
}

export function useUpdateStage() {
  const stagesById = useAtomValue(dealStagesByIdMapState);
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushInfoToast } = useToastMessageState();
  const doUpdateStage = useDoUpdateStage();

  return useAtomCallback(
    useCallback(
      async (
        get,
        set,
        deal: IDealDataModel,
        updates: Partial<IDealDataModel>,
        showUndoAndProgress = true
      ) => {
        const updatedDeal: IDealDataModel = {
          ...cloneDeep(deal),
          ...updates,
          stageUpdateDate: getTimelessZonedNow(),
        };

        const nextStage = stagesById?.get(updates.stageId!);
        const nextStageName = nextStage?.name ?? 'unknown';

        const company = await get(companyStateJ(deal.companyId));
        if (!company) {
          pushErrorToast({ message: 'An unexpected error has occurred' });
          return;
        }
        const companyName = `"${company.name}"`;

        if (showUndoAndProgress) {
          actions.startLoading(LoadingId.updateDeal);
        }

        await doUpdateStage(deal, updatedDeal, {
          onSuccess: () => {
            if (showUndoAndProgress) {
              pushInfoToast({
                title: `THE DEAL WAS MOVED TO ${nextStageName.toUpperCase()} STAGE`,
                message: `${companyName} deal has been successfully moved to the ${nextStageName} stage`,
                buttonLabel: 'Undo',
                buttonIconType: IconTypes.UNDO,
                buttonAction: () => {
                  doUpdateStage(updatedDeal, deal);
                },
              });
            }
          },
          onError: () => {
            pushErrorToast({ message: `Failed to move deal to '${nextStageName}' stage` });
          },
          onComplete: () => {
            actions.stopLoading(LoadingId.updateDeal);
          },
        });
      },
      [actions, doUpdateStage, pushErrorToast, pushInfoToast, stagesById]
    )
  );
}

export function useDoUpdateStage() {
  const { updateDeals } = useDealStateUpdates();

  return useCallback(
    async (
      dealPrevState: IDealDataModel,
      dealNextState: IDealDataModel,
      callbacks?: IDealActionCallbacks
    ) => {
      try {
        const updated = await updateDeal(dealNextState);
        updateDeals([updated]);
        callbacks?.onSuccess?.();
      } catch (error) {
        callbacks?.onError?.(error);
      } finally {
        callbacks?.onComplete?.();
      }
    },
    [updateDeals]
  );
}

export function useCreateDeal() {
  const { pushErrorToast, pushInfoToast } = useToastMessageState();
  const { actions } = useLoadingBarState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { addNewDeal } = useDealStateUpdates();

  return useCallback(
    async (
      dealInfo: Partial<IDealDataModel>,
      byPassDuplicate = false,
      showErrorToastIfDuplicate = true
    ): Promise<IDealDataModel | undefined> => {
      actions.startLoading(LoadingId.createDeal);
      const params = byPassDuplicate ? { params: { byPassDuplicate } } : undefined;
      try {
        const newDeal = await createDeal(dealInfo, params);
        addNewDeal(newDeal);

        const toastConfig: ToastProps = {
          message: 'Deal successfully created!',
        };
        if (!pathname.includes('deal-flow')) {
          toastConfig.buttonLabel = 'Go to Board';
          toastConfig.buttonAction = () => navigate('/deal-flow');
        }
        pushInfoToast(toastConfig);
        return newDeal;
      } catch (err) {
        console.error(err);
        if (isDuplicateDealResponseError(err) && !showErrorToastIfDuplicate) {
          throw err;
        } else {
          const message = getErrorMessage(err, 'An error occurred while trying to create a deal.');
          pushErrorToast({ message });
        }
      } finally {
        actions.stopLoading(LoadingId.createDeal);
      }
    },
    [actions, addNewDeal, navigate, pathname, pushErrorToast, pushInfoToast]
  );
}

export function useUpdateDeal2() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { updateDeals } = useDealStateUpdates();

  return useAtomCallback(
    useCallback(
      async (
        get,
        set,
        dealToUpdate: IDealDataModel,
        updates: Partial<IDealDataModel>,
        showSuccessMessage = true
      ) => {
        let stageUpdateDate = dealToUpdate.stageUpdateDate;
        if (updates.stageId !== dealToUpdate.stageId) {
          stageUpdateDate = getTimelessZonedNow();
        }

        try {
          const updated = await updateDeal({
            id: dealToUpdate.id,
            companyId: dealToUpdate.companyId,
            dealTypeId: dealToUpdate.dealTypeId,
            ...updates,
            stageUpdateDate,
          });
          updateDeals([updated]);

          if (showSuccessMessage) {
            pushSuccessToast({ message: 'Successfully updated deal' });
          }
        } catch (err) {
          pushErrorToast({ message: 'Failed to update deal' });
        }
      },
      [pushErrorToast, pushSuccessToast, updateDeals]
    )
  );
}

export function useDebouncedUpdateDeal() {
  const updateDeal = useUpdateDeal2();
  return useMemo(
    () => debounce((...params: Parameters<typeof updateDeal>) => updateDeal(...params), 1000),
    [updateDeal]
  );
}

export function useDealStateUpdates() {
  return {
    updateDeals: useAtomCallback(
      useCallback((get, set, updatedDeals: IDealDataModel[]) => {
        const updatedDealsMap = updatedDeals.reduce((res, deal) => {
          return res.set(deal.id, deal);
        }, new Map<number, IDealDataModel>());
        const currentDeals = get(allDealsState);
        const nextState = currentDeals?.map((deal) => updatedDealsMap.get(deal.id) ?? deal) ?? [];
        set(allDealsState, nextState);
      }, [])
    ),
    deleteDeals: useAtomCallback(
      useCallback((get, set, dealsToDelete: IDealDataModel[]) => {
        const dealsToDeleteMap = dealsToDelete.reduce((res, deal) => {
          return res.set(deal.id, deal);
        }, new Map<number, IDealDataModel>());
        const currentDeals = get(allDealsState);
        const nextState = currentDeals.filter((deal) => dealsToDeleteMap.get(deal.id) === undefined);
        set(allDealsState, nextState);
      }, [])
    ),
    addNewDeal: useAtomCallback(
      useCallback((_get, set, newDeal: IDealDataModel) => {
        set(allDealsState, (currentDeals) => {
          return [...cloneDeep(currentDeals), newDeal].sort(dealSort);
        });
      }, [])
    ),
  };
}

interface IDuplicateDealErrorResponse extends AxiosResponse {
  data: {
    deal: IDealDataModel;
  };
}
export interface IDuplicateDealError extends AxiosError {
  response: IDuplicateDealErrorResponse;
}

export function isDuplicateDealResponseError(err: unknown): err is IDuplicateDealError {
  return (
    (err as AxiosError)?.response?.status === 409 &&
    (err as IDuplicateDealError).response?.data?.deal !== undefined
  );
}

export function useMoveDealToNextStage() {
  const updateDealStage = useUpdateStage();
  const { pushErrorToast } = useToastMessageState();
  const {
    actions: { startLoading, stopLoading },
  } = useLoadingBarState();

  const getNextStage = useAtomCallback(
    useCallback((get, set, deal: IDealDataModel) => {
      const sortedStageIds = get(SortedStageIdsState(deal.dealTypeId));
      return getNextStageId(deal, sortedStageIds);
    }, [])
  );

  const moveToNextStage = useAtomCallback(
    useCallback(
      async (get, set, deal: IDealDataModel) => {
        const nextStageId = getNextStage(deal);
        if (!nextStageId) {
          console.warn('No next stage id found for deal', deal);
          return;
        }
        startLoading(LoadingId.updateDeal);
        const updates: Partial<IDealDataModel> = {
          stageId: nextStageId,
          stageUpdateDate: getTimelessZonedNow(),
        };

        try {
          await updateDealStage(deal, updates);
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Failed to update deal') });
        } finally {
          stopLoading(LoadingId.updateDeal);
        }
      },
      [getNextStage, pushErrorToast, startLoading, stopLoading, updateDealStage]
    )
  );
  return { moveToNextStage, getNextStage };
}

function getTimelessZonedNow() {
  return Temporal.Now.zonedDateTimeISO().toString().split('T')[0];
}
