import { compareAsc } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { FieldValues } from 'react-hook-form';
import { Temporal } from 'temporal-polyfill';
import { KpiSection } from '../../data-models/company-financials.data-model';
import { RendererType } from '../../data-models/field.data-model';
import { RendererTypeOfComponent } from '../../data-models/field2.data-model';
import {
  IKPIDataValue,
  IKPIResponseForm,
  IKPIResponseFormSectionData,
  KPIRequestFrequency,
  KPIRequestPeriod,
} from '../../data-models/kpi-requests.data-model';
import {
  IKPITableDataModel,
  IKPITemplate,
  IKPITemplateSectionDataModel,
  isKpiTableDataModel,
} from '../../data-models/kpi-template.data-model';
import { FDMap } from '../../util/data-structure/FDMap';
import { FormatterService } from '../../util/formatter-service';
import {
  DateFormattersConfig,
  fiscalDateFormatter,
  formatISODateOnly,
} from '../../util/formatters/DateFormatters';
import { sortNumbers } from '../../util/numericUtils';

// Assign sectionId to each object into sectionData
export const assignSortOrderTemplateSections = (data: FieldValues) => {
  const sections = data.sections.map((section: IKPITemplateSectionDataModel, index: number) => {
    const updatedSection = section;
    if (section.type === RendererTypeOfComponent.grid) {
      (updatedSection.meta as IKPITableDataModel).metrics = (
        updatedSection.meta as IKPITableDataModel
      ).metrics?.map((metric, index) => ({
        ...metric,
        sortOrder: index,
      }));
    }

    return {
      ...updatedSection,
      meta: {
        ...updatedSection.meta,
        sortOrder: index,
      },
    };
  });

  return {
    ...data,
    sections,
  };
};

export const sortTemplateSections = (template: IKPITemplate | null) => {
  if (!template) return null;

  const templateWithSortedSections = cloneDeep(template);
  templateWithSortedSections.sections = templateWithSortedSections.sections ?? [];
  templateWithSortedSections.sections
    .sort((secA, secB) => sortNumbers(secA.meta.sortOrder, secB.meta.sortOrder))
    .map((section) => {
      if (section.type === RendererTypeOfComponent.grid) {
        (section.meta as IKPITableDataModel).metrics.sort((metricA, metricB) => {
          return sortNumbers(metricA.sortOrder, metricB.sortOrder);
        });
      }
    });

  return templateWithSortedSections;
};

export function formatFileName(fileName: string) {
  // Remove illegal characters
  fileName = fileName.replace(/[\\/:"*?<>|]/g, '');
  fileName = fileName.replace(/ /g, '_');

  // Limit length
  const maxLength = 255;
  fileName = fileName.substring(0, maxLength);

  return fileName;
}

export function convertFileSize(size: number) {
  const units = ['B', 'KB', 'MB'];
  let convertedSize = size;
  let unitIndex = 0;
  while (convertedSize > 1024) {
    convertedSize /= 1024;
    unitIndex++;
  }

  return `${convertedSize.toFixed(2)}${units[unitIndex]}`;
}

export function validateFileExtension(fileName: string) {
  const fileExtension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

  const allowedExtensions = ['xlsx', 'xls', 'csv', 'pdf'];
  if (!allowedExtensions.includes(fileExtension)) {
    return false;
  }

  return true;
}

export function getFieldRefByRenderType(renderType: RendererType | null, defaultRef: string) {
  switch (renderType) {
    case RendererType.fileUpload:
      return `${defaultRef}.fileId`;
    default:
      return defaultRef;
  }
}

export function kpiDataValuesToFormStructure(values: IKPIDataValue[]) {
  if (!values.length) return [];
  const newValues: Array<IKPIDataValue[]> = [];
  const setKpisIds = new Set();
  values.map((val) => setKpisIds.add(val.kpiId));
  const kpisIds = Array.from(setKpisIds);

  kpisIds.forEach((kpiId, index) => {
    newValues[index] = values.filter((val) => val.kpiId === kpiId);
  });

  return newValues;
}

export function getMetricsSections(template: IKPITemplate) {
  return (
    template.sections?.filter((section) => {
      return isKpiTableDataModel(section.meta);
    }) ?? []
  );
}

export function getMetricsSectionData(values: IKPIDataValue[], template: IKPITemplate) {
  const kpiSections = getMetricsSections(template);
  if (!kpiSections) return {};

  const valuesSortedByDate = [...values].sort((a, b) => {
    return compareAsc(new Date(a.date), new Date(b.date));
  });

  const kpiValuesBySectionById = new FDMap<KpiSection, FDMap<number, IKPIDataValue[]>>();
  valuesSortedByDate.forEach((value) => {
    const { section, kpiId } = value;
    if (section) {
      kpiValuesBySectionById.update(section, (bySection) => {
        return (bySection ?? new FDMap<number, IKPIDataValue[]>()).setOrUpdate(kpiId, [value], (curr) =>
          curr.concat(value)
        );
      });
    }
  });

  const metricsSectionsById = new Map<number, IKPIResponseFormSectionData>();
  kpiSections.reduce((acc, section) => {
    const { id, meta } = section;
    if (!id || !meta) return acc;
    const kpiValues: IKPIDataValue[][] = [];
    return acc.set(id, {
      sectionId: id,
      value: (section.meta as IKPITableDataModel).metrics?.reduce((financials, field) => {
        const financialValues =
          kpiValuesBySectionById.get((section.meta as IKPITableDataModel).section)?.get(field.id) ?? [];
        financials.push(financialValues);
        return financials;
      }, kpiValues),
    });
  }, metricsSectionsById);

  return Object.fromEntries(metricsSectionsById.entries());
}

export function prepareKpiResponsePayload(
  kpiTemplate: IKPITemplate,
  formData: IKPIResponseForm,
  requestId: string | undefined
) {
  const metricsSectionIds = new Set(getMetricsSections(kpiTemplate).map((section) => section.id));
  const metricsValues = formData.sectionData
    .filter((data) => metricsSectionIds.has(data.sectionId) && Array.isArray(data.value))
    .map((data) => data.value as IKPIDataValue[])
    .flat(2)
    .filter((kpiValue) => kpiValue.value != null);

  const kpiData = {
    ...formData.kpiData,
    values: metricsValues,
  };

  const payload = {
    requestId: requestId as string,
    responseFormData: {
      sectionData: formData.sectionData.map((dataForSection) => {
        if (metricsSectionIds.has(dataForSection.sectionId)) {
          return { ...dataForSection, value: undefined };
        } else {
          return dataForSection;
        }
      }),
      kpiData,
    },
  };

  return payload;
}

type GenerateKPITableColHeaderDataParams = {
  fyeDateString: string;
  periodISODate: string;
  selectedFrequency: KPIRequestPeriod;
  kpiRequestFrequency: KPIRequestFrequency;
  nextPeriod?: boolean;
};

export type KPITableColHeaderData = {
  title: string;
  date: string;
};

export function generateKPITableColHeaderData({
  fyeDateString,
  periodISODate,
  selectedFrequency,
  kpiRequestFrequency,
  nextPeriod = false,
}: GenerateKPITableColHeaderDataParams): KPITableColHeaderData[] {
  const formatMonthYear = FormatterService.get().getFormatterForModel(DateFormattersConfig.monthYear);
  const fyeDateT = Temporal.PlainDate.from(fyeDateString.split('T')[0]);
  let periodDateT = Temporal.PlainDate.from(periodISODate.split('T')[0]);

  if (nextPeriod) {
    if (kpiRequestFrequency === KPIRequestFrequency.Annual) {
      periodDateT = periodDateT.add({ years: 1 });
    } else if (kpiRequestFrequency === KPIRequestFrequency.Monthly) {
      periodDateT = periodDateT.add({ months: 1 });
    } else if (kpiRequestFrequency === KPIRequestFrequency.Quarterly) {
      periodDateT = periodDateT.add({ months: 3 });
    }
  }
  const periodISO = periodDateT.toString();

  switch (kpiRequestFrequency) {
    case KPIRequestFrequency.Annual:
      switch (selectedFrequency) {
        case KPIRequestPeriod.Month: {
          const res: KPITableColHeaderData[] = [];

          for (let i = 0; i < 12; i++) {
            const date = new Date(periodDateT.year, periodDateT.month - i, 0);

            res.push({
              title: formatMonthYear(date),
              date: formatISODateOnly(date),
            });
          }

          return res.reverse();
        }

        case KPIRequestPeriod.Quarter: {
          const periodDateT = Temporal.PlainDate.from(periodISO);
          const res: KPITableColHeaderData[] = [];

          for (let i = 0; i < 4; i++) {
            const dateIso = periodDateT.subtract({ months: i * 3 }).toString();

            res.push({
              title: fiscalDateFormatter(KPIRequestFrequency.Quarterly, dateIso, fyeDateString),
              date: dateIso,
            });
          }

          return res.reverse();
        }

        case KPIRequestPeriod.Annual:
          return [
            {
              title: fiscalDateFormatter(KPIRequestFrequency.Annual, periodISO, fyeDateString),
              date: formatISODateOnly(new Date(periodDateT.year, fyeDateT.month, 0)),
            },
          ];
        default:
          return [];
      }

    case KPIRequestFrequency.Quarterly:
      if (selectedFrequency === KPIRequestPeriod.Quarter) {
        const date = new Date(periodDateT.year, periodDateT.month, 0);

        return [
          {
            title: fiscalDateFormatter(KPIRequestFrequency.Quarterly, periodISO, fyeDateString),
            date: formatISODateOnly(date),
          },
        ];
      }
      if (selectedFrequency === KPIRequestPeriod.Month) {
        const res: KPITableColHeaderData[] = [];

        for (let i = 0; i < 3; i++) {
          const date = new Date(periodDateT.year, periodDateT.month - i, 0);

          res.push({
            title: formatMonthYear(date),
            date: formatISODateOnly(date),
          });
        }

        return res.reverse();
      }
      return [];

    case KPIRequestFrequency.Monthly: {
      const date = new Date(periodDateT.year, periodDateT.month, 0);

      return [
        {
          title: formatMonthYear(date),
          date: formatISODateOnly(date),
        },
      ];
    }

    default:
      return [];
  }
}
