export enum PreferenceScope {
  USER = 'user',
  ORGANIZATION = 'organization',
}

export const PreferenceKey = {
  clientSettings: 'client-settings',
  companyInvestments: 'company-profiles.summary.company-investments',
  companyPerformanceSelectedKPICharts: 'company-profiles.performance.selectedKpiCharts',
  companyPerformanceSelectedKPIs: 'company-profiles.performance.selectedKpis',
  companySummaryDetails: 'company-profiles.summary.company-details',
  companySummaryInvestments: 'company-profiles.summary.investments',
  tearSheetCompanySummary: 'tear-sheet.company-summary.section',
  tearSheetInvestmentSummary: 'tear-sheet.investment-summary.section',
  tearSheetPerformanceSummary: 'tear-sheet.performance-summary.section',
} as const;
export type PreferenceKey = (typeof PreferenceKey)[keyof typeof PreferenceKey] | string;

export type PanelPreferences<T = string> = {
  availableFields?: T[];
  selectedFields: T[];
};

export type ClientSettingsPreferences = {
  managedServices: boolean;
  name: string;
  logoUrl: string;
};

export interface IPreferencesV3DataModel<T> {
  scope: PreferenceScope;
  preferenceKey: PreferenceKey | string;
  entityId: number | null;
  type: string | null;
  value: T;
}

export function createPreferenceV3DataModel<T>(
  overrides: Partial<IPreferencesV3DataModel<T>> = {}
): IPreferencesV3DataModel<T> {
  return {
    scope: PreferenceScope.USER,
    preferenceKey: '',
    entityId: null,
    type: null,
    value: {} as T,
    ...overrides,
  };
}
