import { useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { CompanyType } from '../../../data-models/company.data-model';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { companyState } from '../../../services/state/CompanyState';
import { DealsByCompanyIdState } from '../../../services/state/DealState';
import { getForesightStore } from '../../../util/jotai-store';
import { IDealStageDataModel } from '../../DealFlow2/data-models/dealStage.data-model';
import { dealStagesByIdMapState } from '../../DealFlow2/state/DealboardDataState';

export function useCompanyType(companyId: number) {
  const [companyType, setCompanyType] = useState<CompanyType | undefined>();

  const getCompanyType = useRecoilCallback(
    ({ snapshot }) =>
      async (companyId: number) => {
        const store = getForesightStore();
        const company = await snapshot.getPromise(companyState(companyId));
        const allCompanyDeals = store.get(DealsByCompanyIdState(companyId));
        const stagesMap = await store.get(dealStagesByIdMapState);

        switch (true) {
          case company?.companyType === CompanyType.portfolio:
            return CompanyType.portfolio;
          case hasActiveDeal(allCompanyDeals, stagesMap):
            return CompanyType.pipeline;
          case hasPassedDeal(allCompanyDeals, stagesMap):
            return CompanyType.passed;
          default:
            return CompanyType.discovery;
        }
      },
    [companyId]
  );

  useEffect(() => {
    getCompanyType(companyId).then((companyType) => {
      setCompanyType(companyType);
    });
  }, [companyId, getCompanyType]);

  return companyType;
}

function hasActiveDeal(deals: IDealDataModel[] | null, stagesMap?: Map<number, IDealStageDataModel>) {
  if (!stagesMap || !deals) return false;

  return deals.some((d) => {
    const stageName = stagesMap.get(d.stageId)?.name;
    if (!stageName) return false;
    return !['Pass', 'Track', 'Missed', 'Closed'].includes(stageName);
  });
}

function hasPassedDeal(deals: IDealDataModel[] | null, stagesMap?: Map<number, IDealStageDataModel>) {
  if (!stagesMap || !deals) return false;

  return deals.some((d) => {
    const stageName = stagesMap.get(d.stageId)?.name;
    if (!stageName) return false;
    return stageName === 'Pass';
  });
}
