import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../../constants/RouteNameMapping';

export const MANAGEMENT_SECTIONS = [
  {
    id: ROUTES.NOTIFICATION_PREFERENCES,
    value: ROUTE_TITLE_MAPPING[ROUTES.NOTIFICATION_PREFERENCES],
  },
];

export const CUSTOMIZATION_SECTIONS = [
  {
    id: ROUTES.ADMIN_PANEL_FIELDS,
    value: ROUTE_TITLE_MAPPING[ROUTES.ADMIN_PANEL_FIELDS],
  },
  {
    id: `${ROUTES.ADMIN_PANEL_DATA_DISPLAY}/${ROUTES.COMPANY_PROFILES}`,
    value: ROUTE_TITLE_MAPPING[ROUTES.ADMIN_PANEL_DATA_DISPLAY],
  },
  {
    id: ROUTES.ADMIN_PANEL_RUBRICS,
    value: ROUTE_TITLE_MAPPING[ROUTES.ADMIN_PANEL_RUBRICS],
  },
];

export const PIPELINE_SECTIONS = [
  {
    id: ROUTES.CONFIG_DEAL_TYPES,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_DEAL_TYPES],
  },
  {
    id: ROUTES.CONFIG_DEAL_STAGES,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_DEAL_STAGES],
  },
  {
    id: ROUTES.CONFIG_PASS_REASONS,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_PASS_REASONS],
  },
  {
    id: ROUTES.CONFIG_MISS_REASONS,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_MISS_REASONS],
  },
];

export const PORTFOLIO_SECTIONS = [
  {
    id: ROUTES.CONFIG_FUNDS,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_FUNDS],
  },
  {
    id: ROUTES.GENERAL_LEDGER,
    value: ROUTE_TITLE_MAPPING[ROUTES.GENERAL_LEDGER],
  },
  {
    id: ROUTES.CONFIG_KPI,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_KPI],
  },
  {
    id: ROUTES.CONFIG_KPI_GROUPS,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_KPI_GROUPS],
  },
];

export const SOURCING_SECTIONS = [
  {
    id: ROUTES.CONFIG_SECTORS,
    value: ROUTE_TITLE_MAPPING[ROUTES.CONFIG_SECTORS],
  },
];

export const CONFIG_ROUTE = `${ROUTES.ADMIN_PANEL}/${ROUTES.CONFIG}`;
