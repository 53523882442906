import { Alert, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanySearch } from '../../../components/CompanySearch/CompanySearch';
import {
  FormField,
  Label,
  StepperBox,
  StepperFormFieldsContainer,
  StepperSx,
  StepSx,
} from '../../../components/Form/FormComponents';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { createDealFormViewModel, DealFormViewModel } from '../../../schemas/deal.schema';
import { CompanyFormStep } from '../../Finance2/Forms/CompanyFormStep';
import { SelectedCompanyInfo } from '../../Finance2/Forms/CompanySelectForm';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { FMT } from '../../../util/formatter-service';
import { useAddDealFormState, useResetAddDealFormState } from './AddDealFormState';
import { AddDealFormStep } from './AddDealStep';

export interface IAddDealFormProps {
  onClose: () => void;
}

export function AddDealForm(props: IAddDealFormProps) {
  const { onClose } = props;
  const { currentStep, dealFormData, selectedCompany, onCreateDeal, setCurrentStep, setDealFormData } =
    useAddDealFormState();

  const resetFormState = useResetAddDealFormState();

  useEffect(() => {
    return resetFormState;
  }, [resetFormState]);

  const handleSubmit = useCallback(
    async (data: DealFormViewModel) => {
      await onCreateDeal(data);
      resetFormState();
      onClose();
    },
    [onCreateDeal, onClose, resetFormState]
  );

  const handleGoBack = useCallback(
    (data: Partial<DealFormViewModel>) => {
      setDealFormData(data);
      setCurrentStep((prev) => prev - 1);
    },
    [setCurrentStep, setDealFormData]
  );

  const defaultValues = useMemo(() => {
    return (dealFormData ? { ...dealFormData } : createDealFormViewModel()) as Partial<DealFormViewModel>;
  }, [dealFormData]);

  const steps = useMemo(
    () => [
      {
        label: selectedCompany?.name ?? 'Company',
        component: <SelectCompanyForDeal onCancel={onClose} />,
      },
      {
        label: 'Deal Details',
        component: (
          <AddDealFormStep
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            handleGoBack={handleGoBack}
          />
        ),
      },
    ],
    [defaultValues, handleGoBack, handleSubmit, onClose, selectedCompany?.name]
  );

  return (
    <>
      <StepperBox>
        <Stepper activeStep={currentStep} sx={StepperSx}>
          {steps.map(({ label }) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps} sx={StepSx}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <>{steps[currentStep]?.component}</>
      </StepperBox>
    </>
  );
}

interface ISelectCompanyForDealProps {
  onCancel: () => void;
}

export function SelectCompanyForDeal({ onCancel }: ISelectCompanyForDealProps) {
  const {
    duplicateDeal,
    selectedCompany,
    onClearCompany,
    onCompanySelect,
    handleValidateCompanyStep,
    onCreateOrUpdateCompany,
    dealCompanyFormData,
    setDealCompanyFormData,
  } = useAddDealFormState();

  const [showCompanyForm, setShowCompanyForm] = useState(false);

  const _onSubmit = useCallback(
    async (data: Partial<ICompanyDataModel>) => {
      const res = await onCreateOrUpdateCompany(data);
      setShowCompanyForm(false);
      return res;
    },
    [onCreateOrUpdateCompany]
  );

  const onCancelCreateOrEdit = useCallback(() => {
    setShowCompanyForm(false);
  }, []);

  const onCreateCompany = useCallback(
    (name: string) => {
      setDealCompanyFormData({ name });
      setShowCompanyForm(true);
    },
    [setDealCompanyFormData]
  );

  const onEditCompany = useCallback(() => {
    if (!selectedCompany) return;
    setDealCompanyFormData({ ...selectedCompany });
    setShowCompanyForm(true);
  }, [setDealCompanyFormData, selectedCompany]);

  if (showCompanyForm) {
    return (
      <CompanyFormStep
        defaultValues={dealCompanyFormData ?? {}}
        onSubmit={_onSubmit}
        title='Company Details'
        onCancel={onCancelCreateOrEdit}
      />
    );
  }
  return (
    <>
      <StepperFormFieldsContainer sx={{ alignSelf: 'start' }}>
        <Typography variant='body1'>Select a Company</Typography>
        <FormField>
          <Label required>Company</Label>
          {selectedCompany ? (
            <>
              <SelectedCompanyInfo
                company={selectedCompany}
                onClear={onClearCompany}
                onEdit={onEditCompany}
              />
              {duplicateDeal && <DuplicateDealWarning deal={duplicateDeal} />}
            </>
          ) : (
            <CompanySearch
              onChange={onCompanySelect}
              showCreateOption
              disablePortal={false}
              autoFocus
              onCompanyCreateClick={onCreateCompany}
            />
          )}
        </FormField>
      </StepperFormFieldsContainer>
      <StepperFormButtons
        handleGoBack={onCancel}
        backButtonLabel={'Cancel'}
        stepIsValid={handleValidateCompanyStep}
        nextButtonLabel={duplicateDeal ? 'Yes, proceed' : 'Next'}
      />
    </>
  );
}

function DuplicateDealWarning({ deal }: { deal: IDealDataModel }) {
  const { createdBy, createdAt } = deal;
  let additionalInfo = '';
  if (createdBy) {
    additionalInfo = `created by ${FMT.format('userByEmail', createdBy)}`;
  }
  if (createdAt) {
    additionalInfo = additionalInfo
      ? `${additionalInfo} on ${FMT.format('date', createdAt)}`
      : `created on ${FMT.format('date', createdAt)}`;
  }
  return (
    <Alert severity='error' sx={{ margin: '2rem 0 0' }}>
      <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
        {`Deal for this company already exists ${additionalInfo ? `(${additionalInfo}).` : '.'}
Are you sure you want to proceed?`}
      </Typography>
    </Alert>
  );
}
