import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { companyState } from '../state/CompanyState';
import { companyStateJ } from '../state/CompanyStateJ';

export function useSaveCompanyData(overwrite = true) {
  const batchSaveCompanyData = useRecoilCallback(({ set, snapshot }) => (companies: ICompanyDataModel[]) => {
    companies.forEach((company) => {
      if (overwrite || !snapshot.getInfo_UNSTABLE(companyState(company.id)).loadable?.valueMaybe()) {
        set(companyState(company.id), company);
      }
      // else do nothing, don't overwrite state
    });
  });

  return { batchSaveCompanyData };
}

export function useSaveCompanyDataJ() {
  return useAtomCallback(
    useCallback((get, set, companies: ICompanyDataModel[]) => {
      companies.forEach((company) => {
        set(companyStateJ(company.id), company);
      });
    }, [])
  );
}
