import { useAtom, useAtomValue } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { selectedFundStateFP, showFundFormsState, useResetFundFormState } from '../state/FPState';
import { FundCapitalAllocationForm } from './Allocation/FundCapitalAllocationForm';
import { FundWaterfallForm, IFundFormProps } from './FundWaterfallForm';
import { FundWaterfallForm2, IFundFormProps as IFundFormProps2 } from './FundWaterfallForm2';

export function FundFormModal({
  defaultValues,
  onSubmit,
}: {
  defaultValues: IFundFormProps['defaultValues'] | IFundFormProps2['defaultValues'];
  onSubmit: IFundFormProps2['onSubmit'];
}) {
  const fund = useAtomValue(selectedFundStateFP);
  const [showForm, setShowForm] = useAtom(showFundFormsState);
  const resetFormState = useResetFundFormState();
  const { showFundWaterfallForm2 } = useFlags<MaggieFeatureFlags>();

  const onClose = useCallback(() => {
    setShowForm(null);
    resetFormState();
  }, [resetFormState, setShowForm]);

  const title =
    defaultValues?.name &&
    `${defaultValues.name} ${showForm === 'waterfall' ? 'Waterfall' : 'Allocation'} Settings`;

  if (!fund) return null;

  return (
    <BasicDialog open={showForm != null} title={title} onClose={onClose}>
      {showForm === 'waterfall' &&
        (showFundWaterfallForm2 ? (
          <FundWaterfallForm2
            defaultValues={defaultValues as IFundFormProps2['defaultValues']}
            onCancel={onClose}
            onSubmit={onSubmit}
          />
        ) : (
          <FundWaterfallForm
            defaultValues={defaultValues as IFundFormProps['defaultValues']}
            onSubmit={onSubmit}
            onCancel={onClose}
          />
        ))}
      {showForm === 'allocation' && (
        <FundCapitalAllocationForm
          defaultValues={defaultValues as IFundFormProps['defaultValues']}
          onSubmit={onSubmit}
        />
      )}
    </BasicDialog>
  );
}
