import { Alert, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { DealConversionState, IDealConversionStateKey } from '../../../state/DealConversionState';
import { useFiltersParams } from '../Details/hooks/useFiltersParams';
import { BreakdownChart } from './BreakdownChart';
import { ConversionBarChart } from './ConversionBarChart';
import { DealAnalyticsChartsSkeletonLoader } from './DAOverviewSkeletonLoader';
import { OverviewChart } from './OverviewChart';

const ChartWrapper = styled('div')`
  display: grid;
  gap: 2rem;
`;

export const DAOverview: FC = () => {
  const { queryParamsWithGroupBy } = useFiltersParams();

  const { groupBy: groupByKey, ...conversionParams } = queryParamsWithGroupBy;
  const key = JSON.stringify(conversionParams);

  const { data: dealConversionLoadable, error: dealConvError } = useAtomValue(
    DealConversionState(conversionParams as IDealConversionStateKey)
  );

  const { data: dealGroupedLoadable, error: dealGroupError } = useAtomValue(
    DealConversionState(queryParamsWithGroupBy as IDealConversionStateKey)
  );

  const dealsGroupedData = dealGroupedLoadable?.dealsGrouped;

  const dealConversionData = dealConversionLoadable?.conversionRates;

  return (
    <GenericFallbacksWrapper suspenseFallback={<DealAnalyticsChartsSkeletonLoader />}>
      <ChartWrapper>
        {dealConversionData ? (
          <>
            <OverviewChart dealConversion={dealConversionData} key={`overview-${key}`} />
            <ConversionBarChart dealConversion={dealConversionData} key={`conversion-${key}`} />
          </>
        ) : dealConvError ? (
          <Alert severity='error'>Error loading data</Alert>
        ) : (
          <DealAnalyticsChartsSkeletonLoader />
        )}
        {dealsGroupedData ? (
          <BreakdownChart dealsGrouped={dealsGroupedData} key={`breakdown-${key}-${groupByKey}`} />
        ) : dealGroupError ? (
          <Alert severity='error'>Error loading data</Alert>
        ) : (
          <Skeleton height={'500px'} variant='rectangular' width={'100%'} />
        )}
      </ChartWrapper>
    </GenericFallbacksWrapper>
  );
};
