import { css } from '@emotion/react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { BasicDragHandle, SortableItem } from '../../../components/SortableList/SortableItem';
import {
  FixedSizeMultiSortable,
  IRenderGroupProps,
} from '../../../components/SortableMultiList/FixedSizeMultiSortable';
import { ISimpleChoice } from '../../../data-models/field2.data-model';
import { colors } from '../../../theme/colors';
import { FieldsPerPage } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { getPageForIndex } from './data-display-utils';
import { DetailsFieldSelect } from './DetailsFieldSelect';

const fieldsConfigContainer = css`
  overflow-y: scroll;
  background: ${colors.primary[0]};
  padding: 1rem;
  border-radius: 4px;
`;
const fieldsContainer = css`
  margin: 0.5rem;
  gap: 1rem;
  background: ${colors.neutral[2]};
  border-radius: 4px;
`;
const itemContainer = css`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding-inline: 0.5rem;
`;
const pageLabel = css`
  background: ${colors.neutral[10]};
  padding: 0.75rem 1rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const separator = css`
  background: ${colors.primary[0]};
  height: 0.5rem;
`;

export interface ReOrderableField {
  label?: string;
  key: string;
}

export interface IDraggableField extends ReOrderableField {
  index: number;
  id: string;
}

export interface IFieldOrderConfigProps {
  onRemoveField: (field: IDraggableField) => void;
  onReorder: (groupedItems: Record<string, IDraggableField[]>) => void;
  onAddField: (value: string) => void;
  selectedFields: string[];
  allFields: ReOrderableField[];
  fieldsPerPage?: number;
}

export function FieldOrderConfig({
  onAddField,
  onRemoveField,
  onReorder,
  selectedFields,
  allFields,
  fieldsPerPage = FieldsPerPage,
}: IFieldOrderConfigProps) {
  const fieldOptions = useMemo(() => {
    const selectedSet = new Set(selectedFields);
    return allFields.reduce((acc, { label, key }) => {
      if (selectedSet.has(key)) return acc;
      acc.push({
        displayName: label ?? key,
        value: key,
      });
      return acc;
    }, [] as ISimpleChoice<string>[]);
  }, [allFields, selectedFields]);

  const renderGroup = useCallback(
    ({ groupName, children }: IRenderGroupProps) => {
      return (
        <Stack key={groupName}>
          {groupName !== 'Page 1' && <div css={separator} />}
          <div css={pageLabel}>
            <Typography variant='body2' color='text.secondary'>
              {groupName}
            </Typography>
            <Typography variant='caption' color='text.secondary'>
              {`${fieldsPerPage} fields/page`}
            </Typography>
          </div>
          {children}
        </Stack>
      );
    },
    [fieldsPerPage]
  );

  const renderItem = useCallback(
    (field: IDraggableField) => {
      const { id, label, key } = field;
      return (
        <Stack>
          <SortableItem id={id}>
            <div css={itemContainer}>
              <BasicDragHandle />
              <Typography variant={'body2'} noWrap minWidth={'1rem'}>
                {label ?? key}
              </Typography>
              <IconButton
                onClick={() => {
                  onRemoveField(field);
                }}
              >
                <ClearIcon htmlColor={colors.neutral[50]} fontSize='small' />
              </IconButton>
            </div>
          </SortableItem>
        </Stack>
      );
    },
    [onRemoveField]
  );

  const fields = useMemo(() => {
    if (!selectedFields) return null;
    const fieldMap = allFields.reduce((acc, field) => {
      return acc.set(field.key, field);
    }, new Map<string, ReOrderableField>());

    const draggableItems: Record<string, IDraggableField[]> = selectedFields.reduce(
      (acc, field, i) => {
        const formField = fieldMap.get(field)!;

        if (!formField) {
          return acc;
        }

        const page = getPageForIndex(i, fieldsPerPage);
        const next = { ...formField, id: formField.key, index: i };
        if (acc[`Page ${page}`]) {
          acc[`Page ${page}`].push(next);
        } else {
          acc[`Page ${page}`] = [next];
        }
        return acc;
      },
      {} as Record<string, IDraggableField[]>
    );

    return (
      <FixedSizeMultiSortable
        items={draggableItems}
        onChange={onReorder}
        renderItem={renderItem}
        renderGroup={renderGroup}
        groupSize={fieldsPerPage}
      />
    );
  }, [allFields, fieldsPerPage, onReorder, renderGroup, renderItem, selectedFields]);

  return (
    <Stack css={fieldsConfigContainer} className='fieldOrderConfig'>
      <Typography component='h6' ml={'1rem'}>
        Field Order
      </Typography>
      <Stack css={fieldsContainer}>
        {fields}
        <DetailsFieldSelect options={fieldOptions} onChange={onAddField} />
      </Stack>
    </Stack>
  );
}
