import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endOfMonth } from 'date-fns';
import { KpiPeriod } from '../../../../data-models/company-financials.data-model';
import { formatISODateOnly, getFiscalQuarter } from '../../../../util/formatters/DateFormatters';

import {
  selectedFrequencyPerfState,
  selectedMetricsDateCPState,
} from '../../state/CompanyFinancialsDateState';
import { selectedCompanyIdProfile } from '../../state/UIState';
import {
  calendarMonthOfFiscalQuarter,
  endOfFiscalPeriod,
  getFormattedFiscalDate,
} from '../../utils/financialUtils';
import { FMT } from '../../../../util/formatter-service';

export function useFiscalDatePicker({ fye }: { fye: number }) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  const fyeMonth = fye - 1;
  const [frequency, setFrequency] = useRecoilState(selectedFrequencyPerfState(companyId));
  const [date, setDate] = useRecoilState(selectedMetricsDateCPState(companyId));

  const [month, setMonth] = useState<number>(() => date.getMonth());
  const [quarter, setQuarter] = useState<number>(() => getFiscalQuarter(date, fyeMonth + 1).fiscalQuarter);
  const [year, setYear] = useState<number>(() => date.getFullYear());

  const onChangeFrequency = useCallback(
    (freq: KpiPeriod) => {
      setFrequency(freq);
      const newDate = endOfFiscalPeriod(date, freq, fyeMonth);
      setMonth(newDate.getMonth());
      setQuarter(getFiscalQuarter(newDate, fyeMonth + 1).fiscalQuarter);
      setYear(newDate.getFullYear());
      setDate(newDate);
    },
    [date, fyeMonth, setDate, setFrequency]
  );

  useEffect(() => {
    switch (frequency) {
      case KpiPeriod.month: {
        setDate(endOfMonth(new Date(year, month)));
        break;
      }
      case KpiPeriod.quarter: {
        setDate(endOfMonth(new Date(year, calendarMonthOfFiscalQuarter(quarter, fyeMonth))));
        break;
      }
      case KpiPeriod.year: {
        setDate(endOfMonth(new Date(year, fyeMonth)));
        break;
      }
    }
  }, [frequency, fye, fyeMonth, month, quarter, setDate, year]);

  const label = `As Of: ${FMT.format('dateNumeric', date)}${
    frequency === KpiPeriod.month || fye === 12
      ? ''
      : ` (${getFormattedFiscalDate(formatISODateOnly(date), frequency, fye)})`
  }`;
  return { frequency, onChangeFrequency, label, year, month, quarter, setMonth, setQuarter, setYear, date };
}
