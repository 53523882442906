import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { CompanyType } from '../../../data-models/company.data-model';
import { CompanySearchResponse } from '../../../schemas/CompanySearchResponse.schema';
import { FundInvestment, FundInvestmentTransaction } from '../../../schemas/FundReserves.schema';
import {
  createFundInvestment,
  deleteFundInvestment,
  updateFundInvestment,
} from '../../../services/queries/MaggieFundQueries';
import { getErrorMessage } from '../../../services/queryHelpers';
import {
  forceReservesHistoryUpdateState,
  fundReservesTransactionUuidToInvestmentState,
  selectedFundIdStateFP,
  selectedFundInvestmentsByCompanyNameState,
} from '../state/FPState';
import { useInvalidateReservesData } from './useInvalidateReservesData';

interface IActionParams extends Pick<IPrepareAddInvestmentPayloadParams, 'company' | 'transaction'> {}

export function useReservesActions() {
  const fundId = useAtomValue(selectedFundIdStateFP);
  const companyNameToInvestment = useAtomValue(selectedFundInvestmentsByCompanyNameState);
  const transactionUuidToInvestment = useAtomValue(fundReservesTransactionUuidToInvestmentState);
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const setForceHistoryReservesUpdate = useSetAtom(forceReservesHistoryUpdateState);
  const invalidateReserves = useInvalidateReservesData();

  const addInvestment = useCallback(
    async ({ company, transaction }: IActionParams) => {
      if (!fundId) return;
      try {
        const payload = prepareAddInvestmentPayload({
          company,
          fundId,
          investmentsByCompanyName: companyNameToInvestment,
          transaction,
        });
        let data;
        if (payload.id) {
          data = await updateFundInvestment(payload);
        } else {
          // ƒor portcos without hypothetical investments there won't be an id on the investment object,
          // so send POST when adding the first hypothetical - the investment will get an id on success
          data = await createFundInvestment(payload);
        }
        pushSuccessToast({ message: 'Investment added successfully' });
        invalidateReserves();
        setForceHistoryReservesUpdate((curr) => curr + 1);
        return data;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to add investment') });
      }
    },
    [
      companyNameToInvestment,
      fundId,
      invalidateReserves,
      pushErrorToast,
      pushSuccessToast,
      setForceHistoryReservesUpdate,
    ]
  );

  const deleteInvestment = useCallback(
    async (investmentId: number) => {
      if (!fundId) return;
      try {
        await deleteFundInvestment(investmentId);
        pushSuccessToast({ message: 'Investment deleted successfully' });
        invalidateReserves();
        setForceHistoryReservesUpdate((curr) => curr + 1);
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to delete investment') });
      }
    },
    [fundId, invalidateReserves, pushErrorToast, pushSuccessToast, setForceHistoryReservesUpdate]
  );

  const editInvestmentTransaction = useCallback(
    async ({ company, transaction }: IActionParams) => {
      const investment = companyNameToInvestment.get(company.fields.name ?? '');
      if (!investment || !investment.id) {
        pushErrorToast({ message: 'Missing investment data' });
        return;
      }
      try {
        const payload = prepareEditInvestmentTransactionPayload(transaction, investment);
        const res = await updateFundInvestment(payload);
        pushSuccessToast({ message: 'Investment edited successfully' });
        invalidateReserves();
        setForceHistoryReservesUpdate((curr) => curr + 1);
        return res;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to edit investment') });
      }
    },
    [
      companyNameToInvestment,
      pushErrorToast,
      pushSuccessToast,
      invalidateReserves,
      setForceHistoryReservesUpdate,
    ]
  );

  const deleteInvestmentTransaction = useCallback(
    async (transactionUuid: string) => {
      const investment = transactionUuidToInvestment.get(transactionUuid ?? '');
      if (!investment || !investment.id) {
        pushErrorToast({ message: 'Missing investment data' });
        return;
      }
      try {
        const payload = {
          ...investment,
          transactions: investment.transactions.filter((t) => t.uuid !== transactionUuid && t.hypothetical),
        };
        const res = await updateFundInvestment(payload);
        pushSuccessToast({ message: 'Transaction deleted successfully' });
        invalidateReserves();
        setForceHistoryReservesUpdate((curr) => curr + 1);
        return res;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to delete transaction') });
      }
    },
    [
      transactionUuidToInvestment,
      pushErrorToast,
      pushSuccessToast,
      invalidateReserves,
      setForceHistoryReservesUpdate,
    ]
  );

  return { addInvestment, deleteInvestment, editInvestmentTransaction, deleteInvestmentTransaction };
}

function prepareEditInvestmentTransactionPayload(
  transaction: FundInvestmentTransaction,
  currentInvestment: FundInvestment
) {
  return {
    ...currentInvestment,
    transactions: currentInvestment.transactions.reduce((payload, t) => {
      if (!t.hypothetical) return payload;
      return [...payload, t.uuid === transaction.uuid ? transaction : t];
    }, [] as FundInvestmentTransaction[]),
  };
}

export interface IPrepareAddInvestmentPayloadParams {
  company: CompanySearchResponse;
  fundId: number;
  investmentsByCompanyName: Map<string, FundInvestment>;
  transaction: FundInvestmentTransaction;
}

// because of the way investments are stored on the BE, we always need to send
// the whole investment object as payload (excluding current investments from payload.transactions)
function prepareAddInvestmentPayload({
  company,
  fundId,
  investmentsByCompanyName,
  transaction,
}: IPrepareAddInvestmentPayloadParams) {
  const existingInvestment = investmentsByCompanyName.get(company.fields.name);

  if (existingInvestment) {
    const hypotheticalTransactions = existingInvestment.transactions.filter((t) => t.hypothetical);
    return {
      ...existingInvestment,
      transactions: [...hypotheticalTransactions, transaction],
    };
  } else {
    const companyId = parseInt(company.id);
    return {
      // currently the only way to differentiate between hypothetical and real investments is by checking if companyId exists
      companyId: company.fields.type === CompanyType.portfolio ? companyId : null,
      name: company.fields.name,
      fundId,
      transactions: [transaction],
    };
  }
}
