import { useSetAtom } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { FundDataModel } from '../../../schemas/Fund.schema';
import { createFundData, FundData, fundDataFormSchema } from '../../../schemas/FundData.schema';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { schemaToFormFields } from '../../../util/schema-utils';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { isEditingFundDataState } from '../state/FPState';
import { EditableComponent } from './EditableComponent';
import { editableFundDataFields, editableFundDataFields2 } from './FundDataFormActions';
import { SettingsFieldset } from './WaterfallSettingsFields';

export function WaterfallSettingsEditableFields({
  fund,
  fundData,
}: {
  fund: FundDataModel;
  fundData?: FundData;
}) {
  const { showFundDataForm2 } = useFlags<MaggieFeatureFlags>();
  const setEdit = useSetAtom(isEditingFundDataState);
  const fields = useMemo(() => {
    return schemaToFormFields(
      fundDataFormSchema().pick(showFundDataForm2 ? editableFundDataFields2 : editableFundDataFields)
    ).map((f) => ({
      ...f,
      variant: 'form-inline' as const,
      disabled: true,
    }));
  }, [showFundDataForm2]);
  const methods = useForm<FundData>({
    defaultValues: fundData ?? createFundData({ fundId: fund.id }),
  });
  return (
    <FormProvider {...methods}>
      <EditableComponent
        onEdit={() => {
          setEdit(true);
        }}
      >
        <UnstyledFieldset css={SettingsFieldset}>
          {fields.map((field) => (
            <FormFieldWithLabelFactory key={field.key} formField={field} />
          ))}
        </UnstyledFieldset>
      </EditableComponent>
    </FormProvider>
  );
}
