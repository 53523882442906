import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useSaveCompanyData, useSaveCompanyDataJ } from '../../../services/hooks/useSaveCompanyData';
import { fetchAllPortCos } from '../../../services/queries/MaggieCompanyQueries';
import { portCoIdsState } from '../../../services/state/PortCosState';
import { ICompanyDataModel } from '../../../data-models/company.data-model';

// saves portfolio company data in state and sets portCoIdsState
export function usePortCos() {
  const setPortCoIds = useSetRecoilState(portCoIdsState);
  const [loading, setLoading] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<ICompanyDataModel[]>();

  const fetchAndSavePortCos = useFetchAndSavePortCos();

  useEffect(() => {
    if (dataReady || loading) return;
    setLoading(true);
    fetchAndSavePortCos()
      .then((res) => {
        setDataReady(true);
        setData(res);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataReady, fetchAndSavePortCos, loading, setPortCoIds]);

  return { loading, dataReady, error, fetchAndSavePortCos, data };
}

export function useFetchAndSavePortCos() {
  const { batchSaveCompanyData } = useSaveCompanyData();
  const batchSaveCompanyDataJ = useSaveCompanyDataJ();
  const setPortCoIds = useSetRecoilState(portCoIdsState);

  return useCallback(async () => {
    const data = await fetchAllPortCos();
    batchSaveCompanyData(data);
    batchSaveCompanyDataJ(data);
    setPortCoIds(data.map((company) => company.id));
    return data;
  }, [batchSaveCompanyData, batchSaveCompanyDataJ, setPortCoIds]);
}
