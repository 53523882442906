import { useAtomValue } from 'jotai';
import { pick } from 'lodash-es';
import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFactoryNoLayout } from '../../../../../components/Form/FormFactory';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { field2ToField3, FieldEntity } from '../../../../../data-models/field2.data-model';
import { field3ToFormField } from '../../../../../data-models/field3.data-model';
import { DealFormViewModel, useDealFormSchema } from '../../../../../schemas/deal.schema';
import { customFieldsByEntityAtom } from '../../../../../services/state/AdminPanel/CustomFieldsStateJ';
import { FDMap } from '../../../../../util/data-structure/FDMap';
import { createForm } from '../../../../../view-models/form.view-model';
import { Panel } from '../../../../CompanyProfiles2/Summary/Panel';
import { useDebouncedUpdateDeal } from '../../../hooks/useDealActions';
import { UnstyledFieldset } from '../../../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { FieldsWrapper } from '../../../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { schemaToFormFields } from '../../../../../util/schema-utils';
import { FMT } from '../../../../../util/formatter-service';

export interface IDealPanelProps {
  deal: IDealDataModel;
  editable?: boolean;
}

export const DealCustomDataSection: FC<IDealPanelProps> = ({ deal, editable = true }) => {
  const updateDeal = useDebouncedUpdateDeal();

  const methods = useForm<Partial<DealFormViewModel>>({
    defaultValues: {
      ...(pick(deal, 'customData') ?? {}),
      createdAt: deal.createdAt as Date | null,
      createdBy: FMT.format('userByEmail', deal.createdBy),
      labels: deal.labels,
    },
  });

  const onUpdateDeal = useCallback(
    async (payload: Partial<DealFormViewModel>) => {
      const { companyId, dealTypeId } = deal;
      updateDeal(deal, { companyId, dealTypeId, ...payload } as Partial<IDealDataModel>, false);
    },
    [deal, updateDeal]
  );

  return (
    <FormProvider {...methods}>
      <DeaCustomFields onChange={onUpdateDeal} editable={editable} />
    </FormProvider>
  );
};

interface IDealCustomFieldsProps {
  onChange: (data: Partial<DealFormViewModel>) => Promise<void>;
  editable?: boolean;
}
function DeaCustomFields({ onChange, editable = true }: IDealCustomFieldsProps) {
  const dealFields = schemaToFormFields(useDealFormSchema().pick(['createdAt', 'createdBy', 'labels']));
  const dealCustomFields = useAtomValue(customFieldsByEntityAtom).get(FieldEntity.deal);
  const fields = (dealCustomFields ?? []).map((f) => ({
    ...field3ToFormField(field2ToField3(f)),
    disabled: !editable,
  }));
  const form = createForm<DealFormViewModel>({
    fields: FDMap.fromArray([...dealFields, ...fields], 'key'),
    variant: 'form-inline',
  });

  return (
    <Panel title='Audit & Custom Fields' showContentOverflow>
      <UnstyledFieldset disabled={!editable}>
        <FieldsWrapper>
          <FormFactoryNoLayout form={form} onChange={onChange} />
        </FieldsWrapper>
      </UnstyledFieldset>
    </Panel>
  );
}
