import { CashFlow } from '@webcarrot/xirr';
import { IFundDataModel } from '../../../../data-models/fund.data-model';
import { isReturnOfCapitalType } from '../../../../data-models/transaction.data-model';
import { MetricsTransactionDataModel } from '../../../../schemas/MetricsTransaction.schema';
import { FDIterator } from './StandardIterators';

export function initialSharesReceiptDateIterator() {
  let res = '';

  return {
    get value() {
      return res;
    },
    next: (transaction: MetricsTransactionDataModel) => {
      if (transaction.quantity > 0 && !res) {
        res = transaction.transactionDate;
      }

      return res;
    },
  };
}

export function noOfSharesIterator(companyIsMerged = false): FDIterator<number, MetricsTransactionDataModel> {
  let res = 0;

  return {
    get value() {
      return res;
    },
    next: (transaction: MetricsTransactionDataModel) => {
      const restructureDealId = transaction.restructureId?.split('.').pop();
      const isAcquired =
        !companyIsMerged && restructureDealId !== undefined && restructureDealId !== transaction.dealId;

      if (!isAcquired) {
        res += transaction.quantity;
      }
      return res;
    },
  };
}

export function cashFlowsCalculator(): FDIterator<CashFlow[], MetricsTransactionDataModel> {
  const cashFlows: CashFlow[] = [];

  return {
    get value() {
      return cashFlows;
    },
    next: (transaction: MetricsTransactionDataModel) => {
      if (transaction.investmentAmount) {
        cashFlows.push({
          date: new Date(transaction.transactionDate),
          amount: -1 * transaction.investmentAmount,
        });
      }

      if (transaction.distributions) {
        cashFlows.push({
          date: new Date(transaction.transactionDate),
          amount: transaction.distributions,
        });
      }

      return cashFlows;
    },
  };
}

export function fundsIncludedInTransactionsCalculator(
  fundsMap: Map<number, IFundDataModel>
): FDIterator<Map<number, IFundDataModel>, MetricsTransactionDataModel> {
  const res = new Map<number, IFundDataModel>();

  return {
    get value() {
      return res;
    },
    next: (transaction: MetricsTransactionDataModel) => {
      if (transaction.fundId != null && fundsMap.has(transaction.fundId) && !res.has(transaction.fundId)) {
        const fund = fundsMap.get(transaction.fundId!)!;
        res.set(transaction.fundId, fund);
      }

      return res;
    },
  };
}

export function exitDateCalculator(companyIsMerged = false): FDIterator<string, MetricsTransactionDataModel> {
  const noOfShares = noOfSharesIterator(companyIsMerged);
  let firstRoc: string;
  let zeroRoc: string;
  let res = '';

  return {
    get value() {
      return res;
    },
    next: (transaction: MetricsTransactionDataModel) => {
      noOfShares.next(transaction);
      if (
        isReturnOfCapitalType(transaction.transType) &&
        transaction.quantity <= 0 &&
        !transaction.position?.toLowerCase().includes('warrant')
      ) {
        if (!firstRoc) {
          firstRoc = transaction.transactionDate;
        }
        if (noOfShares.value <= 0 && !zeroRoc) {
          zeroRoc = transaction.transactionDate;
        }
      }
      res = zeroRoc ?? firstRoc ?? res;
      return res;
    },
  };
}

export function initialTransactionMetaIterator() {
  const ACCOUNTING_MIN_ZERO_VALUE = 5; // Anything below that is considered 0 for transactions.
  let investmentAmount = 0;
  let investmentDate: string = '';
  let investmentRoundId: number | undefined = undefined;
  let initialSharePrice = 0;
  let initialSharesHeld = 0;

  return {
    get value() {
      return {
        investmentAmount,
        investmentDate,
        investmentRoundId,
        initialSharesHeld,
        initialSharePrice,
      };
    },
    next: (transaction: MetricsTransactionDataModel) => {
      if (
        transaction.investmentAmount < ACCOUNTING_MIN_ZERO_VALUE ||
        transaction.investmentRoundId == undefined
      ) {
        return;
      }

      if (investmentRoundId && transaction.investmentRoundId === investmentRoundId) {
        investmentAmount += transaction.investmentAmount;
        initialSharesHeld += transaction.quantity;
      }

      if (investmentRoundId == null) {
        initialSharePrice = transaction.pps ?? 0;
        initialSharesHeld = transaction.quantity;
        investmentAmount = transaction.investmentAmount;
        investmentDate = transaction.transactionDate;
        investmentRoundId = transaction.investmentRoundId;
      }
    },
  };
}
