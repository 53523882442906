import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { colors } from '../../../theme/colors';
import { MprColumnConfigList } from './MprColumnConfigList';
import { MprKpiColumnSettingsForm } from './MprKpiColumnSettingsForm';
import { useMprColumnConfigs } from './useMprColumnConfigs';
import { MprMetricsColumnSettingsForm } from './MprMetricsColumnSettingsForm';

interface IMultiPeriodReportingSettingsProps {
  onClose: () => void;
}

export function MultiPeriodReportingSettings({ onClose }: IMultiPeriodReportingSettingsProps) {
  const { resetDrafts } = useMprColumnConfigs();
  const onCancel = useCallback(() => {
    resetDrafts();
    onClose();
  }, [onClose, resetDrafts]);

  return (
    <BasicDialog open onClose={onCancel} title={'Report Settings'} fullWidth maxWidth={'lg'}>
      <Box padding={'0 2rem 1rem'} position={'relative'}>
        <Box position={'sticky'} top={0} sx={{ background: colors.primary[0], zIndex: 3 }}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'body2'} color={'text.secondary'} display={'flex'} gap={'0.2rem'}>
              <InfoOutlinedIcon fontSize={'small'} />
              Note: Re-generation of the report may take a few moments.
            </Typography>
            <Stack direction={'row'} gap={'1rem'}>
              <Button variant={'outlined'} color={'secondary'} onClick={onCancel} size={'medium'}>
                Cancel
              </Button>
              <SaveAndRefreshButton onClose={onClose} />
            </Stack>
          </Stack>
          <Divider sx={{ marginY: '1rem' }} />
        </Box>

        <FieldSettings />
      </Box>
    </BasicDialog>
  );
}

function SaveAndRefreshButton({ onClose }: { onClose: () => void }) {
  const { saveConfigState } = useMprColumnConfigs();

  const onClick = useCallback(() => {
    saveConfigState();
    // report is refetched on column config change (see useMprReportData)
    onClose();
  }, [onClose, saveConfigState]);

  return (
    <Button variant={'contained'} color={'secondary'} onClick={onClick}>
      Save and Refresh
    </Button>
  );
}

function FieldSettings() {
  return (
    <Box sx={{ borderRadius: '4px', border: `thin solid ${colors.neutral[10]}` }}>
      <Box
        sx={{ background: colors.primary[10], padding: '0.5rem 1rem', borderRadius: '4px 4px 0 0 ' }}
        padding={'0.2rem 1rem'}
      >
        <Typography variant={'subtitle1'}>{'Columns Displayed'}</Typography>
      </Box>
      <Box padding={'1rem'}>
        <Stack direction={'row'} alignItems={'center'} mb='0.25rem' justifyContent={'space-between'}>
          <Box>
            <Typography mb='0.5rem'>Add Columns</Typography>
          </Box>
          <Stack direction={'row'} gap={'1rem'} alignItems={'baseline'}>
            <Typography variant={'body2'} color={'text.secondary'} display={'flex'} gap={'0.2rem'}>
              <InfoOutlinedIcon fontSize={'small'} />
              FYE is aligned
            </Typography>
          </Stack>
        </Stack>
        <Box display={'grid'} gridTemplateColumns={'minmax(0, 3fr) auto minmax(0, 5fr)'} gap={'1rem'}>
          <ColumnConfigForm />
          <Divider orientation={'vertical'} />
          <MprColumnConfigList />
        </Box>
      </Box>
    </Box>
  );
}

function ColumnConfigForm() {
  const [columnType, setColumnType] = useState<'kpi' | 'metrics'>('kpi');

  return (
    <Box position={'relative'}>
      <ToggleButtonGroup
        id='column-type'
        color='secondary'
        size='small'
        value={columnType}
        exclusive
        onChange={(e, newValue: typeof columnType | null) => {
          if (newValue == null) return;
          setColumnType(newValue);
        }}
        sx={{ mb: '0.5rem' }}
      >
        <ToggleButton value={'kpi'}>Add KPIs</ToggleButton>
        <ToggleButton value={'metrics'}>Add Metrics</ToggleButton>
      </ToggleButtonGroup>
      {columnType === 'kpi' && <MprKpiColumnSettingsForm />}
      {columnType === 'metrics' && <MprMetricsColumnSettingsForm />}
    </Box>
  );
}
