import { Fragment, useMemo } from 'react';
import { SchemaDescription } from 'yup';
import { Chip, Collapse } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { colors } from '../../../theme/colors';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { FMT } from '../../../util/formatter-service';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderRow,
  StyledTableRow,
} from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { selectedFundStateFP } from '../state/FPState';
import { useFundMetricsFP } from '../useFundMetricsFP';
import { DistributionBar } from './DistributionBar';
import {
  getWaterfallData,
  getWaterfallOutput,
  WaterfallGridData,
  waterfallGridDataSchema,
} from './FPWaterfallData';
import { showWaterfallCalcs, WaterfallCalcsTableProto } from './WaterfallCalcsDisplay';
import { WaterfallModellingSummaryRow } from './WaterfallModellingSummaryRow';

export function WaterfallModellingGrid() {
  const fund = useAtomValue(selectedFundStateFP);
  const { metrics: fundMetrics } = useFundMetricsFP();
  const gridData = useMemo(() => {
    if (!fundMetrics) return [];
    return getWaterfallData(fundMetrics.metrics);
  }, [fundMetrics]);
  const fieldDescriptions = useMemo(() => {
    return Object.entries(waterfallGridDataSchema().omit(['lpPortion', 'gpPortion', 'key']).fields).map(
      ([fieldKey, value]) => ({
        key: fieldKey as keyof WaterfallGridData,
        desc: value.describe() as SchemaDescription,
      })
    );
  }, []);
  const rows = useMemo(() => {
    if (!gridData) return null;
    return gridData.map((row: WaterfallGridData) => (
      <StyledTableRow key={row.key}>
        {fieldDescriptions.map((f) => {
          const value = row[f.key];
          let type: 'text' | 'numeric' = 'text';
          let formattedValue = String(value ?? '');
          if (f.desc.type === 'number') {
            type = 'numeric';
            formattedValue = value != null ? FMT.format(f.desc.meta?.formatter || 'string', value) : '';
          }
          if (f.key === 'visualization') {
            return value ? <DistributionBar key={f.key} data={row} /> : <Fragment key={f.key}></Fragment>;
          }
          if (f.key === 'phase') {
            return (
              <Chip
                key={f.key}
                label={formattedValue}
                style={{ background: colors.tertiary[20], marginLeft: '-0.75rem' }}
              />
            );
          }
          return (
            <StyledTableCell key={f.key} type={type!}>
              {formattedValue}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    ));
  }, [gridData, fieldDescriptions]);
  const showCalcs = useAtomValue(showWaterfallCalcs);
  const { showWaterfallFormulas } = useFlags<MaggieFeatureFlags>();
  const output = useMemo(() => {
    if (!fundMetrics) return null;
    return getWaterfallOutput(fundMetrics.metrics);
  }, [fundMetrics]);
  if (!fundMetrics || !fund || !output) return null;

  return (
    <>
      <StyledTable style={{ gridTemplateColumns: '2fr repeat(5, 3fr) 5fr' }}>
        <StyledTableHeaderRow>
          {fieldDescriptions.map((f, i) => (
            <StyledTableCell
              key={f.key}
              style={i === 0 ? { paddingLeft: '1rem' } : undefined}
              type={f.desc.type === 'number' ? 'numeric' : 'text'}
              header
            >
              {f.desc.label}
            </StyledTableCell>
          ))}
        </StyledTableHeaderRow>
        {rows}

        <WaterfallModellingSummaryRow output={output} />
      </StyledTable>
      {showWaterfallFormulas && (
        <Collapse in={showCalcs}>
          {showCalcs ? (
            <WaterfallCalcsTableProto
              fundMetricsByFund={fundMetrics}
              gridData={[...gridData, output]}
              fund={fund}
            />
          ) : (
            <div style={{ height: '35rem' }} />
          )}
        </Collapse>
      )}
    </>
  );
}
