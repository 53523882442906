import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Stack, styled, Typography, TypographyProps } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { MUIAvatarGroup } from '../../../components/Avatar/MUIAvatarGroup';
import { IPersonDataModel } from '../../../data-models/person.data-model';
import { usersByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { FMT } from '../../../util/formatter-service';
import { DateFormattersConfig } from '../../../util/formatters/DateFormatters';
import { getActivities, PersonActivity } from '../utils/getPersonActivityData';
import { UnknownUser } from '../../../data-models/user.data-model';

function ActivitiesTitle({ children }: TypographyProps) {
  return (
    <Typography
      variant='caption'
      color='text.secondary'
      display={'flex'}
      alignItems={'center'}
      gap='0.25rem'
      mb='0.5rem'
      component={'div'}
    >
      {children}
    </Typography>
  );
}

const ActivitiesGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 3fr) 2fr;
`;

export function PersonActivities({ person }: { person: IPersonDataModel }) {
  const activities = useMemo(() => getActivities(person), [person]);

  const hasScheduled = activities.scheduledMeetings.length > 0;
  const hasOther =
    activities.firstEmail || activities.firstMeeting || activities.lastEmail || activities.lastMeeting;

  return (
    <>
      {hasScheduled && (
        <>
          <ActivitiesTitle>
            <CircleOutlinedIcon sx={{ color: 'warning.dark' }} fontSize='small' />
            Scheduled
          </ActivitiesTitle>
          {activities.scheduledMeetings.map((meeting) => (
            <ActivityItem key={meeting.createdAt} {...meeting} label='Future Meeting' />
          ))}
        </>
      )}
      {hasOther && (
        <>
          <ActivitiesTitle>
            <CircleOutlinedIcon sx={{ color: 'success.dark' }} fontSize='small' />
            Completed
          </ActivitiesTitle>
          {activities.firstEmail && <ActivityItem {...activities.firstEmail} label='First Email' />}
          {activities.lastEmail && <ActivityItem {...activities.lastEmail} label='Last Email' />}
          {activities.firstMeeting && <ActivityItem {...activities.firstMeeting} label='First Meeting' />}
          {activities.lastMeeting && <ActivityItem {...activities.lastMeeting} label='Last Meeting' />}
        </>
      )}
    </>
  );
}

interface IActivityItemProps extends PersonActivity {
  label: string;
}
function ActivityItem({ userIds, createdAt, label, primaryConnectionId }: IActivityItemProps) {
  const usersById = useAtomValue(usersByIdMapAtom);

  const primaryConnection = useMemo(() => {
    const user = usersById.get(primaryConnectionId ?? -1);
    if (!user) return <span />;
    return (
      <Stack direction={'row'} gap={'0.25rem'} alignItems={'center'}>
        <MUIAvatar nameParts={[user.firstName, user.lastName]} size={'small'} src={null} />
        <Typography variant='caption' color='text.secondary' noWrap>
          {user?.name ?? ''}
        </Typography>
      </Stack>
    );
  }, [primaryConnectionId, usersById]);

  const relations = useMemo(() => {
    return userIds?.filter(Boolean).map((userId) => {
      const user = usersById.get(userId) ?? UnknownUser;
      return <MUIAvatar key={userId} nameParts={[user.firstName, user.lastName]} size={'small'} src={null} />;
    });
  }, [userIds, usersById]);

  return (
    <ActivitiesGrid>
      <Typography variant='caption'>{label}</Typography>
      <Typography variant='caption' color='text.secondary'>
        {FMT.format(DateFormattersConfig.date, createdAt)}
      </Typography>
      {primaryConnection}
      <MUIAvatarGroup max={4} size={'small'} sx={{ height: '100%', alignItems: 'center' }}>
        {relations}
      </MUIAvatarGroup>
    </ActivitiesGrid>
  );
}
