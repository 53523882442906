import { ValueFormatterParams } from 'ag-grid-community';
import { ColumnMeta } from '../../../../../types';
import { AgFormatterService } from '../../../../../util/ag-formatter-service';
import { StandardFormatterId } from '../../../../../util/formatter-service';

// FIXME MAGGIE-7905 Replace these with new formatters id
type Formatter = 'date' | 'currency' | 'currencyAsInteger' | 'percent' | 'number' | 'multiplier' | 'integer';

type ColumnValueFormatter = (params: ValueFormatterParams) => string;

const deprecatedFormattersToNew: Record<Formatter, StandardFormatterId> = {
  date: 'date',
  number: 'numeric',
  currency: 'usd',
  currencyAsInteger: 'currencyRounded',
  percent: 'percent2dpAsIs',
  multiplier: 'multiplier',
  integer: 'integer',
};

export function getValueFormatter(column: ColumnMeta): ColumnValueFormatter | undefined {
  // FIXME MAGGIE-7905 After that ticket is done, delete this function & call FMT or GridFormatter
  const formatterId = deprecatedFormattersToNew[column.formatting as Formatter] ?? column.formatting;

  return AgFormatterService.get().getFormatterForId(formatterId as StandardFormatterId);
}
