import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { MetricsTransactionDataModel } from '../../../schemas/MetricsTransaction.schema';
import { fundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { getIsoDateColDef } from '../../../util/ag-grid-utils';
import { ColoredNumericRenderer } from '../common-grid-defs/ColoredNumericRenderer';
import { genericKeyCreator, getCurrencyColDefs, getIntegerColDefs } from '../common-grid-defs/commonColDefs';
import { eventFormatter } from './securityUtils';

export function useLedgerColDefs(): ColDef<MetricsTransactionDataModel>[] {
  const fundsById = useAtomValue(fundsByIdMapAtom);

  const defs: ColDef<MetricsTransactionDataModel>[] = [
    {
      headerName: 'Security',
      field: 'position',
      rowGroup: true,
      enableRowGroup: true,
      allowedAggFuncs: [],
      minWidth: 200,
      keyCreator: genericKeyCreator,
    },
    getIsoDateColDef({
      headerName: 'Event Date',
      field: 'transactionDate',
      initialSort: 'desc',
      keyCreator: genericKeyCreator,
    }),
    {
      headerName: 'Event',
      field: 'transType',
      valueFormatter: (params) => {
        return eventFormatter(params.value ?? '');
      },
    },
    {
      headerName: 'Fund',
      valueGetter: (params: ValueGetterParams<MetricsTransactionDataModel>) => {
        return fundsById.get(params.data?.fundId ?? -1)?.name ?? '';
      },
      enableRowGroup: true,
      keyCreator: genericKeyCreator,
      minWidth: 200,
    },
    {
      headerName: 'Investment Amount',
      field: 'investmentAmount',
      aggFunc: 'sum',
      ...getCurrencyColDefs(),
      cellRenderer: ColoredNumericRenderer,
    },
    {
      headerName: 'FMV',
      field: 'currentInvestment',
      aggFunc: 'sum',
      ...getCurrencyColDefs(),
      cellRenderer: ColoredNumericRenderer,
    },
    {
      headerName: 'Realized Value',
      field: 'distributions',
      aggFunc: 'sum',
      cellRenderer: ColoredNumericRenderer,
      ...getCurrencyColDefs(),
    },
    {
      headerName: 'Contribution to Escrow',
      field: 'escrowAmount',
      aggFunc: 'sum',
      cellRenderer: ColoredNumericRenderer,
      ...getCurrencyColDefs(),
    },
    {
      headerName: 'Number of Shares',
      field: 'quantity',
      aggFunc: 'sum',
      cellRenderer: ColoredNumericRenderer,
      ...getIntegerColDefs(),
    },
    { headerName: 'PPS', field: 'pps', colId: 'pps', ...getCurrencyColDefs() },
    getIsoDateColDef({
      headerName: 'Date Modified',
    }),
  ];

  return defs;
}
