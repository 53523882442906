import * as yup from 'yup';
import { dateField, exitTypeField, multiplierField, percentField, usdShortField } from './common-schema-defs';

export function companyFundMetricsFields() {
  return {
    acquisitionDate: yup.date(),
    acquisitionShares: yup.number(),
    initialInvestmentRound: yup.number(),
    amountInvested: usdShortField().label('Total Cost'),
    companyId: yup.number().required(),
    convertedSharesHeld: yup.number(),
    convertedOwnership: percentField().label('Ownership % (converted)'),
    customData: yup.object(),
    dilutionAssumption: yup.number(),
    distributions: usdShortField().label('Realized Value'),
    entryOwnership: percentField().label('Entry Ownership %'),
    exitDate: dateField(),
    exitOwnershipPercentage: percentField().label('Exit Ownership %'),
    exitType: exitTypeField(),
    exitValuation: usdShortField(),
    fmv: usdShortField().label('FMV'),
    fmvBasis: yup.number(),
    fundId: yup.number().required(),
    id: yup.number().required(),
    impliedEquityValue: usdShortField(),
    initialInvestmentDate: yup.string(),
    initialPreMoney: usdShortField(),
    initialSharePrice: yup.number(),
    initialSharesHeld: yup.number(),
    irr: percentField().label('IRR'),
    moic: multiplierField().label('MOIC'),
    noOfShares: yup.number(),
    ownerShipPercentage: percentField().label('Ownership %'),
    ownershipSource: yup.number(),
    projectedExitTiming: yup.number(),
    projectedMOIC: yup.number(),
    projectedProceeds: yup.number(),
    reserves: usdShortField(),
    percentOfFund: percentField().label('Percentage of Fund'),
    percentTotalValue: percentField(),
    rtfe: usdShortField().label('RTFE'),
  };
}

export function companyFundMetricsFormSchema() {
  return yup.object().shape(companyFundMetricsFields());
}

export type CompanyFundMetricsDataModel = yup.InferType<ReturnType<typeof companyFundMetricsFormSchema>>;

export type CompanyFundMetricsField = keyof ReturnType<typeof companyFundMetricsFields>;
