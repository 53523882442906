import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { object, SchemaDescription } from 'yup';
import {
  StyledTableHeaderRow,
  StyledTableCell,
  StyledTableRow,
} from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { FMT } from '../../../util/formatter-service';
import { WaterfallGridData, waterfallGridDataFields } from './FPWaterfallData2';

export function waterfallModellingSummaryDataFields() {
  const { lpDistributed, gpDistributed, gpCarry } = waterfallGridDataFields();
  return {
    lpDistributed: lpDistributed.label('LP NAV'),
    gpDistributed: gpDistributed.label('GP NAV'),
    gpCarry: gpCarry.label(''), // ??
  };
}

export function waterfallModellingSummaryDataSchema() {
  return object().shape(waterfallModellingSummaryDataFields());
}

interface IWaterfallModellingSummaryRowProps {
  lpDistributed: number | null;
  gpDistributed: number | null;
  gpCarry: number | null;
}
export function WaterfallModellingSummaryRow({ output }: { output: IWaterfallModellingSummaryRowProps }) {
  const { colors } = useTheme();

  const fieldDescriptionsMap = useMemo(() => {
    return new Map(
      Object.entries(
        waterfallModellingSummaryDataSchema().pick(['lpDistributed', 'gpDistributed', 'gpCarry']).fields
      ).map(([fieldKey, value]) => [
        fieldKey as keyof WaterfallGridData,
        value.describe() as SchemaDescription,
      ])
    );
  }, []);

  return (
    <>
      <StyledTableHeaderRow style={{ marginTop: '1rem' }}>
        <StyledTableCell style={{ gridColumn: 4 }} type={'numeric'} header>
          {fieldDescriptionsMap.get('lpDistributed')!.label}
        </StyledTableCell>
        <StyledTableCell style={{ gridColumn: 5 }} type='numeric' header>
          {fieldDescriptionsMap.get('gpDistributed')!.label}
        </StyledTableCell>
        <StyledTableCell style={{ gridColumn: 6 }} type='numeric' header>
          {fieldDescriptionsMap.get('gpCarry')!.label}
        </StyledTableCell>
      </StyledTableHeaderRow>

      <StyledTableRow style={{ background: colors.primary[10] }}>
        <>
          {output.lpDistributed != null ? (
            <div style={{ gridColumn: 4 }}>
              <StyledTableCell type={'numeric'}>
                {FMT.format(
                  fieldDescriptionsMap.get('lpDistributed')!.meta!.formatter!,
                  output.lpDistributed
                )}
              </StyledTableCell>
            </div>
          ) : (
            <StyledTableCell style={{ gridColumn: 4 }} type={'numeric'}>
              -
            </StyledTableCell>
          )}
        </>
        <StyledTableCell style={{ gridColumn: 5 }} type={'numeric'}>
          {output.gpDistributed != null
            ? FMT.format(fieldDescriptionsMap.get('gpDistributed')!.meta!.formatter!, output.gpDistributed)
            : '-'}
        </StyledTableCell>
        <StyledTableCell style={{ gridColumn: 6 }} type={'numeric'}>
          {output.gpCarry != null
            ? FMT.format(fieldDescriptionsMap.get('gpCarry')!.meta!.formatter!, output.gpCarry)
            : '-'}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
