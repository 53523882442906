import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { CommentCreation } from '../../../../components/Comment/CommentCreation';
import { CommentList } from '../../../../components/Comment/CommentsList';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { companyStateJ } from '../../../../services/state/CompanyStateJ';
import { useCreateComment } from '../../hooks/useCreateComment';
import { useDeleteComment } from '../../hooks/useDeleteComment';
import { useUpdateComment } from '../../hooks/useUpdateComment';
import { isPresentModeState, selectedDealState } from '../../state/DealboardUIState';
import { CheckList } from '../CheckList/CheckList';
import { DataSection } from './DataSection/DataSection';
import { DrawerHeader } from './DrawerHeader';

const Container = styled('div')<{ presentModeOn: boolean }>`
  width: 900px;
  height: 100%;
  padding: 24px;
  background: #ffffff;
  padding-top: ${({ presentModeOn }) => (presentModeOn ? '65px' : 'none')};
`;

const ContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 10px;
  scrollbar-gutter: stable;
  max-height: calc(100vh - 12.5rem);
  overflow-y: auto;
`;

const ListAndCommentsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

export const DealDrawer = () => {
  const { colors } = useTheme();
  const selectedDeal = useAtomValue(selectedDealState);
  const presentModeOn = useAtomValue(isPresentModeState);
  const company = useAtomValue(companyStateJ(selectedDeal?.companyId ?? -1));
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  const { createDealComment, isLoading: isLoadingUpdate } = useCreateComment();
  const deleteComment = useDeleteComment();
  const { updateDealComment, loadingId } = useUpdateComment();

  const commentsWithLoadingStatus = useMemo(
    () => selectedDeal?.comments.map((c) => ({ ...c, isLoading: loadingId === c.id })),
    [loadingId, selectedDeal?.comments]
  );

  const handleCreate = (comment: string) => {
    if (!selectedDeal) return;
    const commentPayload = { dealId: selectedDeal.id, comment };
    createDealComment(commentPayload);
  };

  const handleDelete = useCallback(
    (commentId: number) => {
      if (!selectedDeal) return;
      deleteComment({ commentId, dealId: selectedDeal.id });
    },
    [deleteComment, selectedDeal]
  );

  const handleUpdate = useCallback(
    async (comment: string, commentId: number) => {
      if (!selectedDeal) return;

      updateDealComment({
        comment,
        commentId,
        dealId: selectedDeal.id,
      });
    },
    [selectedDeal, updateDealComment]
  );

  if (!selectedDeal || !company) return null;

  return (
    <Container presentModeOn={presentModeOn} key={selectedDeal.id}>
      <DrawerHeader selectedDeal={selectedDeal} company={company} />
      <ContentWrapper>
        <DataSection deal={selectedDeal} company={company} />
        <ListAndCommentsWrapper>
          <CheckList selectedDeal={selectedDeal} />
          <Typography color={colors.primary[100]} variant='subtitle2'>
            Comments
          </Typography>
          {canEditDeal && <CommentCreation onCreate={handleCreate} isLoading={isLoadingUpdate} />}
          <CommentList
            comments={commentsWithLoadingStatus ?? []}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
          />
        </ListAndCommentsWrapper>
      </ContentWrapper>
    </Container>
  );
};
