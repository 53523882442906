import { MenuItem, Select } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEqual, noop } from 'lodash-es';
import { FC, useCallback, useMemo, useState } from 'react';
import { CompanyAutocomplete } from '../../../../../components/CompanyAutocomplete/CompanyAutocomplete';
import { FundSelector } from '../../../../../components/Form/FundSelector';
import { RoundSelector } from '../../../../../components/Form/RoundSelector';
import { SectorSelector } from '../../../../../components/Form/SectorSelector';
import { UserSelector } from '../../../../../components/Form/UserSelector';
import { FormTextField } from '../../../../../components/FormField/FormTextField';
import { LabelInput } from '../../../../../components/LabelInput/LabelInput';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { useUpdateCompanyAndState } from '../../../../../services/hooks/useUpdateCompanyAndState';
import { LoadingId } from '../../../../../types';
import { formatLocation } from '../../../../../util/location-formatter';
import { useUpdateDeal2 } from '../../../hooks/useDealActions';
import { useLabelActions } from '../../../hooks/useLabelActions';
import { dealLabelsState } from '../../../state/DealboardDataState';
import { DrawerLabel, MultiSelectDrawerLabel } from './commonDrawerComponents';

enum SourceType {
  Referral = 'Referral',
  Direct = 'Direct',
  Outbound = 'Outbound',
}

interface GeneralInfoProps {
  company: ICompanyDataModel;
  deal: IDealDataModel;
  disableEdit?: boolean;
}

export const GeneralInfo2: FC<GeneralInfoProps> = ({ company, deal, disableEdit }) => {
  const updateCompany = useUpdateCompanyAndState();
  const updateDeal = useUpdateDeal2();
  const location = {
    city: company.city ?? '',
    country: company.country ?? '',
    state: company.state ?? '',
  };
  const { actions } = useLoadingBarState();

  const [dealTeamIds, setDealTeamIds] = useState(deal.dealTeamIds ?? []);
  const [fundIds, setFundIds] = useState(deal.fundIds ?? []);
  const [sourceCompany, setSourceCompany] = useState(company?.sourceCompany ?? null);
  const [dealLabels, setDealLabels] = useState<string[]>(deal?.labels ?? []);

  const onUpdateCompany = useCallback(
    async (payload: Partial<ICompanyDataModel>) => {
      actions.startLoading(LoadingId.updateCompany);
      await updateCompany(company.id, { ...payload });
      actions.stopLoading(LoadingId.updateCompany);
    },
    [actions, company, updateCompany]
  );

  const onUpdateDeal = useCallback(
    async (payload: Partial<IDealDataModel>) => {
      actions.startLoading(LoadingId.updateDeal);
      await updateDeal(deal, { ...payload });
      actions.stopLoading(LoadingId.updateDeal);
    },
    [actions, deal, updateDeal]
  );

  const allLabelsLoadable = useAtomValue(dealLabelsState);
  const labelOptions = useMemo(() => {
    return allLabelsLoadable.state === 'hasData'
      ? allLabelsLoadable.data?.map((l) => ({ id: l.name, value: l.name }))
      : [];
  }, [allLabelsLoadable]);
  const { handleCreateLabel } = useLabelActions();
  const onChangeLabels = useCallback(
    (vals: string[]) => {
      if (isEqual(vals, dealLabels) || (!vals?.length && !dealLabels?.length)) return;
      setDealLabels(vals);
    },
    [dealLabels]
  );

  const [ceoValues, setCeoValues] = useState<string[]>(() =>
    company.ceoName?.length ? company.ceoName.split(',').map((name) => name.trim()) : []
  );

  const ceoOptions = useMemo(() => {
    return ceoValues.map((c) => ({ id: c, value: c, label: c }));
  }, [ceoValues]);

  const onCeoChange = useCallback(
    (newValue: string[]) => {
      setCeoValues(newValue);
      onUpdateCompany({ ceoName: newValue.length ? newValue.join(', ') : '' });
    },
    [onUpdateCompany]
  );

  const onAddCeo = useCallback(
    (newCeo: string) => {
      onCeoChange([...ceoValues, newCeo]);
    },
    [ceoValues, onCeoChange]
  );

  const onCreateLabel = useCallback(
    async (newLabel: string) => {
      onChangeLabels([...dealLabels, newLabel]); // optimistic update
      const createdLabel = await handleCreateLabel(newLabel);
      if (!createdLabel) onChangeLabels(dealLabels); // undo optimistic update
    },
    [dealLabels, handleCreateLabel, onChangeLabels]
  );

  return (
    <>
      <DrawerLabel>Sector</DrawerLabel>
      <SectorSelector
        className='form-select'
        disabled={disableEdit}
        value={company.sectorId}
        onChange={(e) => onUpdateCompany({ sectorId: (e.target.value as number) ?? null })}
      />
      <MultiSelectDrawerLabel>Fund</MultiSelectDrawerLabel>
      <FundSelector
        multiple
        className='form-inline'
        disabled={disableEdit}
        value={fundIds ?? []}
        onChange={(newValue) => {
          if (!newValue) return;
          setFundIds(newValue as number[]);
        }}
        onBlur={() => {
          onUpdateDeal({ fundIds });
        }}
        disableCloseOnSelect
      />
      <DrawerLabel>Stage</DrawerLabel>
      <RoundSelector
        blurOnSelect
        className='form-inline'
        disabled={disableEdit}
        value={deal.roundId}
        onChange={(newValue) => {
          onUpdateDeal({ roundId: (newValue as number) ?? null });
        }}
      />
      <DrawerLabel>Deal Lead</DrawerLabel>
      <UserSelector
        blurOnSelect
        className='form-inline'
        disabled={disableEdit}
        value={deal.dealLeadId}
        onChange={(newValue) => {
          if (!newValue) return;
          onUpdateDeal({ dealLeadId: newValue as number });
        }}
      />
      <MultiSelectDrawerLabel>Deal Team</MultiSelectDrawerLabel>
      <UserSelector
        multiple
        className='form-inline'
        disabled={disableEdit}
        value={dealTeamIds ?? []}
        onChange={(newValue) => {
          setDealTeamIds(newValue as number[]);
        }}
        onBlur={() => {
          onUpdateDeal({ dealTeamIds });
        }}
        autoHighlight
        disableCloseOnSelect
      />
      <MultiSelectDrawerLabel>Description</MultiSelectDrawerLabel>
      <FormTextField
        multiline
        className='transparent'
        defaultValue={company.shortDescription}
        disabled={disableEdit}
        onValueChanged={(newValue) => {
          onUpdateCompany({ shortDescription: newValue || null });
        }}
      />
      <DrawerLabel>Website</DrawerLabel>
      <FormTextField
        className='transparent'
        defaultValue={company.website}
        disabled={disableEdit || company.crunchbaseId !== null}
        onValueChanged={(newValue) => {
          onUpdateCompany({ website: newValue || null });
        }}
      />

      <MultiSelectDrawerLabel>CEO</MultiSelectDrawerLabel>
      <LabelInput
        fullWidth
        value={ceoValues}
        onChange={onCeoChange}
        onCreateLabel={onAddCeo}
        multiple
        options={ceoOptions}
        createPrompt='Add'
        disableCloseOnSelect={false}
        showMenuHelperText={false}
        textFieldSx={{ marginTop: '-.5rem' }}
        className='transparent form-inline'
      />

      <DrawerLabel>Location</DrawerLabel>
      <FormTextField
        className='transparent'
        defaultValue={formatLocation(location)}
        disabled={true}
        onValueChanged={noop}
      />

      <DrawerLabel>Internal Source</DrawerLabel>
      <UserSelector
        blurOnSelect
        className='form-inline'
        disabled={disableEdit}
        value={company.internalSourceId}
        onChange={(newValue) => {
          if (!newValue) return;
          onUpdateCompany({ internalSourceId: (newValue as number) ?? null });
        }}
      />

      <DrawerLabel>Source</DrawerLabel>
      <FormTextField
        className='transparent'
        defaultValue={company.source}
        disabled={disableEdit}
        onValueChanged={(newValue) => {
          onUpdateCompany({ source: newValue || null });
        }}
      />
      <DrawerLabel>Source Type</DrawerLabel>
      <Select
        className='form-select'
        disabled={disableEdit}
        value={company.sourceType ?? ''}
        onChange={(e) => onUpdateCompany({ sourceType: e.target.value || null })}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={(value) => (!value || value === 'None' ? '' : value)}
      >
        <MenuItem value={SourceType.Referral}>{SourceType.Referral}</MenuItem>
        <MenuItem value={SourceType.Direct}>{SourceType.Direct}</MenuItem>
        <MenuItem value={SourceType.Outbound}>{SourceType.Outbound}</MenuItem>
      </Select>

      <DrawerLabel>Source Company</DrawerLabel>
      <CompanyAutocomplete
        className='form-inline transparent'
        disabled={disableEdit}
        value={sourceCompany ?? undefined}
        onChange={(newValue) => {
          setSourceCompany(newValue ?? null);
        }}
        onBlur={() => {
          onUpdateCompany({ sourceCompany: sourceCompany ?? null });
        }}
        sx={{ margin: 0 }}
      />

      <MultiSelectDrawerLabel>Labels</MultiSelectDrawerLabel>
      <LabelInput
        options={labelOptions}
        onCreateLabel={onCreateLabel}
        value={dealLabels}
        onChange={onChangeLabels}
        textFieldSx={{ marginTop: '-.5rem' }}
        loading={allLabelsLoadable.state === 'loading'}
        className='transparent form-inline'
        onBlur={() => {
          onUpdateDeal({ labels: dealLabels });
        }}
      />
    </>
  );
};
