import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { getFundMetrics, getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { LoadingId } from '../../../types';
import { forceFundMetricsUpdate } from '../../PortfolioOverview/state/MetricsState';
import { selectedDateFPState } from '../state/FPState';
import { useFundMetricsFP } from '../useFundMetricsFP';

export function useInvalidateFundMetrics() {
  const { actions } = useLoadingBarState();
  const { setSelectedFundMetrics: setSelectedFundMetrics } = useFundMetricsFP();
  const setForceFundMetricsUpdate = useSetRecoilState(forceFundMetricsUpdate);

  const invalidateFundMetrics = useAtomCallback(
    useCallback(
      async (get, set, { date, fundId }: { date: string; fundId: number }) => {
        try {
          actions.startLoading(LoadingId.updateFundMetrics);
          const selectedDate = get(selectedDateFPState);
          const selectedDateIso = getPlainDateString(selectedDate);
          const displayedFundMetrics = await getFundMetrics(selectedDateIso, [fundId]);
          setSelectedFundMetrics(displayedFundMetrics);
          if (selectedDateIso !== date) {
            getFundMetrics(date, [fundId]).then((fundMetrics) => {
              setSelectedFundMetrics(fundMetrics);
            });
          }
          setForceFundMetricsUpdate((curr) => curr + 1);
        } catch (err) {
          console.error(err);
        } finally {
          actions.stopLoading(LoadingId.updateFundMetrics);
        }
      },
      [actions, setForceFundMetricsUpdate, setSelectedFundMetrics]
    )
  );
  return invalidateFundMetrics;
}
