import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { ILabelDataModel } from '../../../data-models/deal-label.data-model';
import { createDealLabel } from '../../../services/queries/MaggieDealQueries';
import { _dealLabelsState } from '../state/DealboardDataState';

export function useLabelActions() {
  const { pushErrorToast } = useToastMessageState();

  const handleCreateLabel = useAtomCallback(
    useCallback(
      async (get, set, labelName: string) => {
        const labels: ILabelDataModel[] = [];

        const dealLabels = await get(_dealLabelsState);
        if (dealLabels) labels.push(...dealLabels);

        const updatedLabels = labels.concat({ name: labelName });
        set(_dealLabelsState, Promise.resolve(updatedLabels));

        try {
          const newLabel = await createDealLabel(labelName);
          if (newLabel) {
            return newLabel;
          }
        } catch (err) {
          console.error(err);
          set(_dealLabelsState, Promise.resolve(dealLabels));
          pushErrorToast({ message: 'Failed to create label' });
        }
      },
      [pushErrorToast]
    )
  );

  return {
    handleCreateLabel,
  };
}
