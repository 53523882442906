import { isErrorValue } from './FormattedComponent';
import { GetFormatterProps, getFormatter } from './getFormatter';
import { isObject } from './object-util';

interface Props extends GetFormatterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

/** @deprecated  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleDeprecatedCases = (value: any) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  } else if (isObject(value)) {
    return '?';
  }
  return value;
};

export const getFormattedValue = ({ type, formatting, value }: Props): string | null => {
  const formatter = getFormatter({ type, formatting });

  if (isErrorValue(value)) {
    return value;
  } else if (!formatter) {
    return handleDeprecatedCases(value);
  } else if (value === null) {
    return null;
  } else if (value === undefined) {
    return '';
  }
  return formatter(value);
};
