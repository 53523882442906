import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { GetRowIdParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockerFunction } from 'react-router';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { FormLoadingButton } from '../../../../../components/Form/FormComponents';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { colors } from '../../../../../theme/colors';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { companyProfileFinancialsDefaultColDef } from '../../../commonGridColDefs';
import { NavigationBlocker } from '../../../Scenarios/SingleScenario/block-navigation/NavigationBlocker';
import { getEachDateByPeriodAndFrequency } from '../../../utils/financialUtils';
import { selectedEndDateFinancialsState } from '../../FinancialsDatePickers/useFinancialsDateSettings';
import { useBulkUpdateFinancials } from '../hooks/useCustomKPIActions';
import { useFinancialsColumnDefs } from '../hooks/useFinancialsColumnDefs';
import { IFinancialsRowData2, useFinancialsGridData } from '../hooks/useFinancialsGridData';
import { useFinancialsGridRowHeight } from '../hooks/useFinancialsGridRowHeight';
import { PinnedYearColumnTag } from '../pinnedYears/usePinnedYearColDefs';
import { financialsGridApiState, useVisibleColumns } from '../pinnedYears/useVisibleColumns';
import { useFinancialsGridSettings } from './FinancialsSettingsState';
import { useFinancialsSortState } from './financialsSortState';

const rowBg = `${colors.primary[0]}55`;

export const finTableStyles = css`
  padding-top: 1rem;
  height: 70vh;
  --ag-borders: none;
  .ag-root-wrapper {
    --ag-row-background-color: ${rowBg};
    --ag-odd-row-background-color: ${rowBg};
    --ag-background-color: ${rowBg};
    // we can remove this if we decide to pass columnBorder: true to theme
    .ag-cell {
      border-right: 1px solid ${colors.neutral[20]};
    }
    .ag-pinned-right-cols-container {
      background: ${colors.primary[20]}22;
    }
    .${PinnedYearColumnTag} {
      background: ${colors.primary[20]}88;
      &:hover {
        background-color: ${colors.primary[20]}99 !important;
      }
    }
  }
  .centered-header .ag-header-cell-label {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
`;

export type CompanyProfileFinancialsTableProps = {
  totalSelectedPeriods: number;
  frequency: KpiPeriod;
  fye: number;
};

const dataChangedAtom = atom(false);

export function CompanyProfileFinancialsTable({
  totalSelectedPeriods,
  frequency,
  fye,
}: CompanyProfileFinancialsTableProps) {
  const selectedEndDate = useAtomValue(selectedEndDateFinancialsState)!;
  const allPeriods = useMemo(
    () => getEachDateByPeriodAndFrequency({ totalSelectedPeriods, frequency, fye, endDate: selectedEndDate }),
    [totalSelectedPeriods, frequency, fye, selectedEndDate]
  );
  const [gridApi, setGridApi] = useAtom(financialsGridApiState);
  const { useBatchUpdateFinancials } = useFlags<MaggieFeatureFlags>();

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      setGridApi(e.api);
    },
    [setGridApi]
  );
  const onGridPreDestroyed = useCallback(() => {
    setGridApi(null);
  }, [setGridApi]);

  const { onColumnVisible } = useVisibleColumns();
  const { displaySettings } = useFinancialsGridSettings();

  const getRowData = useFinancialsGridData();
  const rowData = useMemo(
    () => getRowData(frequency, displaySettings),
    [getRowData, frequency, displaySettings]
  );

  const { columnDefs } = useFinancialsColumnDefs({
    allPeriods: allPeriods.dates,
    frequency,
    options: displaySettings,
  });

  const { getRowHeight } = useFinancialsGridRowHeight(gridApi, displaySettings);

  const getRowId = useCallback((params: GetRowIdParams<IFinancialsRowData2>) => {
    return String(params.data?.id);
  }, []);

  const { onSortChanged, resetSort } = useFinancialsSortState();
  useEffect(() => {
    return resetSort;
  }, [resetSort]);

  const setDataChanged = useSetAtom(dataChangedAtom);
  const onCellValueChanged = useCallback(() => {
    setDataChanged(true);
  }, [setDataChanged]);

  return (
    <>
      {useBatchUpdateFinancials && <SaveFinancialsButton frequency={frequency} initialRowData={rowData} />}
      <div css={finTableStyles}>
        <AgTable
          key={`${totalSelectedPeriods}-${frequency}`}
          columnDefs={columnDefs}
          defaultColDef={companyProfileFinancialsDefaultColDef}
          groupDefaultExpanded={-1}
          groupDisplayType='groupRows'
          rowData={rowData}
          rowGroupPanelShow='never'
          suppressMovableColumns={true}
          suppressRowDrag={true}
          getRowHeight={getRowHeight}
          suppressScrollOnNewData
          suppressCsvExport
          suppressExcelExport
          getRowId={getRowId}
          onSortChanged={onSortChanged}
          suppressColumnMoveAnimation // prevent pinned menu popover from being misplaced
          onGridReady={onGridReady}
          onGridPreDestroyed={onGridPreDestroyed}
          onColumnVisible={onColumnVisible}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
    </>
  );
}

function SaveFinancialsButton({
  frequency,
  initialRowData,
}: {
  frequency: KpiPeriod;
  initialRowData: IFinancialsRowData2[];
}) {
  const gridApi = useAtomValue(financialsGridApiState);
  const [dataChanged, setDataChanged] = useAtom(dataChangedAtom);
  const { shouldBlock } = useBlockNavigation(dataChanged);
  const saveKpiData = useSaveFinancials({ frequency, initialRowData, gridApi });
  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(async () => {
    if (!dataChanged) return;
    setLoading(true);
    await saveKpiData();
    setDataChanged(false);
    setLoading(false);
  }, [dataChanged, saveKpiData, setDataChanged]);

  return (
    <Box display={'flex'} justifyContent={'end'} mb={'-1rem'}>
      <NavigationBlocker shouldBlock={shouldBlock} />
      <FormLoadingButton disabled={!dataChanged || loading} loading={loading} onClick={handleSave}>
        Save
      </FormLoadingButton>
    </Box>
  );
}

function useSaveFinancials({
  frequency,
  initialRowData,
  gridApi,
}: {
  frequency: KpiPeriod;
  initialRowData: IFinancialsRowData2[];
  gridApi: GridApi<IFinancialsRowData2> | null;
}) {
  const batchUpdateFinancials = useBulkUpdateFinancials();

  return useCallback(async () => {
    if (!gridApi || !initialRowData.length) return;
    const rowData: IFinancialsRowData2[] = [];
    gridApi.forEachNode((node) => {
      if (node.data) rowData.push(node.data);
    });
    await batchUpdateFinancials({
      initialRowData,
      period: frequency,
      updatedGridRowData: rowData,
    });
  }, [batchUpdateFinancials, frequency, gridApi, initialRowData]);
}

function useBlockNavigation(block: boolean) {
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return block && currentLocation.pathname !== nextLocation.pathname;
    },
    [block]
  );

  return { shouldBlock };
}
