import { useCallback } from 'react';
import { getForesightStore } from '../util/jotai-store';
import { companyStateJ } from '../services/state/CompanyStateJ';

export function useCombineCompaniesWithData() {
  const store = getForesightStore();
  const mergeData = useCallback(
    async (data: { companyId: number }[]) => {
      const result = data.map((dataItem) => {
        const company = store.get(companyStateJ(dataItem.companyId));
        return {
          ...dataItem,
          company,
        };
      });
      return result;
    },
    [store]
  );

  return mergeData;
}
