import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { motion } from 'framer-motion';
import { LoadingBar } from '../../../../components/LoadingBar/LoadingBar';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { companyStateLoadable } from '../../../../services/state/CompanyStateJ';
import { selectedDealState } from '../../state/DealboardUIState';
import { CheckList } from '../CheckList/CheckList2';
import { Panel } from '../../../CompanyProfiles2/Summary/Panel';
import { DealCompanyPanel } from './DataSection/DealCompanyPanel';
import { DealCustomDataSection } from './DataSection/DealCustomFields2';
import { MainDealPanel } from './DataSection/MainDealPanel';
import { DrawerHeader } from './DrawerHeader';
import { DealComments } from './DealComments2';

const Container = css`
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  height: 100%;
  max-height: 100%;
  align-items: start;
`;

const ContentWrapper = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 1rem;
  scrollbar-gutter: stable;
  overflow-y: auto;
  max-height: 100%;
`;

export const DealDrawer = () => {
  const selectedDeal = useAtomValue(selectedDealState);
  const company = useAtomValue(companyStateLoadable(selectedDeal?.companyId ?? -1));

  if (!selectedDeal || company.state != 'hasData' || !company.data) return null;
  const editable = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  return (
    <motion.div
      css={Container}
      key={selectedDeal.id}
      animate={{ opacity: [0.6, 1] }}
      transition={{ duration: 0.5 }}
    >
      <DrawerHeader selectedDeal={selectedDeal} company={company.data} />
      <LoadingBar />
      <div css={ContentWrapper}>
        <Box gridColumn='1 / 3'>
          <Panel title='Comments'>
            <DealComments deal={selectedDeal} />
          </Panel>
        </Box>
        <DealCompanyPanel deal={selectedDeal} editable={editable} />

        <MainDealPanel deal={selectedDeal} />
        <DealCustomDataSection deal={selectedDeal} editable={editable} />
        <CheckList deal={selectedDeal} editable={editable} />
      </div>
    </motion.div>
  );
};
