import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue, useSetAtom } from 'jotai';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { companyStateJ } from '../../../../services/state/CompanyStateJ';
import { DealBoardDealCategory } from '../../../../types';
import { ColumnStage } from '../../data-models/columnStage.data-model';
import {
  dealBoardConfigState,
  dealsByCategoryState,
  dealStageIdToCategoryState,
} from '../../state/DealboardDataState';
import {
  dealBoardSettingsState,
  isOpenDrawerState,
  selectedDealCategoryState,
  selectedDealIdState,
  selectedDealState,
  selectedFieldState,
} from '../../state/DealboardUIState';
import { getDealCategory } from '../../utils/getDealCategory';
import { getCurrentDealPosition, getNextDeal, getPrevDeal } from './dealNavigationUtils';

const Navbar = styled('div')`
  margin-top: 1rem;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  height: 2rem;
`;

const NavButtonsContainer = styled('div')`
  display: flex;
  gap: 1rem;
  & > :last-child {
    margin-left: 0.8rem;
  }
`;

export const DrawerNavigation: FC = () => {
  const selectedDeal = useAtomValue(selectedDealState);
  const setSelectedDealId = useSetAtom(selectedDealIdState);
  const setIsOpenDrawer = useSetAtom(isOpenDrawerState);
  const dealBoardSettings = useAtomValue(dealBoardSettingsState);
  const activeField = useAtomValue(selectedFieldState);
  const selectedDealCategory = useAtomValue(selectedDealCategoryState);
  const { dealStages } = useAtomValue(dealBoardConfigState);
  const dealsByCategory = useAtomValue(
    dealsByCategoryState(selectedDealCategory.id as DealBoardDealCategory)
  );
  const dealStageIdToCategory = useAtomValue(dealStageIdToCategoryState);
  const { colors } = useTheme();

  const company = useAtomValue(companyStateJ(selectedDeal?.companyId ?? -1));
  const companyName = company!.name;

  const dealIsInCurrentDealsCategory = useMemo(() => {
    if (!selectedDeal) return false;
    return getDealCategory(dealStageIdToCategory, selectedDeal.stageId) === DealBoardDealCategory.CURRENT;
  }, [dealStageIdToCategory, selectedDeal]);

  const currentDealsCategoryDealBoardState = useMemo(() => {
    return [{ stage: { id: selectedDeal?.stageId }, deals: dealsByCategory }];
  }, [dealsByCategory, selectedDeal?.stageId]);

  const currentPosition: { columnIndex: number; dealIndex: number } | null = useMemo(() => {
    if (!selectedDeal) return null;
    if (!dealIsInCurrentDealsCategory)
      return getCurrentDealPosition(currentDealsCategoryDealBoardState as ColumnStage[], selectedDeal);
    return getCurrentDealPosition(dealBoardSettings, selectedDeal);
  }, [currentDealsCategoryDealBoardState, dealBoardSettings, dealIsInCurrentDealsCategory, selectedDeal]);

  const prevDeal = useMemo(() => {
    if (!dealIsInCurrentDealsCategory) {
      return getPrevDeal(currentDealsCategoryDealBoardState as ColumnStage[], selectedDeal);
    }
    return getPrevDeal(dealBoardSettings, selectedDeal);
  }, [currentDealsCategoryDealBoardState, dealBoardSettings, dealIsInCurrentDealsCategory, selectedDeal]);

  const prevDisabled = useMemo(() => {
    return prevDeal === null;
  }, [prevDeal]);

  const nextDeal = useMemo(() => {
    if (!dealIsInCurrentDealsCategory) {
      return getNextDeal(currentDealsCategoryDealBoardState as ColumnStage[], selectedDeal);
    }
    return getNextDeal(dealBoardSettings, selectedDeal);
  }, [currentDealsCategoryDealBoardState, dealBoardSettings, dealIsInCurrentDealsCategory, selectedDeal]);

  const nextDisabled = useMemo(() => {
    return nextDeal === null;
  }, [nextDeal]);

  const handlePrev = useCallback(() => {
    if (prevDeal) setSelectedDealId(prevDeal.id);
  }, [prevDeal, setSelectedDealId]);

  const handleNext = useCallback(() => {
    if (nextDeal) setSelectedDealId(nextDeal.id);
  }, [nextDeal, setSelectedDealId]);

  const breadcrumbsText = useMemo(() => {
    if (!currentPosition || !selectedDeal) return '';
    const stage = !dealIsInCurrentDealsCategory
      ? dealStages.find((s) => s.id === selectedDeal.stageId)
      : dealBoardSettings[currentPosition.columnIndex].stage;
    return `${stage?.displayName} / ${companyName}`;
  }, [
    currentPosition,
    selectedDeal,
    dealIsInCurrentDealsCategory,
    dealStages,
    dealBoardSettings,
    companyName,
  ]);

  useEffect(() => {
    const downHandler = ({ key }: KeyboardEvent) => {
      if (activeField) return;
      if (key === 'ArrowLeft') leftArrowHandler();
      if (key === 'ArrowRight') rightArrowHandler();
    };

    const leftArrowHandler = () => {
      if (!prevDisabled) handlePrev();
    };

    const rightArrowHandler = () => {
      if (!nextDisabled) handleNext();
    };

    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [activeField, handleNext, handlePrev, nextDisabled, prevDisabled]);

  if (!currentPosition || !selectedDeal) return null;

  return (
    <Navbar data-testid='drawer-navigation'>
      <Typography variant={'caption'} color={colors.neutral[60]}>
        {breadcrumbsText}
      </Typography>
      <NavButtonsContainer>
        <IconButton onClick={handlePrev} disabled={prevDisabled}>
          <ArrowBackIosNewIcon htmlColor={prevDisabled ? colors.neutral[60] : colors.primary[60]} />
        </IconButton>

        <IconButton onClick={handleNext} disabled={nextDisabled}>
          <ArrowForwardIosIcon htmlColor={nextDisabled ? colors.neutral[60] : colors.primary[60]} />
        </IconButton>

        <IconButton onClick={() => setIsOpenDrawer(false)} aria-label='close drawer'>
          <CloseIcon htmlColor={colors.secondary[60]} />
        </IconButton>
      </NavButtonsContainer>
    </Navbar>
  );
};
