import { useAtomCallback } from 'jotai/utils';
import { useCallback, useEffect, useState } from 'react';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { dealSort, fetchDealsByCompanyIds } from '../../../../services/queries/MaggieDealQueries';
import { DealsByCompanyIdState, DealsForCompanyLoadedState } from '../../../../services/state/DealState';
import { allDealsState } from '../../../DealFlow2/state/DealboardDataState';

const companyDataLoadedOrLoading = new Set<number>();

// Use this hook to acquire the company deals data and ensure it remains synced with
// the deals from deal board as the user navigates through the page.
export function useFetchDealsForCompany(companyId: number, autoInit = true) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const permSvc = PermissionService.get();

  const initData = useAtomCallback(
    useCallback(
      async (get, set) => {
        if (companyDataLoadedOrLoading.has(companyId)) {
          return get(DealsByCompanyIdState(companyId));
        }

        companyDataLoadedOrLoading.add(companyId);

        const companyDeals = permSvc.hasPermission(PermissionKey.canViewDeal)
          ? await fetchDealsByCompanyIds([companyId])
          : [];
        set(DealsForCompanyLoadedState(companyId), true);

        if (get(allDealsState)) {
          set(allDealsState, (currentDeals) => {
            const updatedDeals = currentDeals.filter((deal) => deal.companyId !== companyId);
            updatedDeals.push(...(companyDeals as IDealDataModel[]));
            return updatedDeals.sort(dealSort);
          });
        } else {
          set(allDealsState, companyDeals);
          return companyDeals;
        }
      },
      [companyId, permSvc]
    )
  );

  useEffect(() => {
    if (!autoInit) return;
    initData().finally(() => {
      setIsDataLoaded(true);
    });
  }, [initData, companyId, autoInit]);

  return { isDataLoaded, initData };
}
