import { useAtomCallback } from 'jotai/utils';
import { useCallback, useState } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { getErrorMessage } from '../../../services/queryHelpers';
import { getJotaiSnapshot, goToJotaiSnapshot } from '../../../util/jotai-store';
import { DealComment } from '../data-models/comment.data-model';
import { allDealsState } from '../state/DealboardDataState';

export interface ICommentCreationPayload {
  dealId: number;
  comment: string;
}

const createComment = (commentPayload: ICommentCreationPayload) => {
  return MaggieHttp.maggie.post<ICommentCreationPayload, DealComment>('/dealComment', commentPayload);
};

interface ICreateCommentParams extends ICommentCreationPayload {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateComment = () => {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [isLoading, setIsLoading] = useState(false);

  const createDealComment = useAtomCallback(
    useCallback(
      async (get, set, { comment, dealId, onSuccess, onError }: ICreateCommentParams) => {
        setIsLoading(true);
        const snapshot = getJotaiSnapshot([allDealsState]);

        try {
          const newComment = await createComment({ comment, dealId });

          if (!newComment?.data) return;

          set(allDealsState, (current) => {
            if (!current) return current;
            return current.map((d) => {
              if (d.id === dealId) {
                return { ...d, comments: [...d.comments, newComment.data] };
              } else {
                return d;
              }
            });
          });

          pushSuccessToast({ message: 'Comment created' });
          onSuccess?.();
        } catch (err) {
          console.error(err);
          goToJotaiSnapshot(snapshot);
          const message = getErrorMessage(err, 'Error creating comment');
          pushErrorToast({ message });
          onError?.();
        } finally {
          setIsLoading(false);
        }
      },
      [pushErrorToast, pushSuccessToast]
    )
  );

  return { createDealComment, isLoading };
};
