// istanbul ignore file
import { LoadingButton } from '@mui/lab';
import { Button, Chip, Container, Icon, Stack, styled, Typography, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { add } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import {
  IKPIRequestDataModel,
  IKPIRequestResponse,
  IKPIResponseForm,
  KPIRequestStatus,
} from '../../../../../data-models/kpi-requests.data-model';
import { PermissionKey, RoleKey } from '../../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../../services/PermissionService';
import { formatDate } from '../../../../../util/formatters/DateFormatters';
import { LoadingId } from '../../../../../types';
import { kpisTemplateByIdState } from '../../../../../services/state/KPI/KPITemplatesState';
import { prepareKpiResponsePayload } from '../../../utils';
import { FormatterService } from '../../../../../util/formatter-service';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { useKPIRequestResponseActions, useMoveBackToReview } from './hooks/useKPIRequestResponseActions';
import { ConfirmReject } from './ConfirmReject';
import { AcceptResponse } from './AcceptResponse';
import { KpiResponseStatus } from './KpiResponseStatus';
import { useManagedServiceActionsV1 } from './hooks/useManagedServiceActions';
import { ManagedServiceActionButtons } from './ManagedServiceHeader';
import { useEnableManagedServices } from './managed-services-utils';

interface IKPIRequestResponseHeaderProps {
  onSubmit: () => void;
  company: ICompanyDataModel | null;
  kpiRequest: IKPIRequestDataModel | null;
  kpiResponse: IKPIRequestResponse | null;
  readOnly?: boolean;
}

const StickyHeaderWrapper = styled('div')`
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 1rem 2rem;
`;

export function KPIRequestResponseHeader({
  onSubmit,
  company,
  kpiResponse,
  kpiRequest,
  readOnly = false,
}: IKPIRequestResponseHeaderProps) {
  useTheme();
  const { requestId } = useParams<{
    requestId: string;
  }>();
  const { pushSuccessToast } = useToastMessageState();
  const navigate = useNavigate();
  const { acceptRequestResponse, updateRequestResponse, rejectRequestResponse } =
    useKPIRequestResponseActions();
  const { trigger, getValues, formState } = useFormContext();
  const respondentFormatter = FormatterService.get().getFormatterForId('userByEmail');
  const [showConfirm, setShowConfirm] = useState(false);
  const { showAcceptKpiResponseMessage } = useFlags<MaggieFeatureFlags>();
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);

  const template = useRecoilValue(kpisTemplateByIdState(kpiRequest?.templateId as number));

  const requestTitleToast = useMemo(
    () => `${company?.name} - ${formatDate(kpiRequest?.period as string)}`,
    [company?.name, kpiRequest?.period]
  );

  const validateFormAndShowModal = useCallback(async () => {
    await trigger();
    if (formState.isValid) {
      onSubmit();
    }
  }, [formState.isValid, onSubmit, trigger]);

  const respondentNames = respondentFormatter(kpiRequest?.respondent ?? []);

  const sendAcceptRequest = useCallback(
    async (acceptMessage?: string) => {
      const response = await acceptRequestResponse(requestId as string, acceptMessage);

      if (response) {
        navigate(`/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`);
        pushSuccessToast({
          title: `THE REQUEST WAS ACCEPTED`,
          message: `Request "${requestTitleToast}" from ${respondentNames} has been accepted.`,
          autoHideDuration: 9000,
        });
      }
    },
    [acceptRequestResponse, navigate, pushSuccessToast, requestId, requestTitleToast, respondentNames]
  );

  const sendRejectRequest = useCallback(
    async (observation?: string) => {
      const response = await rejectRequestResponse(requestId!, observation);

      if (response) {
        navigate(`/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`);
        pushSuccessToast({
          title: `THE REQUEST WAS REJECTED`,
          message: `Request "${requestTitleToast}" from ${respondentNames} has been rejected.`,
          autoHideDuration: 9000,
        });
      }
    },
    [rejectRequestResponse, requestId, navigate, pushSuccessToast, requestTitleToast, respondentNames]
  );

  const updateRequestAndAcceptOrReject = useCallback(
    async (callBack: () => void) => {
      const isValid = await trigger();
      if (isValid && formState.isDirty) {
        const formValues = getValues();

        const payload = prepareKpiResponsePayload(template!, formValues as IKPIResponseForm, requestId);
        const updateResponse = await updateRequestResponse(payload);

        if (updateResponse) {
          callBack();
        }
      } else if (isValid) {
        callBack();
      }
    },
    [formState.isDirty, getValues, requestId, template, trigger, updateRequestResponse]
  );

  const handleAcceptRequestResponse = useCallback(
    async (comment?: string) => {
      await updateRequestAndAcceptOrReject(() => sendAcceptRequest(comment));
    },
    [sendAcceptRequest, updateRequestAndAcceptOrReject]
  );

  const handleRejectRequestResponse = useCallback(
    async (observation?: string) => {
      await updateRequestAndAcceptOrReject(() => sendRejectRequest(observation));
      setShowConfirm(false);
    },
    [sendRejectRequest, updateRequestAndAcceptOrReject]
  );
  const { finishReview, startReview, isLoading } = useManagedServiceActions(kpiRequest);
  const moveBackToReview = useMoveBackToReview();

  const actions = useMemo(() => {
    if (!kpiRequest) return null;
    if (
      PermissionService.get().hasPermission(PermissionKey.canWriteDataCollection) &&
      (kpiRequest?.status === KPIRequestStatus.Accepted || kpiRequest?.status === KPIRequestStatus.Rejected)
    ) {
      return <MoveBackToReviewButton requestId={kpiRequest.id} />;
    } else if (!readOnly) {
      const acceptAction = showAcceptKpiResponseMessage
        ? () => setShowAcceptDialog(true)
        : handleAcceptRequestResponse;
      return (
        <>
          {showConfirm && kpiRequest && company && (
            <ConfirmReject
              company={company}
              request={kpiRequest}
              onCancel={() => {
                setShowConfirm(false);
              }}
              onConfirm={(observation?: string) => handleRejectRequestResponse(observation)}
            />
          )}
          {showAcceptKpiResponseMessage && kpiRequest && company && showAcceptDialog && (
            <AcceptResponse
              company={company}
              request={kpiRequest}
              onCancel={() => setShowAcceptDialog(false)}
              onAccept={(comment?: string) => handleAcceptRequestResponse(comment)}
            />
          )}
          {PermissionService.get().hasRole(RoleKey.managedServices) ? (
            <div>
              {kpiRequest && (
                <ManagedServiceActionButtons
                  isSubmitting={isLoading}
                  kpiRequest={kpiRequest}
                  onRejectResponse={() => setShowConfirm(true)}
                  onFinishReview={finishReview}
                  onStartReview={startReview}
                  onMoveBackToReview={() => moveBackToReview(kpiRequest.id)}
                />
              )}
            </div>
          ) : (
            <HeaderActionButtons
              validateFormAndShowModal={validateFormAndShowModal}
              handleAcceptRequestResponse={acceptAction}
              handleRejectRequestResponse={() => setShowConfirm(true)}
              requestStatus={kpiRequest.status}
              requestId={kpiRequest.id}
            />
          )}
        </>
      );
    } else {
      return null;
    }
  }, [
    company,
    finishReview,
    handleAcceptRequestResponse,
    handleRejectRequestResponse,
    isLoading,
    kpiRequest,
    moveBackToReview,
    readOnly,
    showAcceptDialog,
    showAcceptKpiResponseMessage,
    showConfirm,
    startReview,
    validateFormAndShowModal,
  ]);

  return (
    <StickyHeaderWrapper>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack direction={'row'} gap={'1rem'} alignItems='center'>
          <Stack direction='row' alignItems={'center'} justifyContent={'center'} gap='.5rem'>
            <MUIAvatar
              nameParts={company?.name.split(' ') as string[]}
              src={company?.logoUrl as string}
              size={'medium'}
            />
            <Typography variant='h5'>{company?.name}</Typography>
          </Stack>
          <HeaderInfo
            kpiRequest={kpiRequest}
            kpiResponse={kpiResponse}
            respondentName={respondentNames as string}
          />
        </Stack>
        {actions}
      </Container>
      {kpiRequest && (
        <Container maxWidth='lg' sx={{ marginTop: '1rem' }}>
          <KpiResponseStatus {...kpiRequest} />
        </Container>
      )}
    </StickyHeaderWrapper>
  );
}

const HeaderInfo = ({
  kpiRequest,
  respondentName,
  kpiResponse,
}: {
  kpiRequest: IKPIRequestDataModel | null;
  kpiResponse: IKPIRequestResponse | null;
  respondentName: string;
}) => {
  const { colors } = useTheme();

  const deadLineDate = useMemo(() => {
    if (kpiRequest?.status !== KPIRequestStatus.Sent || !kpiRequest?.sentAt) return null;

    const sentAt = new Date(kpiRequest.sentAt);
    return formatDate(add(sentAt, { days: 14 }).toISOString());
  }, [kpiRequest?.status, kpiRequest?.sentAt]);

  const responsedDate = useMemo(() => {
    if (!kpiResponse?.respondedAt) return null;

    return formatDate(kpiResponse?.respondedAt as string);
  }, [kpiResponse?.respondedAt]);

  return kpiRequest?.status === KPIRequestStatus.Sent ? (
    <Chip
      size='medium'
      label={`Deadline ${deadLineDate || 'N/A'}`}
      sx={{
        marginTop: '0.25rem',
        '& .MuiChip-label': {
          display: 'block',
          color: colors.secondary[80],
          fontSize: '1rem',
          whiteSpace: 'normal',
        },
      }}
    />
  ) : (
    <Stack direction='row' style={{ paddingTop: '.25rem' }} gap='1rem'>
      <Typography variant='body1' sx={{ color: colors.gray[700] }}>
        <b>From:</b> {respondentName}
      </Typography>
      <Typography variant='body1' sx={{ color: colors.gray[700] }}>
        <b>Response:</b> {responsedDate}
      </Typography>
    </Stack>
  );
};

const HeaderActionButtons = ({
  validateFormAndShowModal,
  handleAcceptRequestResponse,
  handleRejectRequestResponse,
  requestStatus,
  requestId,
}: {
  validateFormAndShowModal: () => void;
  requestStatus: KPIRequestStatus;
  handleAcceptRequestResponse: (comment?: string) => Promise<void> | void;
  handleRejectRequestResponse: () => void | Promise<void>;
  requestId: number;
}) => {
  const permSvc = PermissionService.get();
  const {
    state: { isLoading, ids },
  } = useLoadingBarState();

  const enableManagedServices = useEnableManagedServices();

  if (
    (requestStatus === KPIRequestStatus.Sent || requestStatus === KPIRequestStatus.Rejected) &&
    permSvc.hasPermission(PermissionKey.canSubmitCollectionSubmit)
  ) {
    return (
      <>
        <div>
          <Button onClick={validateFormAndShowModal} size='medium' variant='contained' color='secondary'>
            Continue
          </Button>
        </div>
      </>
    );
  } else if (
    (requestStatus === KPIRequestStatus.Submitted ||
      requestStatus === KPIRequestStatus.ManagedServiceFinished) &&
    permSvc.hasPermission(PermissionKey.canWriteDataCollection)
  ) {
    const isAcceptLoading = isLoading && ids.includes(LoadingId.acceptKPIRequestResponse);
    const isRejectLoading = isLoading && ids.includes(LoadingId.rejectKPIRequestResponse);

    return (
      <>
        <Stack direction={'row'} gap='1rem'>
          {enableManagedServices && requestStatus == KPIRequestStatus.ManagedServiceFinished && (
            <MoveBackToReviewButton requestId={requestId} />
          )}
          <LoadingButton
            size='medium'
            loading={isRejectLoading}
            loadingPosition='start'
            startIcon={isRejectLoading ? <Icon /> : <></>}
            onClick={handleRejectRequestResponse}
            variant='outlined'
            color='secondary'
          >
            Reject
          </LoadingButton>
          <LoadingButton
            size='medium'
            loading={isAcceptLoading}
            loadingPosition='start'
            startIcon={isAcceptLoading ? <Icon /> : <></>}
            onClick={() => handleAcceptRequestResponse()}
            variant='contained'
            color='secondary'
          >
            Accept
          </LoadingButton>
        </Stack>
      </>
    );
  }

  return null;
};

function MoveBackToReviewButton({ requestId }: { requestId: number }) {
  const moveBackToReview = useMoveBackToReview();
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(async () => {
    setLoading(true);
    await moveBackToReview(requestId);
    setLoading(false);
  }, [moveBackToReview, requestId]);
  return (
    <LoadingButton
      size='medium'
      loading={loading}
      loadingPosition='start'
      startIcon={loading ? <Icon /> : <></>}
      onClick={handleClick}
      variant='contained'
      color='secondary'
    >
      Move Back to Review
    </LoadingButton>
  );
}

function useManagedServiceActions(kpiRequest: IKPIRequestDataModel | null) {
  const { finishReview, startReview } = useManagedServiceActionsV1();
  const [isLoading, setIsLoading] = useState(false);
  const _finishReview = useCallback(async () => {
    if (!kpiRequest) return;
    setIsLoading(true);
    await finishReview(kpiRequest.id);
    setIsLoading(false);
  }, [finishReview, kpiRequest]);
  const _startReview = useCallback(async () => {
    if (!kpiRequest) return;
    setIsLoading(true);
    await startReview(kpiRequest.id);
    setIsLoading(false);
  }, [kpiRequest, startReview]);

  return {
    finishReview: _finishReview,
    isLoading,
    startReview: _startReview,
  };
}
