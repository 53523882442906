import { RecoilState, useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../services/queryHelpers';

interface IInvalidateParams<T> {
  state: RecoilState<T>;
  query: () => Promise<T>;
}
export function useInvalidateData() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ set }) =>
      async function invalidateData<T>({ state, query }: IInvalidateParams<T>) {
        try {
          const data = await query();
          set(state, data);
          return data;
        } catch (err) {
          const message = getErrorMessage(err, 'Failed to refresh data');
          pushErrorToast({ message });
        }
      },
    [pushErrorToast]
  );
}
