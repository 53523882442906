import TimelineIcon from '@mui/icons-material/Timeline';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormField } from '../../../../view-models/form.view-model';
import { BasicDialog } from '../../../Dialog/BasicDialog';
import { GenericFallbacksWrapper } from '../../../Fallback/GenericFallbacksWrapper';
import { TimeSeriesDataTable } from './TimeSeriesDataTable';

export function FieldWithTimeSeriesButton({ formField }: { formField: IFormField<unknown> }) {
  const { entityId, customFieldId } = useCustomFieldDataValues(formField.key);
  const [open, setOpen] = useState(false);

  const isDisabled = !entityId || !customFieldId;

  return (
    <>
      <IconButton onClick={() => setOpen(true)} size='small' title={'View time series'} disabled={isDisabled}>
        <TimelineIcon fontSize='small' />
      </IconButton>
      {open && (
        <BasicDialog
          open={open}
          onClose={() => setOpen(false)}
          lightDismiss={true}
          title={`${formField.label} - Effective Dates`}
        >
          <Box height='50dvh' minHeight='50dvh' maxWidth='90dvw' width={'60rem'} p={'1rem 2.5rem'}>
            <GenericFallbacksWrapper>
              <TimeSeriesDataTable
                formField={formField}
                entityId={entityId ?? null}
                customFieldId={customFieldId}
              />
            </GenericFallbacksWrapper>
          </Box>
        </BasicDialog>
      )}
    </>
  );
}

function useCustomFieldDataValues(key: string) {
  const methods = useFormContext();
  const entityId = useWatch({ name: 'id', control: methods?.control }) as number | undefined;
  const customFieldId = parseInt(key.split('.')[1]);

  return {
    entityId,
    customFieldId: isNaN(customFieldId) ? null : customFieldId,
  };
}
