import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CircularProgress, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentUser } from '../../services/hooks/useCurrentUser';
import { MaggieFeatureFlags } from '../../util/feature-flags';
import { CommentHeader } from './CommentHeader';

interface ICommentProps {
  id: number;
  authorFirstName: string;
  authorLastName: string;
  authorEmail: string;
  comment: string;
  createdAt: Date;
  onDelete: (commentId: number) => void;
  onUpdate: (comment: string, commentId: number) => Promise<void>;
  isLoading?: boolean;
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  word-wrap: break-word;
  width: 100%;
`;

const ActionsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Action = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.2rem;

  [data-icon='edit'] {
    transform: scale(0.6) translateY(0.2rem);
  }

  [data-icon='delete-1'] {
    transform: scale(0.7) translateY(0.2rem);
  }
`;

const SpinnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export function Comment({
  authorFirstName,
  authorLastName,
  authorEmail,
  comment,
  createdAt,
  id,
  onDelete,
  onUpdate,
  isLoading = false,
}: ICommentProps) {
  const { primary, neutral } = useTheme().colors;
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(comment);
  const user = useCurrentUser();

  const { enableEditCommentsFromCard } = useFlags<MaggieFeatureFlags>();

  const handleDelete = () => {
    onDelete(id);
  };

  const handleUpdate = useCallback(async () => {
    if (editValue === comment) return;
    await onUpdate(editValue, id);
    setIsEditing(false);
  }, [comment, editValue, id, onUpdate]);

  const handleBlur = useCallback(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    if (!isEditing) setEditValue(comment);
  }, [comment, isEditing]);

  return (
    <Wrapper data-testid={'comment'}>
      <CommentHeader
        createdAt={createdAt}
        authorFirstName={authorFirstName}
        authorLastName={authorLastName}
        isLoading={isLoading}
      />
      {isEditing ? (
        <TextField
          key={id}
          onBlur={handleBlur}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          variant='outlined'
          disabled={isLoading}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (isLoading) return;
            if (e.key === 'Enter') {
              e.preventDefault();
              handleUpdate();
            }
          }}
        />
      ) : (
        <Typography variant='body2' color={isLoading ? 'text.secondary' : 'text.primary'}>
          {comment}
        </Typography>
      )}
      <ActionsWrapper>
        {isLoading && (
          <SpinnerWrapper>
            <CircularProgress color={'secondary'} size={12} />
            <Typography variant={'caption'} color={primary['40']}>
              Sending...
            </Typography>
          </SpinnerWrapper>
        )}
        {enableEditCommentsFromCard && user.email === authorEmail && (
          <>
            <Action onClick={() => setIsEditing((prevState) => !prevState)}>
              <IconButton>
                <EditOutlinedIcon htmlColor={neutral['60']} fontSize='small' />
              </IconButton>
              <Typography variant={'caption'} color={neutral['60']}>
                Edit
              </Typography>
            </Action>
            <Action onClick={handleDelete}>
              <IconButton>
                <DeleteOutlinedIcon htmlColor={neutral['60']} fontSize='small' />
              </IconButton>
              <Typography variant={'caption'} color={neutral['60']}>
                Delete
              </Typography>
            </Action>
          </>
        )}
      </ActionsWrapper>
    </Wrapper>
  );
}
