import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { companyState } from '../../../../../services/state/CompanyState';
import { isMsGridEditable } from './managed-services-utils';
import { IFinancialsRowData, MSFinancialsGrid } from './MSFinancialsGrid';
import { useGetDraftFinancials, useSaveDraftFinancials } from './msFinancialsGridActions';

interface IManagedServiceKpiGridProps {
  request: IKPIRequestDataModel;
}
export function ManagedServiceKpiGrid({ request }: IManagedServiceKpiGridProps) {
  const company = useRecoilValue(companyState(request?.companyId ?? -1));
  const saveDraft = useSaveDraftFinancials();
  const savedData = useGetDraftFinancials(request?.id ?? -1);

  const onDataUpdate = useCallback(
    (rowData: IFinancialsRowData[]) => {
      if (!request) return;
      saveDraft({ requestId: request.id, financials: rowData });
    },
    [request, saveDraft]
  );
  if (!request || !company) return null;

  return (
    <div>
      <Typography mb='0.5rem'>Company Financials</Typography>
      <Box height={'75dvh'}>
        <MSFinancialsGrid
          disabled={!isMsGridEditable(request.status)}
          companyFYEDateString={request.fye}
          kpiRequestFrequency={request.frequency}
          kpiRequestGranularity={request.granularity}
          kpiRequestPeriod={request.period}
          onDataUpdate={onDataUpdate}
          savedRowData={savedData.data ?? undefined}
        />
      </Box>
    </div>
  );
}
