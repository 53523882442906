import { ICellRendererParams } from 'ag-grid-community';
import * as yup from 'yup';
import { RendererType } from '../../../../../data-models/field.data-model';
import { KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { fiscalDateFormatter } from '../../../../../util/formatters/DateFormatters';
import { SentAtRenderer } from '../components/SentAtRenderer';
import { KpiRequestManageViewModel, kpiRequestViewModelFields } from './kpi-manage.view-model';
import { KpiManageActionCellRenderer, KpiManageStatusCellRenderer } from './KpiManageGrid';

function kpiRequestGridFields() {
  const { companyId, fye, period, sentAt, status, ...rest } = kpiRequestViewModelFields();

  return {
    ...rest,
    companyId: companyId
      .gridMeta({
        renderer: RendererType.companyId,
      })
      .label('Company'),
    fye: fye.gridMeta({
      formatter: 'monthLong',
    }),
    period: period.gridMeta({
      cellRenderer: (params: ICellRendererParams<KpiRequestManageViewModel>) => {
        const { frequency, fye, period } = params.data ?? {};

        if (frequency && period && fye) {
          return fiscalDateFormatter(frequency, period, fye as string);
        }
        return '';
      },
      formatter: 'fiscalDate',
    }),
    status: status.gridMeta({
      cellRenderer: KpiManageStatusCellRenderer,
    }),
    sentAt: sentAt.gridMeta({
      cellRenderer: SentAtRenderer,
    }),
    actions: yup.string().gridMeta({
      cellRenderer: KpiManageActionCellRenderer,
    }),
  };
}

export function kpiAllRequestGridSchema() {
  return yup.object(kpiRequestGridFields());
}
export type KpiAllRequestGridViewModel = yup.InferType<ReturnType<typeof kpiAllRequestGridSchema>>;

export function getFieldsForStatus(kpiStatus: KPIRequestStatus | string) {
  const fields: (keyof KpiAllRequestGridViewModel)[] = [
    'companyId',
    'templateUuid',
    'status',
    'frequency',
    'period',
    'createdAt',
  ];

  if (kpiStatus !== KPIRequestStatus.NotSent) {
    fields.push('dueDate');
  }

  if (kpiStatus === KPIRequestStatus.Sent) {
    fields.push('granularity', 'fye', 'respondent');
  } else if (kpiStatus === KPIRequestStatus.Submitted) {
    fields.push('sentAt', 'responseDate', 'granularity', 'fye', 'respondent');
  } else if (kpiStatus === KPIRequestStatus.Accepted) {
    fields.push('sentAt', 'responseDate', 'granularity', 'fye', 'respondent', 'acceptMessage');
  } else if (kpiStatus === KPIRequestStatus.Rejected) {
    fields.push('sentAt', 'responseDate', 'granularity', 'fye', 'respondent', 'rejectReason');
  } else if (kpiStatus === KPIRequestStatus.Archived) {
    fields.push('granularity', 'fye', 'respondent', 'archivedAt');
  }

  if (kpiStatus !== KPIRequestStatus.Archived) {
    fields.push('actions');
  }

  return fields as (keyof KpiRequestManageViewModel)[];
}
