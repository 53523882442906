import { styled } from '@mui/system';
import { useRecoilValue } from 'recoil';
import { FinancingHistoryTable } from '../../CompanyProfiles/Summary/components/FinancingHistory/FinancingHistoryTable';
import { NewsSection } from '../../CompanyProfiles/Summary/components/NewsSection';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { Panel } from './Panel';
import { DigestPanel } from './DigestPanel';
import { RecentActivityPanel } from './RecentActivity/RecentActivityPanel';
import { SummaryStrongestConnections } from './SummaryStrongestConnections';
import { LeadershipPanel } from './LeadershipPanel';
import { CompetitiveIntelligenceSummary } from './CompetitiveIntelligenceSummary';
import { CompanyProfilesContainerBreakpoint } from './CompanySummaryPortfolio';

const MainWrapper = styled('div')`
  container-name: company-profiles-container;
  container-type: inline-size;
  display: grid;
  height: 100%;
  overflow: auto;
  width: 100%;
`;

const SummaryLayout = styled('div')`
  display: grid;
  gap: 1rem;
  height: 100%;
  padding: 1rem;
  width: 100%;

  @container company-profiles-container (width < ${CompanyProfilesContainerBreakpoint}) {
    grid-template-columns: 1fr 0fr;
    grid-template-areas:
      'digest'
      'digest'
      'digest'
      'leader'
      'connections'
      'connections'
      'connections'
      'comp-intel'
      'activity'
      'activity'
      'news'
      'news'
      'fin-hist'
      'fin-hist';
  }

  @container company-profiles-container (width >= ${CompanyProfilesContainerBreakpoint}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'digest leader'
      'digest connections'
      'digest connections'
      'comp-intel connections'
      'news activity'
      'news activity'
      'fin-hist fin-hist'
      'fin-hist fin-hist';
  }
`;

const PanelWrapper = styled('div')`
  height: 100%;
  width: 100%;
  min-height: 160px;
`;

export function CompanySummaryDiscovery() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  return (
    <MainWrapper>
      <SummaryLayout>
        <PanelWrapper style={{ gridArea: 'digest' }}>
          <DigestPanel companyId={companyId} />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'leader' }}>
          <LeadershipPanel />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'connections' }}>
          <SummaryStrongestConnections />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'comp-intel' }}>
          <CompetitiveIntelligenceSummary />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'news' }}>
          <Panel title={'News'}>
            <NewsSection />
          </Panel>
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'activity' }}>
          <RecentActivityPanel />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'fin-hist' }}>
          <Panel title={'Financing History'} showMore={{ url: '../financials', title: 'Go to Financials' }}>
            <FinancingHistoryTable />
          </Panel>
        </PanelWrapper>
      </SummaryLayout>
    </MainWrapper>
  );
}
