import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { merge } from 'lodash-es';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, set, useForm, useFormContext } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { KpiPeriod } from '../../../data-models/company-financials.data-model';
import { schemaToFormFields } from '../../../util/schema-utils';
import { IFormField } from '../../../view-models/form.view-model';
import { MONTHS } from '../../KPI/components/Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { useMprColumnConfigs } from './useMprColumnConfigs';
import {
  MprKpiConfigFormData,
  ReportingPeriodType,
  useMultiReportingKpiColumnsSchema,
} from './MultiPeriodReporting.schemas';
import { kpiColumnConfigsFromFormData } from './multiPeriodReportingUtils';

const commonFields: (keyof MprKpiConfigFormData)[] = ['kpiKey', 'section', 'type', 'period'];

export function MprKpiColumnSettingsForm() {
  const { setDraftColumnConfigs } = useMprColumnConfigs();
  const schema = useMultiReportingKpiColumnsSchema();

  const methods = useForm<MprKpiConfigFormData>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
  });

  const [rerender, setRerender] = useState(0);
  const onAdd = useCallback(
    async (data: Partial<MprKpiConfigFormData>) => {
      const isValid = await methods.trigger();
      if (!isValid) return;
      const columnConfigs = kpiColumnConfigsFromFormData(data as MprKpiConfigFormData);
      setDraftColumnConfigs((prev) => (prev ?? []).concat(columnConfigs));
      methods.reset(schema.getDefault());
      setRerender((prev) => prev + 1);
    },
    [methods, schema, setDraftColumnConfigs]
  );

  return (
    <FormProvider {...methods} key={rerender}>
      <Box>
        <Box display={'grid'} alignContent={'start'} sx={{ '.field-label': { marginBottom: '-0.5rem' } }}>
          <MprColumnSettingsFields schema={schema} />
        </Box>
        <Button
          startIcon={<AddIcon />}
          variant={'contained'}
          color={'secondary'}
          onClick={methods.handleSubmit(onAdd)}
          size={'medium'}
          sx={{ width: '100%', position: 'absolute', bottom: 0 }}
        >
          Add
        </Button>
      </Box>
    </FormProvider>
  );
}

function MprColumnSettingsFields({ schema }: { schema: ObjectSchema<MprKpiConfigFormData> }) {
  const fieldsMap = useMemo(() => {
    return new Map(schemaToFormFields(schema).map((field) => [field.key, field]));
  }, [schema]);
  const dynamicFields = useDynamicMprFields(
    fieldsMap as Map<keyof MprKpiConfigFormData, IFormField<unknown>>,
    schema
  );
  return (
    <Fragment>
      {commonFields.map((key) => {
        return <FormFieldWithLabelFactory key={key} formField={fieldsMap.get(key)!} />;
      })}
      {dynamicFields.map((field) => {
        return <FormFieldWithLabelFactory key={field.key!} formField={field} />;
      })}
    </Fragment>
  );
}

function useDynamicMprFields(
  fieldsMap: Map<keyof MprKpiConfigFormData, IFormField<unknown>>,
  schema: ObjectSchema<MprKpiConfigFormData>
) {
  const { setValue, resetField, watch } = useFormContext<MprKpiConfigFormData>();
  const [fields, setFields] = useState<IFormField<unknown>[]>(() =>
    getInitialDynamicFields(schema, fieldsMap)
  );

  useEffect(() => {
    const subscription = watch((data, { name, type: eventType }) => {
      if (eventType !== 'change' || !['period', 'type'].includes(name ?? '')) return;
      const { type, period } = data;

      const monthField = { ...fieldsMap.get('month')! };
      const yearField = { ...fieldsMap.get('year')! };

      if (name === 'period') {
        resetField('relativeDistance'); // options are different depending on period
      }

      if (period === KpiPeriod.year) {
        setValue('month', [12]);
      } else {
        resetField('month');
      }
      if (period === KpiPeriod.quarter) {
        monthField.label = 'Quarter';
      } else if (period === KpiPeriod.month) {
        monthField.label = 'Month';
      }

      const result = [];

      if (type === ReportingPeriodType.absolute) {
        if (period && period !== KpiPeriod.year) {
          const monthOptions = MonthValues[period];

          set(
            monthField,
            'rendererMeta',
            merge({}, monthField.rendererMeta ?? {}, {
              values: monthOptions,
            })
          );
          result.push(monthField);
        }
        set(
          yearField,
          'rendererMeta',
          merge({}, yearField?.rendererMeta ?? {}, {
            values: YearValues,
          })
        );
        result.push(yearField);
        setValue('relativeDistance', null);
      } else if (type === ReportingPeriodType.relative) {
        const relativeDistanceField: IFormField<unknown> = { ...fieldsMap.get('relativeDistance')! };
        relativeDistanceField.rendererMeta = {
          ...(relativeDistanceField.rendererMeta ?? {}),
          values: getRelativeDistanceOptions(period),
        };
        result.push(relativeDistanceField!);
        setValue('relativeDistance', [0]);
        setValue('month', null);
        setValue('year', null);
      }

      setFields(result);
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  return fields;
}

export const MonthValues = {
  [KpiPeriod.quarter]: [3, 6, 9, 12].map((month) => ({
    value: month,
    displayName: `Q${month / 3}`,
  })),
  [KpiPeriod.month]: MONTHS.map((month, index) => ({
    value: index + 1,
    displayName: month,
  })),
};

export const YearValues = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => ({
  value: year,
  displayName: String(year),
}));

function getInitialDynamicFields(
  schema: ObjectSchema<MprKpiConfigFormData>,
  fieldsMap: Map<keyof MprKpiConfigFormData, IFormField<unknown>>
) {
  const initialValues = schema.getDefault();
  const period = initialValues.period;
  const result: IFormField<unknown>[] = [];

  if (initialValues.type === ReportingPeriodType.relative) {
    result.push(fieldsMap.get('relativeDistance')!);
  } else if (initialValues.type === ReportingPeriodType.absolute) {
    const monthField = fieldsMap.get('month')!;
    const yearField = fieldsMap.get('year')!;
    if (period && period !== KpiPeriod.year) {
      set(
        monthField,
        'rendererMeta',
        merge({}, monthField.rendererMeta ?? {}, {
          values: MonthValues[period as keyof typeof MonthValues],
        })
      );
      monthField.label = period === KpiPeriod.quarter ? 'Quarter' : 'Month';
      result.push(monthField);
    }
    set(
      yearField!,
      'rendererMeta',
      merge({}, yearField?.rendererMeta ?? {}, {
        values: YearValues,
      })
    );
    result.push(yearField);
  }
  return result;
}

const periodToRelativeDistances = {
  [KpiPeriod.month]: [0, 1, 2, 3, 4, 12, 24],
  [KpiPeriod.quarter]: [0, 1, 2, 3, 4, 8],
  [KpiPeriod.year]: [0, 1, 2, 3, 4],
};
export function getRelativeDistanceOptions(period?: KpiPeriod) {
  return (periodToRelativeDistances[period as KpiPeriod] ?? [0, 1, 2, 3, 4]).map((value) => ({
    value,
    displayName: value > 0 ? `Last Full ${period ?? 'Period'} - ${value}` : `Last Full ${period ?? 'Period'}`,
  }));
}
