import { css } from '@emotion/react';
import { Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { FC, useCallback, useMemo } from 'react';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { TodoItem } from '../../../../types';
import { getRequiredChecklistItems } from '../../helpers/checklistHelpers';
import { useUpdateDeal2 } from '../../hooks/useDealActions';
import { dealBoardConfigState } from '../../state/DealboardDataState';

const CheckListSection = styled('section')`
  & h4 {
    margin-bottom: 0.8rem;
  }
`;

const ItemContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledCheckItem = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0;
`;
export interface ICheckListProps {
  selectedDeal: IDealDataModel;
}

export const CheckList: FC<ICheckListProps> = (props) => {
  const { selectedDeal } = props;
  const dealBoardConfig = useAtomValue(dealBoardConfigState);
  const updateDeal = useUpdateDeal2();

  const checkedItems = useMemo(() => new Set(selectedDeal.todoList ?? []), [selectedDeal.todoList]);

  const required = useMemo(() => {
    return new Set(getRequiredChecklistItems({ dealBoardConfig, deal: selectedDeal }));
  }, [dealBoardConfig, selectedDeal]);

  const todoList = useMemo(() => {
    const list = [...dealBoardConfig.todoList].filter((item) => required.has(item.name));
    list.sort((a, b) => a.sortOrder - b.sortOrder);
    return list;
  }, [dealBoardConfig, required]);

  const handleCheckItemChange = useCallback(
    async (todoItem: TodoItem) => {
      if (!selectedDeal) return;
      const removed = [...checkedItems].filter((item) => item !== todoItem.name);
      const newList = removed.length === checkedItems.size ? [...checkedItems, todoItem.name] : removed;

      await updateDeal(
        selectedDeal,
        {
          todoList: newList,
        },
        false
      );
    },
    [checkedItems, selectedDeal, updateDeal]
  );

  if (required.size === 0) {
    return null;
  }

  return (
    <CheckListSection data-testid='checklist-section'>
      <Typography variant='subtitle1' component='h4'>
        To-Do List
      </Typography>
      <ItemContainer role='listbox'>
        {todoList.map((item) => {
          const checked = checkedItems.has(item.name);
          return (
            <div className={checked ? 'checked' : ''} key={item.id} css={StyledCheckItem}>
              <Checkbox size='small' checked={checked} onChange={() => handleCheckItemChange(item)} />
              <Typography
                component='label'
                variant='body2'
                sx={checked ? { color: 'text.secondary', textDecoration: 'line-through' } : {}}
              >
                {item.name}
              </Typography>
            </div>
          );
        })}
      </ItemContainer>
    </CheckListSection>
  );
};
