import { ColDef } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { Temporal } from 'temporal-polyfill';
import * as yup from 'yup';
import { ICustomFieldDataDataModel } from '../../../../data-models/custom-field-data.data-model';
import { useCustomFieldDataActions } from '../../../../services/hooks/useCustomFieldDataActions';
import { AgFormatterService } from '../../../../util/ag-formatter-service';
import { withOverrides } from '../../../../util/ag-grid-utils';
import { CustomFormatterId } from '../../../../util/formatter-service';
import { useAsync } from '../../../../util/hook-utils';
import { useSchemaToGrid } from '../../../../util/schema-utils';
import { IFormField } from '../../../../view-models/form.view-model';
import { AgTableWithBaseStyle } from '../../../AgTable/AgTable2';
import { DateFormattersId } from '../../../../util/formatters/DateFormatters';

interface ITimeSeriesDataTableProps {
  entityId: number | null;
  customFieldId: number | null;
  formField: IFormField<unknown>;
}
export function TimeSeriesDataTable({ formField, entityId, customFieldId }: ITimeSeriesDataTableProps) {
  const { colDefs, rowData } = useCustomFieldDataGridColDefs({ formField, entityId, customFieldId });

  return <AgTableWithBaseStyle rowData={rowData} columnDefs={colDefs} defaultColDef={defaultColDef} />;
}

function customFieldDataGridFields() {
  return {
    updatedBy: yup.string().gridMeta({
      formatter: CustomFormatterId.userByEmail,
    }),
    customFieldId: yup.number(),
    entityId: yup.number(),
    value: yup.mixed().nullable(),
    startDate: yup.date(),
    endDate: yup.date(),
    updatedAt: yup.date().nullable().label('Updated On'),
  };
}
function customFieldDataGridSchema() {
  return yup.object().shape(customFieldDataGridFields());
}

type CustomFieldDataGridData = yup.InferType<ReturnType<typeof customFieldDataGridSchema>>;

export function getCustomFieldDataGridData(history?: ICustomFieldDataDataModel[] | null) {
  if (!history) return;
  const sortedHistory = history.toSorted((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  const rowData: CustomFieldDataGridData[] = [];
  for (let i = 0; i < sortedHistory.length; i++) {
    const nextDate = i < sortedHistory.length - 1 ? sortedHistory[i + 1].date : null;
    const endDate = nextDate ? Temporal.PlainDate.from(nextDate).subtract({ days: 1 }) : null;
    const row = {
      ...sortedHistory[i],
      startDate: sortedHistory[i].date as unknown as Date,
      endDate: endDate?.toString(),
      updatedBy: sortedHistory[i].updatedBy || sortedHistory[i].createdBy,
      updatedAt: sortedHistory[i].updatedAt || sortedHistory[i].createdAt,
    } as CustomFieldDataGridData;
    rowData.push(row);
  }
  return rowData;
}

function useCustomFieldDataGridColDefs({ formField, entityId, customFieldId }: ITimeSeriesDataTableProps) {
  const schemaToGrid = useSchemaToGrid();
  const { fetchCustomFieldDataHistory } = useCustomFieldDataActions();
  const { data } = useAsync(
    useCallback(async () => {
      if (!entityId || !customFieldId) {
        console.warn('Missing data');
        return;
      }
      return fetchCustomFieldDataHistory({ entityId, customFieldId });
    }, [entityId, customFieldId, fetchCustomFieldDataHistory]),
    {
      id: `${entityId}-${customFieldId}`,
    }
  );

  return useMemo(() => {
    const rowData = getCustomFieldDataGridData(data);

    const overrides: Partial<Record<keyof CustomFieldDataGridData, ColDef<CustomFieldDataGridData>>> = {
      startDate: {
        initialSort: 'desc',
      },
      value: {
        valueFormatter: AgFormatterService.get().getFormatterForFormField(formField),
      },
      updatedAt: { valueFormatter: AgFormatterService.get().getFormatterForId(DateFormattersId.dateLocal) },
    };
    return {
      colDefs: withOverrides(
        schemaToGrid(
          customFieldDataGridSchema().pick(['value', 'startDate', 'endDate', 'updatedAt', 'updatedBy'])
        ),
        overrides
      ),
      rowData,
    };
  }, [data, formField, schemaToGrid]);
}

const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 100,
  resizable: true,
};
