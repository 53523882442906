import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';
import { FundDataModel } from '../../schemas/Fund.schema';
import { toFundViewModel } from '../../schemas/FundViewModel.schema';
import { toFundViewModel as toFundViewModel2 } from '../../schemas/FundViewModel2.schema';

import { MaggieFeatureFlags } from '../../util/feature-flags';
import { FundFormModal } from './FundForms/FundFormModal';
import { useUpdateFund } from './state/useUpdateFund';
import { IFundFormProps } from './FundForms/FundWaterfallForm';
import { IFundFormProps as IFundFormProps2 } from './FundForms/FundWaterfallForm2';

export function FundSettings({ fund }: { fund: FundDataModel }) {
  const updateFund = useUpdateFund();
  const { showFundWaterfallForm2 } = useFlags<MaggieFeatureFlags>();
  const defaultValues = useMemo(
    () => (showFundWaterfallForm2 ? toFundViewModel2(cloneDeep(fund)) : toFundViewModel(cloneDeep(fund))),
    [fund, showFundWaterfallForm2]
  );
  return (
    <>
      <FundFormModal
        onSubmit={updateFund as (fund: Partial<FundDataModel>) => Promise<FundDataModel>}
        defaultValues={defaultValues as IFundFormProps['defaultValues'] | IFundFormProps2['defaultValues']}
      />
    </>
  );
}
