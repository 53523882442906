import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EastIcon from '@mui/icons-material/East';
import { useAtomValue, useSetAtom } from 'jotai';
import { FC, useCallback, useMemo } from 'react';
import { StyledTooltip } from '../../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { useDealActions2, useMoveDealToNextStage } from '../../../../DealFlow2/hooks/useDealActions';
import { selectedDealActionAtom } from '../../../../DealFlow2/state/DealboardUIState';
import { MenuButton } from '../../../../Finance2/common-grid-defs/MenuButton';
import { LastUpdatedCurrentDealState } from '../../../state/CompanyDealsState';
import { selectedCompanyIdProfileAtom } from '../../../state/UIStateJ';

export const DealActionsBtn: FC = () => {
  const companyId = useAtomValue(selectedCompanyIdProfileAtom);
  const activeDeal = useAtomValue(LastUpdatedCurrentDealState(companyId));
  const { handleClose } = useDealActions2();
  const { moveToNextStage, getNextStage } = useMoveDealToNextStage();
  const nextStageId = useMemo(
    () => (!activeDeal ? null : getNextStage(activeDeal)),
    [activeDeal, getNextStage]
  );

  const handleMoveToNextStage = useCallback(async () => {
    if (!activeDeal) return;
    moveToNextStage(activeDeal);
  }, [activeDeal, moveToNextStage]);

  const setSelectedDealAction = useSetAtom(selectedDealActionAtom);

  const menuItems = useMemo(() => {
    if (!activeDeal) return [];
    return [
      {
        name: 'Move to next stage',
        icon: <EastIcon />,
        disabled: !nextStageId,
        onClick: handleMoveToNextStage,
      },
      {
        name: 'Close',
        icon: <CheckCircleOutlineIcon />,
        onClick: () => handleClose(activeDeal),
      },
      {
        name: 'Pass',
        icon: <ThumbDownAltOutlinedIcon />,
        onClick: () =>
          setSelectedDealAction({
            action: 'pass',
            dealId: activeDeal.id,
          }),
      },
      {
        name: 'Missed',
        icon: <CallMissedOutgoingIcon />,
        onClick: () =>
          setSelectedDealAction({
            action: 'missed',
            dealId: activeDeal.id,
          }),
        divider: true,
      },
      {
        name: 'Delete',
        icon: <DeleteOutlineIcon />,
        onClick: () =>
          setSelectedDealAction({
            action: 'delete',
            dealId: activeDeal.id,
          }),
      },
    ];
  }, [activeDeal, handleClose, handleMoveToNextStage, nextStageId, setSelectedDealAction]);

  if (!activeDeal) return null;

  return (
    <StyledTooltip title='Deal Actions' placement='top-start'>
      <MenuButton menuItems={menuItems} label='Deal Actions' />
    </StyledTooltip>
  );
};
