import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { KpiPeriod } from '../../../data-models/company-financials.data-model';
import { schemaToFormFields } from '../../../util/schema-utils';
import { IFormField } from '../../../view-models/form.view-model';
import { useMprColumnConfigs } from './useMprColumnConfigs';
import {
  MprMetricsConfigFormData,
  ReportingPeriodType,
  useMultiReportingMetricsColumnsSchema,
} from './MultiPeriodReporting.schemas';
import { metricsColumnConfigsFromFormData } from './multiPeriodReportingUtils';

const commonFields: (keyof MprMetricsConfigFormData)[] = ['columnPaths', 'type'];

export function MprMetricsColumnSettingsForm() {
  const { setDraftColumnConfigs } = useMprColumnConfigs();
  const schema = useMultiReportingMetricsColumnsSchema();

  const methods = useForm<MprMetricsConfigFormData>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
  });

  const [rerender, setRerender] = useState(0);
  const onAdd = useCallback(
    async (data: Partial<MprMetricsConfigFormData>) => {
      const isValid = await methods.trigger();
      if (!isValid) return;
      const columnConfigs = metricsColumnConfigsFromFormData(data as MprMetricsConfigFormData);
      setDraftColumnConfigs((prev) => (prev ?? []).concat(columnConfigs));
      methods.reset(schema.getDefault());
      setRerender((prev) => prev + 1);
    },
    [methods, schema, setDraftColumnConfigs]
  );

  return (
    <FormProvider {...methods} key={rerender}>
      <Box>
        <Box display={'grid'} alignContent={'start'} sx={{ '.field-label': { marginBottom: '-0.5rem' } }}>
          <MprColumnSettingsFields schema={schema} />
        </Box>
        <Button
          startIcon={<AddIcon />}
          variant={'contained'}
          color={'secondary'}
          onClick={methods.handleSubmit(onAdd)}
          size={'medium'}
          sx={{ width: '100%', position: 'absolute', bottom: 0 }}
        >
          Add
        </Button>
      </Box>
    </FormProvider>
  );
}

function MprColumnSettingsFields({ schema }: { schema: ObjectSchema<MprMetricsConfigFormData> }) {
  const fieldsMap = useMemo(() => {
    return new Map(schemaToFormFields(schema).map((field) => [field.key, field]));
  }, [schema]);
  const dynamicFields = useDynamicMprMetricsFields(
    fieldsMap as Map<keyof MprMetricsConfigFormData, IFormField<unknown>>,
    schema
  );
  return (
    <Fragment>
      {commonFields.map((key) => {
        return <FormFieldWithLabelFactory key={key} formField={fieldsMap.get(key)!} />;
      })}
      {dynamicFields.map((field) => {
        return <FormFieldWithLabelFactory key={field.key!} formField={field} />;
      })}
    </Fragment>
  );
}

function useDynamicMprMetricsFields(
  fieldsMap: Map<keyof MprMetricsConfigFormData, IFormField<unknown>>,
  schema: ObjectSchema<MprMetricsConfigFormData>
) {
  const { setValue, watch } = useFormContext<MprMetricsConfigFormData>();
  const [fields, setFields] = useState<IFormField<unknown>[]>(() =>
    getInitialMetricsDynamicFields(schema, fieldsMap)
  );

  useEffect(() => {
    const subscription = watch((data, { name, type: eventType }) => {
      if (eventType !== 'change' || name !== 'type') return;
      const { type } = data;

      const yearField = { ...fieldsMap.get('year')! };
      const monthField = { ...fieldsMap.get('month')! };
      const relativeDistanceField = { ...fieldsMap.get('relativeDistance')! };
      const result = [];

      if (type === ReportingPeriodType.absolute) {
        result.push(monthField, yearField);
        setValue('relativeDistance', null);
      } else if (type === ReportingPeriodType.relative) {
        setValue('relativeDistance', [0]);
        setValue('month', null);
        setValue('year', null);
        result.push(relativeDistanceField);
      }
      setFields(result);
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  return fields;
}

function getInitialMetricsDynamicFields(
  schema: ObjectSchema<MprMetricsConfigFormData>,
  fieldsMap: Map<keyof MprMetricsConfigFormData, IFormField<unknown>>
) {
  const initialValues = schema.getDefault();
  initialValues.period = KpiPeriod.quarter;
  const result: IFormField<unknown>[] = [];

  if (initialValues.type === ReportingPeriodType.relative) {
    result.push(fieldsMap.get('relativeDistance')!);
  } else if (initialValues.type === ReportingPeriodType.absolute) {
    const monthField = fieldsMap.get('month')!;
    const yearField = fieldsMap.get('year')!;
    result.push(monthField, yearField);
  }
  return result;
}
