import { useAtomValue } from 'jotai';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ICompanySearchResultDataModel } from '../../../data-models/company-search-result.data-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { ComponentWithChildren } from '../../../types';
import { useCreateDeal } from '../hooks/useDealActions';
import { dealStagesByNameMapState } from '../state/DealboardDataState';
import { getDuplicateDeal } from '../utils/getLatestDuplicateDeal';

export interface IDeal {
  companyInfo: ICompanySearchResultDataModel | null;
  setCompanyInfo: Dispatch<SetStateAction<ICompanySearchResultDataModel | null>>;
  companyAdditionalInfo: ICompanyDataModel | null | undefined;
  setCompanyAdditionalInfo: Dispatch<SetStateAction<ICompanyDataModel | null | undefined>>;
  dealInfo: Partial<IDealDataModel> | null;
  setDealInfo: Dispatch<SetStateAction<Partial<IDealDataModel> | null>>;
  isDealFormValid: boolean;
  setIsDealFormValid: Dispatch<SetStateAction<boolean>>;
  handleCreateDeal: (byPassDuplicate?: boolean) => Promise<IDealDataModel | undefined>;
  setIsItemDropdownOpen: Dispatch<SetStateAction<boolean>>;
  isItemDropdownOpen: boolean;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  showForm: boolean;
  setShowForm: Dispatch<SetStateAction<boolean>>;
  companyInput: string;
  setCompanyInput: Dispatch<SetStateAction<string>>;
  resetState: () => void;
  duplicateDeal: IDealDataModel | null;
}

const DealContext = createContext<IDeal | undefined>(undefined);

export const DealProvider: FC<ComponentWithChildren> = ({ children }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<ICompanySearchResultDataModel | null>(null);
  const [companyAdditionalInfo, setCompanyAdditionalInfo] = useState<ICompanyDataModel | null | undefined>(
    null
  );
  const [dealInfo, setDealInfo] = useState<Partial<IDealDataModel> | null>(null);
  const [isDealFormValid, setIsDealFormValid] = useState(false);
  const [isItemDropdownOpen, setIsItemDropdownOpen] = useState(false);
  const stagesMap = useAtomValue(dealStagesByNameMapState);
  const [companyInput, setCompanyInput] = useState('');

  const [duplicateDeal, setDuplicateDeal] = useState<IDealDataModel | null>(null);

  useEffect(() => {
    const companyId = companyAdditionalInfo?.id;
    if (!companyId) return;

    getDuplicateDeal(companyId).then((deal) => {
      if (deal) setDuplicateDeal(deal);
      else setDuplicateDeal(null);
    });
  }, [companyAdditionalInfo?.id]);

  const resetState = () => {
    setActiveStep(1);
    setCompanyInfo(null);
    setCompanyAdditionalInfo(null);
    setShowForm(false);
    setCompanyInput('');
  };

  const createDealAndUpdateState = useCreateDeal();

  const handleCreateDeal = useCallback(
    async (byPassDuplicate = false) => {
      if (!stagesMap) return;
      const payload = {
        ...dealInfo,
        companyId: parseInt(companyInfo?.id as string),
        stageId: stagesMap.get('Prospects')!.id as number,
      };

      const dealCreationResponse = await createDealAndUpdateState(payload, byPassDuplicate);
      return dealCreationResponse;
    },
    [companyInfo?.id, createDealAndUpdateState, dealInfo, stagesMap]
  );

  const value = useMemo(
    () => ({
      companyInfo,
      setCompanyInfo,
      companyAdditionalInfo,
      setCompanyAdditionalInfo,
      dealInfo,
      setDealInfo,
      isDealFormValid,
      setIsDealFormValid,
      handleCreateDeal,
      isItemDropdownOpen,
      setIsItemDropdownOpen,
      activeStep,
      setActiveStep,
      resetState,
      showForm,
      setShowForm,
      companyInput,
      setCompanyInput,
      duplicateDeal,
    }),
    [
      companyInfo,
      companyAdditionalInfo,
      dealInfo,
      isDealFormValid,
      handleCreateDeal,
      isItemDropdownOpen,
      activeStep,
      showForm,
      companyInput,
      setCompanyInput,
      duplicateDeal,
    ]
  );

  return <DealContext.Provider value={value}>{children}</DealContext.Provider>;
};

export const useDeal = (): IDeal => {
  const context = useContext(DealContext);

  if (context === undefined) {
    throw new Error('useDeal cannot be used outside DealProvider');
  }

  return context;
};
