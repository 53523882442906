import { useMemo, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { SxProps, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps, PickersShortcutsItem } from '@mui/x-date-pickers';
import { debounce, noop } from 'lodash-es';
import { addDays, addYears, endOfQuarter, endOfYear, isValid, isWithinInterval, sub } from 'date-fns';
import { ErrorText } from '../Form/FormComponents';

export type ICalendar2Props = Pick<
  DatePickerProps<Date>,
  'minDate' | 'maxDate' | 'onClose' | 'disabled' | 'sx'
> & {
  initialValue?: Date;
  onChange: (newDate: Date) => void;
  shortcutItems?: PickersShortcutsItem<Date | null>[];
  textFieldProps?: Partial<TextFieldProps>;
  triggerUpdateOnChange?: boolean;
};

export function Calendar(props: ICalendar2Props) {
  const {
    initialValue = null,
    maxDate,
    minDate,
    onChange,
    disabled,
    textFieldProps,
    shortcutItems,
    sx,
  } = props;
  const [hasError, setHasError] = useState(false);
  const [viewDate, setViewDate] = useState(initialValue);
  const updateDate = useMemo(
    () =>
      debounce((newDate: Date) => {
        if (isDateInputValid(newDate, minDate, maxDate)) {
          setHasError(false);
          setViewDate(newDate);
          onChange(newDate);
        } else {
          setHasError(true);
        }
      }, 1000),
    [maxDate, minDate, onChange]
  );

  const onAccept = (newValue: Date | null) => {
    if (newValue !== null) {
      if (!isDateInputValid(newValue, minDate, maxDate)) {
        setHasError(true);
      } else {
        setHasError(false);
        updateDate(newValue);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slotProps={{
          shortcuts: {
            items: shortcutItems ?? defaultShortcuts,
            sx: shortcutsSx,
          },
          textField: {
            ...textFieldProps,
          },
        }}
        maxDate={maxDate}
        minDate={minDate}
        views={['year', 'month', 'day']}
        onAccept={onAccept}
        value={viewDate}
        onChange={props.triggerUpdateOnChange ? updateDate : noop}
        onClose={props.onClose}
        disabled={disabled}
        sx={sx}
      />
      <div>
        <ErrorText sx={{ height: 'unset' }}>{hasError ? 'Invalid date' : ''}</ErrorText>
      </div>
    </LocalizationProvider>
  );
}

export enum CalendarShortcut {
  prevQuarter = 'Prev. Quarter',
  prevYear = 'Prev. Year',
  today = 'Today',
  endOfQuarter = 'End of Quarter',
  endOfYear = 'End of Year',
  in30Days = 'In 30 Days',
  in60Days = 'In 60 Days',
  in90Days = 'In 90 Days',
  in3Years = 'In 3 Years',
  in5Years = 'In 5 Years',
  in10Years = 'In 10 Years',
  in30Years = 'In 30 Years',
}

export const calendarShortcutToValueMap: Record<CalendarShortcut, () => Date> = {
  [CalendarShortcut.prevQuarter]: () => endOfQuarter(sub(new Date(), { months: 3 })),
  [CalendarShortcut.prevYear]: () => endOfYear(sub(new Date(), { years: 1 })),
  [CalendarShortcut.today]: () => new Date(),
  [CalendarShortcut.endOfQuarter]: () => endOfQuarter(new Date()),
  [CalendarShortcut.endOfYear]: () => endOfYear(new Date()),
  [CalendarShortcut.in30Days]: () => addDays(new Date(), 30),
  [CalendarShortcut.in60Days]: () => addDays(new Date(), 60),
  [CalendarShortcut.in90Days]: () => addDays(new Date(), 90),
  [CalendarShortcut.in3Years]: () => addYears(new Date(), 3),
  [CalendarShortcut.in5Years]: () => addYears(new Date(), 5),
  [CalendarShortcut.in10Years]: () => addYears(new Date(), 10),
  [CalendarShortcut.in30Years]: () => addYears(new Date(), 30),
};

export const defaultShortcuts = [
  {
    label: CalendarShortcut.prevQuarter,
    getValue: calendarShortcutToValueMap[CalendarShortcut.prevQuarter],
  },
  {
    label: CalendarShortcut.prevYear,
    getValue: calendarShortcutToValueMap[CalendarShortcut.prevYear],
  },
  {
    label: CalendarShortcut.today,
    getValue: calendarShortcutToValueMap[CalendarShortcut.today],
  },
];

export const shortcutsSx: SxProps = {
  '&.MuiList-root': {
    paddingTop: '1rem',
    borderRight: '1px solid #d2d5db80',
  },
};

const maxDateAsNumber = addYears(new Date(), 100).getTime();
export function isDateInputValid(date: Date, minDate?: Date, maxDate?: Date) {
  return isValid(date) && isWithinInterval(date, { start: minDate ?? 0, end: maxDate ?? maxDateAsNumber });
}
