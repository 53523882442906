import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { GenericFallbacksWrapper } from '../../components/Fallback/GenericFallbacksWrapper';
import { DealBoardSkeletonLoader } from '../../components/SkeletonLoader/DealBord.SkeletonLoader';
import { MaxHeaderHeightInRems } from '../../constants/styles';
import { DealBoardDealCategory } from '../../types';
import { MaggieFeatureFlags } from '../../util/feature-flags';
import { CategoryDealTable } from './components/CategoryDealTable';
import { DealBoard } from './components/DealBoard/DealBoard';
import { DealDrawer } from './components/DealDrawer/DealDrawer';
import { DealDrawer as DealDrawer2 } from './components/DealDrawer/DealDrawer2';
import { DealFlowHeaderV2 } from './components/DealFlowHeaderV2';
import { FilterOptions } from './components/FilterOptions';
import { useInitDealBoard } from './hooks/useInitDealBoard';
import { useSyncFromPath } from './hooks/useSyncFromPath';
import {
  DefaultDealCategory,
  isOpenDrawerState,
  isPresentModeState,
  selectedDealCategoryState,
} from './state/DealboardUIState';

const drawerContainer = css`
  width: min(95dvw, 1000px);
  min-width: min(95dvw, 1000px);
  max-width: 1000px;
  height: 100%;
  padding: 24px;
  background: #ffffff;
  &.presentModeOn {
    padding-top: 65px;
  }
`;

const Container = styled('div')<{ presentMode: boolean }>`
  height: calc(100vh - ${MaxHeaderHeightInRems}rem);
  ${({ presentMode }) => presentMode && 'height: 100vh'};
  width: 100%;
  max-height: calc(100vh - ${MaxHeaderHeightInRems}rem);
  ${({ presentMode }) => presentMode && 'max-height: 100vh'};
  ${({ presentMode }) => (presentMode ? `position: absolute;` : '')};
  ${({ presentMode }) => (presentMode ? `top: 0;` : '')};
  ${({ presentMode }) => (presentMode ? `left: 0;` : '')};
  ${({ presentMode }) => (presentMode ? `max-width: 100vw;` : '')};
  ${({ presentMode }) => (presentMode ? `min-width: 100vw;` : '')};
`;

export const DealFlowPreLoader = () => {
  const initialized = useInitDealBoard();

  return initialized ? <DealFlow /> : <DealBoardSkeletonLoader />;
};

export function DealFlow() {
  const [isOpenDrawer, setIsOpenDrawer] = useAtom(isOpenDrawerState);
  const isPresentMode = useAtomValue(isPresentModeState);
  const setDealCategory = useSetAtom(selectedDealCategoryState);
  const { showDealDrawer2 } = useFlags<MaggieFeatureFlags>();

  const initialized = useSyncFromPath();

  useEffect(() => {
    return () => setDealCategory(DefaultDealCategory);
  }, [setDealCategory]);

  if (!initialized) return null;

  return (
    <>
      <Drawer
        anchor={'right'}
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        disablePortal={true}
      >
        <div className={isPresentMode ? 'presentModeOn' : ''} css={drawerContainer}>
          {showDealDrawer2 ? (
            <GenericFallbacksWrapper>
              <DealDrawer2 />
            </GenericFallbacksWrapper>
          ) : (
            <DealDrawer />
          )}
        </div>
      </Drawer>
      <>
        <Container presentMode={isPresentMode}>
          <DealFlowHeaderV2 />
          <FilterOptions />
          <GenericFallbacksWrapper>
            <Outlet />
          </GenericFallbacksWrapper>
        </Container>
      </>
    </>
  );
}

export function DealsView() {
  const selectedDealCategory = useAtomValue(selectedDealCategoryState);

  return (
    <GenericFallbacksWrapper>
      {selectedDealCategory.id === DealBoardDealCategory.CURRENT ? <DealBoard /> : <CategoryDealTable />}
    </GenericFallbacksWrapper>
  );
}
