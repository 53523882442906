import { cloneDeep } from 'lodash-es';
import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { companyMetricsByIdState } from '../state/CompanyMetricsByIdState';
import { partialEqualsIgnoreUnsetValues } from '../../util/partialEquals';
import { updateCompany } from '../queries/MaggieCompanyQueries';
import { getErrorMessage } from '../queryHelpers';
import { companyState } from '../state/CompanyState';
import { getForesightStore } from '../../util/jotai-store';
import { companyStateJ } from '../state/CompanyStateJ';

export function useUpdateCompanyAndState() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const store = getForesightStore();

  return useRecoilCallback(
    ({ snapshot, gotoSnapshot, set }) =>
      async (
        companyId: number,
        companyUpdates: Partial<ICompanyDataModel>,
        showSuccessMessage = true,
        optimisticallyUpdateCompanyMetrics = false,
        awaitBeforeUpdate = false
      ) => {
        const currentCompany = snapshot.getLoadable(companyState(companyId))?.valueMaybe();

        if (partialEqualsIgnoreUnsetValues(companyUpdates, currentCompany)) return currentCompany;

        if (currentCompany && !awaitBeforeUpdate) {
          const updatedCompany = {
            ...cloneDeep(currentCompany),
            ...companyUpdates,
          };

          const updatedSnapshot = snapshot.map((mutableSnapshot) => {
            mutableSnapshot.set(companyState(companyId), updatedCompany);
            if (optimisticallyUpdateCompanyMetrics) {
              const currentCompanyMetrics = snapshot
                .getInfo_UNSTABLE(companyMetricsByIdState(companyId))
                .loadable?.valueMaybe();
              if (currentCompanyMetrics) {
                mutableSnapshot.set(companyMetricsByIdState(companyId), {
                  ...currentCompanyMetrics,
                  included: [cloneDeep(updatedCompany)],
                });
              }
            }
          });
          gotoSnapshot(updatedSnapshot);
        }

        const release = snapshot.retain();
        try {
          const updatedCompany = await updateCompany({
            payload: prepareCompanyPayload(companyUpdates),
            companyId,
          });
          store.set(companyStateJ(companyId), updatedCompany);
          if (awaitBeforeUpdate) {
            set(companyState(companyId), updatedCompany);

            if (optimisticallyUpdateCompanyMetrics) {
              const currentCompanyMetrics = snapshot
                .getInfo_UNSTABLE(companyMetricsByIdState(companyId))
                .loadable?.valueMaybe();
              if (currentCompanyMetrics) {
                set(companyMetricsByIdState(companyId), {
                  ...currentCompanyMetrics,
                  included: [updatedCompany],
                });
              }
            }
          }

          if (showSuccessMessage) {
            pushSuccessToast({ message: 'Successfully updated company' });
          }
          return updatedCompany;
        } catch (err) {
          const message = getErrorMessage(err, 'Failed to update company');
          gotoSnapshot(snapshot);
          pushErrorToast({ message });
        } finally {
          release();
        }
      }
  );
}

export function prepareCompanyPayload(data: Partial<ICompanyDataModel>) {
  const payload = cloneDeep(data);
  const internalDescription = data.shortDescription;
  delete payload.shortDescription;
  return { ...payload, internalDescription };
}
