import { Autocomplete, TextField } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { allRoundsOptionsState, selectedRoundsState } from '../state/DealboardUIState';
import { commonMultiselectProps } from './commonMultiselectProps';

export const RoundFilter: FC = () => {
  const allRoundsOptions = useAtomValue(allRoundsOptionsState);
  const [selectedRounds, setSelectedRounds] = useAtom(selectedRoundsState);

  const value = useMemo(
    () =>
      allRoundsOptions.length === selectedRounds.length
        ? []
        : (allRoundsOptions.filter((opt) => selectedRounds?.includes(opt.id as number | null)) as Option[]),
    [allRoundsOptions, selectedRounds]
  );

  const handleSelectRounds = useCallback(
    (e: SyntheticEvent, options: Option[] | undefined) => {
      if (!allRoundsOptions || !options) return;
      if (!options?.length) {
        setSelectedRounds(allRoundsOptions.map((opt) => opt.id as number));
        return;
      }
      setSelectedRounds(options.map((opt) => opt.id as number));
    },
    [allRoundsOptions, setSelectedRounds]
  );

  if (!allRoundsOptions) return null;

  return (
    <Autocomplete
      multiple
      options={allRoundsOptions}
      getOptionLabel={(option) => option.value}
      value={value}
      onChange={handleSelectRounds}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value?.length ? '' : 'Filter by Stage'}
          aria-label='no-label'
          sx={{ '& .MuiInputBase-root.Mui-focused': { background: 'white' } }}
        />
      )}
      disablePortal
      {...commonMultiselectProps}
    />
  );
};
