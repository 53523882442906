import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { TrackDealFormViewModel, trackDealVMSchema } from '../../../schemas/deal.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { useDealActions2 } from '../hooks/useDealActions';
import { IDealActionFormProps } from './PassDealForm';

export function TrackDealForm({ deal, onClose, next }: IDealActionFormProps) {
  const schema = trackDealVMSchema();
  const { handleTrack } = useDealActions2();
  const [loading, setLoading] = useState(false);

  const methods = useForm<TrackDealFormViewModel>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: TrackDealFormViewModel) => {
      setLoading(true);
      const { trackComments, snoozeDate } = data;
      await handleTrack(deal, trackComments ?? '', snoozeDate ?? undefined);
      setLoading(false);
      next?.();
      onClose();
    },
    [deal, handleTrack, next, onClose]
  );

  return (
    <FormProvider {...methods}>
      <FormFieldsContainer>
        <TrackDealFields schema={schema} />
        <StickyFormButtons
          onSubmit={methods.handleSubmit(onSubmit)}
          onCancel={onClose}
          style={{ padding: '0.5rem 0 2rem' }}
          submitLabel={'Move to Track Mode'}
          loading={loading}
        />
      </FormFieldsContainer>
    </FormProvider>
  );
}

function TrackDealFields({ schema }: { schema: ObjectSchema<TrackDealFormViewModel> }) {
  const formFields = schemaToFormFields(schema);
  const form = createFormFromFieldsArray(formFields);
  return <FormFactoryWithStandardLayout form={form} />;
}
