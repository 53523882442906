import { Stack, Tab, Tabs } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NavLink, useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../constants/RouteNameMapping';
import { KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import {
  ClientSettingsPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../../../data-models/preferences-v3.data-model';
import { RoleKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { kpiRequestsCountState } from '../../../../services/state/KPI/KPIRequestsState';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { PreferencesServiceV3 } from '../../../../util/preferences-service-v3';
import { CountBadge, KPITabSx } from './TabComponents';
import { KpiRequestStatusByGroup } from './KPIResponsesGrid';

export default function KPIResponsesTabs() {
  const { pathname } = useLocation();
  const requestCount = useRecoilValue(kpiRequestsCountState);
  const tabs = useResponseTabs(pathname, requestCount);

  return (
    <Tabs variant='scrollable' scrollButtons='auto' value={pathname}>
      {tabs.map((t) => (
        <Tab
          component={NavLink}
          key={t.path}
          value={t.path}
          label={t.title}
          style={{ paddingLeft: 0, marginRight: '1rem' }}
          to={{ pathname: `${t.path}` }}
          sx={KPITabSx}
        />
      ))}
    </Tabs>
  );
}

function useResponseTabs(pathname: string, requestCount: Record<KPIRequestStatus, number>) {
  const { useManagedServices } = useFlags<MaggieFeatureFlags>();
  const showMs =
    useManagedServices &&
    Boolean(
      PreferencesServiceV3.get().getPreference<ClientSettingsPreferences>(
        PreferenceKey.clientSettings,
        PreferenceScope.ORGANIZATION
      )?.managedServices
    );
  if (PermissionService.get().hasRole(RoleKey.managedServices)) {
    return getTabsForMsRole(pathname, requestCount);
  }
  return getDefaultTabs(pathname, requestCount, showMs);
}

function getDefaultTabs(
  pathname: string,
  requestCount: Record<KPIRequestStatus, number>,
  showManagedServices: boolean
) {
  const toReviewWithMs = KpiRequestStatusByGroup[KPIRequestStatus.Submitted];
  const submittedCount = !showManagedServices
    ? requestCount[KPIRequestStatus.Submitted]
    : toReviewWithMs.reduce((acc, curr) => {
        return (acc += requestCount[curr]);
      }, 0);
  const result = [
    {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`,
      title: (
        <Stack direction='row'>
          To Review
          <CountBadge
            badgeContent={submittedCount}
            active={pathname === `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`}
          />
        </Stack>
      ),
    },
    {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_ACCEPTED}`,
      title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_ACCEPTED],
    },
    {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_REJECTED}`,
      title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_REJECTED],
    },
  ];
  if (showManagedServices) {
    result.splice(1, 0, {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_MS_REVIEWED}`,
      title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_MS_REVIEWED],
    });
  }
  return result;
}

function getTabsForMsRole(pathname: string, requestCount: Record<KPIRequestStatus, number>) {
  return [
    {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_MS_PENDING}`,
      title: (
        <Stack direction='row'>
          {ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_MS_PENDING]}
          <CountBadge
            badgeContent={requestCount[KPIRequestStatus.ManagedServiceSubmitted]}
            active={pathname === `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_MS_PENDING}`}
          />
        </Stack>
      ),
    },
    {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_MS_IN_PROGRESS}`,
      title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_MS_IN_PROGRESS],
    },
    {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_MS_REVIEWED}`,
      title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_MS_REVIEWED],
    },
    {
      path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_REJECTED}`,
      title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_REJECTED],
    },
  ];
}
