import {
  Button,
  Stack,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Controller,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useRecoilValueLoadable } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { kpiTemplatesListState } from '../../services/state/KPI/KPITemplatesState';
import { PageContainer } from '../../components/Layout/PageContainer';
import { ROUTES } from '../../constants/RouteNameMapping';
import { createKPITemplateDataModel, IKPITemplate } from '../../data-models/kpi-template.data-model';
import { colors } from '../../theme/colors';
import { MaggieFeatureFlags } from '../../util/feature-flags';
import { PreferencesServiceV3 } from '../../util/preferences-service-v3';
import {
  ClientSettingsPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../data-models/preferences-v3.data-model';
import { KPITemplateFormBuilder } from './components/KPITemplateFormBuilder';
import { useKPITemplateActions } from './hooks/useKPITemplateActions';
import { assignSortOrderTemplateSections, sortTemplateSections } from './utils';
import { KpiTemplateFormNavBlocker } from './KpiTemplateFormNavBlocker';

const ActionButtons = styled('div')`
  position: fixed;
  top: 0.8rem;
  right: 2rem;
`;

const TemplateNameTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'transparent',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

export function KPITemplate() {
  const selectedTemplate = useCurrentTemplate();

  const { colors } = useTheme();
  const methods = useForm<IKPITemplate>({
    mode: 'onTouched',
    values: selectedTemplate,
  });
  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    // Auto focus on name field
    setTimeout(() => methods.setFocus('name'), 50);
  }, [methods, selectedTemplate]);

  return (
    <PageContainer>
      <FormProvider {...methods}>
        {selectedTemplate?.id && <ActionBar template={selectedTemplate} />}
        <Stack direction='row' justifyItems={'space-between'} alignItems={'center'}>
          <Controller
            name='name'
            control={methods.control}
            defaultValue={selectedTemplate?.name || ''}
            render={({ field: { ref, ...field } }) => (
              <TemplateNameTextField
                {...field}
                required
                fullWidth
                sx={{
                  '& fieldset': { border: 'none' },
                  marginLeft: '-1rem',
                }}
                placeholder='Template Name'
                error={!!errors.name}
                helperText={errors.name ? <>{errors.name?.message}</> : ''}
                inputRef={ref}
                inputProps={{
                  style: {
                    fontSize: '1.5rem',
                    fontWeight: 300,
                    color: colors.primary[60],
                  },
                }}
              />
            )}
          />
          <IsManagedServiceField />
        </Stack>
        <Stack direction='row' gap={'.1rem'} width={'100%'} mt={'1.5rem'}>
          <KPITemplateFormBuilder />
        </Stack>
      </FormProvider>
    </PageContainer>
  );
}

interface IActionBarProps {
  template: IKPITemplate;
}
function ActionBar(props: IActionBarProps) {
  const { formState, watch, handleSubmit, trigger } = useFormContext();
  const { isDirty, isValid, isSubmitting } = formState;
  const name = watch('name').trim();
  const disableSave = useMemo(() => {
    return (!isDirty && isValid && !isSubmitting) || !name;
  }, [isDirty, isSubmitting, isValid, name]);
  const navigate = useNavigate();
  const { updateKPITemplateAction: updateKPITemplate, createKPITemplateAction } = useKPITemplateActions();

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    async (data: FieldValues) => {
      const dataWithSortedSections = assignSortOrderTemplateSections(data);

      if (props.template.id === -1) {
        const kpiTemplate = await createKPITemplateAction(dataWithSortedSections);

        if (!kpiTemplate?.id) return;

        return navigate(`/${ROUTES.KPI_TEMPLATES_FULL_ROUTE}/${kpiTemplate.id}`, {
          state: { isProgrammatic: true },
        });
      }
      await updateKPITemplate(dataWithSortedSections);
    },
    [createKPITemplateAction, navigate, props.template.id, updateKPITemplate]
  );

  return (
    <>
      <ActionButtons>
        <Stack direction='row' gap={'.5rem'}>
          <Button
            onClick={handleSubmit(onSubmit)}
            color='secondary'
            variant='contained'
            disabled={disableSave}
          >
            Save Changes
          </Button>
        </Stack>
      </ActionButtons>
      <KpiTemplateFormNavBlocker onSave={handleSubmit(onSubmit)} trigger={trigger} isDirty={isDirty} />
    </>
  );
}

function useCurrentTemplate() {
  const { templateId } = useParams();
  const isNewTemplate = useMemo(() => templateId === 'new', [templateId]);
  const templateList = useRecoilValueLoadable(kpiTemplatesListState).valueMaybe();

  return useMemo(() => {
    if (!templateList || isNewTemplate) {
      return createKPITemplateDataModel({
        id: -1,
        name: '',
        sections: [],
      });
    }

    const selectedTemplated = templateList.find((template) => `${template.id}` === templateId)!;

    return sortTemplateSections(selectedTemplated) as IKPITemplate;
  }, [isNewTemplate, templateId, templateList]);
}

const managedServiceInfo =
  'Default templates require VC approval only. The managed services templates also permit the dedicated team to accept or reject KPI requests submitted by PortCos.';
function IsManagedServiceField() {
  const methods = useFormContext();
  const { useManagedServices } = useFlags<MaggieFeatureFlags>();
  const clientUsesManagedServices = PreferencesServiceV3.get().getPreference<ClientSettingsPreferences>(
    PreferenceKey.clientSettings,
    PreferenceScope.ORGANIZATION
  )?.managedServices;

  if (!useManagedServices || !clientUsesManagedServices) return null;

  return (
    <Stack direction='row' gap={'.5rem'}>
      <Controller
        name='isManagedService'
        control={methods.control}
        render={({ field }) => {
          return (
            <ToggleButtonGroup
              color='secondary'
              size='small'
              exclusive
              value={field.value ?? false}
              onChange={(e, newValue: boolean | null) => {
                if (newValue == null) return;
                field.onChange(newValue);
              }}
            >
              <ToggleButton value={false}>Default</ToggleButton>
              <ToggleButton value={true} sx={{ width: 'max-content' }}>
                Managed Service
              </ToggleButton>
            </ToggleButtonGroup>
          );
        }}
      />
      <Tooltip title={managedServiceInfo}>
        <InfoOutlinedIcon htmlColor={colors.neutral[60]} />
      </Tooltip>
    </Stack>
  );
}
