import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import * as yup from 'yup';
import { RendererType } from '../../../data-models/field.data-model';
import { ICompanySearchMeta } from '../../../data-models/field3.data-model';
import {
  CompanySearchResponse,
  CompanySearchResponseSchema,
} from '../../../schemas/CompanySearchResponse.schema';
import {
  FundInvestment,
  fundInvestmentTransactionFields,
  InvestmentType,
} from '../../../schemas/FundReserves.schema';
import { SearchType } from '../../../services/queries/MaggieCompanyQueries';
import { createFormField } from '../../../view-models/form.view-model';
import { selectedFundInvestmentsByCompanyNameState } from '../state/FPState';

export function addInitialInvestmentSchema() {
  const { amount, date, hypothetical } = fundInvestmentTransactionFields();
  const { company } = selectCompanyField();
  return yup.object({
    amount: amount.nullable().required().label('Initial Investment Amount').default(null),
    company,
    date: date.nullable().required().label('Initial Investment Date').default(null),
    hypothetical: hypothetical.default(true),
    type: yup.string().nullable().required().default(InvestmentType.Initial),
  });
}

export function addFollowOnInvestmentSchema() {
  const { amount, date, hypothetical } = fundInvestmentTransactionFields();
  const { company } = selectCompanyField();
  return yup.object({
    amount: amount.nullable().required().label('Assigned Reserves').default(null),
    company,
    date: date.nullable().required().label('Transaction Date').default(null),
    hypothetical: hypothetical.default(true),
    type: yup.string().nullable().required().default(InvestmentType.FollowOn),
  });
}

export type AddInvestmentSchema = yup.InferType<ReturnType<typeof addInitialInvestmentSchema>>;

export function selectCompanyField() {
  return {
    company: yup.object(CompanySearchResponseSchema.base()).required().label('Select Company'),
  };
}

export function useGetInvestmentSchemaForCompany() {
  const companyNameToInvestment = useAtomValue(selectedFundInvestmentsByCompanyNameState);

  return useCallback(
    (company?: CompanySearchResponse | null) => {
      return getInvestmentSchema({ company, nameToInvestment: companyNameToInvestment });
    },
    [companyNameToInvestment]
  );
}

interface IInvestmentSchemaGetterParams {
  company?: CompanySearchResponse | null;
  nameToInvestment: Map<string, FundInvestment>;
}
export function getInvestmentSchema({ company, nameToInvestment }: IInvestmentSchemaGetterParams) {
  const existingInvestment = nameToInvestment.get(company?.fields.name ?? '');
  if (existingInvestment) {
    return addFollowOnInvestmentSchema();
  } else {
    return addInitialInvestmentSchema();
  }
}

export const companyField = createFormField<ICompanySearchMeta>({
  key: 'company',
  label: 'Select Company',
  renderer: RendererType.companySearch,
  rendererMeta: {
    addLabel: (inputText: string) => `Create new company "${inputText}"`,
    createOnSelection: false,
    multiSelect: false,
    showAdd: true,
    searchType: SearchType.Company,
  },
});
