import { Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta, KPI_COMPONENTS } from '../../../../types';
import { format } from './kpi-formatter';

export class TotalValueConfig implements KpiConfig {
  private id = KPI_COMPONENTS.TOTAL_VALUE as const;
  private title = 'Total Value';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ kpis, data }: KpiCalculationParams): Kpi {
    const escrowAmount =
      data?.metrics.reduce((sum, curr) => {
        return sum + (curr.escrowAmount ?? 0);
      }, 0) ?? 0;
    const value =
      (kpis?.[KPI_COMPONENTS.TOTAL_REALIZED_VALUE]?.value ?? 0) +
      (kpis?.[KPI_COMPONENTS.TOTAL_UNREALIZED_VALUE]?.value ?? 0) +
      escrowAmount;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
