import {
  ICompanyFinancialsDataModel,
  KpiPeriod,
  KpiSection,
} from '../../../data-models/company-financials.data-model';
import { FormatterService } from '../../../util/formatter-service';
import { IField } from '../../../data-models/field2.data-model';
import { RendererType } from '../../../data-models/field.data-model';

export interface TypedFinancials
  extends Pick<
    ICompanyFinancialsDataModel,
    'id' | 'kpiId' | 'companyId' | 'period' | 'section' | 'date' | 'calculated' | 'currencyId'
  > {
  displayName: string;
  // all values come as strings, so parse if type is anything but string or date
  value: number | string | number[] | string[] | boolean | boolean[] | Date;
  valueFormatted: string;
}

export function getTypedFinancials(
  financials: ICompanyFinancialsDataModel[],
  kpiConfigMap: Map<number, IField<unknown>>
): TypedFinancials[] {
  // config.id corresponds to financials.kpiId
  return financials.reduce((acc, curr) => {
    const { kpiId, period, value, id, companyId, date, section } = curr;
    const configForParameter = kpiConfigMap.get(kpiId);

    if (!configForParameter) return acc;

    try {
      const valueTyped = kpiStringToTypedValue(configForParameter, value);
      const formatter = FormatterService.get().getFormatterForField(configForParameter);
      const valueFormatted = formatter ? formatter(valueTyped) : value;

      const parameterDisplayName = configForParameter.displayName;

      acc.push({
        calculated: curr.calculated,
        currencyId: curr.currencyId,
        id,
        kpiId,
        companyId,
        displayName: parameterDisplayName,
        value: valueTyped,
        valueFormatted,
        date: date.replace(/[T|\s].*/, ''),
        period,
        section,
      });
    } catch (e) {
      console.error(`Error parsing financials for  ${id} (value: ${value})`, e);
    }

    return acc;
  }, [] as TypedFinancials[]);
}

function kpiStringToTypedValue<T>(kpiField: IField<T>, value: string) {
  if (
    (kpiField.formMeta?.renderer?.type === RendererType.date ||
      kpiField.formMeta?.formatter?.type === 'date' ||
      kpiField.formMeta?.formatter?.id === 'date') &&
    isDateString(value)
  ) {
    return new Date(value);
  }
  if (kpiField.type === 'number') return parseFloat(value);
  return value;
}

export function isDateString(value: string | null | undefined) {
  return value != null && !isNaN(Date.parse(value));
}

export function createTypedFinancialsDataModel(overrides: Partial<TypedFinancials>): TypedFinancials {
  return {
    calculated: false,
    currencyId: 1,
    id: 0,
    kpiId: 0,
    companyId: 0,
    displayName: '',
    value: '',
    valueFormatted: '',
    date: '',
    period: KpiPeriod.month,
    section: KpiSection.actual,
    ...overrides,
  };
}
