import {
  AgGridEvent,
  ColDef,
  GetRowIdParams,
  GridPreDestroyedEvent,
  GridReadyEvent,
} from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { AgTableWithBaseStyle } from '../../../../../components/AgTable/AgTable2';
import { KpiSection } from '../../../../../data-models/company-financials.data-model';
import { field2ToFormField, IField } from '../../../../../data-models/field2.data-model';
import {
  KPIRequestFrequency,
  periodFromGranularityAndFrequency,
} from '../../../../../data-models/kpi-requests.data-model';
import { kpiConfigAtom } from '../../../../../services/state/KPIConfigState';
import { companyProfileFinancialsDefaultColDef } from '../../../../CompanyProfiles/commonGridColDefs';
import { generateKPITableColHeaderData, KPITableColHeaderData } from '../../../utils';
import { headerDataToColDef, IKPIRowData } from './KPIAgGrid';

export interface IFinancialsRowData extends IKPIRowData {
  section: KpiSection;
}

export interface MSFinancialsGridProps {
  companyFYEDateString: string;
  disabled: boolean;
  kpiRequestFrequency: KPIRequestFrequency;
  kpiRequestGranularity: KPIRequestFrequency | null;
  kpiRequestPeriod: string;
  savedRowData?: IFinancialsRowData[];
  onDataUpdate: (rowData: IFinancialsRowData[]) => void | Promise<void>;
}

const defaultColDef = {
  ...companyProfileFinancialsDefaultColDef,
  flex: 1,
};

export function MSFinancialsGrid(props: MSFinancialsGridProps) {
  const {
    companyFYEDateString,
    disabled,
    kpiRequestFrequency,
    kpiRequestGranularity,
    kpiRequestPeriod,
    savedRowData,
    onDataUpdate,
  } = props;

  const reqPeriod = periodFromGranularityAndFrequency(kpiRequestGranularity, kpiRequestFrequency);

  const gridHeaderData = useMemo(() => {
    const gridHeaderDataForReqPeriod = generateKPITableColHeaderData({
      fyeDateString: companyFYEDateString,
      periodISODate: kpiRequestPeriod,
      kpiRequestFrequency,
      selectedFrequency: reqPeriod,
    });
    const gridHeaderDataForNextPeriod = generateKPITableColHeaderData({
      fyeDateString: companyFYEDateString,
      periodISODate: kpiRequestPeriod,
      kpiRequestFrequency,
      selectedFrequency: reqPeriod,
      nextPeriod: true,
    });

    return gridHeaderDataForReqPeriod.concat(gridHeaderDataForNextPeriod);
  }, [companyFYEDateString, kpiRequestFrequency, kpiRequestPeriod, reqPeriod]);

  const columnDefs = useMemo(() => {
    return generateKPIColDefs(gridHeaderData, disabled);
  }, [gridHeaderData, disabled]);

  const kpis = useAtomValue(kpiConfigAtom);

  const rowData = useMemo(() => {
    if (savedRowData) return savedRowData;
    return generateEmptyKPIGridRowData(gridHeaderData, kpis);
  }, [gridHeaderData, kpis, savedRowData]);

  function getRowId(params: GetRowIdParams<IFinancialsRowData>) {
    return `${params.data.kpiId}-${params.data.section}`;
  }

  const onDataChange = useCallback(
    (event: AgGridEvent) => {
      const updatedRowData = event.api.getGridOption('rowData');
      if (updatedRowData) {
        onDataUpdate(updatedRowData);
      }
    },
    [onDataUpdate]
  );

  function onGridReady(event: GridReadyEvent) {
    event.api.addEventListener('cellValueChanged', onDataChange);
    event.api.addEventListener('pasteEnd', onDataChange);
  }

  function onGridPreDestroyed(event: GridPreDestroyedEvent) {
    event.api.removeEventListener('cellValueChanged', onDataChange);
    event.api.removeEventListener('pasteEnd', onDataChange);
  }

  return (
    <AgTableWithBaseStyle
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      onGridReady={onGridReady}
      onGridPreDestroyed={onGridPreDestroyed}
      rowData={rowData}
      rowGroupPanelShow='never'
      suppressMovableColumns={true}
      suppressRowDrag={true}
    />
  );
}

function generateKPIColDefs(headerData: KPITableColHeaderData[], disabled: boolean) {
  return headerData.reduce(
    (acc, header) => {
      acc.push(headerDataToColDef(header, disabled) as ColDef<IFinancialsRowData>);
      return acc;
    },
    [
      {
        cellStyle: { textAlign: 'left' },
        field: 'formField.label',
        headerName: 'KPI',
        pinned: true,
        valueGetter: (params) => {
          return `${params.data?.formField?.label ?? ''} (${params?.data?.section ?? ''})`;
        },
        filter: true,
        suppressHeaderMenuButton: false,
        suppressHeaderFilterButton: false,
        filterParams: { buttons: ['reset'] },
      },
    ] as ColDef<IFinancialsRowData>[]
  );
}

function generateEmptyKPIGridRowData(
  gridHeaderData: KPITableColHeaderData[],
  kpis: IField<unknown>[]
): IFinancialsRowData[] {
  return kpis.reduce((acc, kpi) => {
    const formField = field2ToFormField(kpi);
    formField.autoFocus = true;
    formField.fullWidth = true;

    const actualByDate: IFinancialsRowData['valuesByDate'] = {};
    const budgetValuesByDate: IFinancialsRowData['valuesByDate'] = {};
    gridHeaderData.forEach((header) => {
      actualByDate[header.date] = null;
      budgetValuesByDate[header.date] = null;
    });

    acc.push({ kpiId: kpi.id, formField, valuesByDate: actualByDate, section: KpiSection.actual });
    acc.push({ kpiId: kpi.id, formField, valuesByDate: budgetValuesByDate, section: KpiSection.budget });
    return acc;
  }, [] as IFinancialsRowData[]);
}
