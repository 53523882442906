import { ColDef } from 'ag-grid-community';
import { merge } from 'lodash-es';
import { genericComparator } from './comparators';
import { ExcelFormatterIdPrefix } from './excel-formatter-service';
import { DateFormattersId } from './formatters/DateFormatters';

export function withOverrides<T>(defs: ColDef<T>[], overrides: Record<string, ColDef>) {
  const defMap = new Map<string, ColDef<T>>(defs.map((def) => [(def.field ?? def.colId) as string, def]));

  for (const [field, customDefs] of Object.entries(overrides)) {
    if (defMap.has(field)) {
      defMap.set(field, merge({}, defMap.get(field), customDefs));
    }
  }
  return Array.from(defMap.values());
}

export function agDateColumnFilterComparator(
  filterLocalDateAtMidnight: Date,
  cellValue: string | null | undefined
) {
  if (cellValue == null) return 1;

  const filteredIsoDate = filterLocalDateAtMidnight.toISOString().slice(0, 10);
  return cellValue === filteredIsoDate ? 0 : cellValue > filteredIsoDate ? 1 : -1;
}

const isoDateColumnDefBase = {
  cellClass: ['monospace', 'text-align-right', `${ExcelFormatterIdPrefix}${DateFormattersId.date}`],
  comparator: genericComparator,
  filter: 'agDateColumnFilter',
  filterParams: {
    comparator: agDateColumnFilterComparator,
  },
};

export function getIsoDateColDef<T>(overrides: Partial<ColDef> = {}): ColDef<T> {
  return merge({}, isoDateColumnDefBase, overrides) as ColDef<T>;
}
