import { capitalize, merge } from 'lodash-es';
import * as yup from 'yup';
import { createCompanySearchResultFields } from '../data-models/company-search-result.data-model';
import { RendererType } from '../data-models/field.data-model';
import {
  createListDataModel,
  IListDataModel,
  ListSharingType,
  ListSubtype,
  ListType,
} from '../data-models/list.data-model';
import { usersAtom } from '../services/state/AppConfigStateJ';
import { getForesightStore } from '../util/jotai-store';
import { CompanySearchResponse, createCompanySearchResponse } from './CompanySearchResponse.schema';
import { companyFields } from './Company.schema';
import { companyNameSearchField } from './common-schema-defs';

export function companyListFormFields() {
  const users = getForesightStore().get(usersAtom);
  const { source, sourceType } = companyFields();

  return {
    id: yup.number(),
    internalSource: yup
      .number()
      .nullable()
      .formMeta({
        formatter: 'user',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: users.map((user) => ({
            displayName: user.name,
            value: user.id,
          })),
        },
      }),
    name: yup.string().required().label('List Name'),
    sharing: yup
      .string<ListSharingType>()
      .oneOf(Object.values(ListSharingType))
      .required()
      .default(ListSharingType.PRIVATE)
      .formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.values(ListSharingType).map((value) => ({
            displayName: capitalize(value),
            value,
          })),
        },
      }),
    source,
    sourceCompany: companyNameSearchField(),
    sourceType,
  };
}

export function companyListFormSchema() {
  return yup.object(companyListFormFields());
}
export interface CompanyListFormData extends Omit<Partial<IListDataModel>, 'sourceCompany'> {
  sourceCompany?: CompanySearchResponse | string | null;
}

export function toUserCreatedCompanyList(data: CompanyListFormData): IListDataModel {
  return merge({
    ...createListDataModel({
      subtype: ListSubtype.DATA_SCIENCE,
      type: ListType.DISCOVERY,
    }),
    ...data,
  });
}

export function fromListDataModel(data: IListDataModel): CompanyListFormData & { id: number } {
  return {
    ...data,
    sourceCompany: createCompanySearchResponse({
      fields: createCompanySearchResultFields({
        name: data.sourceCompany,
      }),
    }),
  };
}
