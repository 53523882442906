import { WritableAtom } from 'jotai';
import { SetStateAction, useMemo } from 'react';
import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../../../../components/ToastMessage/ToastMessageProvider';
import { IKPIRequestDataModel } from '../../../../../../data-models/kpi-requests.data-model';
import {
  managedServiceFinishReview,
  managedServiceStartReview,
} from '../../../../../../services/queries/KPIRequestsQueries';
import { getErrorMessage } from '../../../../../../services/queryHelpers';
import { kpisRequestByIdState } from '../../../../../../services/state/KPI/KPIRequestsState';
import { getForesightStore } from '../../../../../../util/jotai-store';
import { KPIReviewState } from './useKPIRequestReviewActions';

export type KPIReviewStateAtom = WritableAtom<KPIReviewState, [SetStateAction<KPIReviewState>], unknown>;

export function useManagedServiceActions(stateAtom: KPIReviewStateAtom, store = getForesightStore()) {
  const { get, set } = store;

  return useMemo(() => {
    const startReview = async () => {
      const { currentRequest } = get(stateAtom);
      set(stateAtom, (prev) => ({ ...prev, isSubmitting: true }));

      let updatedRequest: IKPIRequestDataModel;
      try {
        updatedRequest = await managedServiceStartReview(currentRequest.id);
      } catch (err) {
        set(stateAtom, (prev) => ({
          ...prev,
          submissionError: getErrorMessage(err, 'Failed to start review'),
        }));
      }

      set(stateAtom, (prev) => {
        return {
          ...prev,
          isSubmitting: false,
          currentRequest: { ...updatedRequest },
        };
      });
    };

    const finishReview = async () => {
      const { currentRequest } = get(stateAtom);
      set(stateAtom, (prev) => ({ ...prev, isSubmitting: true }));

      let updatedRequest: IKPIRequestDataModel;
      try {
        updatedRequest = await managedServiceFinishReview(currentRequest.id);
      } catch (err) {
        set(stateAtom, (prev) => ({
          ...prev,
          submissionError: getErrorMessage(err, 'Failed to finish review'),
        }));
      }

      set(stateAtom, (prev) => {
        return {
          ...prev,
          isSubmitting: false,
          currentRequest: { ...updatedRequest },
        };
      });
    };

    return {
      finishReview,
      startReview,
    };
  }, [get, stateAtom, set]);
}
export type ManagedServiceActions = ReturnType<typeof useManagedServiceActions>;

/**@deprecated: implemented for v1 of kpi response form */
export function useManagedServiceActionsV1() {
  const { pushErrorToast } = useToastMessageState();

  const startReview = useRecoilCallback(
    ({ set }) =>
      async (requestId: number) => {
        try {
          const updatedRequest = await managedServiceStartReview(requestId);
          set(kpisRequestByIdState(requestId), updatedRequest);
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Failed to start review') });
        }
      },
    []
  );

  const finishReview = useRecoilCallback(
    ({ set }) =>
      async (requestId: number) => {
        try {
          const updatedRequest = await managedServiceFinishReview(requestId);
          set(kpisRequestByIdState(requestId), updatedRequest);
        } catch (err) {
          pushErrorToast({ message: getErrorMessage(err, 'Failed to finish review') });
        }
      },
    []
  );

  return {
    finishReview,
    startReview,
  };
}
