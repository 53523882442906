import { css } from '@emotion/react';
import { TearSheetViewModel } from '@foresightdata/tear-sheet';
import React from 'react';
import { FinancingHistory } from './FinancingHistory';
import { PerformanceSummary } from './PerformanceSummary';
import { SummarySection } from './SummarySection';
import { TextSection } from './TextSection';

const tearSheetContainerStyle = css`
  padding: 2rem 3rem;
  max-width: 1200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  color: #333;
  border: thin solid blue;
  overflow: auto;
  background: white;

  @media print {
    padding: 0;
    margin: 2rem;
    width: calc(100vw - 4rem);
    height: calc(100vh - 4rem);
    max-width: none;
    max-height: none;
    font-size: 70%;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const headerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
  padding: 0.1rem 0.15in;
  border-bottom: 1px solid #eee;
  border: thin solid red;

  @media print {
    margin: 0;
    padding: 0.1in 0.15in;
  }
`;

const logoStyle = css`
  font-size: 1.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2d3748;

  @media print {
    font-size: 1rem;
  }

  img {
    height: 2rem;
    width: auto;
    object-fit: contain;

    @media print {
      height: 1.5rem;
    }
  }
`;

const clientInfoStyle = css`
  text-align: right;
  font-size: 0.9rem;
  color: #666;

  @media print {
    font-size: 0.7rem;
  }
`;

const mainGridStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media print {
    gap: 0.25rem;
    padding: 0 0.15in;
  }
`;

const sectionStyle = css`
  margin-bottom: 2rem;

  @media print {
    margin-bottom: 0.25rem;
    page-break-inside: avoid;
  }
`;

const footerStyle = css`
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  color: #718096;

  @media print {
    margin: 0;
    padding: 0.1in 0.15in;
    font-size: 0.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #e2e8f0;
  }
`;

const disclaimerStyle = css`
  flex: 1;
`;

const pageNumberStyle = css`
  text-align: right;
`;

interface TearsheetProps {
  data: TearSheetViewModel;
}

export const TearSheet: React.FC<TearsheetProps> = ({ data }) => {
  return (
    <div css={tearSheetContainerStyle}>
      <header css={headerStyle}>
        <div css={logoStyle}>
          {data.logoUrl ? <img src={data.logoUrl} alt={`${data.logoUrl} logo`} /> : <span></span>}
          {data.companyName}
        </div>
        <div css={clientInfoStyle}>
          <div>{data.clientName}</div>
          <div>{data.date}</div>
        </div>
      </header>

      <div css={mainGridStyle}>
        <div>
          <div css={sectionStyle}>
            <SummarySection title='Company Summary' items={data.companySummary.values} />
          </div>

          <div css={sectionStyle}>
            <TextSection
              title='Performance Commentary'
              content={data.performanceCommentary}
              isCommentary={true}
            />
          </div>
        </div>

        <div>
          <div css={sectionStyle}>
            <TextSection title='Description' content={data.companyDescription} />
          </div>

          <div css={sectionStyle}>
            <SummarySection
              title={`Investment Summary (${data.fundName})`}
              items={data.investmentSummary.values}
            />
          </div>

          <div css={sectionStyle}>
            <PerformanceSummary
              title={`Performance Summary (${data.performanceSummary.date})`}
              data={data.performanceSummary.values}
            />
          </div>

          <div css={sectionStyle}>
            <FinancingHistory title='Financing History' data={data.financingHistory} />
          </div>
        </div>
      </div>

      <footer css={footerStyle}>
        <div css={disclaimerStyle}>
          CONFIDENTIAL - For internal use only. This document contains confidential and proprietary
          information. Do not distribute without proper authorization.
        </div>
        <div css={pageNumberStyle}>Page 1 of 1</div>
      </footer>
    </div>
  );
};
