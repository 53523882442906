import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { FC, useCallback } from 'react';
import { ZINDEX } from '../../../constants/styles';
import { dealBoardConfigState, dealTypesByIdMapState } from '../../DealFlow2/state/DealboardDataState';

interface DealTypeSelectProps {
  value: number | null;
  onChange: (id: number) => void;
  onFocus?: () => void;
}

export const DealTypeSelect: FC<DealTypeSelectProps> = ({ value, onChange, onFocus }) => {
  const dealTypesMap = useAtomValue(dealTypesByIdMapState);
  const dealTypes = useAtomValue(dealBoardConfigState).dealTypes;
  const { colors } = useTheme();

  const handleChange = useCallback(
    (e: SelectChangeEvent<number | undefined>) => {
      const newValue = e.target.value;
      if (newValue !== undefined) onChange(Number(newValue));
    },
    [onChange]
  );

  if (!dealTypes || !dealTypesMap) return null;
  return (
    <Select
      aria-label={'select-deal-type'}
      value={value ?? ''}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label', sx: { fontSize: '0.875rem' } }}
      renderValue={(value) =>
        !value ? (
          <span style={{ color: colors.neutral[40] }}>Select Deal Type</span>
        ) : (
          (dealTypesMap.get(value)?.displayName ?? 'Unknown')
        )
      }
      onFocus={onFocus}
      MenuProps={{ sx: { zIndex: ZINDEX.MENU_INDEX } }}
      IconComponent={ExpandMoreIcon}
      sx={{ '& .MuiSelect-iconOutlined': { fill: colors.neutral[40] } }}
    >
      {dealTypes.map((dealType) => (
        <MenuItem key={dealType.id} value={dealType.id}>
          {dealType.displayName}
        </MenuItem>
      ))}
    </Select>
  );
};
