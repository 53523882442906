import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { get } from 'lodash-es';
import { getFormatterModelForField3, IField3 } from '../data-models/field3.data-model';
import { IDisplayField } from '../view-models/display-field.view-model';
import {
  defaultFormatter,
  FormatterFn,
  FormatterService,
  IPartialFormatterDataModel,
  StandardFormatterId,
} from './formatter-service';
import { isObject } from './object-util';

export class AgFormatterService {
  static #instance: AgFormatterService | undefined;

  getFormatterForFieldV3<TData, TValue>(field: IField3<unknown>): ValueFormatterFunc<TData, TValue> {
    const model = getFormatterModelForField3(field);

    return this.#createFormatter(model);
  }

  getFormatterForId<TData, TValue>(formatterId: StandardFormatterId): ValueFormatterFunc<TData, TValue> {
    return this.#createFormatterForId(formatterId);
  }

  getFormatterForFormField<TData, TValue>(field: IDisplayField<unknown>) {
    const formatter = FormatterService.get().getFormatterForFormField(field);

    return this.#createGridFormatter<TData, TValue>(formatter);
  }

  getFormatterForModel<T>(formatterDataModel: IPartialFormatterDataModel<T>) {
    if (formatterDataModel.id) {
      this.#createFormatter(formatterDataModel);
    }

    return this.#createFormatter(formatterDataModel);
  }

  #createFormatter<TData, TValue>(
    formatterDataModel: IPartialFormatterDataModel<unknown>
  ): ValueFormatterFunc<TData, TValue> {
    const formatter = FormatterService.get().getFormatterForModel(formatterDataModel);

    return this.#createGridFormatter<TData, TValue>(formatter);
  }

  #createFormatterForId<TData, TValue>(formatterId: StandardFormatterId): ValueFormatterFunc<TData, TValue> {
    const formatter = FormatterService.get().getFormatterForId(formatterId);

    if (formatter === defaultFormatter) {
      return defaultAgFormatter as ValueFormatterFunc<TData, TValue>;
    }

    return this.#createGridFormatter<TData, TValue>(formatter);
  }

  #createGridFormatter<TData, TValue>(
    formatter: FormatterFn<TValue>,
    fieldPath?: string
  ): ValueFormatterFunc<TData, TValue> {
    return (params) => {
      if (
        params.column.getAggFunc() === undefined &&
        params.node?.group &&
        !params.column.isRowGroupActive()
      ) {
        return '';
      }

      const value = getCellValue(params, fieldPath);

      return formatter(value);
    };
  }

  static initService() {
    if (!this.#instance) {
      this.#instance = new AgFormatterService();
    }

    return this.#instance;
  }

  static get() {
    if (!this.#instance) {
      this.#instance = this.initService();
    }
    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }
}

export function defaultAgFormatter<TData, TValue>(params: ValueFormatterParams<TData, TValue>) {
  return params.value;
}

function getCellValue<TData>(params: ValueFormatterParams<TData>, fieldPath?: string) {
  if (fieldPath) {
    return get(params.data, fieldPath);
  }
  if (params.node?.group && isObject(params.value)) {
    return 'value' in params.value ? params.value.value : params.value;
  }
  return params.value;
}
