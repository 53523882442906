import { useAtomValue } from 'jotai';
import { createDealTypeDataModel } from '../../../data-models/deal-type.data-model';
import { LoadingStatus } from '../../../types';
import { useInitDealFormatters } from '../../DealFlow2/hooks/useInitDealBoard';
import { dealBoardConfigState } from '../../DealFlow2/state/DealboardDataState';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { useDealTypeFields } from './useDealTypeFields';

export function ConfigDealType() {
  const formatterStatus = useInitDealFormatters();
  const fields = useDealTypeFields();
  const { dealTypes } = useAtomValue(dealBoardConfigState);

  if (formatterStatus === LoadingStatus.loading) {
    return <div>Loading</div>;
  }

  return (
    <GenericConfigPage
      baseTitle={'Deal Type'}
      data={dealTypes}
      fields={fields}
      pageTitle={'Deal Types'}
      queryURL={'/dealType'}
      transformBeforeSubmit={(data) => {
        return createDealTypeDataModel({
          ...data,
          displayName: data.name,
          sortOrder: 0,
        });
      }}
    />
  );
}
