import { createStore, PrimitiveAtom } from 'jotai';

let foresightStore = createStore();

export function getForesightStore() {
  return foresightStore;
}

export function __TEST_ONLY__resetForesightStore() {
  foresightStore = createStore();

  return foresightStore;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getJotaiSnapshot(atoms: PrimitiveAtom<any>[]) {
  const store = getForesightStore();

  return atoms.reduce((acc, atom) => {
    return acc.set(atom, store.get(atom));
  }, new Map<PrimitiveAtom<unknown>, unknown>());
}

export function goToJotaiSnapshot(snapshot: ReturnType<typeof getJotaiSnapshot>) {
  const store = getForesightStore();

  return snapshot.forEach((value, atom) => {
    store.set(atom, value);
  });
}
