import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue, useSetAtom } from 'jotai';
import { FC, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { MuiMultiselect } from '../../../../components/MuiMultiselect/MuiMultiselect';
import { Option } from '../../../../components/MuiSingleSelect/MuiSingleSelect';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useDealActions2 } from '../../hooks/useDealActions';
import { dealBoardConfigState } from '../../state/DealboardDataState';
import { isOpenDrawerState } from '../../state/DealboardUIState';
import { ModalActionButtons } from './ModalActionButtons';

const Wrapper = styled('div')``;

interface PassModalProps {
  deal: IDealDataModel;
  handler?: (deal: IDealDataModel, reason: string, comments: string) => void;
}

export const DealPassModalContent: FC<PassModalProps> = ({ deal, handler }) => {
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);
  const [passComments, setPassComments] = useState<string | null>(null);
  const { handlePass } = useDealActions2();
  const { passReasons } = useAtomValue(dealBoardConfigState);
  const { onCloseModal } = useModalState();
  const setIsOpenDrawer = useSetAtom(isOpenDrawerState);

  /* Closing the dropdown didn't work inside the modal without this;  */
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);
  const selectRef = useRef<HTMLDivElement>(null);
  useOutsideClick(selectRef, closePopper);
  /*
  Typing into the input doesn't work either,
  I'm guessing also because it's inside the modal
  */

  const onSubmit = useCallback(async () => {
    onCloseModal();
    const passReasonsString = selectedItems.map((i) => i.value).join(',');
    if (handler) {
      handler(deal, passReasonsString, passComments ?? '');
    } else {
      setIsOpenDrawer(false);
      await handlePass(deal, passReasonsString, passComments);
    }
  }, [deal, handlePass, handler, onCloseModal, passComments, selectedItems, setIsOpenDrawer]);

  const onChange = (e: SyntheticEvent, newValues: Option[] | undefined) => {
    setSelectedItems(newValues ?? []);
  };

  const items = useMemo(() => {
    return passReasons.map(({ id, reason: value }) => ({ id, value }));
  }, [passReasons]);
  return (
    <Wrapper ref={selectRef}>
      <MuiMultiselect
        fieldPlaceholder='Select the Reason'
        options={items}
        value={selectedItems}
        onChange={onChange}
        open={open}
        onOpen={openPopper}
        onClose={closePopper}
        onFocus={(e) => e.stopPropagation()}
      />
      <TextField
        value={passComments}
        onChange={(e) => setPassComments(e.target.value)}
        variant='outlined'
        fullWidth
        placeholder='Add a comment'
        style={{ marginTop: '16px' }}
        onFocus={(e) => e.stopPropagation()}
      />
      <ModalActionButtons
        isSubmitDisabled={!selectedItems.length}
        onSubmit={onSubmit}
        onCancel={() => onCloseModal()}
        submitLabel='Move to Pass'
      />
    </Wrapper>
  );
};
