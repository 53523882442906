import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { Outlet } from 'react-router';
import { useRecoilValue } from 'recoil';
import { GenericFallbacksWrapper } from '../../components/Fallback/GenericFallbacksWrapper';
import { Loader } from '../../components/Loader/Loader';
import { useFdSearchParams } from '../../hooks/useFdSearchParams';
import { companyStateLoadable } from '../../services/state/CompanyStateJ';
import { ActivityDrawer2 } from '../CompanyProfiles/components/ActivityDrawer/ActivityDrawer2';
import { useSyncFromPath } from '../CompanyProfiles/hooks/useSyncFromPath';
import { isDrawerOpenState, selectedCompanyIdProfile } from '../CompanyProfiles/state/UIState';
import { CompanyProfilesHeader2 } from './Header/CompanyProfilesHeader2';
import { useInitCompanyProfile } from './hooks/useInitCompanyProfile';

const CompanyProfileWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const HeaderAndContentsWrapper = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  width: 100%;
`;

// Wrapper needed to ensure companyId is resolved before rest of code.
export function CompanyProfiles2() {
  useSyncFromPath();
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  return companyId < 0 ? null : <CompanyProfiles2Inner />;
}

export function CompanyProfiles2Inner() {
  const initialized = useInitCompanyProfile();
  const selectedCompany = useRecoilValue(selectedCompanyIdProfile);
  useAtomValue(companyStateLoadable(selectedCompany));
  const isDrawerOpen = useRecoilValue(isDrawerOpenState);
  const { noCpNav } = useFdSearchParams();

  if (!initialized) {
    return <Loader />;
  }

  return (
    <CompanyProfileWrapper key={selectedCompany}>
      <HeaderAndContentsWrapper>
        {!noCpNav && <CompanyProfilesHeader2 />}
        <Outlet />
      </HeaderAndContentsWrapper>
      <GenericFallbacksWrapper>
        <ActivityDrawer2 companyId={selectedCompany} isOpen={isDrawerOpen} />
      </GenericFallbacksWrapper>
    </CompanyProfileWrapper>
  );
}
