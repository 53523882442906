import { useCallback } from 'react';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { ICustomFieldDataDataModel } from '../../data-models/custom-field-data.data-model';
import { getCustomFieldDataHistory } from '../queries/MaggieCustomFieldQueries';
import { getErrorMessage } from '../queryHelpers';

export function useCustomFieldDataActions() {
  const { pushErrorToast } = useToastMessageState();

  const fetchCustomFieldDataHistory = useCallback(
    async ({ entityId, customFieldId }: Pick<ICustomFieldDataDataModel, 'entityId' | 'customFieldId'>) => {
      try {
        return await getCustomFieldDataHistory({ entityId, customFieldId });
      } catch (error) {
        pushErrorToast({ message: getErrorMessage(error, 'Error fetching custom field data history') });
      }
    },
    [pushErrorToast]
  );

  return {
    fetchCustomFieldDataHistory,
  };
}
