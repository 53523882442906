import { ICaptableDataModel } from '../../data-models/captable2.data-model';
import { MaggieHttp } from '../MaggieHttp';

export async function getCaptableById(id: number): Promise<ICaptableDataModel> {
  const { data } = await MaggieHttp.maggie.get<ICaptableDataModel>(`/capTable/${id}`, {
    params: {
      version: 2,
    },
  });
  return data;
}

export async function getCapTablesForCompany(companyId: number): Promise<ICaptableDataModel[]> {
  const { data } = await MaggieHttp.maggie.get<ICaptableDataModel[]>(`/company/${companyId}/captables`);
  return data;
}

export async function createCaptable(
  payload: Partial<ICaptableDataModel>,
  preview = false
): Promise<ICaptableDataModel> {
  const { data } = await MaggieHttp.maggie.post<Partial<ICaptableDataModel>, ICaptableDataModel>(
    `/capTable`,
    payload,
    {
      params: {
        preview,
      },
    }
  );
  return data;
}

export async function updateCaptable(payload: Partial<ICaptableDataModel>): Promise<ICaptableDataModel> {
  const { data } = await MaggieHttp.maggie.put<Partial<ICaptableDataModel>, ICaptableDataModel>(
    `/capTable/${payload.id}`,
    payload
  );
  return data;
}

export async function makeCaptablePrimary(id: number): Promise<ICaptableDataModel> {
  const { data } = await MaggieHttp.maggie.put<undefined, ICaptableDataModel>(`/capTable/${id}/set-primary`);
  return data;
}

export async function deleteCaptable(id: number): Promise<void> {
  await MaggieHttp.maggie.delete(`/capTable/${id}`);
}

export async function duplicateCaptable(id: number): Promise<ICaptableDataModel> {
  const { data } = await MaggieHttp.maggie.post<undefined, ICaptableDataModel>(`/capTable/${id}/duplicate`);
  return data;
}

export async function checkCaptableExists(companyId: number): Promise<boolean> {
  const { data } = await MaggieHttp.maggie.get<boolean>(`/capTable/company/${companyId}/present`);
  return data;
}
