import {
  createFormatterDataModel,
  IFormatterDataModel,
  IStringFormatConfig,
} from '../../data-models/formatter.data-model';
import { FormatterFn } from '../formatter-service';

export enum StringTransform {
  capitalize = 'capitalize',
  camelCaseToWords = 'camelCaseToWords',
}

export const StringTransforms: Record<string, FormatterFn<string>> = {
  capitalize: (value: string) => {
    return value
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ');
  },
  camelCaseToWords: (value: string) => {
    return value.replace(/([A-Z])/g, ' $1');
  },
};

export enum StringFormatterId {
  capitalizedString = 'capitalizedString',
  string = 'string',
  camelCaseToCapitalizedString = 'camelCaseToCapitalizedString',
}

export const StringFormattersConfig: Record<StringFormatterId, IFormatterDataModel<IStringFormatConfig>> = {
  camelCaseToCapitalizedString: createFormatterDataModel<IStringFormatConfig>({
    id: 'camelCaseToCapitalizedString',
    type: 'string',
    config: {
      transforms: [StringTransform.camelCaseToWords, StringTransform.capitalize],
    },
  }),
  capitalizedString: createFormatterDataModel<IStringFormatConfig>({
    id: 'capitalizedString',
    type: 'string',
    config: {
      transforms: [StringTransform.capitalize],
    },
  }),
  string: createFormatterDataModel({
    id: 'string',
    type: 'string',
  }),
};

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatStringArray(value: string[]) {
  return value.join(', ');
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function capitalizeString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
