import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { IKPITemplate } from '../../../../../data-models/kpi-template.data-model';
import {
  ClientSettingsPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../../../../data-models/preferences-v3.data-model';
import { PermissionKey, RoleKey } from '../../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../../services/PermissionService';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { PreferencesServiceV3 } from '../../../../../util/preferences-service-v3';

export function useEnableManagedServices() {
  const { useManagedServices } = useFlags<MaggieFeatureFlags>();

  return (
    useManagedServices &&
      PreferencesServiceV3.get().getPreference<ClientSettingsPreferences>(PreferenceKey.clientSettings)
        ?.managedServices,
    PreferenceScope.ORGANIZATION
  );
}
const ManagedServicesStatuses = new Set<KPIRequestStatus>([
  KPIRequestStatus.ManagedServiceSubmitted,
  KPIRequestStatus.ManagedServiceInProgress,
  KPIRequestStatus.ManagedServiceFinished,
]);
const ShowGridToStatuses = new Set<KPIRequestStatus>([
  KPIRequestStatus.ManagedServiceInProgress,
  KPIRequestStatus.ManagedServiceFinished,
  KPIRequestStatus.Accepted,
]);
export function useShouldShowManagedServicesKpiGrid(
  requestStatus: KPIRequestStatus,
  template: IKPITemplate | null
) {
  const enableManagedServices = useEnableManagedServices();
  return enableManagedServices && ShowGridToStatuses.has(requestStatus) && template?.isManagedService;
}

export function useShouldSaveMsEnteredFinancials() {
  const enableManagedServices = useEnableManagedServices();
  return useCallback(
    (requestStatus: KPIRequestStatus) => {
      return enableManagedServices && requestStatus == KPIRequestStatus.ManagedServiceFinished;
    },
    [enableManagedServices]
  );
}

export function isMsGridEditable(requestStatus: KPIRequestStatus) {
  if (PermissionService.get().hasRole(RoleKey.managedServices)) {
    return (
      requestStatus == KPIRequestStatus.ManagedServiceInProgress ||
      requestStatus == KPIRequestStatus.ManagedServiceSubmitted
    );
  }
  return (
    PermissionService.get().hasPermission(PermissionKey.canWriteDataCollection) &&
    ManagedServicesStatuses.has(requestStatus)
  );
}
