import { useMemo } from 'react';
import { noop } from 'lodash-es';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { fundMetricsFormSchema, FundMetrics } from '../../../schemas/FundMetrics.schema';
import { FDMap } from '../../../util/data-structure/FDMap';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import { createForm } from '../../../view-models/form.view-model';
import { usePanelPagination, FieldsWrapper } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { Panel } from '../../CompanyProfiles2/Summary/Panel';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { getFundMetricsAdditionalData, useFundMetricsFP } from '../useFundMetricsFP';
import { FundCompanyData, fundCompanyDataFormSchema, useFundCompanyData } from './useFundCompanyData';

const metricsFields: FieldPath<FundMetrics>[] = [
  'activationDate',
  'managementFee',
  'ttvpi',
  'dpi',
  'rvpi',
  'netIRR',
  'netMOIC',
  'lpTvpi',
  'lpDpi',
  'lpNetIrr',
  'fmv',
  'realizedValue',
  'grossIRR',
  'grossMOIC',
  'investmentAmount',
  'distributions',
  'contributions',
  'lossRatio',
  'capitalLossRatio',
  'capitalCalledPercent',
  'initialInvestmentAmount',
  'followOnInvestmentAmount',
  'realizedGL',
  'unrealizedGL',
];

export function FundMetricsPanel() {
  const { metrics } = useFundMetricsFP();
  const schemaToFormFields = useSchemaToFormFields();

  const fundCompanySchema = fundCompanyDataFormSchema();
  const fundCompanyFields = schemaToFormFields(fundCompanySchema);
  const fundCompanyData = useFundCompanyData();

  const fundMetricsSchema = fundMetricsFormSchema().pick(metricsFields);
  const fundMetricsFields = schemaToFormFields(fundMetricsSchema, metricsFields);
  const additionalDataMap = useMemo(() => {
    if (!metrics) return;
    return getFundMetricsAdditionalData(metrics.metrics);
  }, [metrics]);

  const fields = useMemo(() => {
    const splitIdx = metricsFields.indexOf('distributions');
    return [
      ...fundMetricsFields.slice(0, splitIdx),
      ...fundCompanyFields,
      ...fundMetricsFields.slice(splitIdx),
    ].map((f) => {
      if (f.key === 'distributions') {
        const lpDistributions = additionalDataMap?.get('lpDistributions');
        const gpDistributions = additionalDataMap?.get('gpDistributions');
        return {
          ...f,
          required: false,
          description: `${lpDistributions?.label}: ${lpDistributions?.formattedValue} 
          ${gpDistributions?.label}: ${gpDistributions?.formattedValue}`,
          disabled: true,
        };
      }
      if (f.key === 'contributions') {
        const lpContributions = additionalDataMap?.get('lpContributions');
        const gpContributions = additionalDataMap?.get('gpContributions');
        return {
          ...f,
          required: false,
          description: `${lpContributions?.label}: ${lpContributions?.formattedValue}
          ${gpContributions?.label}: ${gpContributions?.formattedValue}`,
          disabled: true,
        };
      }

      return { ...f, required: false, disabled: true };
    });
  }, [additionalDataMap, fundCompanyFields, fundMetricsFields]);

  const { paginationConfig, visibleFields } = usePanelPagination({
    fields,
    itemsPerPage: 10,
  });

  const methods = useForm({
    values: { ...(metrics?.metrics ?? {}), ...fundCompanyData },
  });

  if (!metrics) return null;

  const form = createForm<FundMetrics | FundCompanyData>({
    fields: FDMap.fromArray(visibleFields, 'key'),
    data: methods.getValues() ?? {},
    variant: 'form-inline',
  });

  return (
    <Panel title={'Fund Metrics'} paginationConfig={paginationConfig} showContentOverflow={true}>
      <UnstyledFieldset>
        <FieldsWrapper style={{ minHeight: '22rem' }}>
          <FormProvider {...methods}>
            <FormFactoryNoLayout form={form} onChange={noop} />
          </FormProvider>
        </FieldsWrapper>
      </UnstyledFieldset>
    </Panel>
  );
}
