export function sortNumbers(
  a: number | undefined | null,
  b: number | undefined | null,
  direction: 'asc' | 'desc' = 'asc'
) {
  const numA = a ?? Number.MAX_VALUE;
  const numB = b ?? Number.MAX_VALUE;

  return direction === 'asc' ? numA - numB : numB - numA;
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !isNaN(value) && Number.isFinite(value);
}
