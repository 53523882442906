import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue } from 'jotai';
import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { object, ObjectSchema } from 'yup';
import { merge } from 'lodash-es';
import { FormFactoryNoLayout } from '../../../../../components/Form/FormFactory';
import { companyFields } from '../../../../../schemas/Company.schema';
import { DealFormViewModel } from '../../../../../schemas/deal.schema';
import { companyStateJ } from '../../../../../services/state/CompanyStateJ';
import { FDMap } from '../../../../../util/data-structure/FDMap';
import { schemaToFormFields } from '../../../../../util/schema-utils';
import { ICompanyViewModel } from '../../../../../view-models/company.view-model';
import { createForm, IForm, IFormField } from '../../../../../view-models/form.view-model';
import { UnstyledFieldset } from '../../../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { FieldsWrapper } from '../../../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import {
  fromCompanyDataModel,
  toCompanyDataModel,
} from '../../../../CompanyProfiles2/Summary/DigestPanel.view-model';
import { Panel } from '../../../../CompanyProfiles2/Summary/Panel';
import { companyFormSchema, useCompanyFields } from '../../../../Finance2/Forms/CompanyFormUtils';
import { useDebounceCompanyUpdates } from '../../../../CompanyProfiles2/Summary/useDebounceCompanyUpdates';
import { IDealPanelProps } from './DealCustomFields2';

const fieldContainer = css`
  & [data-fieldkey='shortDescription'] {
    grid-column: 1/-1;
    @supports (field-sizing: content) {
      field-sizing: content;
    }
  }
`;

export const DealCompanyPanel: FC<IDealPanelProps> = ({ deal, editable = true }) => {
  const updateCompany = useDebounceCompanyUpdates(deal.companyId);

  const company = useAtomValue(companyStateJ(deal.companyId));
  const schema = companyFormSchema();

  const methods = useForm<Partial<ICompanyViewModel>>({
    defaultValues: fromCompanyDataModel(company!),
    resolver: yupResolver(schema as ObjectSchema<Partial<ICompanyViewModel>>),
  });

  const onUpdateCompany = useCallback(
    async (data: Partial<ICompanyViewModel>) => {
      if (data.website) {
        try {
          await schema.validateAt('website', data);
        } catch (error) {
          return;
        }
      }
      updateCompany(toCompanyDataModel(data));
    },
    [schema, updateCompany]
  );

  return (
    <FormProvider {...methods}>
      <DealCompanyFields onChange={onUpdateCompany} editable={editable} />
    </FormProvider>
  );
};

interface IDealCompanyFieldsProps {
  onChange: (data: Partial<ICompanyViewModel>) => Promise<void>;
  editable?: boolean;
}
function DealCompanyFields({ onChange, editable = true }: IDealCompanyFieldsProps) {
  const fields = useDealCompanyFields().map((f) => ({
    ...f,
    disabled: !editable,
  }));
  const form = createForm<DealFormViewModel>({
    fields: FDMap.fromArray(fields, 'key'),
    variant: 'form-inline',
  });

  return (
    <Panel title='Company Details' showContentOverflow>
      <UnstyledFieldset disabled={!editable} css={fieldContainer}>
        <FieldsWrapper>
          <FormFactoryNoLayout form={form as unknown as IForm<ICompanyViewModel>} onChange={onChange} />
        </FieldsWrapper>
      </UnstyledFieldset>
    </Panel>
  );
}

function useDealCompanyFields() {
  const baseFormFieldMap = useCompanyFields(['_viewModel.sourceCompany' as keyof ICompanyViewModel]).reduce(
    (map, f) => map.set(f.key, f),
    new Map<string, IFormField<unknown>>()
  );
  const fields1 = [
    baseFormFieldMap.get('_viewModel.primaryLocation'),
    baseFormFieldMap.get('_viewModel.ceoName'),
    baseFormFieldMap.get('sectorId'),
    baseFormFieldMap.get('_viewModel.sourceCompany'),
  ] as IFormField<unknown>[];
  const fields3 = schemaToFormFields(object(companyFields()), [
    'source',
    'sourceType',
    'website',
    'internalSourceId',
  ]);
  const [descriptionField] = schemaToFormFields(object(companyFields()), ['shortDescription']);
  merge(descriptionField, { rendererMeta: { multiline: true } });

  return [...fields1, ...fields3, descriptionField];
}
