import { atom } from 'jotai';
import { atomWithLazy, loadable } from 'jotai/utils';
import { FieldEntity, IField } from '../../../data-models/field2.data-model';
import { isCustomField } from '../../../util/field-utils';
import { fetchCustomFields } from '../../queries/MaggieCustomFieldQueries';

// export const customFieldsSelectedTemplateAtom = atom<string>('company');

// export const customFieldsOpenUploadModalAtom = atom<boolean>(false);

// export const templatesTypeListAtom = atomWithDefault<ITemplatesResponse | null>(async () => {
//   try {
//     return await fetchCustomFieldTemplates();
//   } catch (error) {
//     console.error(error);
//     return null;
//   }
// });

export const customFieldsListAtom = atomWithLazy(fetchCustomFields);
export const customFieldsListLoadable = loadable(customFieldsListAtom);

export const customFieldsByIdMapAtom = atom(async (get) => {
  const fields = await get(customFieldsListAtom);
  if (!fields) return null;
  return new Map(fields.map((field) => [field.id, field]));
});

export const customFieldsByEntityAtom = atom(async (get) => {
  const fields = (await get(customFieldsListAtom)) ?? [];

  return fields.reduce((res, field) => {
    const entity = field.entity as FieldEntity;
    if (isCustomField(field)) {
      if (res.has(entity)) {
        res.get(entity)!.push(field);
      } else {
        res.set(entity, [field]);
      }
    }

    return res;
  }, new Map<FieldEntity, IField<unknown>[]>());
});
