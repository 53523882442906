import { useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useEffect, useState } from 'react';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { fetchDealsByStageId } from '../../../services/queries/MaggieDealQueries';
import { DealBoardDealCategory, LoadingId, LoadingStatus } from '../../../types';
import { FormatterService } from '../../../util/formatter-service';
import { DealStageFormatter } from '../../../util/formatters/DealStageFormatter';
import { DealTypeFormatter } from '../../../util/formatters/DealTypeFormatter';
import {
  allDealsLoadStatusState,
  allDealsState,
  dealStageIdToCategoryState,
  dealStagesByIdMapState,
  dealTypesByIdMapState,
} from '../state/DealboardDataState';
import { companyStateJ } from '../../../services/state/CompanyStateJ';
import { useSyncFromPath } from './useSyncFromPath';

const categories = [
  DealBoardDealCategory.CURRENT,
  DealBoardDealCategory.PASS,
  DealBoardDealCategory.TRACK,
  DealBoardDealCategory.MISSED,
  DealBoardDealCategory.CLOSED,
];

export function useInitDealBoard() {
  const initialized = useSyncFromPath();
  const fetchAllDeals = useFetchAllDeals();
  useInitDealFormatters();
  const dealState = useAtomValue(allDealsLoadStatusState);

  useEffect(() => {
    if (initialized) {
      fetchAllDeals();
    }
  }, [fetchAllDeals, initialized]);

  return initialized && dealState.status === LoadingStatus.success;
}

export function useFetchAllDeals() {
  const { actions } = useLoadingBarState();

  return useAtomCallback(
    useCallback(async (get, set) => {
      const { status, lastUpdated } = get(allDealsLoadStatusState);

      if (status === LoadingStatus.idle) {
        set(allDealsState, []);
      }

      if (status !== LoadingStatus.loading) {
        actions.startLoading(LoadingId.refreshAllDeals);
        set(allDealsLoadStatusState, { status: LoadingStatus.loading, lastUpdated: new Date() });

        const res = categories.map(async (category) => {
          const dealStageIdToCategory = await get(dealStageIdToCategoryState);
          const updatedDeals = await fetchDealsForCategory(category, dealStageIdToCategory, lastUpdated);
          const updatedDealsId = updatedDeals.deals.reduce(
            (res, deal) => res.add(deal.id),
            new Set<number>()
          );
          set(allDealsState, (curr) => {
            const unchangedDeals = curr.filter((deal) => !updatedDealsId.has(deal.id));
            return unchangedDeals.concat(updatedDeals.deals);
          });
          updatedDeals.companies.forEach((company) => {
            set(companyStateJ(company.id), company);
          });
        });

        try {
          await Promise.all(res);
          set(allDealsLoadStatusState, (curr) => {
            return { status: LoadingStatus.success, lastUpdated: curr.lastUpdated };
          });
        } catch (err) {
          set(allDealsLoadStatusState, { status: LoadingStatus.error, lastUpdated: undefined });
        } finally {
          actions.stopLoading(LoadingId.refreshAllDeals);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );
}

function fetchDealsForCategory(
  categoryId: DealBoardDealCategory,
  dealStageIdToCategory: Map<DealBoardDealCategory, Set<number>>,
  lastUpdated?: Date
) {
  let stageIds: number[] = [];

  if (!lastUpdated) {
    if (categoryId === DealBoardDealCategory.PASS) {
      stageIds = Array.from(dealStageIdToCategory.get(DealBoardDealCategory.PASS) ?? []);
    } else if (categoryId === DealBoardDealCategory.MISSED) {
      stageIds = Array.from(dealStageIdToCategory.get(DealBoardDealCategory.MISSED) ?? []);
    } else {
      stageIds = Array.from(dealStageIdToCategory.get(categoryId) ?? []);
    }
  }

  return fetchDealsByStageId(stageIds, lastUpdated);
}

export function useInitDealFormatters() {
  const [status, setStatus] = useState(LoadingStatus.loading);
  const dealStages = useAtomValue(dealStagesByIdMapState);
  const dealTypes = useAtomValue(dealTypesByIdMapState);

  useEffect(() => {
    if (!dealStages || !dealTypes) return;
    const formatterService = FormatterService.get();
    formatterService.setFormatterForId('dealStage', new DealStageFormatter().init(dealStages).format);
    formatterService.setFormatterForId('dealType', new DealTypeFormatter().init(dealTypes).format);
    setStatus(LoadingStatus.success);
  }, [dealStages, dealTypes]);

  return status;
}
