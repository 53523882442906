import { Atom, atom } from 'jotai';
import { atomFamily, unwrap } from 'jotai/utils';
import { cloneDeep } from 'lodash-es';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { DealsByCompanyIdState } from '../../../services/state/DealState';
import { getForesightStore } from '../../../util/jotai-store';
import { dealBoardConfigState, dealStagesByIdMapState } from '../../DealFlow2/state/DealboardDataState';

export const CurrentStagesIdsState = atom<Set<number> | null>((get) => {
  const stagesMap = get(unwrap(dealStagesByIdMapState));
  if (!stagesMap) return null;
  const currentStageIds = new Set<number>();
  stagesMap.forEach((stage) => {
    if (
      stage.name !== 'Closed' &&
      stage.name !== 'Missed' &&
      stage.name !== 'Pass' &&
      stage.name !== 'Track'
    ) {
      currentStageIds.add(stage.id);
    }
  });
  return currentStageIds;
});

export const CurrentStagesIdsByTypeState = atomFamily((dealTypeId: number) =>
  atom<Set<number> | null>((get) => {
    if (typeof dealTypeId !== 'number') return null;
    const dealboardConfig = get(unwrap(dealBoardConfigState));
    const stagesForDealType = dealboardConfig?.dealTypes?.find(
      (dealType) => dealType.id === dealTypeId
    )?.dealStageIds;
    const stagesMap = get(unwrap(dealStagesByIdMapState));
    const stagesForDealTypeSansClosed = stagesForDealType?.filter(
      (id) => stagesMap?.get(id)?.name !== 'Closed'
    );
    if (!stagesForDealTypeSansClosed) {
      return null;
    }
    return new Set(stagesForDealTypeSansClosed);
  })
);

export const SortedStageIdsState = atomFamily((dealTypeId: number) =>
  atom((get) => {
    const currentStagesIds = get(CurrentStagesIdsByTypeState(dealTypeId));
    const stagesMap = get(unwrap(dealStagesByIdMapState));
    if (!currentStagesIds || !stagesMap) return null;
    return Array.from(currentStagesIds).sort(
      (a, b) => (stagesMap.get(a)?.sortOrder ?? -1) - (stagesMap.get(b)?.sortOrder ?? -1)
    );
  })
);

export const DealsSortedByLastUpdatedDescState = atomFamily((companyId: number) =>
  atom((get) => {
    const companyDeals = get(DealsByCompanyIdState(companyId));
    if (!companyDeals?.length) return [];
    return [...cloneDeep(companyDeals)].sort((a, b) => {
      if (!a.stageUpdateDate) return 1;
      if (!b.stageUpdateDate) return -1;
      return new Date(b.stageUpdateDate).getTime() - new Date(a.stageUpdateDate).getTime();
    });
  })
);

export const LastUpdatedCurrentDealState = atomFamily<number, Atom<IDealDataModel | null>>(
  (companyId: number) =>
    atom((get) => {
      const store = getForesightStore();
      const currentStageIds = get(CurrentStagesIdsState);
      const companyDeals = store.get(DealsByCompanyIdState(companyId));
      if (!companyDeals?.length || !currentStageIds) return null;

      const currentDeals = companyDeals.filter((deal) => currentStageIds.has(deal.stageId)) ?? [];

      let lastUpdated: IDealDataModel | null = null;
      currentDeals.forEach((deal) => {
        if (!deal.stageUpdateDate) {
          return;
        }
        if (!lastUpdated || !lastUpdated.stageUpdateDate) {
          lastUpdated = deal;
          return;
        }
        if (
          !lastUpdated ||
          new Date(deal.stageUpdateDate).getTime() > new Date(lastUpdated.stageUpdateDate).getTime()
        ) {
          lastUpdated = deal;
        }
      });

      return lastUpdated;
    })
);
