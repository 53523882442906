import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { getIsoDateColDef } from '../../../../../util/ag-grid-utils';
import { FormattedComponent } from '../../../../../util/FormattedComponent';
import { getFormattedValue } from '../../../../../util/getFormattedValue';
import { LinkRenderer } from '../../../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/LinkRenderer';
import { MultiLinkRenderer } from '../../../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/MultiLinkRenderer';
import { BoardMembersRenderer } from '../CellRenderers/BoardMembersRenderer';

/** @deprecated */
const handleDeprecatedCases = (type: string): ColDef | ColGroupDef | undefined => {
  if (type === 'link')
    return {
      cellRenderer: LinkRenderer,
    };
};

const commonNumberColSettings: ColDef | ColGroupDef = {
  headerClass: 'ag-left-aligned-header',

  filter: 'agNumberColumnFilter',
  sortingOrder: ['desc', 'asc', null],
};

export const getTypeBasedDefs = (field: FieldDataModel, loading?: boolean): ColDef | ColGroupDef => {
  const { type, formatting } = field;

  let defs: ColDef | ColGroupDef = {
    valueFormatter: (params: ValueFormatterParams) =>
      getFormattedValue({ type, formatting, value: params.value }) || '',
    cellRenderer: FormattedComponent,
    cellRendererParams: {
      type,
      formatting,
      loading,
    },
    sortable: true,
  };

  switch (type) {
    case 'string':
      defs = {
        ...defs,
      };
      break;
    case 'number':
      defs = {
        ...defs,
        ...commonNumberColSettings,
      };
      break;
    case 'date':
      defs = getIsoDateColDef(defs);
      break;
    case 'array':
      if (formatting === 'boardMembers') {
        defs = {
          cellRenderer: BoardMembersRenderer,
          filter: 'agTextColumnFilter',
        };
      }
      if (formatting === 'url') {
        defs = {
          cellRenderer: MultiLinkRenderer,
          filter: 'agTextColumnFilter',
        };
      }
      break;

    default:
      defs = { ...defs, ...(handleDeprecatedCases(type) || {}) };
  }

  return defs;
};
