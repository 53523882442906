import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  ColDef,
  ColGroupDef,
  FilterModel,
  GridApi,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  SortModelItem,
} from 'ag-grid-community';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash-es';
import { getEnvVariable } from '../../../../util/environment';

// https://github.com/ForesightData/microservices/blob/eee61a74bca255cd401c6cb620bad6a9f1fb397b/airquery/graph/schema/schema.graphqls
export class ServerSideDatasource {
  #gridApi: GridApi;
  #client: ApolloClient<NormalizedCacheObject>;
  constructor(gridApi: GridApi, token: string) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });

    this.#gridApi = gridApi;
    this.#client = new ApolloClient({
      link: authLink.concat(
        createHttpLink({ uri: `${getEnvVariable('VITE_MAGGIE_SOURCING_API')}/api/graphql` })
      ),
      cache: new InMemoryCache(),
    });
  }

  getRows(params: IServerSideGetRowsParams) {
    const columns = this.#gridApi.getColumnDefs()?.filter((c) => !(c as ColDef).hide);

    this.#client
      .query(query(params.request, columns ?? []))
      .then((response) => {
        const rows = response.data.companies;
        params.success({ rowData: rows });
      })
      .catch((err) => {
        console.error(err);
        params.fail();
      });
  }
}

const query = (request: IServerSideGetRowsRequest, columns: (ColDef | ColGroupDef)[]) => {
  const fields = columns.reduce((acc, col) => {
    if ((col as ColDef).field) {
      return `${acc}${(col as ColDef).field}\n`;
    }
    return acc;
  }, '');

  const { endRow, startRow, filterModel: gridFilterModel, sortModel: gridSortModel } = request;
  const filterModel = adaptFilterModel(gridFilterModel as FilterModel | null);
  const sortModel = getSortModel(gridSortModel);

  return {
    query: gql`
      query GetCompanies($filterModel: CompanyFilter, $startRow: Int, $endRow: Int, $sortModel: [SortModel!]) {
        companies(filterModel: $filterModel, startRow: $startRow, endRow: $endRow, sortModel: $sortModel) {
          ${fields}
        }
      }
    `,
    variables: {
      endRow,
      filterModel,
      sortModel,
      startRow,
    },
  };
};

function adaptFilterModel(gridFilterModel?: FilterModel | null) {
  const filterModel = cloneDeep(gridFilterModel);
  if (filterModel) {
    Object.keys(filterModel).forEach((key) => {
      const columnName = key as keyof typeof filterModel;
      if (filterModel[columnName]?.filterType) {
        filterModel[columnName].filterType = filterModel[columnName].filterType.toUpperCase();
      }
      if (filterModel[columnName]?.type) {
        filterModel[columnName].type = filterModel[columnName].type.toUpperCase();
      }
    });
  }
  return filterModel;
}

function getSortModel(gridSortModel: SortModelItem[]) {
  const sortModel: { colId: string; order: string }[] = [];
  if (gridSortModel) {
    gridSortModel.forEach((sm) => {
      const order = sm.sort;
      sortModel.push({
        colId: sm.colId,
        order,
      });
    });
  }
  return sortModel;
}
