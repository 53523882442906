import { FieldItem, TearSheetViewModel } from '@foresightdata/tear-sheet';
import { createHeadlessEditor } from '@lexical/headless';
import { subQuarters } from 'date-fns';
import { getDefaultStore, useAtomValue } from 'jotai';
import { $getRoot } from 'lexical';
import { get, noop } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { Temporal } from 'temporal-polyfill';
import { FieldEntity, IField } from '../../data-models/field2.data-model';
import { KPIRequestFrequency } from '../../data-models/kpi-requests.data-model';
import { IMetricFundDataModel, IMetricsResponseDataModel } from '../../data-models/metrics.data-model';
import { PanelPreferences } from '../../data-models/preferences-v3.data-model';
import { COMPANY_VIEW_TYPE } from '../../data-models/view-config.data-model';
import { Commentary } from '../../schemas/Commentary.schema';
import { customFieldsByEntityAtom } from '../../services/state/AdminPanel/CustomFieldsStateJ';
import { fundsAtom } from '../../services/state/AppConfigStateJ';
import { commentariesByCompanyIdState } from '../../services/state/CommentariesState';
import { companyMetricsByIdState } from '../../services/state/CompanyMetricsByIdState';
import { companyStateJ } from '../../services/state/CompanyStateJ';
import { kpiConfigAtom } from '../../services/state/KPIConfigState';
import { ColumnMeta } from '../../types';
import { FDMap } from '../../util/data-structure/FDMap';
import { getEnvironment } from '../../util/environment';
import { FMT } from '../../util/formatter-service';
import { fiscalDateFormatter, getFiscalQuarter } from '../../util/formatters/DateFormatters';
import { IFormField } from '../../view-models/form.view-model';
import { useCompanyInvestments } from '../CompanyProfiles/hooks/useCompanyInvestments';
import { typedFinancialsByPeriodState } from '../CompanyProfiles/state/CompanyFinancialsState';
import { CompanyFinancingHistoryState } from '../CompanyProfiles/state/CompanyFundingsState';
import { selectedCompanyIdProfile } from '../CompanyProfiles/state/UIState';
import { fundsByDateDescending } from '../CompanyProfiles/Summary/components/FinancingHistory/FinancingHistoryTable';
import { TypedFinancials } from '../CompanyProfiles/utils/getTypedFinancials';
import { getInvestmentsByFund } from '../CompanyProfiles2/Summary/InvestmentMetricsPanel';
import { useAllInvestmentMetricsFields } from '../CompanyProfiles2/Summary/useInvestmentMetricsFields';
import { columnsMetaState } from '../PortfolioOverview/state/ViewState';
import { TearSheet } from './TearSheet';
import { createTearSheetViewModel } from './TearSheet.view-model';
import {
  tearSheetCompanySummaryPreferenceAtom,
  tearSheetInvestmentSummaryPreferenceAtom,
  tearSheetPerformanceSummaryPreferenceAtom,
} from './TearSheetPreferences';

export const TearSheetDataLoader: React.FC = () => {
  const [data, setData] = useState<null | TearSheetViewModel>(null);
  const getData = useGetTearSheetViewModelForEachFund();

  useEffect(() => {
    getData().then((res) => {
      if (res.at(0)?.companyName) {
        window.addEventListener('afterprint', window.close);
        setData(res[0]);
        setTimeout(() => {
          window.print();
        }, 500);
      }
    });
  }, [data, getData]);

  if (data == null) {
    return <div>Tear sheet data unavailable</div>;
  }

  return <TearSheet data={data} />;
};

export function useGetTearSheetViewModelForEachFund() {
  const funds = useAtomValue(fundsAtom);
  const getTearSheetViewModel = useGetTearSheetViewModelForFund();

  return useCallback(async () => {
    const res = funds.map((fund) => {
      return getTearSheetViewModel(fund.id);
    });
    return (await Promise.all(res)).filter((model) => model !== null);
  }, [funds, getTearSheetViewModel]);
}

export function useGetTearSheetViewModelForFund() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const { companyMetrics, data } = useCompanyInvestments(companyId);
  const allInvestmentMetricsFields = useAllInvestmentMetricsFields();

  return useRecoilCallback(
    ({ snapshot }) =>
      async (fundId: number) => {
        const jStore = getDefaultStore();

        const company = await jStore.get(companyStateJ(companyId));
        const investmentsByFund = getInvestmentsByFund(data, companyMetrics);
        const fundData = investmentsByFund.get(fundId);

        if (!company || !fundData) {
          return null;
        }

        const metricsResponse = await snapshot.getPromise(companyMetricsByIdState(companyId));
        const companyCustomFields = (await jStore.get(customFieldsByEntityAtom)).get(FieldEntity.company);
        const allCompanyFields = await jStore.get(columnsMetaState(COMPANY_VIEW_TYPE.RETURN_FORECAST));
        const companyPref = jStore.get(tearSheetCompanySummaryPreferenceAtom)!;
        const displayedCompanyFields = getCompanySummaryFields(
          metricsResponse,
          companyPref,
          allCompanyFields,
          companyCustomFields
        );

        const investmentPref = jStore.get(tearSheetInvestmentSummaryPreferenceAtom) as PanelPreferences;
        const investmentSummaryFields = getInvestmentSummaryFields(
          investmentPref,
          fundData,
          allInvestmentMetricsFields
        );

        const commentary = (await snapshot.getPromise(commentariesByCompanyIdState(companyId))).at(0);

        const fundings = await snapshot.getPromise(
          CompanyFinancingHistoryState({
            companyId,
            isPortfolioCompany: true,
          })
        );
        const financingHistory: TearSheetViewModel['financingHistory'] = [...fundings]
          .sort(fundsByDateDescending)
          .map((funding) => {
            return {
              date: funding.dealDate,
              round: funding.fundingRoundCategory,
              stage: funding.fundingRound,
              raiseAmount: FMT.format('currencyRounded', funding.dealSizeInMillions),
              investedAmount: '',
              postMoneyValuation: FMT.format('currencyRounded', funding.valuationInMillions),
              leadInvestor: funding.leadInvestors ?? '',
              investors: funding.investors?.map((investor) => investor.orgName).join(', ') ?? '',
            };
          });

        const previousQuarter = subQuarters(new Date(), 1);
        const kpiConfig = await jStore.get(kpiConfigAtom);
        const period = getFiscalQuarter(previousQuarter, company?.fye ?? 12);
        const kpiData = await snapshot.getPromise(
          typedFinancialsByPeriodState({
            period: 'Quarter',
            companyId,
            date: period.plainDate.toString(),
          })
        );
        const performanceSummaryPref = jStore.get(
          tearSheetPerformanceSummaryPreferenceAtom
        ) as PanelPreferences;

        const performanceSummaryData = getPerformanceSummary(
          performanceSummaryPref,
          period.plainDate,
          company?.fye ?? 12,
          kpiConfig,
          kpiData
        );

        return createTearSheetViewModel({
          companyDescription: company.description ?? '',
          companyName: company.name ?? '',
          clientLogoUrl: getLogoUrl(),
          companySummary: {
            values: displayedCompanyFields,
          },
          date: FMT.format('date', Temporal.Now.plainDateISO().toString()),
          financingHistory,
          fundName: FMT.format('fund', fundData.fundId),
          investmentSummary: { values: investmentSummaryFields },
          logoUrl: company.logoUrl ?? '',
          performanceCommentary: commentary ? getPerformanceCommentary(commentary) : '',
          performanceSummary: performanceSummaryData,
        });
      },
    []
  );
}

export function getCompanySummaryFields(
  metricsResponse: IMetricsResponseDataModel | null,
  companyPref: PanelPreferences,
  allCompanyFields: ColumnMeta[],
  companyCustomFields: IField<unknown>[] | undefined
): FieldItem[] {
  const companyDataObj = {
    ...(metricsResponse?.metrics.at(0) ?? {}),
    company: metricsResponse?.included.at(0) ?? {},
  };
  const allCompanyFieldsMap = FDMap.fromArray(allCompanyFields, 'path');
  const companyCustomFieldsMap = FDMap.fromArray(companyCustomFields ?? [], 'entityField');

  return companyPref.selectedFields.reduce((acc, fieldPath) => {
    if (allCompanyFieldsMap.has(fieldPath)) {
      const fieldMeta = allCompanyFieldsMap.get(fieldPath)!;
      const value = get(companyDataObj, fieldPath);
      const formattedValue = FMT.format(fieldMeta.formatting, value);
      acc.push({ label: fieldMeta.displayName, value: formattedValue === '' ? ' ' : formattedValue });
    } else if (companyCustomFieldsMap.has(fieldPath)) {
      const fieldMeta = companyCustomFieldsMap.get(fieldPath)!;
      const value = get(companyDataObj.company, fieldPath);
      const formattedValue = FMT.format(fieldMeta.formMeta?.formatter ?? 'string', value);
      acc.push({ label: fieldMeta.displayName, value: formattedValue === '' ? ' ' : formattedValue });
    }

    return acc;
  }, [] as FieldItem[]);
}

export function getInvestmentSummaryFields(
  investmentPref: PanelPreferences,
  fundData: IMetricFundDataModel,
  allInvestmentMetricsFields: IFormField<unknown>[]
): FieldItem[] {
  if (!fundData || investmentPref.selectedFields.length === 0) {
    return [];
  }

  const allInvestmentMetricsFieldsMap = FDMap.fromArray(allInvestmentMetricsFields, 'key');

  return investmentPref.selectedFields.reduce((acc, key) => {
    if (allInvestmentMetricsFieldsMap.has(key)) {
      const field = allInvestmentMetricsFieldsMap.get(key)!;
      const value = get(fundData, field.key);
      const formattedValue = FMT.format(field.formatter ?? 'string', value);
      acc.push({ label: field.label ?? field.key, value: formattedValue === '' ? ' ' : formattedValue });
    }
    return acc;
  }, [] as FieldItem[]);
}

function getPerformanceCommentary(commentary: Commentary) {
  const editor = createHeadlessEditor({
    nodes: [],
    onError: noop,
  });
  const parsedEditorState = editor.parseEditorState(commentary.text);

  return parsedEditorState.read(() => {
    const res = $getRoot().getTextContent();

    return res.replace(/(&nbsp;|\u00A0)/g, ' ');
  });
}

function getPerformanceSummary(
  performanceSummaryPref: PanelPreferences,
  date: Temporal.PlainDate,
  fye: number,
  kpiConfig: IField<unknown>[],
  kpiData: TypedFinancials[]
): TearSheetViewModel['performanceSummary'] {
  const kpiDataAsMap = FDMap.fromArray(kpiData, 'kpiId');
  const kpiConfigAsMap = FDMap.fromArray(kpiConfig, 'entityField');

  return {
    date: fiscalDateFormatter(KPIRequestFrequency.Quarterly, date.toString(), `2025-${fye}-15`),
    values: performanceSummaryPref.selectedFields.reduce((res, kpiKey) => {
      if (kpiConfigAsMap.has(kpiKey)) {
        const kpiId = kpiConfigAsMap.get(kpiKey)!.id;

        if (kpiDataAsMap.has(kpiId)) {
          const kpi = kpiDataAsMap.get(kpiId)!;
          res.push({ label: kpi.displayName, value: kpi.valueFormatted });
        }
      }
      return res;
    }, [] as FieldItem[]),
  };
}

function getLogoUrl() {
  const client = getEnvironment();

  return `https://foresight-public-files.s3.amazonaws.com/base-logo-${client}.png`;
}
