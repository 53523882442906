import { css } from '@emotion/react';
import { FieldItem } from '@foresightdata/tear-sheet';
import React from 'react';

const summaryGridStyle = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  @media print {
    gap: 0.1rem;
  }
`;

const summaryItemStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
  border-bottom: 1px dotted #e2e8f0;

  @media print {
    padding: 0.05rem 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const labelStyle = css`
  font-size: 0.8rem;
  color: #718096;
  font-weight: 500;

  @media print {
    font-size: 0.6rem;
  }
`;

const valueStyle = css`
  font-size: 0.8rem;
  font-weight: 500;
  color: #2d3748;
  text-align: right;

  @media print {
    font-size: 0.6rem;
  }
`;

const sectionTitleStyle = css`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #4a5568;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0;
  display: block;
  width: 100%;

  @media print {
    font-size: 0.65rem;
    margin-bottom: 0.15rem;
  }
`;

type SummarySectionProps = {
  title: string;
  items: FieldItem[];
};

export const SummarySection: React.FC<SummarySectionProps> = ({ title, items }) => {
  return (
    <div>
      <h2 css={sectionTitleStyle}>{title}</h2>
      <div css={summaryGridStyle}>
        {items.map(({ label, value }, index) => (
          <div key={index} css={summaryItemStyle}>
            <div css={labelStyle}>{label}</div>
            <div css={valueStyle}>{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
