import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Popover, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import { useAtomValue, useSetAtom } from 'jotai';
import { FC, MouseEvent, useCallback, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { companyState } from '../../../../services/state/CompanyState';
import { useDealActions2, useDeleteDeal } from '../../hooks/useDealActions';
import {
  dealBoardSettingsState,
  isOpenDrawerState,
  selectedDealIdState,
  selectedDealState,
} from '../../state/DealboardUIState';
import { ConfirmDeleteDeal } from '../DealModal/ConfirmDeleteDeal';
import { DealMissedModalContent } from '../DealModal/DealMissedModalContent';
import { DealModal } from '../DealModal/DealModal';
import { DealPassModalContent } from '../DealModal/DealPassModalContent';
import { getNextDeal, getPrevDeal } from './dealNavigationUtils';

export const MenuCard = styled('ul')`
  all: unset;
  list-style: none;
  width: 8rem;
  display: block;
`;

export const MenuItem = styled('li')`
  display: flex;
  align-items: center;
  height: 2.8rem;
  gap: 8px;
  cursor: pointer;
  & svg {
    height: 1.3rem;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary[5]};
  }
  padding: 0 8px;
`;

const Container = styled('div')``;
const LabelWrapper = styled('div')`
  margin-top: 2px;
`;

export const SplitButton: FC = () => {
  const setIsOpenDrawer = useSetAtom(isOpenDrawerState);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const deal = useAtomValue(selectedDealState);
  const setSelectedDealId = useSetAtom(selectedDealIdState);
  const dealBoardSettings = useAtomValue(dealBoardSettingsState);
  const containerRef = useRef<HTMLDivElement>(null);
  const deleteDeal = useDeleteDeal();
  const { handleClose } = useDealActions2();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const company = useRecoilValue(companyState(deal?.companyId ?? -1));
  const companyName = `“${company!.name}”`;

  const { onOpenModal } = useModalState();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onConfirmDelete = useCallback(async () => {
    if (!deal) return;
    setShowConfirmDelete(false);
    let nearestDeal = getNextDeal(dealBoardSettings, deal);
    if (!nearestDeal) nearestDeal = getPrevDeal(dealBoardSettings, deal);

    await deleteDeal(deal, {
      onSuccess: () => {
        if (!nearestDeal) setIsOpenDrawer(false);
        setSelectedDealId(nearestDeal?.id ?? null);
      },

      onError: () => {
        setIsOpenDrawer(false);
      },
    });
  }, [deal, dealBoardSettings, deleteDeal, setIsOpenDrawer, setSelectedDealId]);

  const onClose = useCallback(async () => {
    if (!deal) return;
    let nearestDeal = getNextDeal(dealBoardSettings, deal);
    if (!nearestDeal) nearestDeal = getPrevDeal(dealBoardSettings, deal);
    await handleClose(deal);
    if (!nearestDeal) setIsOpenDrawer(false);
    setSelectedDealId(nearestDeal?.id ?? null);
  }, [deal, dealBoardSettings, handleClose, setIsOpenDrawer, setSelectedDealId]);

  const handleSelectOption = useCallback(
    (optionId: string) => {
      if (!deal) return;
      switch (optionId) {
        case 'CLOSE':
          onClose();
          setAnchorEl(null);
          return;
        case 'PASS':
          onOpenModal(
            <DealModal title={`Move the Deal ${companyName} to Pass?`}>
              <DealPassModalContent deal={deal} />
            </DealModal>
          );
          setAnchorEl(null);
          return;
        case 'MISSED':
          onOpenModal(
            <DealModal title={`Mark the Deal ${companyName} as Missed?`}>
              <DealMissedModalContent deal={deal} />
            </DealModal>
          );
          setAnchorEl(null);
          return;
        case 'DELETE':
          setShowConfirmDelete(true);
          setAnchorEl(null);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [companyName, deal, onClose, onOpenModal]
  );

  if (!deal || !company) return null;

  return (
    <Container ref={containerRef} data-testid='deal-split-btn'>
      <ButtonGroup
        variant='outlined'
        color={'secondary'}
        size={'medium'}
        aria-label='split button'
        style={{ position: 'relative' }}
      >
        <Button
          color={'secondary'}
          startIcon={<ThumbDownAltOutlinedIcon />}
          onClick={() => handleSelectOption('PASS')}
        >
          <LabelWrapper>Pass</LabelWrapper>
        </Button>

        <Button
          size='small'
          aria-controls={'split-button-menu'}
          aria-expanded={'true'}
          aria-label='select deal action'
          aria-haspopup='menu'
          onClick={handleClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuCard role='listbox'>
          <MenuItem onClick={() => handleSelectOption('CLOSE')} role='option'>
            <CheckCircleOutlineIcon />
            <Typography component='span' variant={'body2'} color='text.secondary'>
              Close
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSelectOption('MISSED')} role='option'>
            <CallMissedOutgoingIcon />
            <Typography component='span' variant={'body2'} color='text.secondary'>
              Missed
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSelectOption('DELETE')} role='option'>
            <DeleteOutlineIcon />
            <Typography component='span' variant={'body2'} color='text.secondary'>
              Delete
            </Typography>
          </MenuItem>
        </MenuCard>
      </Popover>

      <ConfirmDeleteDeal
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={onConfirmDelete}
        deal={deal}
        company={company}
      />
    </Container>
  );
};
