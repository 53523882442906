import { useLayoutEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { IconTypes } from '../../../Icon';
import { ChartTitleWrapper } from '../../ChartTitleWrapper';
import { MostRecentChartData } from '../../../../pages/PortfolioOverview/hooks/useMostRecentRounds';
import { ChartWrapper } from '../../ChartWrapper/ChartWrapper';
import { Chart, ChartSizes } from '../../chart-utils';
import { allChartItems } from '../../../../pages/PortfolioOverview/components/ChartsSection/ChartsSection';
import { CHART_COMPONENTS } from '../../../../types';
import { Loader } from '../../../Loader/Loader';
import { MostRecentRow, PortfolioChartRow } from './MostRecentDataRow';

interface Props {
  id: string;
  width: number;
  data: MostRecentChartData[];
  columnHeaders?: string[];
  loading?: boolean;
}

export const PortfolioChartContainer = styled('ul')`
  display: grid;
  align-items: start;
  gap: 0.5rem;
  @supports (grid-template-columns: subgrid) {
    grid-template-columns: repeat(5, auto);
    justify-content: space-between;
  }
`;

export function MostRecentDataChart({ id, width, data, columnHeaders, loading }: Props) {
  const [showMore, setShowMore] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [widthState, setWidthState] = useState<string | number>(width);
  const [size, setSize] = useState('1/2 Screen');
  const ref = useRef(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [size]);

  return (
    <ChartWrapper
      id={id}
      onMouseLeave={() => {
        setShowMore(false);
        setIsHovering(false);
      }}
      onMouseEnter={() => setIsHovering(true)}
      dataTestid={'mostRecentRoundsChart'}
      width={widthState}
    >
      <ChartTitleWrapper
        isHovering={isHovering}
        title={allChartItems.get(id as CHART_COMPONENTS)?.value ?? ''}
        showMore={showMore}
        setShowMore={setShowMore}
        id={id}
        icon={IconTypes.STATISTICS_1}
        handleSizeChange={setSize}
        size={size}
        refProp={ref}
      />
      {loading ? (
        <Loader />
      ) : (
        <Chart width={'100%'} ref={ref}>
          <PortfolioChartContainer>
            <PortfolioChartRow style={{ alignItems: 'center' }}>
              {columnHeaders?.map((header, k) => (
                <Typography key={k} variant='caption' color='textSecondary' title={header} lineHeight={1.2}>
                  {header}
                </Typography>
              ))}
            </PortfolioChartRow>
            {data.map((row) => (
              <MostRecentRow
                company={row.company}
                dealLead={row.dealLead}
                round={row.round}
                date={new Date(row.date)}
                formattedValue={row.formattedValue}
                formattedValue2={row.formattedValue2}
                key={`${row.company?.name} ${row.date} ${row.formattedValue}`}
              />
            ))}
          </PortfolioChartContainer>
        </Chart>
      )}
    </ChartWrapper>
  );
}
