import { addYears, endOfDay } from 'date-fns';
import { atom } from 'jotai';
import { atomFamily, atomWithDefault, atomWithReset, useAtomCallback, useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { FundDataModel } from '../../../schemas/Fund.schema';
import { FundData } from '../../../schemas/FundData.schema';
import { FundInvestment, FundReserves } from '../../../schemas/FundReserves.schema';
import { FundViewModel } from '../../../schemas/FundViewModel.schema';
import { fetchFundData, fetchFundReserves } from '../../../services/queries/MaggieFundQueries';
import { getFundContributions, getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { fundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { companyStateJ } from '../../../services/state/CompanyStateJ';
import { FundViewModel as FundViewModel2 } from '../../../schemas/FundViewModel2.schema';
export const selectedFundIdStateFP = atom<number | null>(null);

export const selectedFundStateFP = atom<FundDataModel | null>((get) => {
  const fundId = get(selectedFundIdStateFP);
  return get(fundsByIdMapAtom).get(fundId ?? -1) ?? null;
});

export const DefaultSelectedDateFP = endOfDay(new Date());
export const selectedDateFPState = atom(DefaultSelectedDateFP);

export type SelectedFPKey = {
  date: Date;
  fundId: number | null;
};
export const fundDataStateFP = atomFamily((fundId: number) =>
  atom<FundData[] | Promise<FundData[]>>(fetchFundData(fundId))
);

export const fundDataByDateState = atomFamily((fundId: number) =>
  atom(async (get) => {
    const fundData = await get(fundDataStateFP(fundId));
    return fundData.reduce((acc, data) => {
      return acc.set(data.date, data);
    }, new Map<string, FundData>());
  })
);

export const fundDataSortedByDateDescStateFP = atomFamily((fundId: number) =>
  atom(async (get) => {
    const fundData = await get(fundDataStateFP(fundId));
    return fundData.toSorted((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  })
);

export const latestFundDataStateFP = atomFamily((fundId: number) =>
  atom(async (get) => {
    const asOf = get(selectedDateFPState);
    return (await get(fundDataSortedByDateDescStateFP(fundId)))?.find(
      (data) => new Date(data.date).getTime() <= new Date(asOf).getTime()
    );
  })
);

export const contributionsDistributionsState = atom((get) => {
  const date = get(selectedDateFPState);
  const fundId = get(selectedFundIdStateFP);
  if (!fundId) return null;
  return getFundContributions(getPlainDateString(date), [fundId]).then((data) => data?.at(0) ?? null);
});

export const fundFormState = atom<Partial<FundViewModel | FundViewModel2> | null>(null);
export const fundFormCurrentStepState = atom(0);
export function useResetFundFormState() {
  return useAtomCallback(
    useCallback((get, set) => {
      set(fundFormState, null);
      set(fundFormCurrentStepState, 0);
    }, [])
  );
}

export const showFundFormsState = atom<'waterfall' | 'allocation' | null>(null);

export const showWaterfallSettingsState = atom(false);

export const isEditingFundDataState = atom(false);

export const forceReservesHistoryUpdateState = atom(0);
export const selectedFundReservesState = atomWithDefault<Promise<FundReserves | null> | FundReserves | null>(
  (get) => {
    const fund = get(selectedFundStateFP);
    if (!fund) return null;
    return fetchFundReserves(getDefaultProjectionsAsOfDate(fund), fund.id);
  }
);

export const selectedFundInvestmentsByCompanyNameState = atom(async (get) => {
  const result = new Map<string, FundInvestment>();
  const reserves = await get(selectedFundReservesState);
  return (reserves?.investments ?? []).reduce((map, investment) => {
    // portcos should already be loaded
    const company = get(companyStateJ(investment.companyId ?? -1));
    const key = String((company as ICompanyDataModel | null)?.name ?? investment.name);
    if (!key) return map;
    return map.set(key, investment);
  }, result);
});

export const fundReservesTransactionUuidToInvestmentState = atom(async (get) => {
  const reserves = await get(selectedFundReservesState);
  return (reserves?.investments ?? []).reduce((map, investment) => {
    investment.transactions.forEach((transaction) => {
      if (transaction.hypothetical && transaction.uuid) {
        map.set(transaction.uuid, investment);
      }
    });
    return map;
  }, new Map<string, FundInvestment>());
});

export const ReservesAction = {
  AddInvestment: 'AddInvestment',
  AddTransaction: 'AddTransaction',
  DeleteInvestment: 'DeleteInvestment',
  DeleteTransaction: 'DeleteTransaction',
  EditTransaction: 'EditTransaction',
} as const;
export type ReservesAction = (typeof ReservesAction)[keyof typeof ReservesAction];

export const showReservesFormState = atomWithReset<ReservesAction | null>(null);
export const selectedFundCompanyNameState = atomWithReset<string | null>(null);
export const selectedInvestmentTransactionUuidState = atomWithReset<string | null>(null);

export function useResetReservesFormState() {
  const resetSelectedFundCompany = useResetAtom(selectedFundCompanyNameState);
  const resetSelectedInvestmentTransactionUuid = useResetAtom(selectedInvestmentTransactionUuidState);
  const resetShowForm = useResetAtom(showReservesFormState);

  return useCallback(() => {
    resetSelectedFundCompany();
    resetSelectedInvestmentTransactionUuid();
    resetShowForm();
  }, [resetSelectedFundCompany, resetSelectedInvestmentTransactionUuid, resetShowForm]);
}

type ProjectionForm = 'feesAndExpenses' | 'recycling';
export const showProjectionFormState = atomWithReset<ProjectionForm | null>(null);
export const refetchCapitalAllocationState = atom<number>(0);

export function getDefaultProjectionsAsOfDate(fund: FundDataModel | null) {
  return new Date(
    fund?.finalCloseDate ??
      fund?.managementFeeTerminationDate ??
      addYears(new Date(fund?.inceptionDate ?? endOfDay(new Date())), 10)
  );
}
