import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Collapse } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { FormContainer } from '../../../components/Form/FormComponents';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { FormFieldAndLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { FieldEntity } from '../../../data-models/field2.data-model';
import { FDMap } from '../../../util/data-structure/FDMap';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { createForm, IFormField } from '../../../view-models/form.view-model';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { InfoBox } from '../../Mappings/forms/InfoBox';
import { CustomFieldFormType, CustomFieldSchema } from './CustomField.schema';
import { CustomFieldFormCurrentStepState, CustomFieldFormDataState } from './CustomFieldFormState';
import { useCustomFieldFormDynamicValues, useCustomFieldsStep1Fields } from './customFieldFormUtils';

export function CustomFieldFormStep1() {
  const [formData, setFormData] = useRecoilState(CustomFieldFormDataState);

  const defaultValues = useMemo(() => {
    if (formData) {
      return cloneDeep(formData);
    } else {
      return CustomFieldSchema.formSchema().getDefault();
    }
  }, [formData]);

  const methods = useForm<CustomFieldFormType>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(CustomFieldSchema.formSchema()),
  });

  const handleNext = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      setFormData(methods.getValues());
      return true;
    } else {
      return false;
    }
  };

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <FormStep1 />
      </FormContainer>
      <StepperFormButtons stepIsValid={handleNext} stepState={CustomFieldFormCurrentStepState} />
    </FormProvider>
  );
}

function FormStep1() {
  const { control } = useFormContext<CustomFieldFormType>();
  const entityValue = useWatch({ name: 'entity', control });
  const timeSeriesValue = useWatch({ name: 'timeSeries', control });
  const { fields, timeSeriesField } = useCustomFieldsStep1Fields().reduce(
    (acc, field) => {
      if (field.key === 'timeSeries') {
        acc.timeSeriesField = field;
      } else {
        acc.fields.push(field);
      }
      return acc;
    },
    {
      fields: [] as IFormField<unknown>[],
      timeSeriesField: null as IFormField<unknown> | null,
    }
  );
  const form = createForm({ fields: FDMap.fromArray(fields, 'key') });
  useCustomFieldFormDynamicValues();
  const { showCustomFieldsTimeSeries } = useFlags<MaggieFeatureFlags>();

  return (
    <>
      <FormFactoryWithStandardLayout form={form} />
      <Collapse in={entityValue === FieldEntity.fundCompany}>
        <InfoBox
          message={
            'A "Company & Fund" field means that it can have different values for different funds. Only relevant for portfolio companies'
          }
        />
      </Collapse>
      {showCustomFieldsTimeSeries && timeSeriesField && (
        <>
          <FormFieldAndLabelFactory formField={timeSeriesField} />
          <Box height='5rem'>
            <InfoBox
              message={
                timeSeriesValue
                  ? 'Date selection will be required for each new entry, and historical values will be available for the team.'
                  : 'Only the latest data record will be available for the team.'
              }
            />
          </Box>
        </>
      )}
    </>
  );
}
