import {
  PanelPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../data-models/preferences-v3.data-model';
import { preferenceAtom } from '../../util/preferences-service-v3';

export const defaultTearSheetCompanySummaryPref: PanelPreferences = (() => {
  const availableFields = [
    'company.foundedDate',
    'company.lastPostMoney',
    'initialPostMoney',
    'normalizedMostRecentStage',
    'participatingLastRoundSeries',
    'company.mostRcentRound',
    'participatingRound',
    'participatingRoundDate',
    'company.mostRecetRoundDate',
    'normalizedMostRecentStageDate',
    'investmentStatus',
    'company.ceoName',
    'company.website',
    'company.investors',
    'company.primaryLocation', // ?
    'company.secondaryLocations', // ?
    'company.totalRaisedToDate',
    'company.sector.displayName',
    'company.dealLead',
    'company.dealTeam',
    'company.impliedEquityValue',
    'company.internalSource',
    'company.source',
    'initialInvestmentDate',
    'company.sourceCompany',
    'company.boardDirector',
    'company.boardStatus',
    'company.acquirer.fields.name',
  ];

  return {
    availableFields,
    selectedFields: availableFields.slice(0, 15),
  };
})();

const defaultTearSheetInvestmentSummaryPref: PanelPreferences = {
  selectedFields: [
    'moic',
    'ownerShipPercentage',
    'irr',
    'amountInvested',
    'fmv',
    'impliedEquityValue',
    'reserves',
    'exitDate',
    'exitValuation',
    'exitType',
    'initialPreMoney',
    'exitOwnershipPercentage',
    'entryOwnership',
    'distributions',
    'convertedOwnership',
    'percentOfFund',
    'rtfe',
  ],
};

const defaultTearSheetPerformanceSummaryPref: PanelPreferences = {
  selectedFields: [],
};

export const tearSheetCompanySummaryPreferenceAtom = preferenceAtom(
  {
    preferenceKey: `${PreferenceKey.tearSheetCompanySummary}.default`,
    scope: PreferenceScope.ORGANIZATION,
  },
  undefined,
  defaultTearSheetCompanySummaryPref
);

export const tearSheetInvestmentSummaryPreferenceAtom = preferenceAtom(
  {
    preferenceKey: `${PreferenceKey.tearSheetInvestmentSummary}.default`,
    scope: PreferenceScope.ORGANIZATION,
  },
  undefined,
  defaultTearSheetInvestmentSummaryPref
);

export const tearSheetPerformanceSummaryPreferenceAtom = preferenceAtom(
  {
    preferenceKey: `${PreferenceKey.tearSheetPerformanceSummary}.default`,
    scope: PreferenceScope.ORGANIZATION,
  },
  undefined,
  defaultTearSheetPerformanceSummaryPref
);
