import { css } from '@emotion/react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SyncIcon from '@mui/icons-material/Sync';
import { Alert, IconButton, Stack, SvgIconProps, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { FieldSelect } from '../../../../../components/Form/Display/FieldSelect';
import { FieldLabel } from '../../../../../components/Form/FormComponents';
import { Loader } from '../../../../../components/Loader/Loader';
import { RendererType } from '../../../../../data-models/field.data-model';
import { ISelectMeta } from '../../../../../data-models/field3.data-model';
import { SlackChannel, SlackChannelSummary } from '../../../../../schemas/SlackChannel.schema';
import { colors } from '../../../../../theme/colors';
import { cardStyles } from '../../../../../theme/component-styles';
import { LoadingStatus } from '../../../../../types';
import { FMT } from '../../../../../util/formatter-service';
import { createFormField } from '../../../../../view-models/form.view-model';
import { selectedCompanyIdProfileAtom } from '../../../state/UIStateJ';
import { NoDataMessage2 } from '../../Messages/HelpMessage';
import { useAllSlackChannels, useSlackIntegration } from './useSlackIntegration';

const ContentContainer = css`
  padding: 1rem;
  display: grid;
  align-content: start;
  gap: 1rem;
`;

export function SlackContent() {
  const companyId = useAtomValue(selectedCompanyIdProfileAtom);
  const { data: channels, loading: channelsLoading, error: channelsError } = useAllSlackChannels();
  const { data, loading, error, remapChannel, refreshSummary } = useSlackIntegration(companyId);

  const onChannelChange = useCallback(
    async (channelId: string) => {
      await remapChannel(channelId);
    },
    [remapChannel]
  );

  return (
    <div css={ContentContainer}>
      {loading == LoadingStatus.loading || channelsLoading == LoadingStatus.loading ? (
        <Loader />
      ) : (
        <>
          <ChannelPicker
            channels={channels ?? []}
            currentChannelId={data?.channelId ?? ''}
            error={channelsError}
            onChannelChange={onChannelChange}
          />
          <SlackSummary data={data} error={error} onRefresh={refreshSummary} />
        </>
      )}
    </div>
  );
}

interface IChannelPickerProps {
  channels: SlackChannel[];
  currentChannelId: string;
  error: string | null;
  onChannelChange: (channelId: string) => void;
}
function ChannelPicker({ channels, currentChannelId, error, onChannelChange }: IChannelPickerProps) {
  const selector = useMemo(() => {
    if (!channels) return;
    return createFormField<ISelectMeta<string>>({
      key: 'selectedChannel',
      label: 'Select Channel',
      dataType: 'string',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: channels.map((channel) => {
          return {
            value: channel.id,
            displayName: `# ${channel.name}`,
          };
        }),
        multi: false,
      },
      disableClearable: true,
    });
  }, [channels]);

  if (error) return <Alert severity='error'>{error}</Alert>;
  if (!selector) return null;

  return (
    <Stack display={'grid'} alignItems={'start'} width='100%'>
      <FieldLabel field={selector} />
      <FieldSelect
        formField={selector}
        formProps={
          {
            onChange: onChannelChange,
            value: currentChannelId,
          } as ControllerRenderProps
        }
      />
    </Stack>
  );
}

interface ISlackSummaryProps {
  data: SlackChannelSummary | null;
  error: string | null;
  onRefresh: () => void;
}
function SlackSummary({ data, error, onRefresh }: ISlackSummaryProps) {
  if (error) return <Alert severity='error'>{error}</Alert>;
  if (!data) return <Alert severity='info'>Please provide the Slack channel</Alert>;
  return (
    <Stack>
      <Typography
        variant={'body2'}
        color={'text.secondary'}
        display={'flex'}
        alignItems={'base'}
        mb={'0.25rem'}
        gap={'0.25rem'}
      >
        <motion.div
          animate={{ opacity: [0, 1], scale: [0.8, 1.2, 1] }}
          transition={{ duration: 0.5 }}
          key={data.updatedAt}
        >
          <AIIcon />
        </motion.div>
        AI Summary
      </Typography>
      {data.summary ? (
        <Stack style={cardStyles}>
          <Typography whiteSpace={'pre-line'} variant='body2'>
            {data.summary}
          </Typography>
        </Stack>
      ) : (
        <NoDataMessage2 />
      )}
      <Stack display='grid' justifyContent={'start'} gridTemplateColumns={'auto auto'} alignItems={'center'}>
        <Typography variant='caption' color='text.secondary'>
          {`Last Updated: ${FMT.format('dateLocal', data.updatedAt)} at ${FMT.format('timeLocal', data.updatedAt)}`}
        </Typography>
        <IconButton onClick={onRefresh} title='Sync Now' size='small'>
          <SyncIcon fontSize='small' color='secondary' />
        </IconButton>
      </Stack>
    </Stack>
  );
}

function AIIcon({ fontSize = 'small', sx, ...rest }: SvgIconProps) {
  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id='linearColors' x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor={colors.secondary[40]} />
          <stop offset={1} stopColor={colors.tertiary[60]} />
        </linearGradient>
      </svg>
      <AutoAwesomeIcon fontSize={fontSize} sx={{ fill: 'url(#linearColors)', ...sx }} {...rest} />
    </>
  );
}
