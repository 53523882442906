import { css } from '@emotion/react';
import { useMemo } from 'react';
import { colors } from '../../../theme/colors';
import { FMT } from '../../../util/formatter-service';
import { NumericFormatterId } from '../../../util/formatters/NumericFormatters';
import { WaterfallGridData } from './FPWaterfallData2';
import { getVisibleColumnWidths } from './utils';

const Segment = css`
  height: 1.25rem;
  outline: 1px solid ${colors.primary[0]};
  width: 100%;
  display: grid;
  place-items: center;
  font-size: 0.6rem;
`;
const Bar = css`
  display: grid;
`;

export function DistributionBar({ data }: { data: WaterfallGridData }) {
  const { visualization: segments } = data;
  const nonZeroSegments = useMemo(() => segments?.filter((s) => s.percentValue > 0) ?? [], [segments]);
  const gridTemplateColumns = useMemo(() => {
    const colWidths = getVisibleColumnWidths(nonZeroSegments);
    return colWidths.map((w) => `${w}%`).join(' ') + ' 1fr';
  }, [nonZeroSegments]);

  return (
    <div css={Bar} style={{ gridTemplateColumns }}>
      {nonZeroSegments.map((segment, index) => {
        const roundedValue = FMT.format(
          NumericFormatterId.percentRound,
          Math.round(segment.percentValue * 100)
        );
        const bar = (
          <div
            key={index}
            css={Segment}
            style={{
              backgroundColor: segment.highlight ? colors.primary[20] : colors.neutral[10],
            }}
          >
            {segment.percentValue >= 0.1 && segment.highlight && roundedValue}
          </div>
        );

        return bar;
      })}
      <div css={Segment} style={{ backgroundColor: colors.secondary[30] }}></div>
    </div>
  );
}
