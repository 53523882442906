import { Typography } from '@mui/material';
import { useDealActions2 } from '../hooks/useDealActions';
import { FMT } from '../../../util/formatter-service';
import { ConfirmDeletionDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { ISelectedDealActionState } from '../state/DealboardUIState';

export interface IDealModalProps extends Omit<ISelectedDealActionState, 'action'> {
  onClose: () => void;
  open: boolean;
}
export function DeleteDealModal({ company, deal, next, open, onClose }: IDealModalProps) {
  const { handleDelete } = useDealActions2();

  return (
    <ConfirmDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={() =>
        handleDelete(deal, {
          onSuccess: next,
        })
      }
      title={`Delete Deal for "${company.name}"?`}
    >
      <Typography variant='body2' color='text.secondary'>{`The deal added ${FMT.format(
        'date',
        deal.createdAt
      )} will be deleted permanently`}</Typography>
    </ConfirmDeletionDialog>
  );
}
