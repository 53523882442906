import { FC, useCallback, useEffect } from 'react';
import { KpiAction } from '../../../../services/state/KPI/KPIRequestsState';
import { KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { useRequestActions, useResetKpiState } from './KPIRequestResponse/hooks/useKPIRequestResponseActions';
import { SentKpiRequestsGrid } from './SentKpiRequestsGrid';
import { SendReminderModal } from './components/SendReminderModal';
import { useKPIRequestsNotSentActions } from './hooks/useKPIRequestsNotSentActions';
import { ConfirmDeleteRequest } from './components/ConfirmDeleteRequest';

export const SentRequests: FC<{
  status: typeof KPIRequestStatus.Sent | typeof KPIRequestStatus.Archived;
}> = ({ status }) => {
  const resetRequestState = useResetKpiState();
  const { modalAction, selectedRequest, onCloseModal } = useRequestActions();
  const { sendKPIRequestAction } = useKPIRequestsNotSentActions();

  const onResend = useCallback(async () => {
    if (!selectedRequest) return;
    await sendKPIRequestAction({
      requestsIds: [selectedRequest.id],
      sendReminder: status === KPIRequestStatus.Sent,
    });
  }, [selectedRequest, sendKPIRequestAction, status]);

  useEffect(() => {
    return resetRequestState;
  }, [resetRequestState]);

  return (
    <>
      {(modalAction === KpiAction.sendReminder || modalAction === KpiAction.resend) && selectedRequest && (
        <SendReminderModal
          open
          onClose={onCloseModal}
          request={selectedRequest}
          onConfirm={onResend}
          action={modalAction}
        />
      )}
      {modalAction === KpiAction.archive && selectedRequest && (
        <ConfirmDeleteRequest open onClose={onCloseModal} request={selectedRequest} />
      )}
      <SentKpiRequestsGrid status={status} />
    </>
  );
};
