import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IInvestmentDataModel } from '../../../data-models/investment.data-model';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { fundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { KPI, KpiConfigMeta } from '../../../types';
import { getForesightStore } from '../../../util/jotai-store';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { filterMetricsTransactionsAndFundDataByFund, KpiCalculator } from '../services/kpi/KpiCalculator';
import { QuartersBuilder } from '../services/QuartersBuilder';
import { selectedViewDatePF } from '../state/PageState';
import { selectedViewPF } from '../state/ViewState';
import { useCurrentViewMetrics } from './useCurrentViewMetrics';

export interface KPIMeta {
  isLoading: boolean;
  isFetching: boolean;
  kpis: KPI | null;
  previousQuarterKPIs: KPI | null;
  kpiMetas: KpiConfigMeta[]; // not needed if we decide to always calculate all kpis
}

export const useKPIs = (): KPIMeta => {
  const view = useRecoilValue(selectedViewPF);
  const date = useRecoilValue(selectedViewDatePF);
  const fundsById = getForesightStore().get(fundsByIdMapAtom);
  const previousQuarter = useMemo(() => QuartersBuilder.getPreviousQuarterEnd(date), [date]);
  const { metrics, previousQuarterMetrics, filteredMetrics } = useCurrentViewMetrics();
  const [kpis, setKpis] = useState<KPI | null>(null);
  const [previousQuarterKPIs, setPreviousQuarterKPIs] = useState<KPI | null>(null);
  const getCompany = useGetCompanyIfSet();
  const companiesMap = useMemo(() => {
    const companyIds = new Set(
      [...(previousQuarterMetrics ?? []), ...(metrics.valueMaybe() ?? [])].map((m) => m.companyId)
    );
    return [...companyIds].reduce((map, id) => {
      const company = getCompany(id);
      if (company) {
        map.set(company.id, company);
      }
      return map;
    }, new Map<number, ICompanyDataModel>());
  }, [metrics, previousQuarterMetrics, getCompany]);

  useEffect(() => {
    let calculatedKPIs = {} as KPI;
    let previousQuarterKPIs = {} as KPI;
    const selectedFunds =
      view.filteredFunds?.reduce((res, fund) => {
        return res.add(fund.id);
      }, new Set<number>()) ?? new Set();

    if (filteredMetrics.state === 'hasValue') {
      let dataFilteredByFund = filteredMetrics.valueMaybe() ?? [];
      if (view.companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER && selectedFunds !== null) {
        dataFilteredByFund = filterMetricsTransactionsAndFundDataByFund(
          dataFilteredByFund as IMetricsDataModel[],
          selectedFunds,
          fundsById
        );
      }

      calculatedKPIs = KpiCalculator.calculate(
        {
          date: date || new Date(),
          filteredData: dataFilteredByFund as IInvestmentDataModel[],
          metrics: dataFilteredByFund as IMetricsDataModel[],
        },
        view.companyViewType,
        companiesMap
      );
    }

    if (previousQuarterMetrics.length > 0) {
      let dataFilteredByFund = filteredMetrics.valueMaybe() ?? [];
      if (view.companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER && selectedFunds !== null) {
        dataFilteredByFund = filterMetricsTransactionsAndFundDataByFund(
          previousQuarterMetrics as IMetricsDataModel[],
          selectedFunds,
          fundsById
        );
      }

      previousQuarterKPIs = KpiCalculator.calculate(
        {
          date: previousQuarter,
          filteredData: dataFilteredByFund as IInvestmentDataModel[],
          metrics: dataFilteredByFund as IMetricsDataModel[],
        },
        view.companyViewType,
        companiesMap
      );
    }

    setKpis(calculatedKPIs);
    setPreviousQuarterKPIs(previousQuarterKPIs);
  }, [
    date,
    filteredMetrics,
    view.companyViewType,
    setKpis,
    setPreviousQuarterKPIs,
    previousQuarterMetrics,
    previousQuarter,
    view.filteredFunds,
    fundsById,
    companiesMap,
  ]);

  return useMemo(
    () => ({
      isLoading: metrics.state === 'loading' || filteredMetrics.state === 'loading',
      isFetching: metrics.state === 'loading' || filteredMetrics.state === 'loading',
      kpis,
      previousQuarterKPIs,
      kpiMetas: KpiCalculator.getKpiMetas(view?.companyViewType),
    }),
    [metrics.state, filteredMetrics.state, kpis, previousQuarterKPIs, view?.companyViewType]
  );
};
