import * as yup from 'yup';
import { merge } from 'lodash-es';
import { FundMetrics } from '../../../schemas/FundMetrics.schema';
import { usdField } from '../../../schemas/common-schema-defs';

export function distributionSegmentFields() {
  return {
    highlight: yup.boolean().nullable().default(false),
    percentValue: yup.number().required().default(0),
    type: yup.string().oneOf(['lp', 'gp']),
  };
}

export function distributionSegmentSchema() {
  return yup.object().shape(distributionSegmentFields());
}

export type DistributionSegment = yup.InferType<ReturnType<typeof distributionSegmentSchema>>;

export function createDistributionSegment(overrides: Partial<DistributionSegment> = {}): DistributionSegment {
  return merge({}, distributionSegmentSchema().getDefault(), overrides);
}

export const WfPhase = {
  Initial: 'Initial',
  Tier0: 'Tier 0',
  Tier1: 'Tier 1',
  Tier2: 'Tier 2',
  Tier3: 'Tier 3',
  AddBackDistributions: 'Add Back Distributions',
  Output: 'Output',
} as const;
export type WfPhase = (typeof WfPhase)[keyof typeof WfPhase];

export const WfGridDataKey = {
  Initial: 'initial',
  Tier0: '0',
  Tier1: '1',
  Tier2: '2',
  Tier3: '3',
  AddBackDistributions: 'Add Back Distributions',
  Output: 'Output',
};
export type WfGridDataKey = (typeof WfGridDataKey)[keyof typeof WfGridDataKey];

export function waterfallGridDataFields() {
  return {
    key: yup.string().oneOf(Object.values(WfGridDataKey)).required(),
    phase: yup.string().oneOf(Object.values(WfPhase)).nullable().label('Phase').default(null),
    tier: yup
      .string()
      .oneOf(['Return of Capital', 'Preferred Return', 'Catch Up', 'Super Return', ''])
      .nullable()
      .label('Tier Name')
      .default(null),
    distributableProceeds: usdField().nullable().label('Distributable Proceeds').default(null),
    totalDistributed: usdField().nullable().label('Total Distributed').default(null),
    lpDistributed: usdField().nullable().label('LP Distributed').default(null),
    lpPortion: usdField().nullable().label('LP Portion').default(null),
    gpDistributed: usdField().nullable().label('GP Distributed').default(null),
    gpPortion: usdField().nullable().label('GP Portion').default(null),
    visualization: yup.array().of(distributionSegmentSchema()).nullable().label('Visualization').default([]),
  };
}

export function waterfallGridDataSchema() {
  return yup.object().shape(waterfallGridDataFields());
}

export type WaterfallGridData = yup.InferType<ReturnType<typeof waterfallGridDataSchema>>;

export function createWaterfallGridData(overrides: Partial<WaterfallGridData> = {}): WaterfallGridData {
  return merge({ key: '' }, waterfallGridDataSchema().getDefault(), overrides);
}

export function getWaterfallData(metrics: FundMetrics): WaterfallGridData[] {
  const initialDistributableProceeds = metrics.distributableProceedsUnadjusted;
  return [
    createWaterfallGridData({
      key: 'initial',
      phase: 'Initial',
      distributableProceeds: initialDistributableProceeds,
    }),
    createWaterfallGridData({
      key: '0',
      phase: 'Tier 0',
      tier: 'Return of Capital',
      distributableProceeds: initialDistributableProceeds,
      totalDistributed: metrics.contributionsForCalculation,
      lpDistributed: metrics.lpROC,
      gpDistributed: metrics.gpROC,
    }),
    createWaterfallGridData({
      key: '1',
      phase: 'Tier 1',
      tier: 'Preferred Return',
      distributableProceeds: metrics.amountAvailableForLpGpSplit,
      totalDistributed: (metrics.lpNavFromLpGpSplit ?? 0) + (metrics.gpIncentiveFromLpGpSplit ?? 0),
      lpDistributed: metrics.lpNavFromLpGpSplit,
      gpDistributed: metrics.gpIncentiveFromLpGpSplit,
      lpPortion: metrics.navFromLpGpSplitLP ?? 0,
      gpPortion: metrics.navFromLpGpSplitGp ?? 0,
    }),
    createWaterfallGridData({
      key: '2',
      phase: 'Tier 2',
      tier: 'Catch Up',
      distributableProceeds: metrics.availableForGpCatchup,
      totalDistributed: metrics.gpIncentiveFromGpCatchup,
      lpDistributed: 0,
      gpDistributed: metrics.gpIncentiveFromGpCatchup,
    }),
    createWaterfallGridData({
      key: '3',
      phase: 'Tier 3',
      tier: 'Super Return',
      distributableProceeds: metrics.availableForSuperReturn,
      totalDistributed: (metrics.lpNavFromSuperReturn ?? 0) + (metrics.gpIncentiveFromSuperReturn ?? 0),
      lpDistributed: metrics.lpNavFromSuperReturn,
      gpDistributed: metrics.gpIncentiveFromSuperReturn ?? 0,
      lpPortion: metrics.navFromSuperReturnLP ?? 0,
      gpPortion: metrics.navFromSuperReturnGP ?? 0,
    }),
    createWaterfallGridData({
      key: 'Add Back Distributions',
      phase: 'Add Back Distributions',
      tier: '',
      lpDistributed: metrics.lpDistributions ? -metrics.lpDistributions : 0,
      gpDistributed: metrics.gpDistributions ? -metrics.gpDistributions : 0,
    }),
  ].reduce((acc, curr) => {
    const prev = acc.at(-1);
    const visualization = getDistributions(
      curr,
      initialDistributableProceeds ?? 0,
      prev?.visualization ?? []
    );
    return [...acc, { ...curr, visualization }];
  }, [] as WaterfallGridData[]);
}

export function getWaterfallOutput(metrics: FundMetrics): WaterfallGridData {
  return createWaterfallGridData({
    key: 'Output',
    lpDistributed: metrics.lpNavTotal,
    gpDistributed: metrics.gpIncentive,
    lpPortion: metrics.navLPPortion ?? 0,
    gpPortion: metrics.navGPPortion ?? 0,
    phase: 'Output',
  });
}

export function getDistributions(
  data: WaterfallGridData,
  initialDistributableProceeds: number,
  prevSegments: DistributionSegment[] = []
): DistributionSegment[] | null {
  if (data.phase === 'Add Back Distributions') return null;
  const { gpDistributed, lpDistributed } = data;
  const lpSegmentValue = initialDistributableProceeds
    ? (lpDistributed ?? 0) / initialDistributableProceeds
    : 0;
  const gpSegmentValue = initialDistributableProceeds
    ? (gpDistributed ?? 0) / initialDistributableProceeds
    : 0;
  const lpSegment = createDistributionSegment({
    percentValue: lpSegmentValue,
    highlight: true,
    type: 'lp' as const,
  });
  const gpSegment = createDistributionSegment({
    percentValue: gpSegmentValue,
    highlight: true,
    type: 'gp' as const,
  });
  const result: DistributionSegment[] = prevSegments.map((s) => ({ ...s, highlight: false }));
  result.push(lpSegment, gpSegment);
  return result;
}
