import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ECharts, init } from 'echarts/core';
import { useAtomValue } from 'jotai';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Chart } from '../../../../../components/Charts/chart-utils';
import { ChartContainer } from '../../../../../components/Charts/ChartContainer';
import { ChartTitleWithIcon } from '../../../../../components/Charts/ChartTitleWithIcon';
import { IConversionRateDataModel } from '../../../../../data-models/deal-conversion.data-model';
import { useIsInViewport } from '../../../../../hooks/useIsInViewport';
import { getDurationText } from '../../../helpers/chart-utils';
import { useSankeyData } from '../../../hooks/useSankeyData';
import { monthsBackState } from '../../../state/DealConversionFilterState';
import { formatDiff, useChartStyles } from './utilHooks/useChartStyles';

const TitleAndSubtitle = styled('div')`
  align-items: end;
  display: grid;
  grid-column-gap: 0.5rem;
  grid-template-columns: max-content max-content;
`;

interface IOverviewSectionProps {
  dealConversion: IConversionRateDataModel[];
}

export const OverviewChart: FC<IOverviewSectionProps> = ({ dealConversion }) => {
  const sankeyData = useSankeyData(dealConversion);
  const { colors } = useTheme();
  const { indicatorRich } = useChartStyles();
  const period = useAtomValue(monthsBackState);

  const chartContainer = useRef<HTMLDivElement | null>(null);
  const sankeyChart = useRef<ECharts | null>(null);
  const isInViewport = useIsInViewport(
    {
      threshold: 0.75,
    },
    chartContainer
  );

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!sankeyChart.current) {
      sankeyChart.current = init(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          sankeyChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  const styledData = useMemo(() => {
    return sankeyData.data.map((item) => {
      let color;
      if (item.name?.startsWith('Passed')) color = colors.neutral[50];
      else if (item.name?.startsWith('Missed')) color = colors.critical[30];
      else if (item.name?.startsWith('Tracked')) color = colors.neutral[30];
      else if (item.name?.startsWith('Closed')) color = colors.success[50];
      else color = colors.primary[40];
      return {
        ...item,
        itemStyle: { color },
        label: {
          show: true,
        },
      };
    });
  }, [colors.critical, colors.neutral, colors.primary, colors.success, sankeyData.data]);

  useEffect(() => {
    if (!(sankeyChart.current && isInViewport)) {
      return;
    }

    const series = {
      type: 'sankey',
      data: styledData,
      links: sankeyData.links,
      lineStyle: {
        curveness: 0.5,
        color: colors.neutral[10],
        opacity: 1,
      },
      emphasis: {
        disabled: true,
      },
      label: {
        lineHeight: 18,
        rich: indicatorRich,
        formatter: formatLabel,
      },
      top: '6%',
      right: '15%',
      left: '20px',
      nodeGap: 20,
    };

    const option = {
      name: 'Overview',
      coordinateSystem: 'view',
      grid: {
        width: '100%',
      },
      series,
      tooltip: [
        {
          trigger: 'item',
          confine: true,
          triggerOn: 'mousemove',
          textStyle: {
            color: colors.primary[100],
            fontSize: 12,
          },
          formatter: formatTooltip,
        },
      ],
    };

    sankeyChart.current.setOption(option, true);
  }, [
    colors.neutral,
    colors.primary,
    indicatorRich,
    isInViewport,
    sankeyData.data,
    sankeyData.links,
    styledData,
  ]);

  const subtitle = useMemo(() => getDurationText(period), [period]);

  return (
    <>
      <ChartContainer>
        <TitleAndSubtitle>
          <ChartTitleWithIcon title={'Overview'} style={{ marginLeft: '20px' }} />
          <Typography color={colors.neutral[50]} variant={'caption'}>
            {subtitle}
          </Typography>
        </TitleAndSubtitle>
        {sankeyData.data.length ? (
          <Chart ref={chartContainer} width={'100%'} height={'450px'} />
        ) : (
          <Typography color={colors.neutral[40]} style={{ marginTop: '2rem' }}>
            No data.
          </Typography>
        )}
      </ChartContainer>
    </>
  );
};

function formatLabel({
  name,
  value,
  data,
}: {
  data: object;
  name: string;
  value: number;
  previousPeriodDifference?: number | null;
}) {
  return name
    ? `${name.split('#')[0]} {strong|${value ?? ''}} ${formatDiff(data, 'previousPeriodDifference')}`
    : '';
}

function formatTooltip({ name, value }: { name: string; value: number }) {
  return name ? `${name.split('#')[0]} \n<strong>${value ?? ''}</strong>` : '';
}
