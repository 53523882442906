import { CashFlow } from '@webcarrot/xirr';
import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { calculateXIRR } from '../../providers/calculateXIRR';
import { format } from './kpi-formatter';

export class IrrKpiConfig implements KpiConfig {
  private id = KPI_COMPONENTS.IRR as const;
  private title = 'Gross IRR';
  private type = 'percentage';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const investments: CashFlow[] = [];

    data?.metrics.forEach(({ transactions }) => {
      transactions.forEach((transaction) => {
        if (transaction.investmentAmount) {
          investments.push({
            date: new Date(transaction.transactionDate),
            amount: -1 * transaction.investmentAmount,
          });
        }

        if (transaction.distributions) {
          investments.push({
            date: new Date(transaction.transactionDate),
            amount: transaction.distributions,
          });
        }

        if (transaction.currentInvestment) {
          investments.push({
            date: data?.date,
            amount: transaction.currentInvestment,
          });
        }
      });
    });

    const value = investments.length < 2 ? 0 : calculateXIRR(investments);

    return {
      ...this.getMeta(),
      value: value,
      formatted: format(value, this.type),
    };
  }
}
