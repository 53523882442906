import { css } from '@emotion/react';
import { Button } from '@mui/material';
import { useAtom, useAtomValue, WritableAtom } from 'jotai';
import { useCallback } from 'react';
import { Link } from 'react-router';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { FieldEntity } from '../../../data-models/field2.data-model';
import { PanelPreferences } from '../../../data-models/preferences-v3.data-model';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { customFieldsByEntityAtom } from '../../../services/state/AdminPanel/CustomFieldsStateJ';
import { kpiConfigAtom } from '../../../services/state/KPIConfigState';
import { FDMap } from '../../../util/data-structure/FDMap';
import { useAllInvestmentMetricsFields } from '../../CompanyProfiles2/Summary/useInvestmentMetricsFields';
import { columnsMetaState } from '../../PortfolioOverview/state/ViewState';
import {
  tearSheetCompanySummaryPreferenceAtom,
  tearSheetInvestmentSummaryPreferenceAtom,
  tearSheetPerformanceSummaryPreferenceAtom,
} from '../../TearSheet/TearSheetPreferences';
import { FieldOrderConfig, IDraggableField, ReOrderableField } from './FieldOrderConfig';

const editContainer = css`
  display: grid;
  grid-template-columns: 1fr min-content;
  row-gap: 1rem;
`;

export function TearSheetConfig() {
  return (
    <div css={editContainer}>
      <div>Company Summary</div>
      <Button
        component={Link}
        to={ROUTES.TEAR_SHEETS_COMPANY_SUMMARY_CONFIG}
        variant='contained'
        color='secondary'
      >
        Edit
      </Button>
      <div>Investment Summary</div>
      <Button
        component={Link}
        to={ROUTES.TEAR_SHEETS_INVESTMENT_SUMMARY_CONFIG}
        variant='contained'
        color='secondary'
      >
        Edit
      </Button>

      <div>Performance Summary</div>
      <Button
        component={Link}
        to={ROUTES.TEAR_SHEETS_PERFORMANCE_SUMMARY_CONFIG}
        variant='contained'
        color='secondary'
      >
        Edit
      </Button>
    </div>
  );
}

export function TearSheetCompanySummaryConfig() {
  const companyConfigPref = useAtomValue(tearSheetCompanySummaryPreferenceAtom)!;
  const allCompanyFields = useAtomValue(columnsMetaState(COMPANY_VIEW_TYPE.RETURN_FORECAST));
  const allCompanyFieldsMap = FDMap.fromArray(allCompanyFields, 'path');
  const companyCustomFields = useAtomValue(customFieldsByEntityAtom)?.get(FieldEntity.company);
  const availableFields: ReOrderableField[] = [];

  (companyConfigPref!.availableFields ?? []).forEach((fieldPath) => {
    if (allCompanyFieldsMap.has(fieldPath)) {
      const meta = allCompanyFieldsMap.get(fieldPath)!;
      availableFields.push({
        key: meta.path,
        label: meta.displayName,
      });
    }
  });

  (companyCustomFields ?? []).forEach((field) => {
    availableFields.push({
      key: field.entityField,
      label: field.displayName,
    });
  });

  return (
    <PanelSelectedFieldsConfig
      allFields={availableFields}
      preference={tearSheetCompanySummaryPreferenceAtom}
      sectionName={'Company Summary'}
    />
  );
}

export function TearSheetInvestmentSummaryConfig() {
  const allInvestmentMetricsFields = useAllInvestmentMetricsFields();

  return (
    <PanelSelectedFieldsConfig
      allFields={allInvestmentMetricsFields}
      preference={tearSheetInvestmentSummaryPreferenceAtom}
      sectionName={'Investment Summary'}
    />
  );
}

export function TearSheetPerformanceSummaryConfig() {
  const allKpis = useAtomValue(kpiConfigAtom).map((field) => ({
    label: field.displayName,
    key: field.entityField,
  }));

  return (
    <PanelSelectedFieldsConfig
      allFields={allKpis}
      preference={tearSheetPerformanceSummaryPreferenceAtom}
      sectionName={'Performance Summary'}
    />
  );
}

export type PanelSelectedFieldsConfig = {
  allFields: ReOrderableField[];
  preference: WritableAtom<PanelPreferences | undefined, [newValue: PanelPreferences], void>;
  sectionName: string;
};

export function PanelSelectedFieldsConfig(props: PanelSelectedFieldsConfig) {
  const [panelPref, setPanelPref] = useAtom(props.preference)!;
  const availableFields = props.allFields;

  const onAddField = useCallback(
    (addedField: string) => {
      const current = panelPref!;
      const newPref = {
        ...current,
        selectedFields: [...current.selectedFields, addedField],
      };

      setPanelPref(newPref);
    },
    [panelPref, setPanelPref]
  );

  const onRemove = (removedField: IDraggableField) => {
    const current = panelPref!;
    const newPref = {
      ...current,
      selectedFields: current.selectedFields.filter((field) => field !== removedField.id),
    };

    setPanelPref(newPref);
  };

  const onReorder = useCallback(
    (groupedItems: Record<string, IDraggableField[]>) => {
      const newOrder = Object.values(groupedItems)[0];
      const selectedFields = newOrder.map((item) => {
        return item.key;
      });
      const newPref = {
        ...panelPref!,
        selectedFields,
      };

      setPanelPref(newPref);
    },
    [panelPref, setPanelPref]
  );

  return (
    <div>
      <div>{props.sectionName}</div>
      <FieldOrderConfig
        allFields={availableFields}
        onAddField={onAddField}
        onRemoveField={onRemove}
        onReorder={onReorder}
        selectedFields={panelPref!.selectedFields}
        fieldsPerPage={availableFields.length}
      />
    </div>
  );
}
