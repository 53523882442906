import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { IField, ISelectConfig } from '../../../../data-models/field2.data-model';
import { AgFormatterService } from '../../../../util/ag-formatter-service';
import { getIsoDateColDef } from '../../../../util/ag-grid-utils';
import ActionsCellRenderer from '../components/ActionsCellRenderer';
import { TooltipWrapperComponent } from '../../../../util/TooltipWrapperComponent';
import { entityFormatter } from '../../../KPI/common/GridFormatters';
import useCreatedByFormatter from '../../../../hooks/useCreatedByFormatter';

export default function useCustomFieldsColDefs() {
  const { createdByFormatter } = useCreatedByFormatter();

  const columnDefs: ColDef<IField<unknown>>[] = useMemo(
    () => [
      {
        field: 'entity',
        headerName: 'Group',
        rowGroup: true,
        hide: true,
        valueFormatter: entityFormatter,
      },
      {
        field: 'displayName',
        headerName: 'Field',
        valueFormatter: entityFormatter,
        getQuickFilterText: (params) => params.value,
      },
      { field: 'description', cellRenderer: TooltipWrapperComponent },
      {
        field: 'systemMeta.formRefs',
        headerName: 'Display',
      },
      { field: 'formMeta.renderer.type', headerName: 'Type' },
      {
        headerName: 'Options',
        field: 'formMeta',
        valueGetter: (params) => {
          const formMeta = (params.data as IField<unknown, ISelectConfig<unknown>>)?.formMeta;
          const values = formMeta?.renderer?.config?.values ?? [];

          return values.map((opt) => opt.displayName).join(', ');
        },
        cellRenderer: TooltipWrapperComponent,
      },
      getIsoDateColDef<IField<unknown>>({
        field: 'createdAt',
        headerName: 'Created At',
        valueFormatter: AgFormatterService.get()?.getFormatterForId('date'),
      }),
      {
        field: 'createdBy',
        headerName: 'Created By',
        resizable: false,
        valueFormatter: createdByFormatter,
      },
      {
        minWidth: 50,
        maxWidth: 100,
        pinned: 'right',
        sortable: false,
        resizable: false,
        cellRenderer: ActionsCellRenderer,
      },
    ],
    [createdByFormatter]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
