import { useRecoilCallback } from 'recoil';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { IKPITemplate } from '../../../data-models/kpi-template.data-model';
import { getErrorMessage } from '../../../services/queryHelpers';
import { kpiTemplatesListState } from '../../../services/state/KPI/KPITemplatesState';
import { LoadingId } from '../../../types';
import {
  createKPITemplate,
  deleteKPITemplate,
  fetchKPITemplates,
  fetchTemplatesAssignments,
  updateKPITemplate,
} from '../../../services/queries/KPITemplatesQueries';
import { useInvalidateKPIRequests } from '../components/KPIRequests/KPIRequestResponse/hooks/useInvalidateKPIRequests';
import { useInvalidateData } from '../../../hooks/useInvalidateData';
import { kpiTemplateAssignmentsListState } from '../../../services/state/KPI/KPICompaniesState';

// FIXME: MAGGIE-4576
export function useKPITemplateActions() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const invalidateRequests = useInvalidateKPIRequests();
  const invalidateData = useInvalidateData();

  const createKPITemplateAction = useRecoilCallback(
    ({ set }) =>
      async (payload: Partial<IKPITemplate>) => {
        actions.startLoading(LoadingId.createKPITemplate);
        try {
          const preparedPayload = prepareCreatePayload(payload);

          const response = await createKPITemplate(preparedPayload);

          set(kpiTemplatesListState, (current) => [...(current ?? []), response]);

          pushSuccessToast({ message: `Template "${payload.name}" was created successfully.` });
          return { id: response.id };
        } catch (err) {
          const message = getErrorMessage(err, 'Failed to create template.');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.createKPITemplate);
        }
      },
    [actions, pushErrorToast, pushSuccessToast]
  );

  const updateKPITemplateAction = useRecoilCallback(
    ({ set }) =>
      async (payload: Partial<IKPITemplate>) => {
        actions.startLoading(LoadingId.updateKPITemplate);
        try {
          const preparedPayload = prepareUpdatePayload(payload);
          const response = await updateKPITemplate(preparedPayload);

          set(kpiTemplatesListState, (current) => {
            const newValues = current?.map((value) => {
              if (value.id === response.id) {
                return response;
              }
              return value;
            });

            return [...(newValues ?? [])];
          });

          pushSuccessToast({ message: `Template "${payload.name}" was updated successfully.` });
        } catch (err) {
          const message = getErrorMessage(err, 'Failed to update template.');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.updateKPITemplate);
        }
      },
    [actions, pushErrorToast, pushSuccessToast]
  );

  const deleteKPITemplateAction = useRecoilCallback(
    ({ set }) =>
      async (payload: IKPITemplate) => {
        actions.startLoading(LoadingId.deleteTemplate);

        try {
          if (!payload.id) {
            throw new Error('Missing data');
          }
          const response = await deleteKPITemplate(payload.id);
          const templates = await fetchKPITemplates();
          set(kpiTemplatesListState, templates);
          invalidateRequests();
          await invalidateData({ state: kpiTemplateAssignmentsListState, query: fetchTemplatesAssignments });

          const successMsg = `Template "${payload?.name}" was removed`;

          pushSuccessToast({
            message: successMsg,
          });
          return response;
        } catch (err) {
          const message = getErrorMessage(err, `Failed to delete template ${payload?.name}.`);
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.deleteTemplate);
        }
      },
    [actions, pushErrorToast, pushSuccessToast]
  );

  return {
    updateKPITemplateAction,
    createKPITemplateAction,
    deleteKPITemplateAction,
  };
}

const prepareCreatePayload = (payload: Partial<IKPITemplate>) => {
  return {
    isManagedService: payload.isManagedService,
    name: payload.name,
    sections: payload.sections,
  };
};

const prepareUpdatePayload = (payload: Partial<IKPITemplate>) => {
  return {
    uuid: payload.uuid,
    id: payload.id,
    name: payload.name,
    sections: payload.sections,
    isManagedService: payload.isManagedService,
  };
};
