import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { StepperFormFieldsContainer } from '../../../components/Form/FormComponents';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { ICompanyViewModel } from '../../../view-models/company.view-model';
import { createFormFromFieldsArray, IForm } from '../../../view-models/form.view-model';
import {
  fromCompanyDataModel,
  toCompanyDataModel,
} from '../../CompanyProfiles2/Summary/DigestPanel.view-model';
import { companyFormSchema, useCompanyFields } from './CompanyFormUtils';
import { StepperFormButtons } from './StepperFormButtons';

type ICompanyFormProps = {
  formConfig?: Omit<IForm, 'fields'>;
};
export const CompanyForm: FC<ICompanyFormProps> = ({ formConfig }) => {
  const { variant, title } = formConfig ?? {};
  const companyFields = useCompanyFields();
  const form = createFormFromFieldsArray(companyFields, { variant, title });

  return <FormFactoryWithStandardLayout form={form} />;
};

interface ICompanyFormStepProps {
  defaultValues: Partial<ICompanyDataModel>;
  onSubmit: (data: Partial<ICompanyDataModel>) => Promise<ICompanyDataModel | void>;
  title?: string;
  onCancel?: () => void;
}
export const CompanyFormStep: FC<ICompanyFormStepProps> = ({ defaultValues, onSubmit, title, onCancel }) => {
  const methods = useForm<Partial<ICompanyViewModel>>({
    defaultValues: fromCompanyDataModel(defaultValues),
    resolver: yupResolver(
      companyFormSchema().omit(['_viewModel']) as ObjectSchema<Partial<ICompanyViewModel>>
    ),
    mode: 'all',
  });

  const handleSubmit = useCallback(async () => {
    const isValid = await methods.trigger();
    if (!isValid) {
      console.error(`Company Form is not valid`, methods.formState.errors);
      return false;
    }
    const values = methods.getValues();
    const data = toCompanyDataModel(values);
    const res = await onSubmit(data as Partial<ICompanyDataModel>);
    return Boolean(res);
  }, [methods, onSubmit]);

  const submitLabel = defaultValues.id ? (methods.formState.isDirty ? 'Update' : 'Confirm') : 'Create';

  return (
    <FormProvider {...methods}>
      <StepperFormFieldsContainer>
        <GenericFallbacksWrapper>
          <CompanyForm formConfig={{ title, variant: 'form' }} />
        </GenericFallbacksWrapper>
      </StepperFormFieldsContainer>
      <StepperFormButtons
        stepIsValid={handleSubmit}
        nextButtonLabel={submitLabel}
        backButtonLabel={onCancel ? 'Cancel' : undefined}
        handleGoBack={onCancel}
      />
    </FormProvider>
  );
};
