import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import * as yup from 'yup';
import { CalendarShortcut } from '../components/DatePicker/Calendar';
import { IDealDataModel } from '../data-models/deal.data-model';
import { RendererType } from '../data-models/field.data-model';
import { useLabelActions } from '../pages/DealFlow2/hooks/useLabelActions';
import {
  dealBoardConfigState,
  dealLabelsState,
  dealTypesByIdMapState,
} from '../pages/DealFlow2/state/DealboardDataState';
import { fundsAtom, roundsAtom, usersByIdMapAtom } from '../services/state/AppConfigStateJ';
import { getForesightStore } from '../util/jotai-store';
import { baseModelFields } from './BaseModel.schema';
import { usdField } from './common-schema-defs';

export const TbdStageId = -100;
export function dealFields() {
  const store = getForesightStore();
  const { createdAt, createdBy } = baseModelFields();
  return {
    customData: yup.mixed().nullable().default({}),
    dealTypeId: yup.number().nullable().required().label('Deal Type'),
    dealLeadId: yup
      .number()
      .nullable()
      .required()
      .label('Deal Lead')
      .formMeta({
        formatter: 'user',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Array.from(store.get(usersByIdMapAtom).values()).map((user) => ({
            displayName: user.name,
            value: user.id,
          })),
        },
        disableClearable: true,
      })
      .default(null),
    dealTeamIds: yup
      .array()
      .of(yup.number())
      .nullable()
      .label('Deal Team')
      .formMeta({
        formatter: 'user',
        renderer: RendererType.multiSelect,
        rendererMeta: {
          values: Array.from(store.get(usersByIdMapAtom).values()).map((user) => ({
            displayName: user.name,
            value: user.id,
          })),
        },
      })
      .default([]),
    roundId: yup
      .number()
      .nullable()
      .label('Stage')
      .formMeta({
        formatter: 'round',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: store
            .get(roundsAtom)
            .map((round) => ({
              displayName: round.name,
              value: round.id,
            }))
            .concat({
              displayName: 'TBD',
              value: TbdStageId,
            }),
        },
        disableClearable: true,
      })
      .default(TbdStageId),
    raiseAmount: usdField().min(0).nullable().default(null),
    gcAmountMin: usdField().nullable().min(0).label('Min Investment Amount').default(null),
    gcAmountMax: usdField().nullable().min(0).label('Max Investment Amount').default(null),
    totalPic: usdField().min(0).nullable().label('Total PIC').default(null),
    fundIds: yup
      .array()
      .of(yup.number())
      .nullable()
      .label('Funds')
      .formMeta({
        formatter: 'fund',
        renderer: RendererType.multiSelect,
        rendererMeta: {
          values: store.get(fundsAtom).map((fund) => ({
            displayName: fund.name,
            value: fund.id,
          })),
        },
      })
      .default(null),
    labels: yup.array().optional().nullable().default([]),
    createdAt: createdAt.label('Date Added').formMeta({
      disabled: true,
    }),
    createdBy: createdBy.label('Added By').formMeta({
      disabled: true,
    }),
  };
}

export function dealSchema() {
  return yup.object().shape(dealFields());
}
export type DealFormViewModel = yup.InferType<ReturnType<typeof dealSchema>>;

export function createDealFormViewModel(overrides: Partial<DealFormViewModel> = {}) {
  return { ...dealSchema().getDefault(), ...overrides };
}

// temp solution until we migrate dealflow to jotai
export function useDealFormSchema() {
  const labelsState = useAtomValue(dealLabelsState);
  const labelValues = useMemo(() => {
    if (labelsState.state != 'hasData') return [];
    return labelsState.data.map((l) => ({
      value: l.name,
      displayName: l.name,
    }));
  }, [labelsState]);
  const { handleCreateLabel } = useLabelActions();
  const dealTypesById = useAtomValue(dealTypesByIdMapState);
  const { dealTypeId, labels, customData, ...rest } = dealFields();

  return yup.object({
    dealTypeId: dealTypeId.formMeta({
      formatter: 'stringArray',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: Array.from(dealTypesById?.values() ?? []).map((dt) => ({
          displayName: dt.name,
          value: dt.id,
        })),
      },
    }),
    ...rest,
    labels: labels.formMeta({
      renderer: RendererType.multiSelect,
      rendererMeta: {
        allowCustomAdd: true,
        onCustomAdd: handleCreateLabel,
        values: labelValues,
      },
    }),
  });
}

export function passDealVMFields() {
  return {
    passComments: yup
      .string()
      .nullable()
      .default(null)
      .label('Comment')
      .formMeta({
        rendererMeta: {
          multiline: true,
        },
      }),
    stageComments: yup.array().of(yup.string()).nullable().default([]).required().min(1).label('Reasons'),
  };
}

export type PassDealFormViewModel = yup.InferType<ReturnType<typeof usePassDealFormVMSchema>>;

export function fromPassVMToDealDM(
  viewModel: Partial<PassDealFormViewModel>
): Pick<IDealDataModel, 'passComments' | 'stageComments'> {
  return {
    ...viewModel,
    stageComments: viewModel.stageComments?.length ? viewModel.stageComments.join(',') : null,
  };
}

export function fromDealDMToPassVM(deal: IDealDataModel): PassDealFormViewModel {
  return {
    stageComments: deal.stageComments?.length ? deal.stageComments.split(',') : [],
    passComments: deal.passComments ?? null,
  };
}

export function usePassDealFormVMSchema() {
  const { passReasons: passReasonOptions } = useAtomValue(dealBoardConfigState);
  const { passComments, stageComments } = passDealVMFields();

  return yup.object({
    stageComments: stageComments.formMeta({
      renderer: RendererType.multiSelect,
      rendererMeta: {
        values: passReasonOptions.map(({ reason }) => ({
          value: reason,
          displayName: reason,
        })),
      },
    }),
    passComments,
  });
}

export function missDealVMFields() {
  return {
    stageComments: yup.array().of(yup.string()).nullable().default([]).required().min(1).label('Reasons'),
    passComments: yup
      .string()
      .nullable()
      .default(null)
      .label('Comment')
      .formMeta({
        rendererMeta: {
          multiline: true,
        },
      }),
  };
}

export type MissDealFormViewModel = yup.InferType<ReturnType<typeof useMissDealFormVMSchema>>;

export function fromMissVMToDealDM(
  viewModel: Partial<MissDealFormViewModel>
): Pick<IDealDataModel, 'stageComments' | 'passComments'> {
  return {
    ...viewModel,
    stageComments: viewModel.stageComments?.length ? viewModel.stageComments.join(',') : null,
  };
}
export function fromDealDMToMissedVM(deal: IDealDataModel): MissDealFormViewModel {
  return {
    stageComments: deal.stageComments?.length ? deal.stageComments.split(',') : [],
    passComments: deal.passComments ?? null,
  };
}

export function useMissDealFormVMSchema() {
  const { missedReasons } = useAtomValue(dealBoardConfigState);

  const { stageComments, passComments } = missDealVMFields();

  return yup.object({
    stageComments: stageComments.formMeta({
      renderer: RendererType.multiSelect,
      rendererMeta: {
        values: missedReasons.map(({ reason }) => ({
          value: reason,
          displayName: reason,
        })),
      },
    }),
    passComments,
  });
}

export function trackDealVMFields() {
  return {
    trackComments: yup
      .string()
      .nullable()
      .default(null)
      .required()
      .label('Comment')
      .formMeta({
        rendererMeta: {
          multiline: true,
        },
      }),
    snoozeDate: yup.date().nullable().default(null),
  };
}
export function trackDealVMSchema() {
  const { trackComments, snoozeDate } = trackDealVMFields();

  return yup.object({
    trackComments,
    snoozeDate: snoozeDate.formMeta({
      renderer: RendererType.date,
      rendererMeta: {
        presets: [CalendarShortcut.in30Days, CalendarShortcut.in60Days, CalendarShortcut.in90Days],
      },
    }),
  });
}

export type TrackDealFormViewModel = yup.InferType<ReturnType<typeof trackDealVMSchema>>;
