import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { GetRowIdParams, GridApi, GridOptions, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { allInvestmentRoundsMapState, financeTransactionsByCompanyState } from '../state/FinanceState';
import { useExtendedExportSettings } from '../../../components/AgTable/exportToExcelDefs';
import {
  RoundCellRenderer,
  RoundCellRendererName,
} from '../../../components/AgTable/cell-renderers/RoundCellRenderer';
import { Loader } from '../../../components/Loader/Loader';
import { AgTable } from '../../../components/AgTable/AgTable';
import { genericComparator } from '../../../util/comparators';
import { ErrorOccurredMessage } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { TransactionModals } from '../Transactions/modals/TransactionModals';
import { useGetTransactionGridColdDefsForTypeId } from '../../../schemas/transactions/TransactionSchemaUtil';
import { companyState } from '../../../services/state/CompanyState';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { ISimpleChoice } from '../../../data-models/field2.data-model';
import { AllTransactionsItem, AvailableTransactionTypePills } from './AvailableTransactionTypesPills';

export const TransactionsTable3: FC = () => {
  const [gridAPI, setGridAPI] = useState<GridApi | null>(null);
  const [selectedTransactionType, setSelectedTransactionType] = useState(AllTransactionsItem.value);
  const selectedCompanyId = useRecoilValue(selectedCompanyIdStateFinance);
  const company = useRecoilValue(companyState(selectedCompanyId ?? -1));
  const { status, rowData } = useTransactionRowData(selectedCompanyId ?? -1);
  const getSchemaForTransactionTypeId = useGetTransactionGridColdDefsForTypeId();
  const { getRowId } = useCommonTransactionGridDefs();
  const { exportParams } = useExtendedExportSettings(new Set(), `${company?.name ?? ''}_Transactions`);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridAPI(event.api);
  }, []);

  const initialGridOptions = useMemo(() => {
    const options: GridOptions = {
      autoGroupColumnDef: {
        comparator: genericComparator,
      },
      columnDefs: [],
      components: {
        [RoundCellRendererName]: RoundCellRenderer,
      },
      defaultColDef: {
        comparator: genericComparator,
        flex: 1,
        cellStyle: {
          color: 'red',
        },
        filterParams: {
          buttons: ['reset'],
          newRowsAction: 'keep',
        },
        suppressAutoSize: false,
        suppressMovable: true,
      },

      doesExternalFilterPass: () => true,
      isExternalFilterPresent: () => true,
      onGridReady,
      rowData: [],
      rowGroupPanelShow: 'never',
    };

    return options;
  }, [onGridReady]);

  useEffect(() => {
    const colDefs = getSchemaForTransactionTypeId(selectedTransactionType);
    gridAPI?.setGridOption('columnDefs', colDefs);
  }, [getSchemaForTransactionTypeId, gridAPI, selectedTransactionType]);

  useEffect(() => {
    gridAPI?.onFilterChanged();
  }, [selectedTransactionType, gridAPI]);

  const onTypeFilterChanged = useCallback((item: ISimpleChoice<number>) => {
    setSelectedTransactionType(item.value);
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    return selectedTransactionType !== -1;
  }, [selectedTransactionType]);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<ITransactionDataModel>) => {
      return node.data?.transactionTypeId === selectedTransactionType;
    },
    [selectedTransactionType]
  );

  if (status === 'loading') return <Loader height='80%' />;
  if (status === 'hasError') return <ErrorOccurredMessage />;

  return (
    <>
      <AvailableTransactionTypePills
        onChange={onTypeFilterChanged}
        selectedItemId={selectedTransactionType}
        transactions={rowData}
      />
      <FadeInGridWrapper key={selectedTransactionType}>
        <div style={{ paddingTop: '1rem', height: '63vh', paddingBottom: '1rem' }}>
          <AgTable
            autoSizeStrategy={initialGridOptions.autoSizeStrategy}
            rowData={rowData}
            gridOptions={initialGridOptions}
            key={selectedCompanyId}
            defaultCsvExportParams={exportParams}
            defaultExcelExportParams={exportParams}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            rowGroupPanelShow={'never'}
            getRowId={getRowId}
            grandTotalRow='bottom'
          />
        </div>
      </FadeInGridWrapper>
      <TransactionModals />
    </>
  );
};

function useTransactionRowData(selectedCompanyId: number) {
  const investmentRounds = useRecoilValue(allInvestmentRoundsMapState);

  const transactionsLoadable = useRecoilValueLoadable(
    financeTransactionsByCompanyState(selectedCompanyId ?? -1)
  );

  if (transactionsLoadable.state === 'loading')
    return {
      status: transactionsLoadable.state,
      rowData: [] as ITransactionDataModel[],
    };

  return {
    status: transactionsLoadable.state,
    rowData: transactionsLoadable.getValue()?.map((transaction) => {
      return {
        ...transaction,
        roundId: investmentRounds.get(transaction.investmentRoundId ?? -1)?.roundId,
      };
    }) as ITransactionDataModel[],
  };
}

export function useCommonTransactionGridDefs() {
  const getRowId = useCallback(
    (params: GetRowIdParams<ITransactionDataModel>) => String(params.data?.id),
    []
  );

  return { getRowId };
}
