import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { FieldEntity } from '../../../data-models/field2.data-model';
import { IKPIRequestDataModel } from '../../../data-models/kpi-requests.data-model';
import { fiscalDateFormatter } from '../../../util/formatters/DateFormatters';
import { camelCaseToCapitalizedString } from '../../../util/stringUtils';

export function entityFormatter(params: ValueFormatterParams) {
  if (!params?.value) return '';
  if (params?.value === FieldEntity.fundCompany) return 'Company & Fund';
  else if (params?.value === FieldEntity.captableInvestment) return 'Security';
  return camelCaseToCapitalizedString(params.value);
}

export function kpiFiscalDateFormatter(
  params: ValueFormatterParams<IKPIRequestDataModel> | ValueGetterParams<IKPIRequestDataModel>
) {
  if (params.data) {
    const { frequency, fye, period } = params.data;

    return fiscalDateFormatter(frequency, period, fye as string);
  }
  return '';
}
