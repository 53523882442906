import { useCallback } from 'react';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import {
  IKPIRequestDataModel,
  kpiRequestPeriodToKpiPeriod,
  periodFromGranularityAndFrequency,
} from '../../../../../data-models/kpi-requests.data-model';
import {
  bulkUpdateCustomKpiData,
  getRequestData,
  saveRequestData,
} from '../../../../../services/queries/KPIRequestsQueries';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { currencyMapByCodeAtom } from '../../../../../services/state/AppConfigStateJ';
import { LoadingStatus } from '../../../../../types';
import { useAsync } from '../../../../../util/hook-utils';
import { getForesightStore } from '../../../../../util/jotai-store';
import {
  BulkCreateKpiCustomDataAction,
  BulkUpdateKpiCustomDataAction,
  BulkUpdateKpiCustomDataActionData,
} from '../../../../CompanyProfiles/components/Financials/hooks/useCustomKPIActions';
import { toPayloadValue } from '../../../../CompanyProfiles/components/Financials/hooks/useFinancialsColumnDefs';
import { IFinancialsRowData } from './MSFinancialsGrid';

export function getBulkUpdateKpiCustomDataPayload({
  companyId,
  financials,
  period,
}: {
  companyId: number;
  financials: IFinancialsRowData[];
  period: KpiPeriod;
}) {
  const actions: BulkUpdateKpiCustomDataActionData[] = [];
  const store = getForesightStore();
  const currencyId = store.get(currencyMapByCodeAtom).get('USD')?.id ?? 1;
  financials.forEach((row) => {
    const { kpiId, section, valuesByDate } = row;
    Object.entries(valuesByDate).forEach(([date, value]) => {
      if (value == null) return;
      actions.push({
        action: BulkUpdateKpiCustomDataAction.create,
        kpi: {
          companyId,
          currencyId,
          date,
          kpiId,
          period,
          section,
          value: toPayloadValue(value),
        },
      } as BulkCreateKpiCustomDataAction);
    });
  });
  return actions;
}

export function useSaveDraftFinancials() {
  const { pushErrorToast } = useToastMessageState();
  return useCallback(
    async ({ financials, requestId }: { financials: IFinancialsRowData[]; requestId: number }) => {
      try {
        return await saveRequestData(requestId, financials);
      } catch (error) {
        pushErrorToast({
          message: getErrorMessage(error, 'Failed to auto-save data'),
        });
      }
    },
    [pushErrorToast]
  );
}

export function useGetDraftFinancials(requestId: number) {
  const { data, loading } = useAsync(
    useCallback(async () => {
      if (requestId <= 0) return;
      const { data } = await getRequestData(requestId);
      return data?.financialsData;
    }, [requestId]),
    { id: String(requestId) }
  );
  return { data, loading: loading === LoadingStatus.loading };
}

export function useSaveMsEnteredFinancials() {
  const { pushErrorToast } = useToastMessageState();

  return useCallback(
    async (request: IKPIRequestDataModel) => {
      try {
        const msEnteredData = await getRequestData(request.id);
        const { companyId, frequency, granularity } = request;
        const financials = msEnteredData.data?.financialsData;
        if (!financials?.length) return;
        const actions = getBulkUpdateKpiCustomDataPayload({
          companyId,
          financials,
          period: kpiRequestPeriodToKpiPeriod[
            periodFromGranularityAndFrequency(granularity, frequency)
          ] as KpiPeriod,
        });
        await bulkUpdateCustomKpiData({ data: actions });
      } catch (error) {
        pushErrorToast({
          message: getErrorMessage(error, 'Failed to save financials'),
        });
      }
    },
    [pushErrorToast]
  );
}
