import { css } from '@emotion/react';
import HistoryIcon from '@mui/icons-material/History';
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useAtomValue } from 'jotai';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { FundProjectedInvestmentAudit } from '../../../schemas/FundProjectedInvestmentAudit.schema';
import { InvestmentType, investmentTypeToLabel } from '../../../schemas/FundReserves.schema';
import { colors } from '../../../theme/colors';
import { FMT } from '../../../util/formatter-service';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { selectedFundIdStateFP } from '../state/FPState';
import { useDiffReservesHistory } from './useDiffReservesHistory';

function useReservesHistoryByCompanyName() {
  const getCompany = useGetCompanyIfSet();
  return useCallback(
    (history: FundProjectedInvestmentAudit[]) => {
      return history?.reduce((acc, audit) => {
        const company = getCompany(audit.companyId ?? -1);
        const key = String(company?.name ?? audit.name);
        if (!key) return acc;
        const audits = acc.get(key) ?? [];
        return acc.set(key, [...audits, audit]);
      }, new Map<string, FundProjectedInvestmentAudit[]>());
    },
    [getCompany]
  );
}

export function ReservesHistoryRenderer(params: CustomCellRendererProps) {
  const selectedFundId = useAtomValue(selectedFundIdStateFP);
  const companyName = params.node?.key;
  const getHistory = useReservesHistoryByCompanyName();
  const reservesHistory = params.context?.reservesHistory;
  const historyByCompany = useMemo(() => getHistory(reservesHistory), [getHistory, reservesHistory]);
  const history = historyByCompany?.get(String(companyName));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const onClose = useCallback(() => setAnchorEl(null), []);

  if (!companyName || !selectedFundId || !history?.length || !params.node?.group) return <div />;
  const open = Boolean(anchorEl);

  return (
    <Stack height='100%' width='100%' alignItems={'center'} justifyContent={'center'}>
      <IconButton onClick={onClick} color='secondary'>
        <HistoryIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {open && history?.length ? (
          <ReservesHistoryList companyName={companyName} historyByCompany={historyByCompany} />
        ) : null}
      </Popover>
    </Stack>
  );
}

const AuditTable = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 0.5rem;
  padding: 0.2rem;
`;
const AuditRow = css`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
  margin-bottom: 0.2rem;
`;
const ValueRow = css`
  ${AuditRow};
  padding: 0.3rem 0.3rem;
  background: ${colors.neutral[5]}88;
  border-radius: 4px;
  margin-bottom: 0.25rem;
  filter: brightness(1);
  &:hover {
    filter: brightness(0.96);
  }
  transition: filter 0.2s;
`;
const PrevValue = css`
  text-decoration: line-through;
  color: ${colors.neutral[50]};
`;

interface IReservesHistoryListProps {
  companyName: string;
  historyByCompany: Map<string, FundProjectedInvestmentAudit[]>;
}
export function ReservesHistoryList({ companyName, historyByCompany }: IReservesHistoryListProps) {
  const diff = useDiffReservesHistory(historyByCompany);
  const history = diff(companyName);

  if (!history.length) return null;

  return (
    <Box m='1rem' maxHeight={'20rem'}>
      <Typography variant='body2' mb='0.5rem'>
        {`History Log (${companyName})`}
      </Typography>
      <Typography component={'div'} variant='caption' noWrap css={AuditTable} role='grid'>
        <Typography component={'div'} variant='caption' color='text.secondary' css={AuditRow} role='row'>
          <span role='columnheader'>Investment</span>
          <span role='columnheader'>Transaction Date</span>
          <span role='columnheader'>Amount</span>
          <span role='columnheader'>Last Updated</span>
          <span role='columnheader'>Updated By</span>
        </Typography>
        <>
          {history.map((audit) => (
            <div css={ValueRow} key={`${audit.uuid}-${audit.updatedAt}`} role='row'>
              <div role='cell'>{investmentTypeToLabel[audit.type as InvestmentType]}</div>
              <div role='cell'>
                <div>{FMT.format('dateNumeric', audit.date) || '-'}</div>
                {audit.prevValues?.has('date') ? (
                  <div css={PrevValue}>{FMT.format('date', audit.prevValues.get('date'))}</div>
                ) : null}
              </div>
              <div role='cell'>
                <div>{FMT.format('usd', audit.amount)}</div>
                {audit.prevValues?.has('amount') ? (
                  <div css={PrevValue}>{FMT.format('usd', audit.prevValues.get('amount'))}</div>
                ) : null}
              </div>
              <div role='cell'>
                <div>{FMT.format('dateNumeric', audit.updatedAt)}</div>
              </div>
              <div role='cell'>{FMT.format('userByEmail', audit.updatedBy)}</div>
            </div>
          ))}
        </>
      </Typography>
    </Box>
  );
}
