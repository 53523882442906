import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import * as yup from 'yup';
import {
  ICompanyFinancialsDataModel,
  KpiPeriod,
  KpiSection,
} from '../../../data-models/company-financials.data-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { ISimpleChoice } from '../../../data-models/field2.data-model';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { getKpiFieldKey, kpiConfigByKeyMapAtom } from '../../../services/state/KPIConfigState';
import { columnsByPathStateLoadable } from '../state/ViewState';
import { IMprKpiColumnConfig, IMprMetricsColumnConfig } from './multiPeriodReportingUtils';
import { getRelativeDistanceOptions, MonthValues, YearValues } from './MprKpiColumnSettingsForm';

export interface IMultiPeriodReportingDataPoint
  extends Pick<ICompanyFinancialsDataModel, 'date' | 'kpiId' | 'period' | 'section'> {}

export interface IMultiPeriodReportingMetricsDataPoint {
  columnIds: number[];
  date: string;
}

export interface IMultiPeriodReportingRequest {
  companyIds?: number[];
  dataPoints: IMultiPeriodReportingDataPoint[];
  isDateAdjusted: boolean;
  metricsDataPoints: IMultiPeriodReportingMetricsDataPoint[];
}

export const ReportingPeriodType = {
  absolute: 'absolute',
  relative: 'relative',
} as const;
export type ReportingPeriod = (typeof ReportingPeriodType)[keyof typeof ReportingPeriodType];

const allowedMetricsColumns = new Set<keyof IMetricsDataModel>([
  'amountInvested',
  'distributions',
  'fmv',
  'ownerShipPercentage',
  'realizedValue',
  'unrealizedValue',
]);

export function useMultiReportingMetricsColumnsSchema() {
  const columnsByPath = useAtomValue(columnsByPathStateLoadable(COMPANY_VIEW_TYPE.RETURN_FORECAST));

  const columnPathsField = useMemo(() => {
    if (columnsByPath.state !== 'hasData') return { columnPaths: yup.array() };
    return {
      columnPaths: yup
        .array()
        .of(yup.string())
        .min(1)
        .required()
        .default([])
        .formMeta({
          renderer: RendererType.multiSelect,
          rendererMeta: {
            values: [...allowedMetricsColumns].reduce((acc, path) => {
              const columnMeta = columnsByPath.data.get(path);
              if (!columnMeta) return acc;
              acc.push({
                value: path,
                displayName: columnMeta?.displayName,
              });
              return acc;
            }, [] as ISimpleChoice<string>[]),
          },
        })
        .label('Metrics'),
    };
  }, [columnsByPath]);

  const periodFields = useMemo(() => {
    const { month, period, relativeDistance, type, year, ...rest } = mprPeriodFields();
    return {
      type,
      period: period.default(KpiPeriod.quarter),
      relativeDistance: relativeDistance.formMeta({
        renderer: RendererType.multiSelect,
        rendererMeta: {
          values: getRelativeDistanceOptions(KpiPeriod.quarter),
        },
      }),
      month: month.label('Quarter').formMeta({
        renderer: RendererType.multiSelect,
        rendererMeta: {
          values: MonthValues[KpiPeriod.quarter],
        },
      }),
      year: year.formMeta({
        renderer: RendererType.multiSelect,
        rendererMeta: {
          values: YearValues,
        },
      }),
      ...rest,
    };
  }, []);

  return useMemo(
    () => yup.object({ ...columnPathsField, ...periodFields }),
    [columnPathsField, periodFields]
  );
}

export function useMultiReportingKpiColumnsSchema() {
  // use this map as it does not contain kpis without entityField
  const kpisByKey = useAtomValue(kpiConfigByKeyMapAtom);
  const sections = Object.values(KpiSection);
  const kpiFields = useMemo(
    () => ({
      kpiKey: yup
        .array()
        .of(yup.string())
        .min(1)
        .label('KPI')
        .nullable()
        .required()
        .default([])
        .formMeta({
          renderer: RendererType.multiSelect,
          rendererMeta: {
            values: [...kpisByKey.values()].map((kpi) => ({
              value: getKpiFieldKey(kpi),
              displayName: kpi.displayName,
            })),
          },
        }),
      section: yup
        .array()
        .of(yup.string().oneOf(sections))
        .min(1)
        .nullable()
        .required()
        .default([KpiSection.actual])
        .formMeta({
          renderer: RendererType.multiSelect,
          rendererMeta: {
            values: sections.map((section) => ({
              value: section,
              displayName: section,
            })),
          },
        }),
    }),
    [kpisByKey, sections]
  );

  return useMemo(() => yup.object({ ...kpiFields, ...mprPeriodFields() }), [kpiFields]);
}

export function mprPeriodFields() {
  const periodTypes = Object.values(ReportingPeriodType);
  const kpiPeriods = Object.values(KpiPeriod);
  return {
    // common
    type: yup
      .string()
      .nullable()
      .oneOf(periodTypes)
      .required()
      .default(ReportingPeriodType.absolute)
      .formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: periodTypes.map((type) => ({
            value: type,
            displayName: `${capitalize(type)}`,
          })),
        },
      }),
    period: yup
      .string()
      .nullable()
      .oneOf(kpiPeriods)
      .required()
      .default(KpiPeriod.quarter)
      .formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: kpiPeriods.map((period) => ({
            value: period,
            displayName: period,
          })),
        },
      }),
    // absolute
    month: yup
      .array()
      .of(yup.number())
      .nullable()
      .default([])
      .test('isMonthRequired', 'This field is required', (value, ctx) => {
        if (ctx.parent.type !== ReportingPeriodType.absolute || ctx.parent.period === KpiPeriod.year) {
          return true;
        }
        return value != null && value.length > 0;
      }),
    year: yup
      .array()
      .of(yup.number())
      .default([])
      .nullable()
      .when('type', {
        is: ReportingPeriodType.absolute,
        then: (schema) => schema.required().min(1),
      }),
    // relative
    relativeDistance: yup
      .array()
      .of(yup.number())
      .default(null)
      .nullable()
      .formMeta({
        renderer: RendererType.multiSelect,
      })
      .when('type', {
        is: ReportingPeriodType.relative,
        then: (schema) => schema.required().min(1),
      }),
  };
}

export type MprKpiConfigFormData = yup.InferType<ReturnType<typeof useMultiReportingKpiColumnsSchema>>;

export function createMprConfigFormData(overrides: Partial<MprKpiConfigFormData> = {}): MprKpiConfigFormData {
  return {
    kpiKey: [],
    section: [KpiSection.actual],
    type: ReportingPeriodType.absolute,
    period: KpiPeriod.quarter,
    month: null,
    year: null,
    relativeDistance: null,
    ...overrides,
  };
}

export type MprMetricsConfigFormData = yup.InferType<
  ReturnType<typeof useMultiReportingMetricsColumnsSchema>
>;
export function createMprMetricsConfigFormData(
  overrides: Partial<MprMetricsConfigFormData> = {}
): MprMetricsConfigFormData {
  return {
    columnPaths: [],
    type: ReportingPeriodType.relative,
    period: KpiPeriod.quarter,
    month: null,
    year: null,
    relativeDistance: [0],
    ...overrides,
  };
}
// sort order for these will be dynamic (because of relative dates and ability to rename custom kpis), so we can't store it in IMprColumnConfig.sortOrder
export const MprColumnSortType = {
  dateAsc: 'dateAsc', // sort by KpiPeriod/periodicity (month, quarter, year), then absolute date (use getDateForConfig )
  displayName: 'displayName', // sort by kpi/metric name
} as const;
export type MprColumnSortType = (typeof MprColumnSortType)[keyof typeof MprColumnSortType];
export interface IMprViewSettings {
  columnSort: MprColumnSortType | null;
  columns: (IMprKpiColumnConfig | IMprMetricsColumnConfig)[];
}
export function createMprViewSettings(overrides: Partial<IMprViewSettings> = {}): IMprViewSettings {
  return {
    columnSort: null,
    columns: [],
    ...overrides,
  };
}
// it IMprMetricsData will contain only those fields/paths that the UI sends as part of POST request
export interface IMprMetricsData extends Partial<IMetricsDataModel> {
  companyId: number;
  date: string;
}

export interface IMultiPeriodReportResponse extends Pick<IMultiPeriodReportingRequest, 'isDateAdjusted'> {
  data: ICompanyFinancialsDataModel[];
  companies: ICompanyDataModel[];
  metricsData: IMprMetricsData[];
}

export function createMultiPeriodReportResponse(
  overrides: Partial<IMultiPeriodReportResponse> = {}
): IMultiPeriodReportResponse {
  const { data, companies, metricsData, ...rest } = overrides;
  return {
    isDateAdjusted: true,
    data: data ?? [],
    companies: companies ?? [],
    metricsData: metricsData ?? [],
    ...rest,
  };
}
