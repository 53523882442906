import { useMemo } from 'react';
import { InferType, number, object } from 'yup';
import { FundMetricsByFund } from '../../../schemas/FundMetrics.schema';
import { useFundMetricsFP } from '../useFundMetricsFP';

export function useFundCompanyData() {
  const { metrics } = useFundMetricsFP();

  return useMemo(() => {
    return getFundCompanyData(metrics);
  }, [metrics]);
}

export type FundCompanyData = InferType<ReturnType<typeof fundCompanyDataFormSchema>>;

export function fundCompanyDataFields() {
  return {
    nActiveCompanies: number().required().label('Active Companies'),
    total: number().required().label('Total Companies'),
  };
}

export function fundCompanyDataFormSchema() {
  return object().shape(fundCompanyDataFields());
}

export function getFundCompanyData(metrics?: FundMetricsByFund) {
  return {
    nActiveCompanies: metrics?.activeCompanies?.length ?? 0,
    total: metrics?.totalCompanies ?? 0,
  };
}
