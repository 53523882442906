import { cloneDeep } from 'lodash-es';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFactoryNoLayout } from '../../../../../components/Form/FormFactory';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { DealFormViewModel, useDealFormSchema } from '../../../../../schemas/deal.schema';
import { FDMap } from '../../../../../util/data-structure/FDMap';
import { schemaToFormFields } from '../../../../../util/schema-utils';
import { createForm } from '../../../../../view-models/form.view-model';
import { UnstyledFieldset } from '../../../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { FieldsWrapper } from '../../../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { Panel } from '../../../../CompanyProfiles2/Summary/Panel';
import { useDebouncedUpdateDeal } from '../../../hooks/useDealActions';

export interface IDealPanelProps {
  deal: IDealDataModel;
  editable?: boolean;
}

export function MainDealPanel({ deal, editable = true }: IDealPanelProps) {
  const updateDeal = useDebouncedUpdateDeal();

  const methods = useForm<Partial<DealFormViewModel>>({
    defaultValues: {
      ...cloneDeep(deal as DealFormViewModel),
    },
  });

  const onUpdateDeal = useCallback(
    async (payload: Partial<DealFormViewModel>) => {
      const { companyId, dealTypeId } = deal;
      updateDeal(deal, { companyId, dealTypeId, ...payload } as Partial<IDealDataModel>, false);
    },
    [deal, updateDeal]
  );

  return (
    <FormProvider {...methods}>
      <DeaCustomFields onChange={onUpdateDeal} editable={editable} />
    </FormProvider>
  );
}

interface IDealCustomFieldsProps {
  onChange: (data: Partial<DealFormViewModel>) => Promise<void>;
  editable?: boolean;
}
function DeaCustomFields({ onChange, editable = true }: IDealCustomFieldsProps) {
  const dealFields = schemaToFormFields(
    useDealFormSchema().pick([
      'dealLeadId',
      'dealTeamIds',
      'fundIds',
      'raiseAmount',
      'gcAmountMin',
      'gcAmountMax',
      'totalPic',
    ])
  ).map((f) => ({ ...f, disabled: !editable, required: false }));

  const form = createForm<DealFormViewModel>({
    fields: FDMap.fromArray(dealFields, 'key'),
    variant: 'form-inline',
  });

  return (
    <Panel title='Main Deal Details' showContentOverflow>
      <UnstyledFieldset disabled={!editable}>
        <FieldsWrapper>
          <FormFactoryNoLayout form={form} onChange={onChange} />
        </FieldsWrapper>
      </UnstyledFieldset>
    </Panel>
  );
}
