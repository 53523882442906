import { IUserRelationDataModel, RelationshipStrengthNormalized } from './user-relation.data-model';
import { IConnectionActivitiesDataModel } from './connection-activity.data-model';

export interface IWorkExperienceDataModel {
  companyId?: number;
  department?: string | null;
  endDate?: string | null;
  isCurrent?: boolean;
  orgName?: string | null;
  startDate?: string | null;
  title?: string | null;
}

export function createWorkExperienceDataModel(
  overrides?: Partial<IWorkExperienceDataModel>
): IWorkExperienceDataModel {
  return {
    companyId: 0,
    department: null,
    endDate: null,
    isCurrent: false,
    orgName: null,
    startDate: null,
    title: null,
    ...overrides,
  };
}

export interface IEducationDataModel {
  degree: string | null;
  graduationYear: number | null;
  institution: string | null;
}

export function createEducationDataModel(overrides: Partial<IEducationDataModel>): IEducationDataModel {
  return {
    degree: null,
    graduationYear: null,
    institution: null,
    ...overrides,
  };
}

export const ScoreToNumber: Record<RelationshipStrengthNormalized, number> = {
  [RelationshipStrengthNormalized.Low]: 1,
  [RelationshipStrengthNormalized.Medium]: 2,
  [RelationshipStrengthNormalized.High]: 3,
};

export interface IActivityParticipantDataModel {
  id?: number;
  name: string;
  type: string; // e.g. "to" | "from" | "cc" for emails, "creator" | "attendee" for meetings
}

export interface IContactActivityItemDataModel {
  participants: IActivityParticipantDataModel[];
  createdAt: string | null;
}
export interface IContactActivityDataModel {
  emails?: (IContactActivityItemDataModel | null)[] | null;
  meetings?: (IContactActivityItemDataModel | null)[] | null;
}

export interface IPersonDataModel extends IPersonEmailsDataModel {
  /** @deprecated use person.activity */
  activities: IConnectionActivitiesDataModel | null;
  activity?: IContactActivityDataModel | null;
  boardAssociations?: IWorkExperienceDataModel[];
  companyIds: number[];
  department: string | null;
  education: IEducationDataModel[];
  firstName: string | null;
  hidden?: boolean;
  id: number;
  isBoardMember?: boolean;
  lastName: string | null;
  locations: ILocations[] | null;
  logoUrl: string | null;
  name: string;
  phoneNumbers: string[];
  rank: null;
  relationshipStrengthScore: number;
  relationshipStrengthScoreNormalized?: RelationshipStrengthNormalized | null;
  relationships: IUserRelationDataModel[];
  socials?: null | IPersonSocials;
  strength: null | number;
  updatedAt: string;
  workExperience?: IWorkExperienceDataModel[];
}

export interface IPersonEmailsDataModel {
  primaryEmail: string | null;
  secondaryEmails: string[] | null;
}

export interface IPersonSocials {
  facebook?: string | null;
  linkedin?: string | null;
  twitter?: string | null;
}

export interface ILocations {
  city: string;
  country?: string;
  state?: string;
  zip?: string;
  region?: string;
}

export function createPersonDataModel(overrides?: Partial<IPersonDataModel>): IPersonDataModel {
  return {
    activities: null,
    boardAssociations: [],
    companyIds: [],
    department: null,
    education: [],
    firstName: '',
    id: 0,
    isBoardMember: false,
    lastName: '',
    locations: [],
    logoUrl: null,
    name: '',
    phoneNumbers: [],
    primaryEmail: '',
    rank: null,
    relationshipStrengthScore: 0,
    relationshipStrengthScoreNormalized: null,
    relationships: [],
    secondaryEmails: [],
    strength: null,
    updatedAt: '',
    workExperience: [],
    ...overrides,
  };
}

export function createEmailsDataModel(overrides?: Partial<IPersonEmailsDataModel>): IPersonEmailsDataModel {
  return {
    primaryEmail: '',
    secondaryEmails: [],
    ...overrides,
  };
}

export function createActivityParticipantDataModel(
  overrides?: Partial<IActivityParticipantDataModel>
): IActivityParticipantDataModel {
  return {
    name: '',
    type: '',
    ...overrides,
  };
}

export function createContactActivityItemDataModel(
  overrides?: Partial<IContactActivityItemDataModel>
): IContactActivityItemDataModel {
  return {
    participants: [],
    createdAt: null,
    ...overrides,
  };
}

export function createContactActivityDataModel(
  overrides?: Partial<IContactActivityDataModel>
): IContactActivityDataModel {
  return {
    emails: [],
    meetings: [],
    ...overrides,
  };
}
