import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ColDef,
  ExpandOrCollapseAllEvent,
  FirstDataRenderedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowClickedEvent,
  RowGroupOpenedEvent,
} from 'ag-grid-community';
import { useAtomValue, useSetAtom } from 'jotai';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useSearchParams } from 'react-router';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { commonProcessCb } from '../../../../components/AgTable/exportToExcelDefs';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { useCombineCompaniesWithData } from '../../../../hooks/useCombineCompaniesWithData';
import { roundsByIdMapAtom, usersByEmailMapAtom } from '../../../../services/state/AppConfigStateJ';
import { GRID_ROW_HEIGHT } from '../../../../theme/gridStyle';
import { DealBoardDealCategory } from '../../../../types';
import { collapsedDealStages } from '../../../PortfolioOverview/state/UIState';
import { IDealStageDataModel } from '../../data-models/dealStage.data-model';
import { getCollapsedStageIds } from '../../helpers/getCollapsedStageIds';
import { getLatestCommentString } from '../../helpers/getLatestCommentString';
import { dealStagesByIdMapState } from '../../state/DealboardDataState';
import {
  dealBoardSettingsState,
  isOpenDrawerState,
  selectedDealCategoryState,
  selectedDealIdState,
  showCommentsState,
} from '../../state/DealboardUIState';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { stageComparator } from './Columns/stageComparator';
import { defaultColDef, useDealGridColDefs } from './Columns/useDealGridColDefs';
import { GridHeader } from './GridHeader';

const GridContainer = styled('section')`
  margin: 0;
  margin-bottom: 2rem;
  & .ag-root-wrapper {
    background-color: transparent;
  }
`;

const GridWrapper = styled('div')`
  margin-top: 1rem;
  height: 38rem;
  & .ag-cell {
    display: flex;
    align-items: center;
  }
  & .ag-cell-last-left-pinned {
    padding: 0;
  }
`;
interface DealGridProps {
  stage?: IDealStageDataModel;
  deals: IDealDataModel[];
}
useAtomValue;
export interface IDealGridData extends IDealDataModel {
  company: ICompanyDataModel;
}

export const DealGrid: FC<DealGridProps> = ({ stage, deals }) => {
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const setIsOpenDrawer = useSetAtom(isOpenDrawerState);
  const setSelectedDealId = useSetAtom(selectedDealIdState);
  const showComments = useAtomValue(showCommentsState);
  const selectedDealCategory = useAtomValue(selectedDealCategoryState);
  const stages = useAtomValue(dealStagesByIdMapState);
  const dealBoardSettings = useAtomValue(dealBoardSettingsState);
  const [collapsedStages, setCollapsedStages] = useRecoilState(collapsedDealStages);
  const columnDefs = useDealGridColDefs();
  const [params] = useSearchParams();
  const dealTypeId = params.get('dealType') ?? '0';
  const roundsMap = useAtomValue(roundsByIdMapAtom);
  const usersByEmail = useAtomValue(usersByEmailMapAtom);
  const getDealGridData = useCombineCompaniesWithData();
  const { colors } = useTheme();

  const handleToggleGrid = useCallback(
    (stageId: number, collapsed: boolean) => {
      const uniqueStageId = `${dealTypeId}-${stageId}`;
      setCollapsedStages((collapsedStages) => ({ ...collapsedStages, [uniqueStageId]: collapsed }));
    },
    [dealTypeId, setCollapsedStages]
  );

  const stagesByName = useMemo(() => {
    if (!stages) return new Map();
    return new Map(Array.from(stages.values()).map((s) => [s.name, s]));
  }, [stages]);

  const onRowGroupOpened = useCallback(
    (e: RowGroupOpenedEvent) => {
      const stageName = e.node.key;
      if (!stageName) return;
      const stageId = stagesByName.get(stageName)?.id;
      if (stageId) handleToggleGrid(stageId, !e.node.expanded);
    },
    [handleToggleGrid, stagesByName]
  );

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridAPI(event.api);
  }, []);

  useEffect(() => {
    if (!gridAPI) return;
    getDealGridData(deals).then((data) => {
      gridAPI.setGridOption('rowData', data as IDealGridData[]);
    });
  }, [dealTypeId, gridAPI, getDealGridData, deals]);

  const onFirstDataRendered = useCallback(
    (e: FirstDataRenderedEvent) => {
      const collapsedStageIds = getCollapsedStageIds(collapsedStages, parseInt(dealTypeId));

      e.api?.forEachNodeAfterFilterAndSort((node) => {
        const stageName = node.key;

        if (!node.group || !stageName) return;
        const stageId = stagesByName.get(stageName)?.id;
        if (!stageId) return;
        if (collapsedStageIds.includes(stageId)) {
          node.setExpanded(false);
        } else {
          node.setExpanded(true);
        }
      });
    },
    [dealTypeId, collapsedStages, stagesByName]
  );

  const onExpandOrCollapseAll = useCallback(
    (e: ExpandOrCollapseAllEvent) => {
      const collapsed = e.source === 'collapseAll'; // e.source is either "expandAll" or "collapseAll"
      const stageIds = dealBoardSettings.map((stageAndDeals) => stageAndDeals.stage.id);
      stageIds.forEach((stageId) => {
        handleToggleGrid(stageId, collapsed);
      });
    },
    [dealBoardSettings, handleToggleGrid]
  );

  const getRowId: GetRowIdFunc = useCallback((params: GetRowIdParams) => {
    return String(params.data?.id);
  }, []);

  const onRowClicked = useCallback(
    (e: RowClickedEvent) => {
      if (e.node.group) return;
      setSelectedDealId(e.data?.id);
      setIsOpenDrawer(true);
    },
    [setIsOpenDrawer, setSelectedDealId]
  );

  useEffect(() => {
    if (!gridAPI) return;
    if (showComments) {
      gridAPI.setColumnsVisible(['comments'], true);
    } else {
      gridAPI.setColumnsVisible(['comments'], false);
    }
  }, [gridAPI, showComments]);

  const autoGroupColumnDef: ColDef = useMemo(() => {
    return {
      width: 260,
      sortable: true,
      sort: 'asc',
      comparator:
        selectedDealCategory.id === DealBoardDealCategory.CURRENT
          ? (stageNameA: string, stageNameB: string): number => {
              const a = stagesByName.get(stageNameA)?.sortOrder ?? -1;
              const b = stagesByName.get(stageNameB)?.sortOrder ?? -1;
              return a - b;
            }
          : undefined,
    };
  }, [stagesByName, selectedDealCategory.id]);

  const exportSettings = useMemo(() => {
    return {
      processCellCallback: (params: ProcessCellForExportParams<IDealGridData>) => {
        if (params.column.getId() === 'company') {
          return params.node?.data?.company.name || '';
        }
        if (params.column.getColId() === 'round') {
          const roundId = params.node?.data?.roundId;
          if (typeof roundId !== 'number') return '';
          return roundsMap.get(roundId)?.displayName || '';
        }
        if (params.column.getColId() === 'comments') {
          return getLatestCommentString(params.node?.data?.comments, usersByEmail);
        }
        if (params.column.getColId() === 'website') {
          return params.node?.data?.company.website || '';
        }
        return commonProcessCb(params);
      },
    };
  }, [roundsMap, usersByEmail]);

  return (
    <GridContainer data-testid='deal-grid'>
      {stage && <GridHeader key={stage.id} title={stage.displayName} deals={deals} />}
      {deals.length > 0 ? (
        <GridWrapper>
          <FadeInGridWrapper key={dealTypeId}>
            <AgTable
              autoGroupColumnDef={autoGroupColumnDef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              defaultCsvExportParams={exportSettings}
              defaultExcelExportParams={exportSettings}
              getRowId={getRowId}
              initialGroupOrderComparator={
                selectedDealCategory.id === DealBoardDealCategory.CURRENT ? stageComparator : undefined
              }
              onExpandOrCollapseAll={onExpandOrCollapseAll}
              onFirstDataRendered={onFirstDataRendered}
              onGridReady={onGridReady}
              onRowClicked={onRowClicked}
              onRowGroupOpened={onRowGroupOpened}
              rowGroupPanelShow={'never'}
              rowHeight={GRID_ROW_HEIGHT}
            />
          </FadeInGridWrapper>
        </GridWrapper>
      ) : (
        <Typography variant='h6' color={colors.primary[90]}>
          No deals to show
        </Typography>
      )}
    </GridContainer>
  );
};
