import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { agForesightTheme } from '../../theme/ag-foresight';
import { GridWrapper } from './AgTable';

export function AgTableWithBaseStyle(props: AgGridReactProps) {
  return (
    <div css={GridWrapper}>
      <AgGridReact {...props} theme={props.theme ?? agForesightTheme} />
    </div>
  );
}
