import { useAtomValue } from 'jotai';
import { dealBoardConfigState } from '../../DealFlow2/state/DealboardDataState';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { getDealReasonFields } from './DealReasonFields';
import { transformDataModelBeforeSubmit } from './DealReasonUtils';

export function ConfigMissReasons() {
  const { missedReasons } = useAtomValue(dealBoardConfigState);

  return (
    <GenericConfigPage
      baseTitle={'Missed Reason'}
      data={missedReasons}
      fields={getDealReasonFields('missedReason')}
      pageTitle={'Missed Reasons'}
      queryURL={'/missedReason'}
      transformBeforeSubmit={transformDataModelBeforeSubmit}
    />
  );
}
