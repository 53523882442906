import { Alert } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { FundDataModel } from '../../../schemas/Fund.schema';
import { CapitalAllocationResponse } from '../../../schemas/FundCapitalAllocation.schema';
import { fetchCapitalAllocation } from '../../../services/queries/MaggieFundQueries';
import { getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { useAsync } from '../../../util/hook-utils';
import {
  getDefaultProjectionsAsOfDate,
  refetchCapitalAllocationState,
  selectedFundStateFP,
  showProjectionFormState,
} from '../state/FPState';
import { CapitalAllocationGrid } from './CapitalAllocationGrid';
import { CapitalDeploymentChart } from './CapitalDeploymentChart';
import { FeesAndExpensesFormModal } from './FeesAndExpensesForm';

export function FPAllocationLoader() {
  const fund = useAtomValue(selectedFundStateFP);
  const date = getDefaultProjectionsAsOfDate(fund);
  const asOfDate = getPlainDateString(new Date(date));
  const refetchAllocation = useAtomValue(refetchCapitalAllocationState);

  const { data, error } = useAsync<CapitalAllocationResponse>(
    useCallback(() => {
      return fetchCapitalAllocation({ id: fund?.id ?? -1, asOfDate });
    }, [fund, asOfDate]),
    {
      id: `${fund}-${asOfDate}-${refetchAllocation}`,
    }
  );
  if (error || !fund) return <Alert severity='error'>{error}</Alert>;
  if (data) return <FPAllocation data={data} fund={fund} />;
  return <Loader />;
}

export interface FPAllocationProps {
  data: CapitalAllocationResponse;
  fund: FundDataModel;
}
export function FPAllocation({ data, fund }: FPAllocationProps) {
  const showForm = useAtomValue(showProjectionFormState);
  return (
    <>
      <CapitalDeploymentChart data={data.quarterlyMetrics ?? []} />
      {showForm == 'feesAndExpenses' && <FeesAndExpensesFormModal data={data} fund={fund} />}
      <CapitalAllocationGrid data={data.quarterlyMetrics} />
    </>
  );
}
