import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { getDealsForAllStages } from '../../helpers/getDealsForAllStages';
import { dealBoardSettingsState } from '../../state/DealboardUIState';
import { DealGrid } from '../DealGrid/DealGrid';

const Container = styled('section')`
  padding: 1rem 2rem 1rem 3rem;
`;

export const DealGrids = () => {
  const dealBoardSettings = useAtomValue(dealBoardSettingsState);

  const dealData = useMemo(() => {
    return getDealsForAllStages(dealBoardSettings);
  }, [dealBoardSettings]);

  return (
    <Container>
      <DealGrid deals={dealData} />
    </Container>
  );
};
