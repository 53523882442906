import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Temporal } from 'temporal-polyfill';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { CompanySearchResponseWithRealId } from '../../../schemas/CompanySearchResponse.schema';
import { createFinanceRoundFormViewModel } from '../../../view-models/finance-round-form.view-model';
import { useInvestmentActions } from '../hooks/useInvestmentActions';
import { useCreateOrUpdateCompanyHandler } from './CompanyFormUtils';
import {
  investmentFormCompanyIdState,
  investmentFormCompanyState,
  investmentFormInvestmentDataState,
} from './InvestmentFormUIState';
import { currentStepState } from './TransactionFormUIState';

export function useAddInvestmentFormState() {
  const [currentStep, setCurrentStep] = useRecoilState(currentStepState);
  const { handleCreateInvestment } = useInvestmentActions();
  const [investmentData, setInvestmentData] = useRecoilState(investmentFormInvestmentDataState);
  const updateCompany = useCreateOrUpdateCompanyHandler();

  const [selectedCompanyId, setSelectedCompanyId] = useRecoilState(investmentFormCompanyIdState);
  const selectedCompany = useRecoilValue(investmentFormCompanyState);

  const resetCompanyId = useResetRecoilState(investmentFormCompanyIdState);
  const resetInvestmentFormData = useResetRecoilState(investmentFormInvestmentDataState);
  const resetCurrentStep = useResetRecoilState(currentStepState);

  const onCompanySelect = useCallback(
    (selectedCompany: CompanySearchResponseWithRealId) => {
      setSelectedCompanyId(selectedCompany.id);
      setCurrentStep(1);
    },
    [setCurrentStep, setSelectedCompanyId]
  );

  const onChangeCompany = useCallback(() => {
    setSelectedCompanyId(null);
  }, [setSelectedCompanyId]);

  const onUpdateCompany = useCallback(
    async (data: Partial<ICompanyDataModel>) => {
      if (!selectedCompany) return;
      const company = await updateCompany(data);
      if (company) {
        setCurrentStep(1);
        return company;
      }
    },
    [selectedCompany, setCurrentStep, updateCompany]
  );

  const handleValidateCompanyStep = useCallback(async () => {
    return Promise.resolve(selectedCompany != null);
  }, [selectedCompany]);

  const resetFormState = useCallback(() => {
    resetCompanyId();
    resetInvestmentFormData();
    resetCurrentStep();
  }, [resetCompanyId, resetCurrentStep, resetInvestmentFormData]);

  const handleGoBack = useCallback(() => {
    setCurrentStep((current) => current - 1);
  }, [setCurrentStep]);

  return {
    currentStep,
    handleCreateInvestment,
    handleGoBack,
    handleValidateCompanyStep,
    investmentData: createFinanceRoundFormViewModel({
      id: undefined,
      roundDate: Temporal.Now.plainDateISO().toString(),
      customData: {},
      ...(investmentData ?? {}),
      companyId: selectedCompanyId ?? undefined,
    }),
    onChangeCompany,
    onCompanySelect,
    onUpdateCompany,
    resetFormState,
    selectedCompany,
    setCurrentStep,
    setInvestmentData,
    selectedCompanyId,
  };
}
