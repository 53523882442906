import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { objectivesByCompanyState } from '../../../state/ObjectivesState';
import { SubSection } from '../../../Summary/components/SubSection';
import { NoDataAddOne } from '../NoDataAddOne';
import { OkrModal } from '../OkrForms/OkrModal';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { ObjectiveComponent } from './ObjectiveComponent';
import { TEMPLATE_COLS } from './ObjectivesConstants';

const HeaderRow = styled('div')`
  display: grid;
  grid-template-columns: ${TEMPLATE_COLS};
  background: transparent;
  height: 2rem; // height of the header row
  ${({ theme }) => `color: ${theme.colors.neutral[50]};`}
  align-content: center;
`;

const GridContainer = styled('div')`
  border-radius: 4px;
`;

export const ObjectivesSection = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const objectives = useRecoilValue(objectivesByCompanyState(companyId));

  const headers = ['Objective', 'Status', 'Progress', 'Category', 'Last Updated', 'Owner'];

  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <GenericFallbacksWrapper>
      <SubSection
        collapseProps={{ collapsible: true, defaultExpanded: true }}
        title='Objectives and Key Results'
        actionComponent={
          <Button
            color={'secondary'}
            endIcon={<AddIcon />}
            onClick={() => setShowAddModal(true)}
            variant={'outlined'}
            sx={{ marginLeft: 'auto' }}
          >
            Add objective
          </Button>
        }
        noCard={objectives?.length === 0}
        cardPadding={'.75rem 1rem'}
      >
        <OkrModal open={showAddModal} onClose={() => setShowAddModal(false)} />
        {objectives?.length ? (
          <GridContainer>
            <HeaderRow>
              {headers.map((header) => (
                <Typography variant='caption' key={header}>
                  {header}
                </Typography>
              ))}
            </HeaderRow>

            {objectives?.map((obj) => <ObjectiveComponent objective={obj} key={obj.id} />)}
          </GridContainer>
        ) : (
          <NoDataAddOne mainMessage='No objectives set' subMessage='Start by adding one' />
        )}
      </SubSection>
    </GenericFallbacksWrapper>
  );
};
