import { useCallback, useLayoutEffect } from 'react';

export function useFocusElement(containerId: string, htmlTag: keyof HTMLElementTagNameMap) {
  useLayoutEffect(() => {
    document
      .getElementById(containerId)
      ?.querySelector<HTMLInputElement>(`${htmlTag}:not(:disabled)`)
      ?.focus();
  }, [containerId, htmlTag]);
}

export function useFocusCallback() {
  return useCallback(
    (containerId: string, htmlTag: keyof HTMLElementTagNameMap) =>
      document
        .getElementById(containerId)
        ?.querySelector<HTMLInputElement>(`${htmlTag}:not(:disabled)`)
        ?.focus(),
    []
  );
}
