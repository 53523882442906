import { useSearchParams } from 'react-router';

export const NoCompanySummaryTopNav = 'no-cp-nav';
export const NoNavWrapper = 'no-nav-wrap';

export type FdSearchParams = {
  noCpNav: boolean;
  noNavWrapper: boolean;
};

export function useFdSearchParams(): FdSearchParams {
  const search = useSearchParams()[0];

  return {
    noCpNav: search.get(NoCompanySummaryTopNav) === 'true',
    noNavWrapper: search.get(NoNavWrapper) === 'true',
  };
}
