import { Stack, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { endOfDay } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { Calendar } from '../../components/DatePicker/Calendar';
import { selectedDateFPState, selectedFundStateFP } from './state/FPState';
import { FPMenu } from './FPMenu';

export const DateInputLabel = 'select fund metrics date';

const FundHeader = css`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: center;
  margin: 0;
`;

export function FPHeader() {
  const fund = useAtomValue(selectedFundStateFP);
  const [date, setDate] = useAtom(selectedDateFPState);

  const onChange = useCallback(
    (date: Date) => {
      setDate(endOfDay(date));
    },
    [setDate]
  );

  if (!fund) return null;

  return (
    <header css={FundHeader}>
      <Typography variant='h4' component={'h1'} justifySelf={'baseline'}>
        {fund.name}
      </Typography>
      <Stack direction='row' alignItems={'center'} gap='0.5rem'>
        <Typography variant='body2' component='label' color='text.secondary' htmlFor={DateInputLabel}>
          As of
        </Typography>
        <Calendar
          initialValue={new Date(date)}
          onChange={onChange}
          sx={{ maxWidth: '12rem' }}
          textFieldProps={{ name: DateInputLabel }}
        />
        <FPMenu />
      </Stack>
    </header>
  );
}
