import { css } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { FundInvestment, InvestmentType } from '../../../schemas/FundReserves.schema';
import { MenuButton } from '../../Finance2/common-grid-defs/MenuButton';
import {
  ReservesAction,
  selectedFundCompanyNameState,
  selectedFundInvestmentsByCompanyNameState,
  selectedInvestmentTransactionUuidState,
  showReservesFormState,
} from '../state/FPState';
import { ReservesHistoryRenderer } from './ReservesHistoryRenderer';
import { ReservesRowData } from './reservesGridData';

const ActionsContainer = css`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  justify-content: end;
`;

export function InvestmentActionsRenderer(params: CustomCellRendererProps<ReservesRowData>) {
  const companyNameToInvestment = useAtomValue(selectedFundInvestmentsByCompanyNameState);

  const isInvestmentLevel = params.node?.group && params.node?.level === 0;
  const showTransactionActions = params.data?.uuid && params.data?.hypothetical;
  const getInvestmentActions = useGetInvestmentActions();
  const getTransactionActions = useGetHypotheticalTransactionActions();

  if (isInvestmentLevel) {
    const companyName = params.node?.key ?? '';
    const actions = getInvestmentActions({ companyName, companyNameToInvestment });
    return (
      <div css={ActionsContainer}>
        <ReservesHistoryRenderer {...params} />
        <MenuButton menuItems={actions} />
      </div>
    );
  } else if (showTransactionActions) {
    const actions = getTransactionActions(params.data);
    return (
      <div css={ActionsContainer}>
        <div></div>
        <MenuButton menuItems={actions} />
      </div>
    );
  } else {
    return null;
  }
}

interface IInvestmentActionsGetterParams {
  companyName: string;
  companyNameToInvestment: Map<string, FundInvestment>;
}
export function useGetInvestmentActions() {
  const setSelectedCompanyName = useSetAtom(selectedFundCompanyNameState);
  const setFormState = useSetAtom(showReservesFormState);

  return useCallback(
    ({ companyName, companyNameToInvestment }: IInvestmentActionsGetterParams) => {
      function onAddInvestment() {
        setSelectedCompanyName(companyName);
        setFormState(ReservesAction.AddTransaction);
      }

      function onDeleteInvestment() {
        setSelectedCompanyName(companyName);
        setFormState(ReservesAction.DeleteInvestment);
      }

      const investment = companyNameToInvestment.get(companyName);
      return [
        {
          name: 'Add Investment',
          icon: <AddIcon />,
          onClick: onAddInvestment,
        },
        {
          name: 'Delete',
          icon: <DeleteOutlinedIcon />,
          onClick: onDeleteInvestment,
          hide: !investment || disableDeleteInvestment(investment),
        },
      ];
    },
    [setFormState, setSelectedCompanyName]
  );
}

export function useGetHypotheticalTransactionActions() {
  const setSelectedTransactionUuid = useSetAtom(selectedInvestmentTransactionUuidState);
  const setSelectedCompanyName = useSetAtom(selectedFundCompanyNameState);
  const setFormState = useSetAtom(showReservesFormState);

  return useCallback(
    (data?: ReservesRowData) => {
      function onEditTransaction() {
        if (!data?.uuid) return;
        setSelectedTransactionUuid(data.uuid);
        setSelectedCompanyName(data.company.name);
        setFormState(ReservesAction.EditTransaction);
      }

      function onDeleteTransaction() {
        if (!data?.uuid) return;
        setSelectedTransactionUuid(data.uuid);
        setSelectedCompanyName(data.company.name);
        setFormState(ReservesAction.DeleteTransaction);
      }
      return [
        {
          name: 'Edit',
          icon: <EditOutlinedIcon />,
          onClick: onEditTransaction,
        },
        {
          name: 'Delete',
          icon: <DeleteOutlinedIcon />,
          onClick: onDeleteTransaction,
          hide: data?.type === InvestmentType.Initial,
        },
      ];
    },
    [setFormState, setSelectedCompanyName, setSelectedTransactionUuid]
  );
}

export function isHypothetical(investment: FundInvestment) {
  return investment.companyId == null;
}

function disableDeleteInvestment(investment: FundInvestment) {
  return !investment || investment.transactions?.some((t) => !t.hypothetical);
}
