import { useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { fetchFundReserves } from '../../../services/queries/MaggieFundQueries';
import {
  forceReservesHistoryUpdateState,
  getDefaultProjectionsAsOfDate,
  selectedFundIdStateFP,
  selectedFundReservesState,
  selectedFundStateFP,
} from '../state/FPState';

export function useInvalidateReservesData() {
  const fundId = useAtomValue(selectedFundIdStateFP);
  const fund = useAtomValue(selectedFundStateFP);

  return useAtomCallback(
    useCallback(
      async (get, set) => {
        if (!fundId) return;
        try {
          const reserves = await fetchFundReserves(getDefaultProjectionsAsOfDate(fund), fundId);
          set(selectedFundReservesState, reserves);
          set(forceReservesHistoryUpdateState, (curr) => curr + 1);
        } catch (err) {
          console.error(err);
        }
      },
      [fund, fundId]
    )
  );
}
