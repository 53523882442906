import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { FormLoadingButton } from '../../../../../components/Form/FormComponents';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { IKPIReviewHeaderProps, MoveBackToReview, RequestDetails } from './KPIReviewHeader';

const headerContainerCSS = css`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  grid-column-gap: 1rem;
`;

export interface IManagedServiceHeaderProps
  extends Omit<IKPIReviewHeaderProps, 'isModified' | 'onAcceptResponse'> {
  onFinishReview: () => void | Promise<void>;
  onStartReview: () => void | Promise<void>;
}

export function ManagedServiceHeader(props: IManagedServiceHeaderProps) {
  const {
    company,
    isSubmitting,
    kpiRequest,
    onFinishReview,
    onRejectResponse,
    onStartReview,
    onMoveBackToReview,
  } = props;

  return (
    <div css={headerContainerCSS}>
      <RequestDetails
        companyName={company.name}
        companyLogoUrl={company.logoUrl ?? ''}
        respondent={kpiRequest.respondent}
        sentAt={kpiRequest.sentAt}
      />
      <div />
      <ManagedServiceActionButtons
        kpiRequest={kpiRequest}
        onFinishReview={onFinishReview}
        onStartReview={onStartReview}
        onRejectResponse={onRejectResponse}
        isSubmitting={isSubmitting}
        onMoveBackToReview={onMoveBackToReview}
      />
    </div>
  );
}

interface IManagedServiceActionButtonsProps {
  kpiRequest: IKPIRequestDataModel;
  onFinishReview: () => void | Promise<void>;
  onMoveBackToReview: () => void | Promise<void>;
  onStartReview: () => void | Promise<void>;
  onRejectResponse: () => void | Promise<void>;
  isSubmitting: boolean;
}
export function ManagedServiceActionButtons({
  kpiRequest,
  isSubmitting,
  onFinishReview,
  onStartReview,
  onRejectResponse,
  onMoveBackToReview,
}: IManagedServiceActionButtonsProps) {
  const actions = useMemo(() => {
    if (kpiRequest.status === KPIRequestStatus.ManagedServiceInProgress) {
      return (
        <>
          <FormLoadingButton loading={isSubmitting} onClick={onRejectResponse} color='error'>
            Reject
          </FormLoadingButton>
          <FormLoadingButton loading={isSubmitting} onClick={onFinishReview}>
            Finish Review
          </FormLoadingButton>
        </>
      );
    } else if (kpiRequest.status == KPIRequestStatus.ManagedServiceSubmitted) {
      return (
        <>
          <FormLoadingButton loading={isSubmitting} onClick={onRejectResponse} color='error'>
            Reject
          </FormLoadingButton>
          <FormLoadingButton loading={isSubmitting} onClick={onStartReview}>
            Start Review
          </FormLoadingButton>
        </>
      );
    } else if (kpiRequest.status == KPIRequestStatus.ManagedServiceFinished) {
      return <MoveBackToReview onMoveBackToReview={onMoveBackToReview} isSubmitting={isSubmitting} />;
    }
  }, [isSubmitting, kpiRequest.status, onFinishReview, onMoveBackToReview, onRejectResponse, onStartReview]);
  return (
    <Stack direction='row' gap='1rem' height={'min-content'}>
      {actions}
    </Stack>
  );
}
