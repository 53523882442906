import { Switch } from '@mui/material';
import { css } from '@emotion/react';
import { useAtom, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { FC, SyntheticEvent, useCallback } from 'react';
import Comments from '../../../assets/icons/comments-2.svg?react';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { SearchInput } from '../../../CoreComponents/base/SearchInput';
import {
  searchTermState,
  selectedDealLeadState,
  selectedSectorsState,
  showCommentsState,
} from '../state/DealboardUIState';
import { colors } from '../../../theme/colors';
import { DealLeadFilter } from './DealLeadFilter';
import { FundsFilter } from './FundsFilter';
import { RoundFilter } from './RoundFilter';
import { SectorFilter } from './SectorFilter';

const InnerContainer = css`
  display: grid;
  grid-template-columns: minmax(8rem, 1fr) minmax(8rem, max-content) repeat(3, 1fr);
  gap: 0.5rem;
`;

const Container = css`
  margin-top: 1rem;
  padding: 2rem 1.4rem 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  gap: 1rem;

  .customSvg {
    stroke: ${colors.neutral[50]};
    * {
      stroke: ${colors.neutral[50]};
    }
    &.active {
      stroke: ${colors.secondary[60]};
      * {
        stroke: ${colors.secondary[60]};
      }
    }
  }
`;

const SwitchWrapper = css`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const FilterOptions: FC = () => {
  const setSearchTerm = useSetAtom(searchTermState);
  const [showComments, setShowComments] = useAtom(showCommentsState);
  const [selectedSectors, setSelectedSectors] = useAtom(selectedSectorsState);
  const [selectedDealLead, setSelectedDealLead] = useAtom(selectedDealLeadState);
  const resetSelectedSectors = useResetAtom(selectedSectorsState);

  const handleSectorChange = useCallback(
    (e: SyntheticEvent, options: Option[] | undefined) => {
      if (!options) return;
      if (options.length === 0) {
        resetSelectedSectors();
        return;
      }
      setSelectedSectors(options.map((opt) => opt.id as number));
    },
    [resetSelectedSectors, setSelectedSectors]
  );

  const handleDealLeadOnChange = useCallback(
    (newValue: number | null) => {
      setSelectedDealLead(newValue);
    },
    [setSelectedDealLead]
  );

  return (
    <section css={Container}>
      <div css={InnerContainer}>
        <SearchInput
          onChange={(newVal) => setSearchTerm(newVal)}
          placeholder='Search for a deal'
          sx={{ marginTop: '0.5rem' }}
        />

        <SectorFilter selectedSectors={selectedSectors} onChange={handleSectorChange} />
        <RoundFilter />
        <FundsFilter />
        <DealLeadFilter onChange={handleDealLeadOnChange} selectedDealLead={selectedDealLead} />
      </div>
      <div css={SwitchWrapper}>
        <Switch
          aria-label={'toggle comments'}
          checked={showComments}
          onChange={() => setShowComments((prevState) => !prevState)}
        />
        <Comments className={showComments ? 'customSvg active' : 'customSvg'} />
      </div>
    </section>
  );
};
