import { styled } from '@mui/system';
import { useAtomValue } from 'jotai';
import { noop } from 'lodash-es';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { FieldFactory } from '../../../components/Form/FieldFactory';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { useFundIdField } from '../../../data-fields/CommonFields';
import { createFundDataModel } from '../../../data-models/fund.data-model';
import {
  createMetricsFundDataModel,
  IMetricFundDataModel,
  IMetricsDataModel,
} from '../../../data-models/metrics.data-model';
import { CompanyFundMetricsDataModel } from '../../../schemas/CompanyFundMetrics.schema';
import { fundsAtom } from '../../../services/state/AppConfigStateJ';
import { cardStyles } from '../../../theme/component-styles';
import { FDMap } from '../../../util/data-structure/FDMap';
import { createForm, IFormField } from '../../../view-models/form.view-model';
import { selectedMetricsFieldsOrgState } from '../../AdminPanel/DataDisplay/DataDisplayState';
import { DummyCompanyId } from '../../AdminPanel/DataDisplay/DummyData';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { useCompanyInvestments } from '../../CompanyProfiles/hooks/useCompanyInvestments';
import { useUpdateInvestmentData } from '../../CompanyProfiles/hooks/useUpdateInvestmentData';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { IProfilePanelProps, usePanelPagination } from './CompanyDetailsPanel';
import { PaneContents, Panel, PanelContainer, PanelPagination, PanelTitle } from './Panel';
import { useCompanyEditPerms } from './useEditPerms';
import { AllFundsId, useInvestmentMetricsFields } from './useInvestmentMetricsFields';

const InvestmentMetricsPanelHeader = styled('div')`
  align-items: center;
  column-gap: 1rem;
  display: grid;
  grid-template-areas: 'title fund-selector spacer pages';
  grid-template-columns: max-content 1fr max-content;
  padding: 0 0.75rem;
`;

const InvestmentMetricsFieldsWrapper = styled('div')`
  display: grid;
  gap: 0 2rem;
  grid-template-columns: 1fr 1fr;
  align-content: start;
  min-height: 275px;
  padding: 0 0.75rem;
`;

export function InvestmentMetricsPanel({
  companyId,
  fieldsToShow,
  defaultPage,
  editable = true,
}: IProfilePanelProps<CompanyFundMetricsDataModel>) {
  const { companyMetrics, data } = useCompanyInvestments(companyId);
  const investmentsByFund = getInvestmentsByFund(data, companyMetrics);
  const hasInvestmentsData = investmentsByFund.size > 0;

  return hasInvestmentsData ? (
    <InvestmentMetricsPanelInner
      investmentsByFund={investmentsByFund}
      fieldsToShow={fieldsToShow}
      defaultPage={defaultPage}
      editable={editable}
    />
  ) : (
    <Panel title={'Investment Metrics'}>
      <NoDataMessage2 />
    </Panel>
  );
}

export function getInvestmentsByFund(
  data: IMetricsDataModel[],
  companyMetrics: IMetricsDataModel | undefined
) {
  const investmentsByFund = new Map<number, IMetricFundDataModel>();
  data
    .at(0)
    ?.fundData.forEach((metrics) =>
      investmentsByFund.set(metrics.fundId, { ...metrics, customData: metrics.customData ?? {} })
    );

  investmentsByFund.set(
    AllFundsId,
    createMetricsFundDataModel({
      ...(companyMetrics ?? { companyId: DummyCompanyId }),
      fundId: AllFundsId,
      id: AllFundsId,
    })
  );

  return investmentsByFund;
}

interface IInvestmentMetricsPanelInnerProps
  extends Omit<IProfilePanelProps<CompanyFundMetricsDataModel>, 'companyId'> {
  investmentsByFund: Map<number, IMetricFundDataModel>;
}
function InvestmentMetricsPanelInner({
  investmentsByFund,
  fieldsToShow,
  defaultPage,
  editable = true,
}: IInvestmentMetricsPanelInnerProps) {
  const fundsWithAllFunds = useGetSelectableFunds(investmentsByFund);
  const [selectedFund, setSelectedFund] = useState(() => fundsWithAllFunds[0].id);
  const selectedFundData = investmentsByFund.get(selectedFund);
  const setEditableFields = useCompanyEditPerms(selectedFundData?.companyId ?? -1);
  const selectedFields = useAtomValue(selectedMetricsFieldsOrgState);
  const invFields = useInvestmentMetricsFields(fieldsToShow ?? selectedFields, selectedFund);
  const fields = setEditableFields(invFields);

  const fundListFormItem = useFundIdField({
    key: 'Select fund',
    label: '',
    disabled: !editable,
    selectableFunds: fundsWithAllFunds,
  });
  fundListFormItem.disableClearable = true;

  const { paginationConfig, visibleFields } = usePanelPagination({
    fields,
    initialPage: defaultPage,
  });

  return (
    <GenericFallbacksWrapper>
      <PanelContainer style={{ ...cardStyles, padding: 0 }}>
        <InvestmentMetricsPanelHeader>
          <PanelTitle key={'title'} title={'Investment Metrics'} />
          <FieldFactory
            formField={fundListFormItem}
            formProps={{
              name: 'selectedFund',
              onBlur: noop,
              onChange: (id) => {
                setSelectedFund(id);
              },
              ref: noop,
              value: selectedFund,
            }}
          />
          <div style={{ gridColumn: '3 / -1' }}>
            <PanelPagination
              pageCount={paginationConfig.pageCount}
              onChange={paginationConfig.onChange}
              defaultPage={defaultPage}
            />
          </div>
        </InvestmentMetricsPanelHeader>
        <PaneContents>
          {selectedFundData ? (
            <UnstyledFieldset disabled={!editable}>
              <InvestmentMetricsPage
                key={selectedFundData.fundId}
                fields={visibleFields}
                selectedFundData={selectedFundData}
              />
            </UnstyledFieldset>
          ) : null}
        </PaneContents>
      </PanelContainer>
    </GenericFallbacksWrapper>
  );
}

export interface IInvestmentMetricsPanelContentsProps {
  fields: IFormField<unknown>[];
  selectedFundData: IMetricFundDataModel;
}

function InvestmentMetricsPage(props: IInvestmentMetricsPanelContentsProps) {
  const { fields, selectedFundData } = props;
  const updateFundCompany = useUpdateInvestmentData();
  const methods = useForm({
    values: selectedFundData,
  });

  const onChange = useCallback(
    async (data: Partial<IMetricFundDataModel>) => {
      await updateFundCompany(selectedFundData.id, data);
    },
    [selectedFundData.id, updateFundCompany]
  );

  const form = createForm<IMetricFundDataModel>({
    fields: FDMap.fromArray(fields, 'key'),
    variant: 'form-inline',
  });

  return (
    <FormProvider {...methods}>
      <InvestmentMetricsFieldsWrapper>
        <FormFactoryNoLayout form={form} onChange={onChange} />
      </InvestmentMetricsFieldsWrapper>
    </FormProvider>
  );
}

function useGetSelectableFunds(investmentsByFund: Map<number, IMetricFundDataModel>) {
  const funds = useAtomValue(fundsAtom);
  const fundsWithData = funds.filter((fund) => investmentsByFund.has(fund.id));

  return [...fundsWithData, createFundDataModel({ id: AllFundsId, name: 'All Funds' })];
}
