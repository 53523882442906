export function hashObject(obj: NonNullable<unknown>) {
  return JSON.stringify(obj, Object.keys(obj).sort());
}

export function isObject(value: unknown) {
  // Not checking for primitive wrappers for perf reasons and:
  // - we are not using those in the codebase
  // - field access on those won't throw.
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
