import AssessmentIcon from '@mui/icons-material/Assessment';
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { FMT } from '../../../../util/formatter-service';
import { chipStack, FinanceDateSelector, PeriodPicker, PeriodRangePicker } from './FinanceDatePickers';
import { useFinancialsDateSettings, useMostRecentReportDate } from './useFinancialsDateSettings';

export function FinancialsDateSettings2() {
  const { label, selectedFrequency, pastRange, futureRange, handleSelectRange, handleFrequencyChange } =
    useFinancialsDateSettings();

  return (
    <FinanceDateSelector label={label}>
      <Stack display={'grid'} gridTemplateColumns={'1fr 1fr 1fr'} p={'1.5rem 1rem 1rem'} columnGap={'1rem'}>
        <div css={chipStack}>
          <PeriodPicker value={selectedFrequency} onChange={handleFrequencyChange} />
        </div>
        <div css={chipStack}>
          <PeriodRangePicker
            value={pastRange}
            onChange={(val) => handleSelectRange(val, false)}
            frequency={selectedFrequency!}
          />
        </div>
        <div css={chipStack}>
          <PeriodRangePicker
            value={futureRange}
            onChange={(val) => handleSelectRange(val, true)}
            frequency={selectedFrequency!}
            future
          />
        </div>
      </Stack>
      {PermissionService.get().hasPermission(PermissionKey.canViewMetrics) && <MostRecentReportDate />}
    </FinanceDateSelector>
  );
}

export function MostRecentReportDate() {
  const { company, mostRecentReportDate } = useMostRecentReportDate();
  const theme = useTheme();

  if (!mostRecentReportDate || !company) {
    return null;
  }

  return (
    <Stack>
      <Divider />
      <Stack
        p={'1rem'}
        display={'grid'}
        gridTemplateColumns={'auto 1fr'}
        columnGap={'0.5rem'}
        alignItems={'center'}
        justifyContent={'start'}
      >
        <AssessmentIcon htmlColor={theme.colors.secondary[40]} />
        <Typography variant='subtitle1'>{FMT.format('date', new Date(mostRecentReportDate))}</Typography>
        <span />
        <Typography
          variant={'caption'}
          color={'text.secondary'}
        >{`most recent report from ${company.name}`}</Typography>
      </Stack>
    </Stack>
  );
}
