import { CSSProperties, FC, PropsWithChildren } from 'react';
import {
  Stack,
  styled,
  SxProps,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Label } from '../../../../components/Form/FormComponents';
import { BorderlessTooltipProps } from '../../../../constants/styles';
import { colors } from '../../../../theme/colors';
import { cardStyles } from '../../../../theme/component-styles';

export const CardLabel = styled(Label)`
  margin-bottom: -0.25rem;
`;

export const ContentContainer = styled('div')`
  margin: 1.5rem 2.25rem;
`;

const ScenarioContainer = styled('div')`
  width: 100%;
  height: 100vh;
`;

export const MainContainerWithAlert: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme();
  return (
    <Typography component={ScenarioContainer} color='text.primary'>
      <Stack
        direction='row'
        justifyContent={'start'}
        alignItems={'center'}
        style={{ background: colors.primary[10], padding: '0.5rem 2.5rem' }}
        gap='0.5rem'
      >
        <ErrorOutlineIcon color='disabled' />
        <Typography variant='body2'>You are in a Hypothetical Scenario Mode</Typography>
      </Stack>
      <ContentContainer>{children}</ContentContainer>
    </Typography>
  );
};

export const formCardStyles: CSSProperties = {
  ...cardStyles,
  borderLeft: `4px solid ${colors.secondary[50]}`,
};

export const listCardStyles: SxProps = {
  ...cardStyles,
  '&:hover': {
    backgroundColor: '#ECE5FF',
  },
};

export const StyledTextField = styled(TextField)`
  margin: 0;

  &.read-write-input.transparent {
    &.MuiInput-root,
    .MuiInput-root:before,
    .MuiInput-root:hover:before,
    .MuiInput-root:after,
    .MuiInput-root:hover:after,
    .MuiInputBase-root-MuiInput-root.MuiInput-Outline:hover:before,
    .MuiInputBase-root-MuiInput-root.MuiInput-Outline:hover:after {
      border-bottom: none;
      transition: none;
    }
  }
`;

export const ScenarioNameFieldStyles = {
  fontSize: 34,
  padding: 0,
  paddingLeft: '0.25rem',
  borderBottom: 'none',
  transition: 'none',
  fieldSizing: 'content',
};

export const StyledScenarioGrid = styled('ul')`
  display: grid;

  @supports (grid-template-columns: subgrid) {
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 2fr)) 3fr minmax(auto, 1fr) 5rem;
    column-gap: 1rem;
    align-items: center;
    justify-items: start;
  }

  row-gap: 0.25rem;
`;

export const ScenarioListItem = styled('li')`
  display: grid;
  align-items: center;
  width: 100%;

  grid-template-columns: 15% 15% 5% 15% 15% 1fr 2rem;
  column-gap: 1rem;

  @supports (grid-template-columns: subgrid) {
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
  }
`;

interface IChipStackProps extends PropsWithChildren {
  style?: CSSProperties;
}
export const ChipStack: FC<IChipStackProps> = ({ children, style }) => {
  return (
    <Stack direction='row' flexWrap={'wrap'} gap='0.5rem' sx={{ textTransform: 'capitalize' }} style={style}>
      {children}
    </Stack>
  );
};

export const ScenarioDataContainer = styled('section')`
  --header-height: 10rem;
  height: calc(100vh - var(--header-height));
  max-height: calc(100vh - var(--header-height));
  overflow-y: auto;
`;

export const MaxInputWidth = '12rem';

export const InputContainer = styled('section')`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, ${MaxInputWidth});
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: start;
  & .MuiFormControl-root {
    margin: 0;
  }
`;

export const UnstyledFieldset = styled('fieldset')`
  border: none;
  &:disabled {
    pointer-events: none;
    opacity: 0.875;
  }
  margin: 0;
  padding: 0;
`;

export const DisabledFieldset: FC<PropsWithChildren> = ({ children }) => {
  return <UnstyledFieldset disabled>{children}</UnstyledFieldset>;
};

export const ScenarioTooltip: FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <Tooltip {...BorderlessTooltipProps} {...props}>
      {children}
    </Tooltip>
  );
};
