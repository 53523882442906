import { ColDef, KeyCreatorParams, ValueFormatterParams } from 'ag-grid-community';
import { createUSDFormatterDataModel } from '../../../data-models/formatter.data-model';
import { getExcelFormatterId } from '../../../util/excel-formatter-service';
import { FMT, FormatterService } from '../../../util/formatter-service';
import { TransactionsMenuCell } from './TransactionsMenuCell';

export const numericCellClasses = ['monospace', 'text-align-right'];
export const numericColDefs: ColDef<unknown> = {
  filter: 'agNumberColumnFilter',
  headerClass: 'ag-left-aligned-header',
  cellClass: numericCellClasses,
  enableValue: true,
  valueFormatter: (params: ValueFormatterParams) =>
    params.value ? FMT.format('numeric2DP', params.value) : '',
};

export function getNumericColdDef<T>() {
  return {
    ...numericColDefs,
  } as ColDef<T>;
}

export function getIntegerColDefs<T>() {
  return {
    ...numericColDefs,
    cellClass: [...(numericColDefs.cellClass as string[]), 'int'],
    valueFormatter: (params: ValueFormatterParams) =>
      typeof params.value === 'number' ? FMT.format('integer', params.value) : '',
  } as ColDef<T>;
}

export function getCurrencyColDefs<T>(maxDecimals = 2, minDecimals = maxDecimals) {
  const formatterDataModel = createUSDFormatterDataModel(maxDecimals, minDecimals);
  const formatter = FMT.get().getFormatterForModel(formatterDataModel);

  return {
    ...numericColDefs,
    valueFormatter: (params: ValueFormatterParams) =>
      typeof params.value === 'number' ? formatter(params.value) : '',
    cellDataType: 'number',
    cellClass: [...(numericColDefs.cellClass as string[]), getExcelFormatterId('usd')],
  } as ColDef<T>;
}

// ensures set filter and group values have 'N/A' instead of 'Blanks', but cells remain empty
export function genericKeyCreator(params: KeyCreatorParams) {
  return params.value || 'N/A';
}

export function getCurrencyFormatter(currencyCode: string) {
  return FormatterService.get().getFormatterForModel({
    type: 'number',
    id: `${currencyCode}-long`,
    config: {
      currency: currencyCode,
      style: 'currency',
    },
  });
}

export function booleanComparator(valueA: boolean, valueB: boolean) {
  return valueA === valueB ? 0 : valueA ? 1 : -1;
}

export function booleanFormatter(params: ValueFormatterParams | KeyCreatorParams) {
  return FMT.format('yesNo', params.value ?? false);
}

export function getMenuColDef<T>() {
  return {
    headerName: '',
    maxWidth: 70,
    flex: 0,
    suppressHeaderMenuButton: true,
    cellRenderer: TransactionsMenuCell,
    pinned: 'right',
    filter: false,
  } as ColDef<T>;
}
