import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily, atomWithDefault } from 'jotai/utils';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { kpiConfigByKeyMapAtomLoadable } from '../../../services/state/KPIConfigState';
import { columnsByPathStateLoadable, selectedViewIdPF } from '../state/ViewState';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { useSaveMprStateInView } from './mprViewActions';
import { mpReportingGridApi } from './MultiPeriodGrid';
import { createMprViewSettings, IMprViewSettings, MprColumnSortType } from './MultiPeriodReporting.schemas';
import { useMprConfigToColDefs } from './multiperiodReportingGridData';
import {
  getColumnConfigKey,
  IMprKpiColumnConfig,
  IMprMetricsColumnConfig,
  mprColumnNameComparator,
  multiPeriodDateComparator,
} from './multiPeriodReportingUtils';

export const savedMprViewConfigAtom = atomFamily(() => atom<IMprViewSettings | null>(null));

const draftColumnConfigsAtom = atomFamily((viewId: number) =>
  atomWithDefault<(IMprKpiColumnConfig | IMprMetricsColumnConfig)[]>(
    (get) => get(savedMprViewConfigAtom(viewId))?.columns ?? []
  )
);
const draftColumnSortConfigsAtom = atomFamily((viewId: number) =>
  atomWithDefault<MprColumnSortType | null>((get) => get(savedMprViewConfigAtom(viewId))?.columnSort ?? null)
);
const sortedAndDedupedDraftColumnsAtom = atomFamily((viewId: number) =>
  atom<(IMprKpiColumnConfig | IMprMetricsColumnConfig)[]>((get) => {
    const columns = get(draftColumnConfigsAtom(viewId));
    const draftSortType = get(draftColumnSortConfigsAtom(viewId));
    const uniqueColumns = Array.from(
      columns
        .reduce(
          (map, config) => map.set(getColumnConfigKey(config), config),
          new Map<string, IMprKpiColumnConfig | IMprMetricsColumnConfig>()
        )
        .values()
    );
    const kpiConfigMap = get(kpiConfigByKeyMapAtomLoadable);
    const columnsByPath = get(columnsByPathStateLoadable(COMPANY_VIEW_TYPE.RETURN_FORECAST));
    if (kpiConfigMap.state !== 'hasData' || columnsByPath.state !== 'hasData') return uniqueColumns;
    switch (draftSortType) {
      case MprColumnSortType.dateAsc:
        return uniqueColumns
          .toSorted((a, b) => mprColumnNameComparator(a, b, kpiConfigMap.data, columnsByPath.data))
          .sort(multiPeriodDateComparator);
      case MprColumnSortType.displayName:
        return uniqueColumns
          .toSorted(multiPeriodDateComparator)
          .sort((a, b) => mprColumnNameComparator(a, b, kpiConfigMap.data, columnsByPath.data));
      default:
        return uniqueColumns;
    }
  })
);

export function useMprColumnConfigs() {
  const updateView = useSaveMprStateInView();
  const viewId = useRecoilValue(selectedViewIdPF);
  const draftColumns = useAtomValue(sortedAndDedupedDraftColumnsAtom(viewId));
  const setDraftColumns = useSetAtom(draftColumnConfigsAtom(viewId));
  const [draftSortType, setDraftSortType] = useAtom(draftColumnSortConfigsAtom(viewId));
  const configToColDefs = useMprConfigToColDefs();

  const [saved, saveConfigs] = useAtom(savedMprViewConfigAtom(viewId));
  const gridApi = useAtomValue(mpReportingGridApi);

  const saveViewConfigs = useCallback(
    async (updateGridColDefs = true) => {
      // save current sort order for custom sort - will be ignored otherwise
      const columnsWithSortOrder = draftColumns.map((config, i) => ({ ...config, sortOrder: i }));
      const newConfig: Pick<IMprViewSettings, 'columns' | 'columnSort'> = {
        columns: columnsWithSortOrder,
        columnSort: draftSortType,
      };

      saveConfigs((prev) => ({
        ...(prev ?? createMprViewSettings()),
        ...newConfig,
      }));

      if (updateGridColDefs) {
        gridApi?.setGridOption('columnDefs', configToColDefs(columnsWithSortOrder, true));
      }
      updateView({ multiPeriodReportingConfig: newConfig });
    },
    [draftColumns, draftSortType, saveConfigs, updateView, gridApi, configToColDefs]
  );

  const resetDrafts = useCallback(() => {
    setDraftColumns(saved?.columns ?? []);
    setDraftSortType(saved?.columnSort ?? null);
  }, [saved, setDraftColumns, setDraftSortType]);

  return {
    draftColumnConfigs: draftColumns,
    setDraftColumnConfigs: setDraftColumns,
    draftColumnSortType: draftSortType,
    setDraftColumnSortType: setDraftSortType,
    saveConfigState: saveViewConfigs,
    resetDrafts,
  };
}
