import { useAtomValue, useSetAtom } from 'jotai';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { MissDealForm } from '../Forms/MissDealForm';
import { PassDealForm } from '../Forms/PassDealForm';
import { TrackDealForm } from '../Forms/TrackDealForm';
import { selectedDealActionAtom, selectedDealActionState } from '../state/DealboardUIState';
import { DeleteDealModal } from './DeleteDealModal';

export function DealActionModals() {
  const selectedDealAction = useAtomValue(selectedDealActionState);
  const setSelectedDealAction = useSetAtom(selectedDealActionAtom);

  if (!selectedDealAction) return null;

  function onClose() {
    setSelectedDealAction(null);
  }

  switch (selectedDealAction.action) {
    case 'delete':
      return (
        <DeleteDealModal
          company={selectedDealAction.company}
          deal={selectedDealAction.deal}
          open={selectedDealAction.action === 'delete'}
          onClose={onClose}
          next={selectedDealAction.next}
        />
      );
    case 'pass':
      return (
        <BasicDialog
          open={selectedDealAction.action === 'pass'}
          onClose={onClose}
          title={`Pass Deal for "${selectedDealAction.company.name}"`}
        >
          <PassDealForm deal={selectedDealAction.deal} onClose={onClose} next={selectedDealAction.next} />
        </BasicDialog>
      );
    case 'track':
      return (
        <BasicDialog
          open={selectedDealAction.action === 'track'}
          onClose={onClose}
          title={`Track Deal for "${selectedDealAction.company.name}"`}
        >
          <TrackDealForm deal={selectedDealAction.deal} onClose={onClose} next={selectedDealAction.next} />
        </BasicDialog>
      );
    case 'missed':
      return (
        <BasicDialog
          open={selectedDealAction.action === 'missed'}
          onClose={onClose}
          title={`Move Deal for "${selectedDealAction.company.name}" to Missed`}
        >
          <MissDealForm deal={selectedDealAction.deal} onClose={onClose} next={selectedDealAction.next} />
        </BasicDialog>
      );
  }
}
