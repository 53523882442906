import { atom, selector } from 'recoil';
import { atom as atomJ } from 'jotai';
import { atomFamily as atomFamilyJ, loadable } from 'jotai/utils';
import { fetchColumns, fetchViews } from '../../../services/queries/MaggieViewQueries';
import { ViewModel } from '../../../models/view.model';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { PredefinedColumns } from '../components/OverviewTable/columns/PredefinedColumns/PredefinedColumns';
import { ColumnMeta } from '../../../types';

export const selectedViewPF = selector<ViewModel>({
  key: 'selectedViewPF',
  get: ({ get }) => {
    const viewId = get(selectedViewIdPF);
    const views = get(viewsPF);

    return views.find((view) => view.id === viewId)!;
  },
});

export const selectedViewIdPF = atom<number>({
  key: 'selectedViewIdPF',
  default: -1,
});

export const viewsPF = atom<ViewModel[]>({
  key: 'viewsPF',
  default: selector({
    key: 'viewsPF/default',
    get: () => {
      return fetchViews();
    },
  }),
});

export const columnsMetaState = atomFamilyJ((companyViewType: COMPANY_VIEW_TYPE) => {
  return atomJ(fetchColumns(companyViewType));
});

export const columnsByPathState = atomFamilyJ((companyViewType: COMPANY_VIEW_TYPE) => {
  return atomJ(async (get) => {
    const columnMeta = await get(columnsMetaState(companyViewType));
    return columnMeta.reduce((map, column) => {
      return map.set(column.path, column);
    }, new Map<string, ColumnMeta>());
  });
});
export const columnsByPathStateLoadable = atomFamilyJ((companyViewType: COMPANY_VIEW_TYPE) => {
  return loadable(columnsByPathState(companyViewType));
});

export const columnNameToDisplayNameState = atomFamilyJ((companyViewType: COMPANY_VIEW_TYPE) => {
  return atomJ(async (get) => {
    const columnMeta = await get(columnsMetaState(companyViewType));
    const predefinedColumnsMeta = PredefinedColumns.getAllMeta(companyViewType);
    return [...columnMeta, ...predefinedColumnsMeta].reduce((map, column) => {
      return map.set(column.name, column.displayName);
    }, new Map<string, string>());
  });
});
