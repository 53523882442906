import { merge } from 'lodash-es';
import { GetterFormatType } from '../util/getFormatter';
import { createFormField, IDateFieldFormSettings, IFormField } from '../view-models/form.view-model';
import { IFormatterDataModel } from './formatter.data-model';
import {
  createField3DataModel,
  getFormatterModelForField3,
  IField3,
  PrimitiveType,
  PrimitiveTypesAsArray,
} from './field3.data-model';
import { RendererType } from './field.data-model';
import { KpiConfigPeriod } from './company-financials.data-model';

export type CustomData = Record<string, string | number | string[]>;

export enum GridRef {
  portfolioReporting = 'portfolioReporting',
  roundTracker = 'roundTracker',
  securityOverview = 'securityOverview',
}

export enum EntityFieldType {
  Custom = 'customData',
  Universal = 'universalFieldData',
}

export enum KpiConfigSection {
  Actual = 'actual',
  Budget = 'budget',
  Both = 'both',
}

export enum KpiConfigScenario {
  Accrued = 'accrued',
  EndOfPeriod = 'endOfPeriod',
  None = 'none',
}

export const KpiConfigScenarioToName: Record<KpiConfigScenario, string> = {
  [KpiConfigScenario.Accrued]: 'Accrued',
  [KpiConfigScenario.EndOfPeriod]: 'End of Period',
  [KpiConfigScenario.None]: 'None',
};

export type BasicFormConfig = Record<string, unknown>;

export interface IField<
  DataType,
  FormMetaType extends BasicFormConfig = BasicFormConfig,
  FormatterType extends BasicFormConfig = BasicFormConfig,
> {
  description: string | null;
  displayName: string;
  entity: string;
  entityField: string;
  sortOrder?: number;
  formMeta: IFieldFormConfig<DataType, FormMetaType, FormatterType> | null;
  gridMeta: IGridMetaConfig | null;
  systemMeta: {
    formRefs: string[];
    gridRefs: GridRef[];
    source: FieldSource;
  };
  id: number;
  type: string; // Primitive type, matches typescript type e.g. number, string, stringArray etc...
  createdBy?: string | null;
  createdAt?: string | null;
  kpiGroupId?: number;
  kpiScenario?: KpiConfigScenario;
  periods?: KpiConfigPeriod[];
  sectionType?: KpiConfigSection;
  timeSeries?: boolean | null;
  updatedBy?: string | null;
  yearCalcType?: string;
}

export enum FieldSource {
  Default = 'default',
  Foresight = 'foresight', // user entered in foresight
  KPICollection = 'kpiCollection',
}

export const EntityType = {
  company: 'company',
  fund: 'fund',
  fundCompany: 'fundCompany',
  person: 'person',
  round: 'round',
} as const;
export type EntityType = (typeof EntityType)[keyof typeof EntityType];

export const FieldEntity = {
  ...EntityType,
  captableInvestment: 'captableInvestment',
  deal: 'deal',
  dummy: 'dummy',
  people: 'people',
  scenario: 'scenario',
  systemKPI: 'systemKPI',
  universalKPI: 'universalKPI',
  userKPI: 'userKPI',
} as const;

export type FieldEntity = (typeof FieldEntity)[keyof typeof FieldEntity];

export enum FormOptions {
  addingCaptableSecurity = 'Adding Captable Security',
  addingTransaction = 'Adding Transaction',
  addingDeal = 'Adding Deal',
  companyLists = 'Company Lists',
  companyProfile = 'Company Profile',
  addingRound = 'Adding Round',
}

////////////////////////////
////
////  Form models
////
////////////////////////////

export interface IFieldFormConfig<
  DataType,
  FormMetaType extends BasicFormConfig,
  FormatterType extends BasicFormConfig = BasicFormConfig,
> {
  defaultValue: DataType | null;
  formatter: IFormatterDataModel<FormatterType>;
  renderer: IRendererConfig<FormMetaType> | null;
  required: boolean | null;
}

export interface IRendererConfig<T> {
  id?: string;
  type: RendererType | string;
  config: T;
}

export enum RendererTypeOfComponent {
  grid = 'grid',
  field = 'field',
}
export interface IInputConfig extends Record<string, unknown> {
  placeholder: string | null;
}

export interface ISimpleChoice<T> {
  disabled?: boolean;
  displayName?: string | null;
  secondaryLabel?: string | null;
  value: T;
}

// TextField
export interface ITextFieldConfig extends IInputConfig {
  maxLength: number | null;
}

export interface ICurrencyFieldConfig extends IInputConfig {
  currencyCode: string;
}

// Date
export interface IDateFieldConfig
  extends IInputConfig,
    Omit<IDateFieldFormSettings, 'disabled' | 'variant' | 'required'> {}

// Select
export interface ISelectConfig<T> extends IInputConfig {
  multi: boolean | null;
  values: ISimpleChoice<T>[];
}

////////////////////////////
////
////  Grid model
////
////////////////////////////

export interface IGridMetaConfig {
  group?: string;
  hide?: boolean;
}

export function isNumericRenderer(type: RendererType) {
  return (
    type === RendererType.currency ||
    type === RendererType.percent ||
    type === RendererType.integer ||
    type === RendererType.multiplier ||
    type === RendererType.number ||
    type === RendererType.numeric
  );
}

export function createFieldDataModel<
  DataType,
  FormRendererMetaType extends Record<string, unknown> = BasicFormConfig,
>(overrides: Partial<IField<DataType, FormRendererMetaType>> = {}): IField<DataType, FormRendererMetaType> {
  return merge(
    {
      description: null,
      displayName: '',
      entity: '',
      entityField: '',
      formMeta: createFieldFormConfigDataModel({}),
      gridMeta: null,
      id: 0,
      type: '',
      systemMeta: {
        formRefs: [],
        gridRefs: [],
        source: FieldSource.Default,
      },
      timeSeries: false,
    },
    overrides
  );
}

export function createFieldFormConfigDataModel<T, RendererConfig extends BasicFormConfig = BasicFormConfig>(
  overrides: Partial<IFieldFormConfig<T, RendererConfig>> = {}
): IFieldFormConfig<T, RendererConfig> {
  const { formatter, renderer, ...rest } = overrides;
  return {
    defaultValue: null,
    required: false,
    renderer: {
      id: 'string',
      type: RendererType.text,
      config: {} as RendererConfig,
      ...renderer,
    },
    formatter: {
      id: '',
      config: {},
      ...formatter,
    },
    ...rest,
  };
}

export function field2ToField3(field: IField<unknown>): IField3<unknown> {
  const { description, id, entity, entityField, formMeta, type, displayName, timeSeries } = field;
  const configV3 = createField3DataModel({
    description: description ?? '',
    displayName,
    entity: entity as FieldEntity,
    entityKey: entityField,
    id,
    providerOrder: [],
    timeSeries,
  });

  const rendererType = formMeta?.renderer?.type;
  const rendererConfig = formMeta?.renderer?.config;
  const formatterConfig = formMeta?.formatter?.config;
  if (rendererType) {
    configV3.format = rendererType as RendererType;

    switch (rendererType) {
      case RendererType.boolean: {
        configV3.dataType = 'boolean';
        break;
      }
      case RendererType.text: {
        configV3.dataType = 'string';
        configV3.meta = {
          maxLength: rendererConfig?.maxLength ?? -1,
        };
        break;
      }
      case RendererType.number: {
        configV3.dataType = 'number';
        break;
      }
      case RendererType.numeric: {
        configV3.dataType = 'number';
        configV3.format = RendererType.number;
        break;
      }
      case RendererType.currency: {
        configV3.dataType = 'number';
        configV3.meta = {
          defaultCurrency: formatterConfig?.currency ?? 'USD',
        };
        break;
      }
      case RendererType.percent: {
        configV3.dataType = 'number';
        break;
      }
      case RendererType.multiplier: {
        configV3.dataType = 'number';
        break;
      }
      case RendererType.date: {
        configV3.dataType = 'string';
        break;
      }
      case RendererType.singleSelect: {
        configV3.dataType = isPrimitiveDataType(type) ? type : 'string';
        configV3.meta = {
          values: rendererConfig?.values ?? [],
          multi: false,
        };
        break;
      }
      case RendererType.colorStatus:
      case RendererType.multiSelect: {
        configV3.dataType = 'array';
        configV3.meta = {
          values: rendererConfig?.values ?? [],
          multi: true,
        };
        break;
      }
    }

    return configV3;
  } else if (field.formMeta?.formatter) {
    const formatterType = field.formMeta.formatter.type as RendererType | string;
    configV3.format = formatterType as RendererType;

    switch (formatterType) {
      case 'string': {
        configV3.dataType = 'string';
        configV3.format = RendererType.text;
        configV3.meta = {
          maxLength: rendererConfig?.maxLength ?? -1,
        };
        break;
      }
      case RendererType.currency: {
        configV3.dataType = 'number';
        configV3.meta = {
          defaultCurrency: formatterConfig?.currency ?? 'USD',
        };
        break;
      }
      case RendererType.number:
      case RendererType.percent:
      case RendererType.multiplier: {
        configV3.dataType = 'number';
        break;
      }
      case RendererType.date: {
        configV3.dataType = 'string';
        break;
      }
      case RendererType.boolean: {
        configV3.dataType = 'boolean';
        break;
      }
    }
  } else {
    configV3.dataType = getPrimitiveFromFieldType(type);
    configV3.format = getRendererTypeFromFieldType(type);
  }
  return configV3;
}

export function getFormatterModelForField2(field: IField<unknown>) {
  return getFormatterModelForField3(field2ToField3(field));
}

export function field2ToFormField<T>(
  field: IField<unknown>,
  overrides: Partial<IFormField<T>> = {}
): IFormField<T> {
  const { description, entityField, formMeta, type, displayName, timeSeries } = field;
  const formField = createFormField<unknown>({
    description: description ?? '',
    label: displayName,
    key: entityField,
    renderer: (formMeta?.renderer?.type as RendererType) ?? RendererType.text,
    timeSeries,
    ...overrides,
  });

  const rendererType = formMeta?.renderer?.type;
  const rendererConfig = formMeta?.renderer?.config;
  const formatterConfig = formMeta?.formatter?.config;
  if (rendererType) {
    formField.renderer = rendererType as RendererType;

    switch (rendererType) {
      case RendererType.boolean: {
        formField.dataType = 'boolean';
        break;
      }
      case RendererType.text: {
        formField.dataType = 'string';
        formField.rendererMeta = {
          maxLength: rendererConfig?.maxLength ?? -1,
        };
        break;
      }
      case RendererType.number: {
        formField.dataType = 'number';
        break;
      }
      case RendererType.numeric: {
        formField.dataType = 'number';
        formField.renderer = RendererType.number;
        break;
      }
      case RendererType.currency: {
        formField.dataType = 'number';
        formField.rendererMeta = {
          defaultCurrency: formatterConfig?.currency ?? 'USD',
        };
        break;
      }
      case RendererType.percent: {
        formField.dataType = 'number';
        break;
      }
      case RendererType.multiplier: {
        formField.dataType = 'number';
        break;
      }
      case RendererType.date: {
        formField.dataType = 'string';
        break;
      }
      case RendererType.singleSelect: {
        formField.dataType = isPrimitiveDataType(type) ? type : 'string';
        formField.rendererMeta = {
          values: rendererConfig?.values ?? [],
          multi: false,
        };
        break;
      }
      case RendererType.colorStatus:
      case RendererType.multiSelect: {
        formField.dataType = 'array';
        formField.rendererMeta = {
          values: rendererConfig?.values ?? [],
          multi: true,
        };
        break;
      }
    }
  } else if (field.formMeta?.formatter) {
    const formatterType = field.formMeta.formatter.type as RendererType | string;
    formField.renderer = formatterType as RendererType;

    switch (formatterType) {
      case 'string': {
        formField.dataType = 'string';
        formField.renderer = RendererType.text;
        formField.rendererMeta = {
          maxLength: rendererConfig?.maxLength ?? -1,
        };
        break;
      }
      case RendererType.number: {
        formField.dataType = 'number';
        break;
      }
      case RendererType.currency: {
        formField.dataType = 'number';
        formField.rendererMeta = {
          defaultCurrency: formatterConfig?.currency ?? 'USD',
        };
        break;
      }
      case RendererType.percent: {
        formField.dataType = 'number';
        break;
      }
      case RendererType.multiplier: {
        formField.dataType = 'number';
        break;
      }
      case RendererType.date: {
        formField.dataType = 'string';
        break;
      }
      case RendererType.boolean: {
        formField.dataType = 'boolean';
        break;
      }
    }
  } else {
    formField.dataType = getPrimitiveFromFieldType(type);
    formField.renderer = getRendererTypeFromFieldType(type);
  }

  return formField as IFormField<T>;
}

function isPrimitiveDataType(type: string | undefined): type is PrimitiveType {
  if (!type) return false;
  return PrimitiveTypesAsArray.includes(type as PrimitiveType);
}

export function getRendererTypeFromFieldType(type: GetterFormatType | string | undefined): RendererType {
  switch (type) {
    case 'number':
      return RendererType.number;
    default:
      return RendererType.text;
  }
}
function getPrimitiveFromFieldType(type: string): PrimitiveType {
  if (isPrimitiveDataType(type)) return type;
  switch (type) {
    case 'stringArray':
    case 'numberArray':
      return 'array';
    default:
      return 'string';
  }
}

export function isUniversalKPIField(field: IField<unknown>) {
  return field.entity === FieldEntity.universalKPI || field.entity === FieldEntity.systemKPI;
}
