import { IUpdatedByAtAndCreatedByAt } from '../types';
import { uuid } from '../util/uuid';
import { createFieldDataModel, IField, RendererTypeOfComponent } from './field2.data-model';
import { KpiSection } from './company-financials.data-model';

export interface IKPITableDataModel {
  displayName: string;
  description: string;
  section: KpiSection;
  sortOrder?: number;
  metrics: IField<unknown>[];
}

export function isKpiTableDataModel(meta: IField<unknown> | IKPITableDataModel): meta is IKPITableDataModel {
  return 'metrics' in meta && 'section' in meta;
}

export interface IKPITemplateSectionDataModel {
  templateId: number;
  id?: number;
  // FIXME: MAGGIE-4576
  type?: string;
  // FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-5684
  meta: IField<unknown> | IKPITableDataModel;
}

export interface IKPITemplate extends IUpdatedByAtAndCreatedByAt {
  uuid: string;
  id: number;
  isDeleted?: boolean | null;
  isManagedService?: boolean | null;
  name: string;
  sections?: IKPITemplateSectionDataModel[] | null | undefined;
  version?: number | null;
}

export function createKPITemplateDataModel(overrides: Partial<IKPITemplate> = {}): IKPITemplate {
  return {
    uuid: uuid(),
    id: 1,
    isManagedService: false,
    name: '',
    sections: [],
    version: 1,
    ...overrides,
  };
}

export function createKPITemplateSectionDataModel(
  overrides: Partial<IKPITemplateSectionDataModel> = {}
): IKPITemplateSectionDataModel {
  return {
    meta: createFieldDataModel({}),
    type: RendererTypeOfComponent.field,
    templateId: 0,
    ...overrides,
  };
}
