import { endOfToday, sub } from 'date-fns';
import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { sectorsAtom } from '../../../services/state/AppConfigStateJ';
import { dealBoardConfigState } from './DealboardDataState';

export enum GroupByOptions {
  ROUND = 'round',
  DEAL_LEAD = 'dealLead',
  LOCATION = 'location',
  SOURCE_TYPE = 'sourceType',
  SECTOR = 'sector',
  SOURCE_COMPANY = 'sourceCompany',
  INVESTMENT_STATUS = 'investmentStatus',
}

export enum PresetPeriods {
  L1M = 1,
  L3M = 3,
  L12M = 12,
}

export const GroupByFilterState = atom<GroupByOptions>(GroupByOptions.ROUND);

export const toDateFilterStateDA = atom<Date>(endOfToday());

export const monthsBackState = atom<PresetPeriods>(PresetPeriods.L1M);

export const fromDateFilterStateDA = atom((get) => {
  const period = get(monthsBackState);
  const toDate = get(toDateFilterStateDA);

  if (period === PresetPeriods.L1M) {
    return sub(toDate, { months: 1 });
  } else if (period === PresetPeriods.L3M) {
    return sub(toDate, { months: 3 });
  } else if (period === PresetPeriods.L12M) {
    return sub(toDate, { years: 1 });
  } else {
    throw new Error('Invalid period');
  }
});

export const dealTypeFilterStateDA = atomWithDefault(async (get) => {
  const dealTypes = (await get(dealBoardConfigState)).dealTypes;

  return dealTypes.find((dealType) => dealType.name === 'New Deals')!.id;
});

export const sectorFilterState = atomWithDefault<number[]>((get) => {
  const allSectors = get(sectorsAtom);

  return allSectors.map((sector) => sector.id);
});

export const dealLeadFilterState = atom<number | null>(null);
