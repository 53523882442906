import { FC, useCallback } from 'react';
import { Stack } from '@mui/material';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { useUpdateDeal2 } from '../../../hooks/useDealActions';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../../../types';
import { NumericFieldInput } from '../../../../../CoreComponents/base/NumericFieldInput';
import { formatUSDShort } from '../../../../../util/formatters/NumericFormatters';
import { DrawerLabel } from './commonDrawerComponents';

interface FinancialInfoProps {
  deal: IDealDataModel;
  disableEdit?: boolean;
}

export const FinancialInfo2: FC<FinancialInfoProps> = ({ deal, disableEdit }) => {
  const updateDeal = useUpdateDeal2();
  const { actions } = useLoadingBarState();

  const onUpdateDeal = useCallback(
    async (payload: Partial<IDealDataModel>) => {
      actions.startLoading(LoadingId.updateDeal);
      await updateDeal(deal, { ...payload });
      actions.stopLoading(LoadingId.updateDeal);
    },
    [actions, deal, updateDeal]
  );

  return (
    <>
      <DrawerLabel>Raise Amount</DrawerLabel>
      <NumericFieldInput
        value={deal.raiseAmount}
        onValueChanged={(newValue) => {
          if (newValue != null) {
            onUpdateDeal({ raiseAmount: newValue });
          }
        }}
        prefix='US$'
        formatOnBlur={formatUSDShort}
        disabled={disableEdit}
      />

      <DrawerLabel>Investment Amount</DrawerLabel>
      <Stack direction={'row'} alignItems={'center'}>
        <NumericFieldInput
          value={deal.gcAmountMin}
          onValueChanged={(newValue) => {
            if (newValue != null) {
              onUpdateDeal({ gcAmountMin: newValue });
            }
          }}
          prefix='US$'
          formatOnBlur={formatUSDShort}
          disabled={disableEdit}
          dynamicWidth
          maxDynamicWidth='12rem'
        />
        {(deal.gcAmountMin != null || deal.gcAmountMax != null) && <span>-</span>}
        <NumericFieldInput
          value={deal.gcAmountMax}
          onValueChanged={(newValue) => {
            if (newValue != null) {
              onUpdateDeal({ gcAmountMax: newValue });
            }
          }}
          prefix='US$'
          formatOnBlur={formatUSDShort}
          disabled={disableEdit}
          dynamicWidth
          maxDynamicWidth='12rem'
        />
      </Stack>

      <DrawerLabel>Total PIC</DrawerLabel>
      <NumericFieldInput
        value={deal.totalPic}
        onValueChanged={(newValue) => {
          if (newValue != null) {
            onUpdateDeal({ totalPic: newValue });
          }
        }}
        prefix='US$'
        formatOnBlur={formatUSDShort}
        disabled={disableEdit}
      />
    </>
  );
};
