import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { companyStateJ } from '../../../../services/state/CompanyStateJ';
import { recentlyClosedDealsState, recentlyPassedDealsState } from '../../state/DealboardDataState';

const Wrapper = styled('div')`
  height: 36px;
  padding-left: 10px;
  padding-right: 45px;
  display: flex;
  gap: 10px;
  align-items: center;
  & img {
    width: 20px;
    object-fit: contain;
  }
`;

const ItemWrapper = styled('div')`
  display: flex;
  gap: 3px;
`;

const ClosedIconWrapper = styled('div')`
  margin-bottom: -4px;
  & svg {
    transform: scale(0.65);
  }
`;

const PassedIconWrapper = styled('div')`
  margin-bottom: -5px;
  & svg {
    transform: scale(0.7);
  }
`;

export const CollapsedMenu: FC = () => {
  const { colors } = useTheme();
  const recentClosed = useAtomValue(recentlyClosedDealsState);
  const recentPassed = useAtomValue(recentlyPassedDealsState);
  const closedDeal = recentClosed[0];
  const passedDeal = recentPassed[0];

  const closedDealCompany = useAtomValue(companyStateJ(closedDeal?.companyId));
  const passedDealCompany = useAtomValue(companyStateJ(passedDeal?.companyId));

  const closedLogo = closedDealCompany?.logoUrl;
  const passedLogo = passedDealCompany?.logoUrl;

  return (
    <Wrapper>
      {closedDeal && closedDealCompany && (
        <ItemWrapper>
          <ClosedIconWrapper>
            <CheckCircleOutlineIcon style={{ color: colors.primary[60] }} />
          </ClosedIconWrapper>

          <MUIAvatar src={closedLogo ?? ''} nameParts={closedDealCompany.name.split(' ') ?? []} />
        </ItemWrapper>
      )}

      {passedDeal && passedDealCompany && (
        <ItemWrapper>
          <PassedIconWrapper>
            <ThumbDownAltOutlinedIcon style={{ color: colors.primary[60] }} />
          </PassedIconWrapper>

          <MUIAvatar src={passedLogo ?? ''} nameParts={passedDealCompany.name.split(' ') ?? []} />
        </ItemWrapper>
      )}
    </Wrapper>
  );
};
