import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useSetAtom } from 'jotai';
import { FC, useCallback, useState } from 'react';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { colors } from '../../../../theme/colors';
import { FMT } from '../../../../util/formatter-service';
import { StringFormatterId } from '../../../../util/formatters/StringFormatters';
import { useDealActions2 } from '../../hooks/useDealActions';
import { DealAction, selectedDealActionAtom } from '../../state/DealboardUIState';

interface IDealActionsBtnProps {
  deal: IDealDataModel;
}

export const CardMenu: FC<IDealActionsBtnProps> = ({ deal }) => {
  const { handleClose } = useDealActions2();
  const setSelectedDealAction = useSetAtom(selectedDealActionAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback(
    (action: DealAction | 'close') => {
      if (action === 'close') {
        handleClose(deal);
      } else {
        setSelectedDealAction({
          action,
          dealId: deal.id,
        });
      }
    },
    [deal, handleClose, setSelectedDealAction]
  );
  const handleSelectOption = useCallback(
    (optionId: DealAction | 'close') => {
      if (!deal) return;
      switch (optionId) {
        case 'close':
          handleClick('close');
          setAnchorEl(null);
          return;
        case 'track':
          handleClick('track');
          setAnchorEl(null);
          return;
        case 'pass':
          handleClick('pass');
          setAnchorEl(null);
          return;
        case 'missed':
          handleClick('missed');
          setAnchorEl(null);
          return;
        case 'delete':
          handleClick('delete');
          setAnchorEl(null);
          return;
        default:
          console.warn(`Unknown option: ${optionId}`);
          return;
      }
    },
    [deal, handleClick]
  );

  if (!deal) return null;

  return (
    <>
      <IconButton
        className='deal-card-menu-button'
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon htmlColor={colors.neutral[50]} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {[
          { icon: <CheckCircleOutlineIcon fontSize='small' />, action: 'close' },
          { icon: <HourglassEmptyIcon fontSize='small' />, action: 'track' },
          { icon: <ThumbDownAltOutlinedIcon fontSize='small' />, action: 'pass' },
          { icon: <CallMissedOutgoingIcon fontSize='small' />, action: 'missed' },
          { icon: <DeleteOutlineIcon fontSize='small' />, action: 'delete' },
        ].map(({ icon, action }) => (
          <div key={action} title={action}>
            <MenuItem
              onClick={(e) => {
                e?.stopPropagation();
                handleSelectOption(action as DealAction | 'close');
              }}
              style={{ width: '8rem' }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{FMT.format(StringFormatterId.capitalizedString, action)}</ListItemText>
            </MenuItem>
          </div>
        ))}
      </Menu>
    </>
  );
};
