import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EastIcon from '@mui/icons-material/East';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { useModalState } from '../../../../../components/Modal/ModalContext';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { companyState } from '../../../../../services/state/CompanyState';
import { theme } from '../../../../../theme';
import { LoadingId } from '../../../../../types';
import { StyledTooltip } from '../../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { ConfirmDeleteDeal } from '../../../../DealFlow2/components/DealModal/ConfirmDeleteDeal';
import { DealMissedModalContent } from '../../../../DealFlow2/components/DealModal/DealMissedModalContent';
import { DealModal } from '../../../../DealFlow2/components/DealModal/DealModal';
import { DealPassModalContent } from '../../../../DealFlow2/components/DealModal/DealPassModalContent';
import { useDealActions2, useUpdateStage } from '../../../../DealFlow2/hooks/useDealActions';
import { LastUpdatedCurrentDealState, SortedStageIdsState } from '../../../state/CompanyDealsState';
import { selectedCompanyIdProfileAtom } from '../../../state/UIStateJ';
import { getNextStageId } from '../../../utils/dealActionsUtils';

const { neutral } = theme.colors;

const MenuItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  color: ${neutral['60']};
  gap: 8px;
`;

export const DealActionsBtn: FC = () => {
  const { colors } = useTheme();
  const companyId = useAtomValue(selectedCompanyIdProfileAtom);
  const activeDeal = useAtomValue(LastUpdatedCurrentDealState(companyId));
  const sortedStageIds = useAtomValue(
    SortedStageIdsState((activeDeal as IDealDataModel)?.dealTypeId ?? null)
  );
  const company = useRecoilValue(companyState(companyId));
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { handleClose, handleDelete, handlePass, handleMissed } = useDealActions2();
  const { onOpenModal } = useModalState();
  const updateDealStage = useUpdateStage();
  const { actions } = useLoadingBarState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onConfirmDelete = useCallback(() => {
    setShowConfirmDelete(false);
    if (!activeDeal) return;
    handleDelete(activeDeal);
  }, [activeDeal, handleDelete]);

  const nextStageId = useMemo(
    () => (!activeDeal || !sortedStageIds ? null : getNextStageId(activeDeal, sortedStageIds)),
    [activeDeal, sortedStageIds]
  );

  const handleMoveToNextStage = useCallback(async () => {
    if (!activeDeal || !nextStageId) return;
    actions.startLoading(LoadingId.updateDeal);

    const updates: Partial<IDealDataModel> = {
      stageId: nextStageId,
      stageUpdateDate: new Date().toISOString(),
    };

    try {
      await updateDealStage(activeDeal, updates);
    } finally {
      actions.stopLoading(LoadingId.updateDeal);
    }
  }, [actions, activeDeal, nextStageId, updateDealStage]);

  const options = useMemo(() => {
    return [
      {
        id: 'MOVE_TO_NEXT_STAGE',
        value: 'Move to next stage',
        icon: <EastIcon htmlColor={!nextStageId ? colors.neutral[40] : undefined} />,
        disabled: !nextStageId,
      },
      {
        id: 'CLOSE',
        value: 'Close',
        icon: <CheckCircleOutlineIcon />,
      },
      {
        id: 'PASS',
        value: 'Pass',
        icon: <ThumbDownAltOutlinedIcon />,
      },
      {
        id: 'MISSED',
        value: 'Missed',
        icon: <CallMissedOutgoingIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete',
        icon: <DeleteOutlineIcon />,
        hasDivider: true,
      },
    ];
  }, [colors.neutral, nextStageId]);

  const handleSelectOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: string) => {
      e.stopPropagation();
      handleCloseMenu();
      if (!activeDeal || !company) return;
      switch (id) {
        case 'CLOSE':
          handleClose(activeDeal);
          return;
        case 'PASS':
          onOpenModal(
            <DealModal title={`Move the Deal “${company.name}” to Pass?`}>
              <DealPassModalContent deal={activeDeal} handler={handlePass} />
            </DealModal>
          );
          return;
        case 'MISSED':
          onOpenModal(
            <DealModal title={`Mark the Deal “${company.name}” as Missed?`}>
              <DealMissedModalContent deal={activeDeal} handler={handleMissed} />
            </DealModal>
          );
          return;
        case 'DELETE':
          setShowConfirmDelete(true);
          return;
        case 'MOVE_TO_NEXT_STAGE':
          handleMoveToNextStage();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [activeDeal, company, handleClose, onOpenModal, handlePass, handleMissed, handleMoveToNextStage]
  );

  if (!company || !activeDeal) return null;

  return (
    <>
      <StyledTooltip title='Deal Actions' placement='top-start'>
        <IconButton
          onClick={(e) => {
            handleClick(e);
          }}
          color={'secondary'}
          size={'small'}
        >
          <MoreVertIcon />
        </IconButton>
      </StyledTooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        PaperProps={{
          style: {
            marginTop: '4px',
            width: '13rem',
            background: '#fff',
            boxShadow:
              '0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06), 0px 24px 48px rgba(75, 82, 93, 0.04)',
            borderRadius: '4px',
          },
        }}
      >
        <div>
          <Typography
            variant='caption'
            color={colors.neutral[50]}
            sx={{ display: 'block', padding: '0.5rem 1rem 0' }}
          >
            Deal actions
          </Typography>
          {options.map(({ id, value, icon, disabled, hasDivider }) => (
            <div key={id}>
              {hasDivider && <Divider variant='middle' />}
              <MenuItem key={id} onClick={(e) => handleSelectOption(e, id)} style={{ height: '2.5rem' }}>
                <MenuItemWrapper>
                  {icon}
                  <Typography variant='body2' color={disabled ? colors.neutral[40] : colors.primary[100]}>
                    {value}
                  </Typography>
                </MenuItemWrapper>
              </MenuItem>
            </div>
          ))}
        </div>
      </Menu>
      <ConfirmDeleteDeal
        deal={activeDeal}
        company={company}
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};
