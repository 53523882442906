import { Stack } from '@mui/material';
import { GridApi } from 'ag-grid-community';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AgTable } from '../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { filterKPIRequestsByStatuses } from '../../../../services/state/KPI/KPIRequestsState';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { ExportGridButton } from '../../../../components/AgTable/ExportGridButton';
import useKPIRequestsSentColDefs from './hooks/useKPIRequestsSentColDefs';
import { ExportButtonContainer } from './components/KpiStyleDefs';

export function SentKpiRequestsGrid({
  status,
}: {
  status: typeof KPIRequestStatus.Sent | typeof KPIRequestStatus.Archived;
}) {
  const requests = useRecoilValue(filterKPIRequestsByStatuses([status])) ?? null;
  const { columnDefs, defaultColDef } = useKPIRequestsSentColDefs(status);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api);
  };

  const { exportParams } = useExtendedExportSettings(
    new Set(['companyId', 'templateUuid']),
    `${status}-kpi-requests`
  );

  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);

  return (
    <Stack height={'100%'}>
      <div css={ExportButtonContainer}>
        <ExportGridButton onClick={handleExport} />
      </div>
      <FadeInGridWrapper key={status}>
        <AgTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDefaultExpanded={-1}
          onGridReady={onGridReady}
          rowData={requests}
          rowGroupPanelShow='always'
          suppressMovableColumns={true}
          suppressRowDrag={true}
          defaultExcelExportParams={exportParams}
          defaultCsvExportParams={exportParams}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
