import { ICellRendererParams } from 'ag-grid-community';
import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { IMetricsDataModel } from '../../../../../../../data-models/metrics.data-model';
import { useGetCompanyIfSet } from '../../../../../../CompanyProfiles/hooks/useGetCompanyData';
import { MUIAvatar } from '../../../../../../../components/Avatar/MUIAvatar';
import { toURLFriendlyStringWithDashes } from '../../../../../../../util/url-utils';
import { AcquirerRenderer } from '../../TypeBasedConfigs/TypeCellRenderers/AcquirerRenderer';

const NameCell = styled('div')`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;

  & a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary[60]};
  }
`;

interface NameRendererParams extends ICellRendererParams<IMetricsDataModel> {}
export const NameRenderer: React.FC<ICellRendererParams<IMetricsDataModel>> = memo(function NameRenderer({
  node,
  data,
  value,
  valueFormatted,
  context,
  api,
}: NameRendererParams) {
  const getCompany = useGetCompanyIfSet();
  const company = getCompany(node.data?.companyId ?? -1);
  const logoUrl = company?.logoUrl;
  const { name = '', id = '' } = company ?? {};
  const linkToProfile = `/company-profiles/${toURLFriendlyStringWithDashes(name)}-${id}`;

  if (!api) return <></>;
  // don't show total if grouping, we handle that in SharedTable
  if (node.footer && context.totalCompanies != null && !(api.getRowGroupColumns().length > 0))
    return <>{`Total (${context.totalCompanies})`}</>;

  if (!data) return <span>{valueFormatted ?? value}</span>;
  if (!company) return null;
  const { acquisitionShares, exitDate, exitValuation, fmv } = data;

  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <NameCell>
        <a target='_blank' rel='noreferrer' href={linkToProfile}>
          <MUIAvatar style={{ minWidth: '24px' }} src={logoUrl ?? ''} nameParts={company.name.split(' ')} />
          <span>{name ?? value}</span>
        </a>
      </NameCell>
      {company.acquirer && (
        <AcquirerRenderer
          acquisitionShares={acquisitionShares}
          fmv={fmv}
          exitDate={exitDate}
          exitValuation={exitValuation}
          acquirer={company.acquirer}
        />
      )}
    </Stack>
  );
});
