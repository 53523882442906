import { capitalize, merge } from 'lodash-es';
import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { ICompanySearchMeta } from '../data-models/field3.data-model';
import {
  createMultiplierFormatterDataModel,
  createUSDFormatterDataModel,
} from '../data-models/formatter.data-model';
import { ExitType } from '../data-models/transaction.data-model';
import { SearchType } from '../services/queries/MaggieCompanyQueries';
import { usersAtom } from '../services/state/AppConfigStateJ';
import { getForesightStore } from '../util/jotai-store';
import { IFormField } from '../view-models/form.view-model';
import { CompanySearchResponse } from './CompanySearchResponse.schema';
import { PreferredShareClassSchema } from './PreferredShareClass.schema';

export enum ViewModelInvestmentType {
  common = 'common',
  notes = 'notes',
  options = 'options',
  preferred = 'preferred',
  warrants = 'warrants',
}

export const DefaultShareClassSchema = PreferredShareClassSchema;

export const shareClassNameField = () => yup.string().nullable().required().label('Name').default('');

export const investmentTypeField = () =>
  yup
    .string()
    .oneOf(Object.values(ViewModelInvestmentType))
    .nullable()
    .required()
    .label('Type')
    .default(ViewModelInvestmentType.common)
    .meta({
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: Object.values(ViewModelInvestmentType).map((investmentType) => ({
          value: investmentType,
          displayName: capitalize(investmentType),
        })),
      },
      disableClearable: true,
    });

export function seniorityField() {
  return integerField().nullable().required().label('Seniority').default(1).meta({
    renderer: RendererType.number,
    formatter: 'integer',
    description: '1 is the highest seniority',
  });
}

export function investmentDateField() {
  return dateField().nullable().label('Investment Date').default(null);
}

export function dateField(minimum?: string, maximum?: string) {
  return yup.date().fieldMeta({
    renderer: RendererType.date,
    rendererMeta: {
      minimum,
      maximum,
    },
  });
}

export function integerField() {
  return yup.number().meta({
    formatter: 'integer',
    renderer: RendererType.number,
  });
}

export function multiplierField(maxDecimals = 2, minDecimals = maxDecimals) {
  return yup.number().meta({
    formatter: createMultiplierFormatterDataModel(maxDecimals, minDecimals),
    renderer: RendererType.number,
  });
}

export function usdField(maxDecimals = 2, minDecimals = maxDecimals) {
  return yup.number().meta({
    formatter: createUSDFormatterDataModel(maxDecimals, minDecimals),
    renderer: RendererType.number,
  });
}

export function ppsField10dp() {
  return usdField(10, 0);
}

export function pricePerShareField() {
  return usdField(6);
}

export function usdShortField() {
  return yup.number().meta({
    formatter: 'usdShort',
    renderer: RendererType.number,
  });
}

export function percentField() {
  return yup.number().meta({
    formatter: 'percent2dpAsIs',
    renderer: RendererType.number,
  });
}

export function percentField0to100() {
  return percentField().min(0).max(100);
}

export function percentFieldHighPrecision() {
  return yup.number().meta({
    formatter: 'percentHighPrecision',
    renderer: RendererType.number,
  });
}

export function percentFieldHighPrecision0to100() {
  return percentFieldHighPrecision().min(0).max(100);
}

export function sharesField() {
  return yup.number().meta({
    formatter: 'integer',
    renderer: RendererType.number,
  });
}

export function currencyIdField() {
  return yup.number().label('Currency').fieldMeta({
    renderer: RendererType.currencyId,
  });
}

export function fundTypeField() {
  return yup.string().label('Type').meta({
    format: RendererType.fundType,
  });
}

export function userField(multi = false) {
  return yup.array(yup.number()).meta({
    renderer: multi ? RendererType.multiSelect : RendererType.singleSelect,
    formatter: 'user',
  });
}

export function exitTypeField() {
  return yup.string().customMeta({
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(ExitType).map((type) => ({ displayName: type, value: type })),
    },
  });
}

export function conversionRatioField() {
  return multiplierField(8, 0).nullable().default(1);
}

export function companyNameSearchField(formMeta: Partial<IFormField<ICompanySearchMeta>> = {}) {
  const meta = merge(
    {},
    {
      renderer: RendererType.companySearch,
      rendererMeta: {
        createOnSelection: false,
        multiSelect: false,
        showAdd: false,
        searchType: SearchType.Company,
      },
    },
    formMeta
  );
  return yup
    .string()
    .nullable()
    .formMeta(meta)
    .transform((value?: CompanySearchResponse | string | null) => {
      const fields = (value as CompanySearchResponse)?.fields;
      return fields?.name ? fields?.name : value;
    });
}

export function userIdField(isMultiSelect: boolean = false) {
  const store = getForesightStore();
  const users = store.get(usersAtom);

  return yup.string().fieldMeta({
    renderer: isMultiSelect ? RendererType.multiSelect : RendererType.singleSelect,
    rendererMeta: {
      values: users.map(({ id, firstName, lastName }) => ({
        value: id,
        displayName: `${firstName} ${lastName}`,
      })),
      multi: isMultiSelect,
    },
  });
}
