import { FC, MouseEvent, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, useTheme, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import { DealPassModalContent } from '../DealModal/DealPassModalContent';
import { DealModal } from '../DealModal/DealModal';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { DealMissedModalContent } from '../DealModal/DealMissedModalContent';
import { useDealActions2 } from '../../hooks/useDealActions';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { ConfirmDeleteDeal } from '../DealModal/ConfirmDeleteDeal';

interface Props {
  deal: IDealDataModel;
  company: ICompanyDataModel;
  style?: React.CSSProperties;
}

const ItemWrapper = styled('div')``;
const Wrapper = styled('div')``;

export const CardMenu: FC<Props> = ({ deal, style, company }) => {
  const { handleClose, handleDelete } = useDealActions2();
  const { colors } = useTheme();
  const { onOpenModal } = useModalState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const onCancelDelete = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    setShowConfirmDelete(false);
  };

  const onConfirmDelete = useCallback(
    (e?: MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      setShowConfirmDelete(false);
      handleDelete(deal);
    },
    [deal, handleDelete]
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: string) => {
      const companyName = `“${company.name}”`;

      e.stopPropagation();
      handleCloseMenu();
      switch (id) {
        case 'CLOSE':
          handleClose(deal);
          return;
        case 'PASS':
          onOpenModal(
            <DealModal title={`Move the Deal ${companyName} to Pass?`}>
              <DealPassModalContent deal={deal} />
            </DealModal>
          );
          return;
        case 'MISS':
          onOpenModal(
            <DealModal title={`Mark the Deal ${companyName} as Missed?`}>
              <DealMissedModalContent deal={deal} />
            </DealModal>
          );
          return;
        case 'DELETE':
          setShowConfirmDelete(true);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [company, handleClose, deal, onOpenModal]
  );

  const options = [
    {
      id: 'CLOSE',
      value: 'Close',
      icon: <CheckCircleOutlineIcon />,
    },
    {
      id: 'PASS',
      value: 'Pass',
      icon: <ThumbDownAltOutlinedIcon fontSize='small' />,
    },
    {
      id: 'MISS',
      value: 'Missed',
      icon: <CallMissedOutgoingIcon fontSize='small' />,
    },
    {
      id: 'DELETE',
      value: 'Delete',
      icon: <DeleteOutlineIcon fontSize='small' />,
      hasSeparator: true,
    },
  ];

  return (
    <Wrapper>
      <IconButton
        size='small'
        style={{ ...style }}
        data-testid='menu-btn'
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        {options.map(({ id, hasSeparator, icon, value }) => (
          <ItemWrapper key={id}>
            {hasSeparator && <Divider />}
            <MenuItem onClick={(e) => handleSelectOption(e, id)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <Typography variant='body2' color={colors.primary[100]}>
                {value}
              </Typography>
            </MenuItem>
          </ItemWrapper>
        ))}
      </Menu>
      <ConfirmDeleteDeal
        open={showConfirmDelete}
        onClose={onCancelDelete}
        onConfirm={onConfirmDelete}
        deal={deal}
        company={company}
      />
    </Wrapper>
  );
};
