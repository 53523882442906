import { createCompanySearchResultFields } from '../../../data-models/company-search-result.data-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import {
  CompanySearchResponse,
  createCompanySearchResponse,
} from '../../../schemas/CompanySearchResponse.schema';
import {
  BaseLocationDataModel,
  CompanyLocationDataModel,
  LocationSource,
} from '../../../schemas/Location.schema';
import { FMT } from '../../../util/formatter-service';
import { NumericFormattersConfig } from '../../../util/formatters/NumericFormatters';
import { ICompanyViewModel } from '../../../view-models/company.view-model';

export interface IDigestPanelViewModel extends Partial<ICompanyDataModel> {
  _viewModel: {
    ceoName: string[];
    lastFunding?: string;
    primaryLocation: BaseLocationDataModel | null;
    raisedToDate?: number | null;
    secondaryLocations: BaseLocationDataModel[] | null;
    sourceCompany?: CompanySearchResponse | null;
  };
}

export function fromCompanyDataModel(company: Partial<ICompanyDataModel>): IDigestPanelViewModel {
  const { primaryLocation } = company;
  return {
    ...company,
    _viewModel: {
      ceoName: company.ceoName ? company.ceoName.split(',').map((name) => name.trim()) : [],
      lastFunding: formatRoundData(company),
      primaryLocation: { ...primaryLocation },
      raisedToDate: company.companyType === 'portfolio' ? company.raisedToDate : company.totalPIC,
      secondaryLocations:
        company.secondaryLocations?.map((location) => ({ ...location, companyId: company.id })) ?? [],
      sourceCompany: createCompanySearchResponse({
        fields: createCompanySearchResultFields({
          name: company.sourceCompany ?? undefined,
        }),
      }),
    },
  };
}

export function toCompanyDataModel(
  companyViewModel: Partial<IDigestPanelViewModel | ICompanyViewModel>
): Partial<ICompanyDataModel> {
  const { _viewModel, ...rest } = companyViewModel;
  const payload: Partial<ICompanyDataModel> = rest;

  if (_viewModel?.ceoName) {
    payload.ceoName = _viewModel?.ceoName.join(', ');
  }

  if (_viewModel?.primaryLocation) {
    if (!areLocationsEqual(_viewModel.primaryLocation, companyViewModel.primaryLocation)) {
      payload.primaryLocation = { ..._viewModel.primaryLocation, source: LocationSource.User };
    } else {
      payload.primaryLocation = companyViewModel.primaryLocation;
    }
  }

  if (_viewModel?.secondaryLocations != null) {
    payload.secondaryLocations = _viewModel.secondaryLocations.map(
      (location) =>
        ({
          ...location,
          source: LocationSource.User,
          companyId: rest.id,
        }) as CompanyLocationDataModel
    );
  }

  if (_viewModel?.sourceCompany) {
    payload.sourceCompany = (_viewModel.sourceCompany as CompanySearchResponse)?.fields?.name;
  } else {
    payload.sourceCompany = null;
  }

  return payload;
}

function formatRoundData(company: Partial<ICompanyDataModel>) {
  return `${company.latestFundingRound ? `${company.latestFundingRound} - ` : ''}${
    company.latestFundingAmount != null
      ? FMT.format(NumericFormattersConfig.usdShort, company.latestFundingAmount)
      : ''
  }`;
}

function areLocationsEqual(a: BaseLocationDataModel, b?: BaseLocationDataModel | null) {
  return a.city === b?.city && a.country === b?.country && a.state === b?.state;
}
