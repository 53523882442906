import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { CommentCreation } from '../../../../components/Comment/CommentCreation';
import { CommentList } from '../../../../components/Comment/CommentsList2';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { useCreateComment } from '../../hooks/useCreateComment';
import { useDeleteComment } from '../../hooks/useDeleteComment';
import { useUpdateComment } from '../../hooks/useUpdateComment';

interface IDealCommentsProps {
  deal: IDealDataModel;
}
export function DealComments({ deal }: IDealCommentsProps) {
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);
  const { createDealComment, isLoading: isLoadingUpdate } = useCreateComment();
  const deleteComment = useDeleteComment();
  const { updateDealComment, loadingId } = useUpdateComment();
  const commentsWithLoadingStatus = useMemo(
    () => deal?.comments.map((c) => ({ ...c, isLoading: loadingId === c.id })),
    [loadingId, deal?.comments]
  );

  const handleCreate = (comment: string) => {
    if (!deal) return;
    const commentPayload = { dealId: deal.id, comment };
    createDealComment(commentPayload);
  };

  const handleDelete = useCallback(
    (commentId: number) => {
      if (!deal) return;
      deleteComment({ commentId, dealId: deal.id });
    },
    [deleteComment, deal]
  );

  const handleUpdate = useCallback(
    async (comment: string, commentId: number) => {
      if (!deal) return;

      updateDealComment({
        comment,
        commentId,
        dealId: deal.id,
      });
    },
    [deal, updateDealComment]
  );

  return (
    <div>
      {canEditDeal && (
        <Box mb='0.5rem'>
          <CommentCreation onCreate={handleCreate} isLoading={isLoadingUpdate} />
        </Box>
      )}
      <CommentList
        comments={commentsWithLoadingStatus ?? []}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
      />
    </div>
  );
}
