import { styled } from '@mui/material/styles';
import { useTheme, Typography, Fab, ButtonBase } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { css } from '@emotion/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { atom, useSetAtom } from 'jotai';
import { DealModal } from '../../../pages/DealFlow2/components/DealModal/DealModal';
import { useModalState } from '../../Modal/ModalContext';
import { DealProvider } from '../../../pages/DealFlow2/providers/DealProvider';
import { DealAddModalContent } from '../../../pages/DealFlow2/components/DealModal/DealAddModalContent/DealAddModalContent';
import { GenericFallbacksWrapper } from '../../Fallback/GenericFallbacksWrapper';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { HeaderButton } from '../../../constants/styles';

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.3rem;
`;

const InnerWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #ac8eff 0%, #51b7d8 100%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  padding: 0.3rem;
  cursor: pointer;
`;

const FabContainer = css`
  border-radius: 50%;
`;

export const isDealFormOpenState = atom(false);

export const AddDealButton = () => {
  const { onOpenModal } = useModalState();
  const { secondary, primary } = useTheme().colors;
  const { showElasticSearchResults, showDealForm2 } = useFlags<MaggieFeatureFlags>();
  const openDealForm2 = useSetAtom(isDealFormOpenState);

  const onCreate = () => {
    onOpenModal(
      <GenericFallbacksWrapper>
        <DealModal title={`Add New Deal`} width='814px'>
          <DealProvider>
            <DealAddModalContent />
          </DealProvider>
        </DealModal>
      </GenericFallbacksWrapper>
    );
  };
  return (
    <Wrapper>
      {showElasticSearchResults ? (
        <ButtonBase component='span' css={FabContainer}>
          <Fab
            css={HeaderButton}
            onClick={showDealForm2 ? () => openDealForm2(true) : onCreate}
            size='small'
            aria-label='add deal'
            title='add deal'
            color='primary'
          >
            <AddIcon fontSize='small' htmlColor={primary[0]} />
          </Fab>
        </ButtonBase>
      ) : (
        <>
          <InnerWrapper onClick={showDealForm2 ? () => openDealForm2(true) : onCreate}>
            <AddIcon />
          </InnerWrapper>
          <Typography variant={'caption2'} color={secondary['10']}>
            Add
          </Typography>
        </>
      )}
    </Wrapper>
  );
};
