import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class TotalInvestmentConfig implements KpiConfig {
  private id = KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT as const;
  private title = 'Total Investment';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const total =
      data?.metrics.reduce((sum, curr) => {
        return sum + (curr.amountInvested ?? 0);
      }, 0) ?? 0;

    return {
      ...this.getMeta(),
      value: total,
      formatted: format(total, this.type),
    };
  }
}
