import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, Stack } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { Fragment, useCallback, useMemo } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FormContainer } from '../../../components/Form/FormComponents';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { FundViewModel, fundViewModelSchema, TimeWeightedType } from '../../../schemas/FundViewModel2.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { fundFormCurrentStepState, fundFormState } from '../state/FPState';
import { IFundFormProps } from './FundWaterfallForm2';

export function WaterfallSettingsFormStep1({
  defaultValues,
  onCancel,
}: Pick<IFundFormProps, 'defaultValues' | 'onCancel'>) {
  const [formData, setFormData] = useAtom(fundFormState);
  const setCurrentStep = useSetAtom(fundFormCurrentStepState);

  const methods = useForm<FundViewModel>({
    defaultValues: (formData ?? defaultValues) as FundViewModel,
    // we can use same schema for steps 1 and 2 as long as no fields are required in step 2
    resolver: yupResolver(fundViewModelSchema() as ObjectSchema<FundViewModel>),
  });

  const handleNext = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      setFormData((curr) => ({ ...curr, ...methods.getValues() }));
      setCurrentStep((curr) => curr + 1);
      return true;
    } else {
      return false;
    }
  }, [methods, setFormData, setCurrentStep]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <WaterfallFormStep1Fields />
      </FormContainer>
      <StepperFormButtons
        stepIsValid={handleNext}
        incrementOnNext={false}
        handleGoBack={onCancel}
        backButtonLabel='Cancel'
      />
    </FormProvider>
  );
}

function WaterfallFormStep1Fields() {
  const tierToggles = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), [
      '_viewModel.tier1',
      'isSecondaryReturnEnabled',
      'enableGPCatchup',
      'enableSuperReturn',
    ]);
  }, []);
  const tier1ThresholdFields = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['lpGpSplit', 'lpGpSplitThreshold']);
  }, []);
  const tier1HurdleFields = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['preferredReturnHurdle']);
  }, []);
  const tier1bFields = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['secondaryReturnSplit', 'secondaryReturnHurdle']);
  }, []);
  const [tier2Field] = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['gpCatchUpPercentage']);
  }, []);
  const [tier3Field] = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['superReturnSplit']);
  }, []);

  const { control } = useFormContext<FundViewModel>();
  const [tier1, tier1b, tier2, tier3] = useWatch({
    name: ['_viewModel.tier1', 'isSecondaryReturnEnabled', 'enableGPCatchup', 'enableSuperReturn'],
    control,
  });

  return (
    <>
      {tierToggles.map((field) => {
        const openTiers1bThru3 =
          field.key === 'isSecondaryReturnEnabled'
            ? !!tier1b
            : field.key === 'enableGPCatchup'
              ? !!tier2
              : !!tier3;
        return (
          <Fragment key={field.key}>
            <FormFieldWithLabelFactory key={field.key} formField={field} />
            {field.key === '_viewModel.tier1' &&
              tier1 === TimeWeightedType.threshold &&
              tier1ThresholdFields.map((field) => {
                return <FormFieldWithLabelFactory key={field.key} formField={field} />;
              })}
            {field.key === '_viewModel.tier1' &&
              tier1 === TimeWeightedType.hurdle &&
              tier1HurdleFields.map((field) => {
                return <FormFieldWithLabelFactory key={field.key} formField={field} />;
              })}
            <Collapse in={openTiers1bThru3}>
              <Stack gap='0.5rem' mt='-0.75rem'>
                {field.key === 'isSecondaryReturnEnabled' &&
                  tier1bFields.map((f) => <FormFieldWithLabelFactory key={f.key} formField={f} />)}
                {field.key === 'enableGPCatchup' && (
                  <FormFieldWithLabelFactory key={field.key} formField={tier2Field} />
                )}
                {field.key === 'enableSuperReturn' && (
                  <FormFieldWithLabelFactory key={field.key} formField={tier3Field} />
                )}
              </Stack>
            </Collapse>
          </Fragment>
        );
      })}
    </>
  );
}
