import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { merge } from 'lodash-es';
import { styled, Typography, TypographyProps } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { CSSProperties, memo } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { IMUIAvatarProps, MUIAvatar } from '../Avatar/MUIAvatar';
import { companyState } from '../../services/state/CompanyState';
import { isGroupColumn } from '../AgTable/cell-renderers/GenericIdRenderer';
import { toURLFriendlyStringWithDashes } from '../../util/url-utils';
import { CompanyType, createCompanyDataModel, ICompanyDataModel } from '../../data-models/company.data-model';
import { portCosByNameMapState } from '../../services/state/PortCosState';

export interface ICompanyIdCellRendererParams<T> extends CustomCellRendererProps<T, number> {
  linkToProfile?: boolean;
  displayLogoInGroupRow?: boolean;
}

export function CompanyIdCellRenderer<T extends { companyId: number }>(
  params: ICompanyIdCellRendererParams<T>
) {
  const { node, data, value, linkToProfile = true } = params;
  const { companyId } = data ?? {};
  const portcosByName = useRecoilValue(portCosByNameMapState);
  let company = useRecoilValueLoadable(companyState(companyId ?? value ?? -1)).valueMaybe();
  if (!company) {
    company = portcosByName.get(node.key ?? '') ?? null;
  }

  let href = '';
  let typographyProps;
  if (company && linkToProfile) {
    href = `/company-profiles/${toURLFriendlyStringWithDashes(company.name)}-${company.id}`;
    typographyProps = {
      component: 'a',
      color: 'secondary',
      href,
      target: '_blank',
      rel: 'noreferrer',
    } as TypographyProps;
  }

  if (!params.displayLogoInGroupRow && isGroupColumn(params) && node.group) {
    return <>{node.key}</>;
  }
  if ((!isGroupColumn(params) && node.group) || !company) {
    return <></>;
  }
  return (
    <CompanyLogoAndName
      name={company.name}
      logoUrl={company.logoUrl ?? ''}
      typographyProps={typographyProps}
    />
  );
}

interface FundCompanyModel extends Partial<ICompanyDataModel> {
  name: string;
}
interface IFundCompanyCellRendererProps<T extends { company: FundCompanyModel }>
  extends CustomCellRendererProps<T> {
  displayLogoInGroupRow?: boolean;
}
export function FundCompanyCellRenderer<T extends { company: FundCompanyModel }>(
  params: IFundCompanyCellRendererProps<T>
) {
  const { data, node, value, displayLogoInGroupRow } = params;
  const portcosByName = useRecoilValue(portCosByNameMapState);
  let company = data?.company;
  const name = value || node.key || 'Unknown Company';
  if (!company)
    company =
      portcosByName.get(node.key ?? '') ??
      createCompanyDataModel({
        id: -1,
        name,
      });

  if (!displayLogoInGroupRow && isGroupColumn(params)) {
    return <>{name}</>;
  }

  if (!isGroupColumn(params) && node.group) {
    return <></>;
  }

  return (
    <CompanyLogoAndName
      name={company.name}
      logoUrl={company.logoUrl ?? ''}
      className={company.companyType === CompanyType.portfolio ? undefined : 'hypothetical'}
    />
  );
}

const CellContainer = styled('div')`
  align-items: center;
  column-gap: 0.75rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  height: 100%;
`;

export interface ICompanyCellValue {
  name: string;
  logoUrl: string;
  role?: string;
  avatarProps?: Partial<IMUIAvatarProps>;
  typographyProps?: TypographyProps;
  style?: CSSProperties;
  url?: string;
  className?: string;
}

export const CompanyCellRenderer = memo(function CompanyCellRenderer(
  props: ICellRendererParams<unknown, ICompanyDataModel> &
    Pick<ICompanyIdCellRendererParams<ICompanyDataModel>, 'linkToProfile'>
) {
  const company = props.value;
  if (!company) return null;

  if (isGroupColumn(props)) {
    return <>{company.name}</>;
  }
  let href = '';
  let typographyProps;
  if (props.linkToProfile) {
    href = `/company-profiles/${toURLFriendlyStringWithDashes(company.name)}-${company.id}`;
    typographyProps = {
      component: 'a',
      color: 'secondary',
      href,
      target: '_blank',
      rel: 'noreferrer',
    } as TypographyProps;
  }

  const { name = '', logoUrl = '' } = company;

  return <CompanyLogoAndName name={name} logoUrl={logoUrl ?? ''} typographyProps={typographyProps} />;
});

export function CompanyLogoAndName({
  name,
  logoUrl,
  role,
  avatarProps,
  typographyProps = {},
  style,
  url,
  className,
}: ICompanyCellValue) {
  let mergedProps = typographyProps;
  if (url) {
    mergedProps = merge(
      {
        component: 'a',
        color: 'secondary',
        href: url,
        target: '_blank',
        rel: 'noreferrer',
      },
      { ...typographyProps }
    ) as TypographyProps;
  }
  return (
    <CellContainer role={role} style={style} className={className}>
      <MUIAvatar nameParts={name?.split(' ')} src={logoUrl} size={'medium'} {...avatarProps} />
      <Typography
        variant={'body2'}
        color={'text.primary'}
        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        {...mergedProps}
      >
        {name}
      </Typography>
    </CellContainer>
  );
}
