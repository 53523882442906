import CircleIcon from '@mui/icons-material/Circle';
import { Tab, Tabs, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ROUTE_TITLE_MAPPING, ROUTES } from '../../../constants/RouteNameMapping';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../services/PermissionService';
import { checkCaptableExists } from '../../../services/queries/MaggieCapTableQueries';
import { companyState } from '../../../services/state/CompanyState';
import { colors } from '../../../theme/colors';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { useAsync } from '../../../util/hook-utils';

export interface ICompanyProfileTabsProps {
  companyId: number;
}

export function CompanyProfileTabs(props: ICompanyProfileTabsProps) {
  const { companyId } = props;
  const { pathname } = useLocation();
  const company = useRecoilValue(companyState(companyId));
  const { competitiveIntelligenceModule, showScenarioModelling, showCapTable } =
    useFlags<MaggieFeatureFlags>();
  const navigate = useNavigate();

  const tabSections = useMemo(() => {
    const permSvc = PermissionService.get();
    const sections = [];

    if (!permSvc.hasPermission(PermissionKey.canViewCompanyBasics)) {
      return [];
    }

    if (permSvc.hasPermission(PermissionKey.canViewMetrics)) {
      sections.push({
        title: 'Summary',
        path: ROUTES.COMPANY_PROFILE_SUMMARY,
      });
    }

    if (company?.companyType === 'portfolio' && permSvc.hasPermission(PermissionKey.canViewKpis)) {
      sections.push({
        title: 'Performance',
        path: ROUTES.COMPANY_PROFILE_PERFORMANCE,
      });
    }

    if (permSvc.hasPermission(PermissionKey.canViewCompanyFinancials)) {
      sections.push({
        title: ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_FINANCIALS],
        path: ROUTES.COMPANY_PROFILE_FINANCIALS,
      });
    }

    if (permSvc.hasPermission(PermissionKey.canViewMetrics)) {
      sections.push({ title: 'People', path: ROUTES.COMPANY_PROFILE_PEOPLE });
    }

    if (competitiveIntelligenceModule) {
      sections.push({ title: 'Competitive Intelligence', path: ROUTES.COMPANY_PROFILE_CI });
    }

    if (showScenarioModelling) {
      sections.push({
        title: ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_SCENARIOS],
        path: ROUTES.COMPANY_PROFILE_SCENARIOS,
      });
    }

    if (showCapTable && permSvc.hasPermission(PermissionKey.canViewTransaction)) {
      sections.push({
        title: ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_CAP_TABLE],
        path: ROUTES.COMPANY_PROFILE_CAP_TABLE,
      });
    }
    if (permSvc.hasPermission(PermissionKey.hasMappingsModule)) {
      sections.push({
        title: ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_MAPPINGS],
        path: ROUTES.COMPANY_PROFILE_MAPPINGS,
      });
    }
    return sections;
  }, [company?.companyType, competitiveIntelligenceModule, showScenarioModelling, showCapTable]);

  const getSelectedTabFromURL = useCallback(() => {
    const [_empty, _base, _companyName, section] = pathname.split('/');
    return section;
  }, [pathname]);

  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    const selectedTab = getSelectedTabFromURL();
    if (!selectedTab) {
      navigate(`./${tabSections[0].path}`, { replace: true });
    }
  }, [getSelectedTabFromURL, navigate, pathname, tabSections]);

  useEffect(() => {
    const selectedTab = getSelectedTabFromURL()!;
    setSelectedTab(selectedTab);
  }, [getSelectedTabFromURL, tabSections]);

  const onChangeTab = useCallback(
    (path: string) => {
      navigate(`./${path}`);
    },
    [navigate]
  );

  if (!selectedTab) {
    return null;
  }

  return (
    <Tabs
      variant='scrollable'
      scrollButtons='auto'
      value={selectedTab}
      onChange={(e, item) => onChangeTab(item)}
    >
      {tabSections.map((item) => (
        <Tab
          key={item.path}
          value={item.path}
          label={
            item.path == ROUTES.COMPANY_PROFILE_CAP_TABLE ? (
              <CaptableTabTitle companyId={companyId} />
            ) : (
              item.title
            )
          }
          className={`mui-custom-tab`}
          onClick={() => {
            if (item.path === ROUTES.COMPANY_PROFILE_CAP_TABLE) onChangeTab(item.path);
          }}
        />
      ))}
    </Tabs>
  );
}

function CaptableTabTitle({ companyId }: { companyId: number }) {
  const { data: captableExists } = useAsync(
    useCallback(() => checkCaptableExists(companyId), [companyId]),
    { id: String(companyId) }
  );

  let color;
  if (captableExists == undefined) color = 'transparent';
  else if (!captableExists) color = colors.critical[60];
  else color = colors.success[60];

  return (
    <Typography
      variant='body2'
      display={'grid'}
      gridTemplateColumns={'1fr 1rem'}
      alignItems={'center'}
      gap='0.2rem'
    >
      {ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_CAP_TABLE]}
      {<CircleIcon sx={{ fontSize: '0.8rem' }} htmlColor={color} />}
    </Typography>
  );
}
