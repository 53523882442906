import { css } from '@emotion/react';
import React from 'react';

const sectionTitleStyle = css`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #4a5568;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0;
  display: block;
  width: 100%;

  @media print {
    font-size: 0.65rem;
    margin-bottom: 0.15rem;
  }
`;

const textStyle = css`
  font-size: 0.9rem;
  line-height: 1.6;
  color: #4a5568;
  margin: 0;
  text-align: left;

  @media print {
    font-size: 0.65rem;
    line-height: 1.2;
  }
`;

const commentaryStyle = css`
  ${textStyle}
  white-space: pre-line;
  text-align: left;
`;

interface TextSectionProps {
  title: string;
  content: string;
  isCommentary?: boolean;
}

export const TextSection: React.FC<TextSectionProps> = ({ title, content, isCommentary = false }) => {
  return (
    <section>
      <h2 css={sectionTitleStyle}>{title}</h2>
      <div css={isCommentary ? commentaryStyle : textStyle}>{content}</div>
    </section>
  );
};
