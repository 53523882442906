import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { MONTHS } from '../pages/KPI/components/Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { SearchType } from '../services/queries/MaggieCompanyQueries';
import { CustomFormatterId } from '../util/formatter-service';
import { DateFormattersId } from '../util/formatters/DateFormatters';
import { SourceType } from '../data-models/list.data-model';
import { usdField, userIdField } from './common-schema-defs';
import { locationFields } from './Location.schema';

export const BoardStatus = {
  Director: 'Director',
  Observer: 'Observer',
  None: 'None',
} as const;

export type BoardStatus = (typeof BoardStatus)[keyof typeof BoardStatus];

export function companyFields() {
  return {
    acquirer: yup
      .string()
      .nullable()
      .label('Acquirer')
      .fieldMeta({
        renderer: RendererType.companySearch,
        rendererMeta: {
          multiSelect: false,
          showAdd: false,
          searchType: SearchType.Company,
          createOnSelection: false,
        },
      }),
    adjustHistoricKPIs: yup.boolean().nullable(),
    aliases: yup.array().label('Also Known As').nullable(),
    activeStatus: yup.string().nullable().optional().label('Status'),
    assignee: userIdField().label('Owner').nullable(),
    boardActiveStatus: yup.string().nullable().optional().label('Board Active Status'),
    boardDirectorId: yup.number().label('Board Director').nullable(),
    boardStatus: yup
      .string()
      .nullable()
      .optional()
      .label('Board Type')
      .customMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.values(BoardStatus).map((status) => ({
            value: status,
            displayName: status,
          })),
        },
      }),
    ceoName: yup.string().label('CEO(s)').nullable(),
    crunchbaseId: yup.string().nullable().label('Crunchbase ID'),
    description: yup.string().label('Long Description').nullable(),
    dealLeadId: yup.number().nullable().label('Deal Lead'),
    dealTeamIds: yup.array().of(yup.number()).nullable().label('Deal Team'),
    eventsRegion: yup.string().nullable().optional().label('Events Region'),
    floRegion: yup.string().nullable().optional().label('FLO Region'),
    founded: yup.number().label('Founded'),
    foundedDate: yup.string().nullable().label('Founded Year'),
    founders: yup.array().of(yup.string()).nullable(),
    founderName: yup.string().label('Founder(s)').nullable(),
    fye: yup
      .number()
      .nullable()
      .optional()
      .label('FYE')
      .meta({
        formatter: 'fye',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: MONTHS.map((month, index) => ({
            value: index + 1,
            displayName: month,
          })),
        },
      }),
    id: yup.number().nullable().optional(),
    impliedEquityValue: usdField().nullable().optional().label('Implied Equity Value'),
    internalDescription: yup.string().nullable(),
    internalSourceId: userIdField().label('Internal Source').nullable(),
    lastPostMoney: yup.number().label('Last Post Money Valuation').nullable(),
    latestFundingAmount: yup.number().label('Last Funding Amount').nullable(),
    latestFundingDate: yup.date().label('Last Funding Date').nullable(),
    latestLeadInvestor: yup.array(yup.string()).label('Latest Lead Investors').nullable(),
    latestFundingRound: yup.string().label('Last Funding Round').nullable(),
    legalName: yup.string().label('Legal Name').nullable(),
    name: yup.string().label('Name'),
    primaryLocation: yup.object(locationFields()).nullable().label('Primary Location'),
    region: yup.string().nullable().optional().label('Region'),
    respondents: yup.array().of(yup.string()).label('Respondent(s)').default([]),
    secondaryLocations: yup.array().of(yup.object(locationFields())).nullable().default([]),
    secondaryRespondents: yup.array().of(yup.string()).label('CC email').nullable().default([]),
    sectorId: yup
      .number()
      .nullable()
      .optional()
      .label('Sector')
      .fieldMeta({ formatter: CustomFormatterId.sector }),
    shortDescription: yup.string().label('Description').nullable(),
    socials: yup.string().label('Profiles').nullable(),
    source: yup.string().nullable(),
    sourceCompany: yup.string().nullable().label('Source Company'),
    sourceType: yup
      .string()
      .nullable()
      .formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.values(SourceType).map((value) => ({
            displayName: value,
            value,
          })),
        },
      }),
    website: yup.string().nullable(),
    customData: yup.object().nullable(),
  };
}

export function companySchema() {
  return yup.object(companyFields());
}
export type CompanyDataModel2 = yup.InferType<ReturnType<typeof companySchema>>;

export function companyGridSchema() {
  const base = companyFields();

  return yup.object({
    ...base,
    boardDirectorId: base.boardDirectorId.gridMeta({
      renderer: RendererType.id,
      formatter: 'user',
    }),
    dealLeadId: base.dealLeadId.gridMeta({ renderer: RendererType.id, formatter: 'user' }),
    foundedDate: base.foundedDate.label('Founded Year').gridMeta({
      renderer: RendererType.date,
      formatter: DateFormattersId.YYYY,
    }),
    id: base.id.label('Company').gridMeta({ renderer: RendererType.companyId }),
    sectorId: base.sectorId.gridMeta({ renderer: RendererType.id, formatter: 'sector' }),
  });
}
