import { useAtomValue } from 'jotai';
import { atomFamily as atomFamilyR, selectorFamily as selectorFamilyR, useRecoilState } from 'recoil';
import { IFundMetricsResponseDataModel } from '../../data-models/fund-metrics.data-model';
import { FundMetrics, FundMetricsByFund, fundMetricsFormSchema } from '../../schemas/FundMetrics.schema';
import { getFundMetrics, getPlainDateString } from '../../services/queries/MaggieMetricsQueries';
import { fieldDisplayValuesFromSchema } from '../../util/schema-utils';
import { forceFundMetricsUpdate } from '../PortfolioOverview/state/MetricsState';
import { selectedDateFPState, SelectedFPKey, selectedFundIdStateFP } from './state/FPState';

// depends on another recoil atom, `forceFundMetricsUpdate`, which is also used to refresh fundMetricsByDateState,
// so leaving it as recoil until we migrate that one to jotai
const selectedFundMetricsStateFP = atomFamilyR<IFundMetricsResponseDataModel | null, SelectedFPKey>({
  key: 'selectedFundMetricsStateFP',
  default: selectorFamilyR({
    key: 'selectedFundMetricsStateFP/default',
    get:
      ({ date, fundId }) =>
      ({ get }) => {
        if (!fundId) return null;
        get(forceFundMetricsUpdate);
        return getFundMetrics(getPlainDateString(date), [fundId]);
      },
  }),
});

export function useFundMetricsFP() {
  const date = useAtomValue(selectedDateFPState);
  const fundId = useAtomValue(selectedFundIdStateFP);

  const [fundMetrics, setSelectedFundMetrics] = useRecoilState(selectedFundMetricsStateFP({ date, fundId }));
  const metrics = fundMetrics?.metrics?.at(0);

  return {
    metrics: metrics as FundMetricsByFund | undefined,
    setSelectedFundMetrics: setSelectedFundMetrics,
  };
}

export function getFundMetricsAdditionalData(metrics: FundMetrics) {
  const fields = fundMetricsFormSchema();
  return fieldDisplayValuesFromSchema({
    schema: fields,
    values: metrics,
    paths: ['lpContributions', 'gpContributions', 'lpDistributions', 'gpDistributions'],
  });
}
