import { atomFamily, atomWithDefault, loadable } from 'jotai/utils';
import { fetchCompanyRaw } from '../queries/MaggieCompanyQueries';
import { ICompanyDataModel } from '../../data-models/company.data-model';

export const companyStateJ = atomFamily((companyId: number) =>
  atomWithDefault<ICompanyDataModel | Promise<ICompanyDataModel | null> | null>(() => {
    if (companyId >= 0) {
      return fetchCompanyRaw(companyId);
    } else {
      return null;
    }
  })
);

export const companyStateLoadable = atomFamily((companyId: number) => loadable(companyStateJ(companyId)));
