import { get, isEqual, set } from 'lodash-es';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import {
  KpiConfigPeriod,
  KpiPeriod,
  KpiPeriodExtended,
  KpiSection,
} from '../../../../../data-models/company-financials.data-model';
import { IField } from '../../../../../data-models/field2.data-model';
import { kpiConfigByIdMapState, kpiConfigState } from '../../../../../services/state/KPI/KPITemplatesState';
import { allCompanyFinancialsState } from '../../../state/CompanyFinancialsState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { getTypedFinancials, TypedFinancials } from '../../../utils/getTypedFinancials';
import { FinancialsDisplaySettings } from '../components/FinancialsSettingsState';
import {
  BulkDeleteKpiCustomDataAction,
  BulkEditKpiCustomDataAction,
  BulkUpdateKpiCustomDataAction,
  BulkUpdateKpiCustomDataActionData,
  IBulkUpdateKpiCustomDataPayload,
} from './useCustomKPIActions';
import { toPayloadValue } from './useFinancialsColumnDefs';
export type FinancialType = KpiSection | KpiPeriodExtended | 'growth';

export interface IFinancialsRowData2 extends IField<unknown> {
  valuesByDate2: Record<string, Record<string, Record<KpiSection, TypedFinancials>>>;
}

export function useFinancialsGridData() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const companyFinancials = useRecoilValue(allCompanyFinancialsState(companyId));
  const kpiConfigMap = useRecoilValue(kpiConfigByIdMapState);
  const typedFinancials = getTypedFinancials(companyFinancials, kpiConfigMap);
  const allKpis = useRecoilValue(kpiConfigState);

  return useCallback(
    (
      period: KpiPeriod,
      displaySettings = {} as Partial<FinancialsDisplaySettings>
    ): IFinancialsRowData2[] => {
      const filterFn = displaySettingsToFinancialsFilter(displaySettings, period);
      const dataMap2 = typedFinancials.filter(filterFn).reduce(
        (acc, item) => {
          return set(acc, [item.kpiId, item.date, item.period!, item.section!], item);
        },
        {} as Record<number, IFinancialsRowData2['valuesByDate2']>
      );

      return allKpis
        .filter((kpi) => {
          // If show reportedOnly check if there is no data for the KPI
          return !displaySettings.reportedOnly || kpi.id in dataMap2;
        })
        .map((kpi) => {
          return { ...kpi, valuesByDate2: dataMap2[kpi.id] };
        });
    },
    [allKpis, typedFinancials]
  );
}

export interface IKpiCustomDataActionsGetterParams {
  initialRowData: IFinancialsRowData2[];
  period: KpiPeriod;
  updatedGridRowData: IFinancialsRowData2[];
}
export function getBulkUpdateKpiCustomDataPayload({
  initialRowData: financialsRowData,
  period,
  updatedGridRowData,
}: IKpiCustomDataActionsGetterParams): IBulkUpdateKpiCustomDataPayload {
  const actions = new Map<BulkUpdateKpiCustomDataAction, BulkUpdateKpiCustomDataActionData[]>([
    [BulkUpdateKpiCustomDataAction.create, []],
    [BulkUpdateKpiCustomDataAction.update, []],
    [BulkUpdateKpiCustomDataAction.delete, []],
  ]);

  const oldDataMap = new Map(financialsRowData.map((kpi) => [kpi.id, kpi]));

  updatedGridRowData.forEach((kpi) => {
    const newValuesByDate = kpi.valuesByDate2;
    const oldValuesByDate = oldDataMap.get(kpi.id)?.valuesByDate2;
    Object.keys(newValuesByDate ?? {}).forEach((date) => {
      const newActualItem = get(newValuesByDate?.[date], [period, KpiSection.actual]);
      const newBudgetItem = get(newValuesByDate?.[date], [period, KpiSection.budget]);

      const oldActualItem = get(oldValuesByDate?.[date], [period, KpiSection.actual]);
      const oldBudgetItem = get(oldValuesByDate?.[date], [period, KpiSection.budget]);

      if (
        isEqual(newActualItem?.value, oldActualItem?.value) &&
        isEqual(newBudgetItem?.value, oldBudgetItem?.value)
      )
        return;

      if (newActualItem?.value == null && oldActualItem?.value && oldActualItem.id) {
        actions.get(BulkUpdateKpiCustomDataAction.delete)!.push({
          action: BulkUpdateKpiCustomDataAction.delete,
          kpi: {
            id: oldActualItem.id,
          },
        } as BulkDeleteKpiCustomDataAction);
      }
      if (newBudgetItem?.value == null && oldBudgetItem?.value && oldBudgetItem.id) {
        actions.get(BulkUpdateKpiCustomDataAction.delete)!.push({
          action: BulkUpdateKpiCustomDataAction.delete,
          kpi: {
            id: oldBudgetItem.id,
          },
        } as BulkDeleteKpiCustomDataAction);
      }

      if (newActualItem?.value != null && !isEqual(newActualItem?.value, oldActualItem?.value)) {
        if (oldActualItem?.id) {
          actions.get(BulkUpdateKpiCustomDataAction.update)!.push({
            action: BulkUpdateKpiCustomDataAction.update,
            kpi: {
              ...newActualItem,
              id: oldActualItem.id,
              value: toPayloadValue(newActualItem.value),
            },
          } as BulkEditKpiCustomDataAction);
        } else {
          actions.get(BulkUpdateKpiCustomDataAction.create)!.push({
            action: BulkUpdateKpiCustomDataAction.create,
            kpi: {
              ...newActualItem,
              value: toPayloadValue(newActualItem.value),
            },
          });
        }
      }

      if (newBudgetItem?.value != null && !isEqual(newBudgetItem?.value, oldBudgetItem?.value)) {
        if (oldBudgetItem?.id) {
          actions.get(BulkUpdateKpiCustomDataAction.update)!.push({
            action: BulkUpdateKpiCustomDataAction.update,
            kpi: {
              ...newBudgetItem,
              id: oldBudgetItem.id,
              value: toPayloadValue(newBudgetItem.value),
            },
          });
        } else {
          actions.get(BulkUpdateKpiCustomDataAction.create)!.push({
            action: BulkUpdateKpiCustomDataAction.create,
            kpi: {
              ...newBudgetItem,
              value: toPayloadValue(newBudgetItem.value),
            },
          });
        }
      }
    });
  });

  return {
    data: Array.from(actions.values()).flat(),
  };
}

function displaySettingsToFinancialsFilter(
  displaySettings: Partial<FinancialsDisplaySettings>,
  selectedPeriod: KpiPeriod
): (item: TypedFinancials) => boolean {
  const includedPeriods = new Set<KpiPeriod | KpiPeriodExtended>([selectedPeriod]);
  if (displaySettings.showPinnedYears) {
    includedPeriods.add(KpiPeriod.year);
  }
  if (displaySettings.showYTD) {
    includedPeriods.add(KpiConfigPeriod.CYTD);
  }
  if (displaySettings.showLTM) {
    includedPeriods.add(KpiConfigPeriod.LTM);
  }
  if (displaySettings.showGrowth) {
    includedPeriods.add(`Growth${selectedPeriod}`);
    if (displaySettings.showPinnedYears) {
      includedPeriods.add(`Growth${KpiPeriod.year}`);
    }
  }

  const standardPeriods = new Set(Object.values(KpiPeriod));
  return function isFinancialIncluded(financials: TypedFinancials) {
    if (!financials.section || !financials.period) {
      return false;
    }
    if (!includedPeriods.has(financials.period)) {
      return false;
    }
    if (!displaySettings.showBudget && financials.section === KpiSection.budget) {
      return false;
    }
    if (!standardPeriods.has(financials.period as KpiPeriod) && financials.section !== KpiSection.actual) {
      return false;
    }

    return true;
  };
}
