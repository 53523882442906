import { css } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography, useTheme } from '@mui/material';
import { FormLoadingButton } from '../../../../../components/Form/FormComponents';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../../services/PermissionService';
import { FMT, FormatterService } from '../../../../../util/formatter-service';

const headerContainerCSS = css`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  grid-column-gap: 1rem;
`;

export interface IKPIReviewHeaderProps {
  company: ICompanyDataModel;
  isModified: boolean;
  isSubmitting: boolean;
  kpiRequest: IKPIRequestDataModel;
  onRejectResponse: () => void;
  onAcceptResponse: () => void;
  onMoveBackToReview: () => void | Promise<void>;
}

export function KPIReviewHeader(props: IKPIReviewHeaderProps) {
  const {
    company,
    isModified,
    isSubmitting,
    kpiRequest,
    onRejectResponse,
    onAcceptResponse,
    onMoveBackToReview,
  } = props;

  return (
    <div css={headerContainerCSS}>
      <RequestDetails
        companyName={company.name}
        companyLogoUrl={company.logoUrl ?? ''}
        respondent={kpiRequest.respondent}
        sentAt={kpiRequest.sentAt}
      />
      <div />
      <KPIReviewButtons
        isModified={isModified}
        isSubmitting={isSubmitting}
        onAcceptResponse={onAcceptResponse}
        onRejectResponse={onRejectResponse}
        kpiRequest={kpiRequest}
        onMoveBackToReview={onMoveBackToReview}
      />
    </div>
  );
}

interface IKPIRecipientViewFooterProps {
  isModified: boolean;
  isSubmitting: boolean;
  onRejectResponse: () => void;
  onAcceptResponse: () => void;
  onMoveBackToReview: () => void;
  kpiRequest: IKPIRequestDataModel;
}

export function KPIReviewButtons({
  isModified,
  isSubmitting,
  onRejectResponse,
  onAcceptResponse,
  kpiRequest,
  onMoveBackToReview,
}: IKPIRecipientViewFooterProps) {
  if (
    PermissionService.get().hasPermission(PermissionKey.canWriteDataCollection) &&
    (kpiRequest.status === KPIRequestStatus.Accepted || kpiRequest.status === KPIRequestStatus.Rejected)
  ) {
    return <MoveBackToReview onMoveBackToReview={onMoveBackToReview} isSubmitting={isSubmitting} />;
  }

  return (
    <Stack direction='row' gap='1rem' height={'min-content'}>
      <LoadingButton
        loading={isSubmitting}
        onClick={onRejectResponse}
        size='medium'
        variant='outlined'
        color='error'
      >
        Reject
      </LoadingButton>
      <LoadingButton
        loading={isSubmitting}
        onClick={onAcceptResponse}
        size='medium'
        variant='contained'
        color='secondary'
      >
        {isModified ? 'Save changes & Accept' : 'Accept'}
      </LoadingButton>
    </Stack>
  );
}

export function MoveBackToReview({
  onMoveBackToReview,
  isSubmitting,
}: {
  onMoveBackToReview: () => void | Promise<void>;
  isSubmitting: boolean;
}) {
  return (
    <FormLoadingButton loading={isSubmitting} onClick={onMoveBackToReview}>
      Move Back to Review
    </FormLoadingButton>
  );
}

interface IRequestDetailsProps extends Pick<IKPIRequestDataModel, 'respondent' | 'sentAt'> {
  companyName: string;
  companyLogoUrl?: string;
}
export function RequestDetails({ companyName, companyLogoUrl, respondent, sentAt }: IRequestDetailsProps) {
  const { colors } = useTheme();
  const respondentFormatter = FormatterService.get().getFormatterForId('userByEmail');

  return (
    <>
      <CompanyLogoAndName
        name={companyName}
        logoUrl={companyLogoUrl ?? ''}
        typographyProps={{ fontSize: '26px' }}
      />
      <Stack direction='column'>
        <Typography variant='body2' sx={{ color: colors.gray[700] }}>
          <b>From:</b> {respondentFormatter(respondent ?? [])}
        </Typography>
        <Typography variant='body2' sx={{ color: colors.gray[700] }}>
          <b>On:</b> {FMT.format('date', sentAt)}
        </Typography>
      </Stack>
    </>
  );
}
