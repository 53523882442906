import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { DealBoardDealCategory } from '../../../types';
import { selectedDealCategoryState, visibleDealsState } from '../state/DealboardUIState';
import { DealGrid } from './DealGrid/DealGrid';
import { GridHeader } from './DealGrid/GridHeader';

const Container = styled('section')`
  padding: 1rem 2rem 1rem 3rem;
`;

export const CategoryDealTable = () => {
  const selectedDealCategory = useAtomValue(selectedDealCategoryState);
  const filteredDeals = useAtomValue(visibleDealsState);

  let title = '';
  switch (selectedDealCategory.id) {
    case DealBoardDealCategory.PASS:
      title = 'Passed';
      break;
    case DealBoardDealCategory.TRACK:
      title = 'Tracked';
      break;
    case DealBoardDealCategory.CLOSED:
      title = 'Closed';
      break;
    case DealBoardDealCategory.MISSED:
      title = 'Missed';
  }

  return (
    <Container key={selectedDealCategory.id}>
      <GridHeader title={title} deals={filteredDeals} />
      <DealGrid deals={filteredDeals} />
    </Container>
  );
};
