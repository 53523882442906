import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { IDealConversionResponse } from '../../../data-models/deal-conversion.data-model';
import { IDealStatsDataModel } from '../../../data-models/deal-stats.data-model';
import { fetchDealConversion, fetchDealStatsHistory } from '../../../services/queries/MaggieDealQueries';
import { LoadingStatus, ValueOrError } from '../../../types';
import { cleanQueryParams } from './../utils/cleanQueryParams';
import { GroupByOptions } from './DealConversionFilterState';

export type IDealConversionStateKey = {
  to: string;
  from: string;
  sectors?: number[];
  dealLead?: number;
  dealType?: number;
  groupBy?: GroupByOptions;
};

export const DealConversionState = atomFamily(
  (keys: IDealConversionStateKey) =>
    atom(async (): Promise<ValueOrError<IDealConversionResponse, string>> => {
      try {
        const params = cleanQueryParams(keys);
        return { data: await fetchDealConversion(params), error: null, loading: LoadingStatus.success };
      } catch (err) {
        return {
          data: null,
          error: (err as Error).message ?? 'Failed to load deal conversion',
          loading: LoadingStatus.error,
        };
      }
    }),
  (a, b) => JSON.stringify(a) === JSON.stringify(b)
);

export const dealsStatsHistory = atomFamily(
  (keys: IDealConversionStateKey) =>
    atom(async (): Promise<ValueOrError<IDealStatsDataModel[], string>> => {
      try {
        const params = cleanQueryParams(keys);
        return { data: await fetchDealStatsHistory(params), error: null, loading: LoadingStatus.success };
      } catch (err) {
        return {
          data: null,
          error: (err as Error).message ?? 'Failed to load deal stats history',
          loading: LoadingStatus.error,
        };
      }
    }),
  (a, b) => JSON.stringify(a) === JSON.stringify(b)
);
