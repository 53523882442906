import { css } from '@emotion/react';
import { FieldItem } from '@foresightdata/tear-sheet';
import React from 'react';

const performanceGridStyle = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 1rem;

  @media print {
    gap: 0.15rem;
    margin-top: 0.15rem;
  }
`;

const performanceTileStyle = css`
  background: #f7fafc;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #e2e8f0;

  @media print {
    padding: 0.15rem;
  }
`;

const tileTitleStyle = css`
  font-size: 0.75rem;
  color: #718096;
  margin-bottom: 0.5rem;
  font-weight: 500;

  @media print {
    font-size: 0.55rem;
    margin-bottom: 0.1rem;
  }
`;

const tileValueStyle = css`
  font-size: 1rem;
  color: #2d3748;
  font-weight: 600;

  @media print {
    font-size: 0.65rem;
  }
`;

const sectionTitleStyle = css`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #4a5568;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0;
  display: block;
  width: 100%;

  @media print {
    font-size: 0.65rem;
    margin-bottom: 0.15rem;
  }
`;

interface PerformanceSummaryProps {
  title: string;
  data: FieldItem[];
}

export const PerformanceSummary: React.FC<PerformanceSummaryProps> = ({ title, data }) => {
  return (
    <section>
      <h2 css={sectionTitleStyle}>{title}</h2>
      <div css={performanceGridStyle}>
        {data.map(({ label, value }, index) => (
          <React.Fragment key={index}>
            <div css={performanceTileStyle}>
              <div css={tileTitleStyle}>${label}</div>
              <div css={tileValueStyle}>{value}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};
