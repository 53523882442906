import { merge } from 'lodash-es';
import { TearSheetViewModel } from '@foresightdata/tear-sheet';

export function createTearSheetViewModel(overrides: Partial<TearSheetViewModel> = {}): TearSheetViewModel {
  return merge(
    {
      clientName: '',
      clientLogoUrl: '',
      companyDescription: '',
      companyName: '',
      companySummary: {
        values: [],
      },
      date: '',
      financingHistory: [],
      fundName: '',
      investmentSummary: {
        values: [],
      },
      logoUrl: '',
      performanceCommentary: '',
      performanceSummary: {
        date: '',
        values: [],
      },
    } satisfies TearSheetViewModel,
    overrides
  );
}
