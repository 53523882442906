import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { useAtomValue } from 'jotai';
import { StepperFormFieldsContainer } from '../../../components/Form/FormComponents';
import { DealFormViewModel, useDealFormSchema } from '../../../schemas/deal.schema';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { schemaToFormFields } from '../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { field2ToField3, FieldEntity } from '../../../data-models/field2.data-model';
import { field3ToFormField } from '../../../data-models/field3.data-model';
import { customFieldsByEntityAtom } from '../../../services/state/AdminPanel/CustomFieldsStateJ';

interface IAddDealFormStepProps {
  defaultValues: Partial<DealFormViewModel>;
  handleGoBack: (data: Partial<DealFormViewModel>) => void;
  onSubmit: (data: DealFormViewModel) => Promise<DealFormViewModel | void>;
}
export function AddDealFormStep({ defaultValues, handleGoBack, onSubmit }: IAddDealFormStepProps) {
  const schema = useDealFormSchema().omit(['createdAt', 'createdBy']) as ObjectSchema<DealFormViewModel>;
  const methods = useForm<DealFormViewModel>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const _handleGoBack = useCallback(() => {
    handleGoBack(methods.getValues());
  }, [handleGoBack, methods]);

  const _onSubmit = useCallback(async () => {
    const data = await methods.handleSubmit(onSubmit)();
    return Boolean(data);
  }, [methods, onSubmit]);

  return (
    <FormProvider {...methods}>
      <StepperFormFieldsContainer>
        <DealFormFields schema={schema} />
      </StepperFormFieldsContainer>
      <StepperFormButtons stepIsValid={_onSubmit} nextButtonLabel={'Create'} handleGoBack={_handleGoBack} />
    </FormProvider>
  );
}

interface IDealFormFieldsProps {
  schema: ObjectSchema<DealFormViewModel>;
}
function DealFormFields({ schema }: IDealFormFieldsProps) {
  const formFields = schemaToFormFields(schema);
  const dealCustomFields = useAtomValue(customFieldsByEntityAtom).get(FieldEntity.deal);
  const form = createFormFromFieldsArray(
    formFields.concat((dealCustomFields ?? []).map((f) => field3ToFormField(field2ToField3(f))))
  );

  return <FormFactoryWithStandardLayout form={form} />;
}
