import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { set } from 'lodash-es';
import { useAtomCallback } from 'jotai/utils';
import { companyWebsiteField } from '../../../data-fields/CommonFields';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { field2ToFormField, FieldEntity, ISimpleChoice } from '../../../data-models/field2.data-model';
import { companyFields } from '../../../schemas/Company.schema';
import { useCreateCompanyAndUpdateState } from '../../../services/hooks/useCreateCompanyAndUpdateState';
import { useUpdateCompanyAndState } from '../../../services/hooks/useUpdateCompanyAndState';
import { fetchCompaniesByWebsite, SearchType } from '../../../services/queries/MaggieCompanyQueries';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { sectorsAtom, usersAtom } from '../../../services/state/AppConfigStateJ';
import { getForesightStore } from '../../../util/jotai-store';
import { VALID_URL_REGEX } from '../../../util/regex';
import { schemaToFormFields } from '../../../util/schema-utils';
import { ICompanyViewModel } from '../../../view-models/company.view-model';
import { IFormFieldSelectMeta } from '../../../view-models/form.view-model';
import { companyStateJ } from '../../../services/state/CompanyStateJ';

export function useCreateOrUpdateCompanyHandler() {
  const createCompany = useCreateCompanyAndUpdateState();
  const updateCompany = useUpdateCompanyAndState();

  return useAtomCallback(
    useCallback(
      async (get, set, data: Partial<ICompanyDataModel>) => {
        const modifiedCustomData = removeEmptyCustomData(data.customData ?? {});
        const payload = { ...pickFormFields(data), id: data.id, customData: modifiedCustomData };
        let company;
        if (!payload.id) {
          company = await createCompany(payload);
        } else {
          company = await updateCompany(payload.id!, payload);
        }
        if (company) set(companyStateJ(company.id), company);
        return company;
      },
      [createCompany, updateCompany]
    )
  );
}

export const companyWebsiteSchema = yup
  .string()
  .nullable()
  .test('is-url', 'Invalid URL', (value) => {
    if (!value) return true;
    return value.match(VALID_URL_REGEX) !== null;
  })
  .test(checkDuplicateWebsite);

// avoid sending entire company object on every put request
export function pickFormFields(data: Partial<ICompanyDataModel>) {
  const { ceoName, primaryLocation, secondaryLocations, customData } = data;
  const rest = Object.keys(companyFormFields()) as (keyof ICompanyDataModel)[];
  return {
    ceoName,
    primaryLocation,
    secondaryLocations,
    customData,
    ...rest.reduce((acc, path) => {
      if (path.startsWith('_viewModel')) return acc;
      set(acc, path, data[path]);
      return acc;
    }, {} as Partial<ICompanyDataModel>),
  };
}

export function companyFormFields() {
  const store = getForesightStore();
  const userOptions = store.get(usersAtom).map((u) => ({
    value: u.id,
    displayName: u.name,
  }));
  const sectorOptions = store.get(sectorsAtom).map((s) => ({
    value: s.id,
    displayName: s.name,
  }));
  const { dealLeadId, dealTeamIds, name, shortDescription } = companyFields();
  return {
    name: name.required(),
    shortDescription,
    socials: yup.mixed(),
    dealLeadId: dealLeadId.formMeta({
      formatter: 'user',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: userOptions,
      },
    }),
    dealTeamIds: dealTeamIds.formMeta({
      formatter: 'user',
      renderer: RendererType.multiSelect,
      rendererMeta: {
        values: userOptions,
      },
    }),
    sectorId: yup
      .number()
      .nullable()
      .label('Sector')
      .default(null)
      .formMeta<IFormFieldSelectMeta<number>>({
        key: 'sectorId',
        label: 'Sector',
        formatter: 'sector',
        renderer: RendererType.singleSelect,
        rendererMeta: { values: sectorOptions },
      }),
    _viewModel: yup.object({
      ...companyViewModelFields(),
    }),
    website: companyWebsiteSchema,
  };
}

export function companyViewModelFields() {
  return {
    ceoName: yup
      .array()
      .of(yup.string().required())
      .formMeta<IFormFieldSelectMeta<string>>({
        key: '_viewModel.ceoName',
        label: 'CEO Names',
        formatter: 'stringArray',
        renderer: RendererType.multiSelect,
        rendererMeta: {
          allowCustomAdd: true,
          values: [], // TODO
        },
      }),
    primaryLocation: yup.mixed().nullable().label('Location').default(null), // we cannot set formMeta on yup.mixed(), and if we set primaryLocation to yup.string(), it will trigger a type validation error
    secondaryLocations: yup.array().label('Secondary Locations').default([]),
    sourceCompany: yup
      .string()
      .nullable()
      .label('Source Company')
      .formMeta({
        renderer: RendererType.companySearch,
        rendererMeta: {
          createOnSelection: false,
          multiSelect: false,
          showAdd: false,
          searchType: SearchType.Company,
        },
      }),
  };
}

export function companyFormSchema(): ObjectSchema<Partial<ICompanyViewModel>> {
  return yup.object({
    ...companyFormFields(),
  }) as ObjectSchema<Partial<ICompanyViewModel>>;
}

export function useCompanyFields(additionalFields: (keyof ICompanyViewModel)[] = []) {
  const customFields = useRecoilValue(customFieldsByEntity);
  const companyCustomFields = (customFields.get(FieldEntity.company) ?? []).map((field) =>
    field2ToFormField(field)
  );
  const { formState, watch } = useFormContext<Partial<ICompanyViewModel>>() ?? {};
  const ceoNames = watch('_viewModel.ceoName');
  const companyData = useMemo(() => formState?.defaultValues ?? {}, [formState]);
  const isCrunchbase = companyData?.id != null && Boolean(companyData?.crunchbaseId);
  const ceoNameOptions: ISimpleChoice<string>[] = useMemo(() => {
    return (
      ceoNames?.map((name) => {
        return { displayName: name, value: name ?? '' };
      }) ?? []
    );
  }, [ceoNames]);

  const [nameField, primaryLocation, secondaryLocations, ceoName, ...rest] = schemaToFormFields(
    companyFormSchema(),
    [
      'name',
      '_viewModel.primaryLocation',
      '_viewModel.secondaryLocations',
      '_viewModel.ceoName',
      'sectorId',
      'shortDescription',
      'dealLeadId',
      'dealTeamIds',
      ...additionalFields,
    ]
  );

  return useMemo(
    () => [
      { ...nameField, disabled: isCrunchbase },
      companyWebsiteField({
        key: 'website',
        label: 'Website',
        disabled: isCrunchbase,
      }),
      {
        ...primaryLocation,
        renderer: RendererType.location,
        rendererMeta: {
          multi: false,
          values: [],
        },
      },
      {
        ...secondaryLocations,
        renderer: RendererType.location,
        rendererMeta: {
          multi: true,
          values: [],
        },
      },
      {
        ...ceoName,
        rendererMeta: {
          allowCustomAdd: true,
          values: ceoNameOptions,
        },
      },
      ...rest,
      ...companyCustomFields,
    ],
    [
      ceoName,
      ceoNameOptions,
      companyCustomFields,
      isCrunchbase,
      nameField,
      primaryLocation,
      rest,
      secondaryLocations,
    ]
  );
}

export const DuplicateWebsiteErrorType = 'duplicate';

export async function checkDuplicateWebsite(
  value: string | null | undefined,
  context: yup.TestContext<yup.AnyObject>
) {
  if (!value || !VALID_URL_REGEX.test(value)) return true;
  const companies = await fetchCompaniesByWebsite(value);

  const company = companies?.at(0);

  if (!company || company.id === context.parent?.id) {
    return true;
  } else {
    return context.createError({
      path: `website`,
      message: 'Duplicate website',
      type: DuplicateWebsiteErrorType,
      params: {
        company: { ...company },
      },
    });
  }
}

// react hook form creates undefined values for empty custom data fields - to avoid api calls when no data changed, remove them from payload
// (update handler will check if data changed before sending request)
export function removeEmptyCustomData(formData: ICompanyDataModel['customData']) {
  if (!formData) return formData;
  return Object.entries(formData).reduce(
    (acc, [key, value]) => {
      if (value !== undefined) acc![key] = value;
      return acc;
    },
    {} as ICompanyDataModel['customData']
  );
}
