import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTES } from '../constants/RouteNameMapping';
import { MaggieFeatureFlags } from '../util/feature-flags';

const routesWithAdditionalNav = new Set([
  ROUTES.PORTFOLIO,
  ROUTES.DEAL_FLOW,
  ROUTES.KPI,
  ROUTES.COMPANIES,
  ROUTES.ADMIN_PANEL,
]);

export function useHasAdditionalNav() {
  const { pathname } = useLocation();
  const { showReconciliation, showAllSecurities } = useFlags<MaggieFeatureFlags>();

  return useMemo(() => {
    const loc = pathname.split('/')[1];
    return (
      routesWithAdditionalNav.has(loc as ROUTES) ||
      pathname.includes('ci-report') ||
      ((showReconciliation || showAllSecurities) && loc === ROUTES.FINANCE2)
    );
  }, [pathname, showAllSecurities, showReconciliation]);
}
