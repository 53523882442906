import { IFormatterDataModel } from '../../../data-models/formatter.data-model';
import { CalculationType, FundDataModel, fundFormSchema } from '../../../schemas/Fund.schema';
import {
  FundMetrics,
  FundMetricsByFund,
  fundMetricsByFundFormSchema,
  fundMetricsCalcsSchema,
} from '../../../schemas/FundMetrics.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { WaterfallGridData, waterfallGridDataSchema, WfGridDataKey } from './FPWaterfallData';
import { waterfallModellingSummaryDataSchema } from './WaterfallModellingSummaryRow';

type UiFieldKey =
  `${WfGridDataKey}-${keyof Pick<WaterfallGridData, 'distributableProceeds' | 'lpDistributed' | 'gpDistributed' | 'totalDistributed'>}`;
export type WfKey = keyof FundDataModel | keyof FundMetrics | keyof FundMetricsByFund | UiFieldKey;
interface CalcData {
  inputs: Set<WfKey>; // for cell highlighting
  formula: string;
}
export interface WaterfallField extends Partial<CalcData> {
  entity: 'fund' | 'fundMetrics' | 'wfGridData' | 'fundMetricsByFund';
  formatter?: string | IFormatterDataModel<unknown>;
  key: WfKey;
  label: string;
  value: unknown;
}

type FundMetricsByFundCalcFields = Pick<
  FundMetricsByFund,
  'mostRecentContributedSecurities' | 'mostRecentDeemedContributions'
>;

class WFG {
  #fund: FundDataModel;
  #fundMetrics: FundMetrics;
  #fundMetricsByFund: FundMetricsByFundCalcFields;
  #wfGridData: Map<WfGridDataKey, WaterfallGridData>;
  static #keyToFormatter = new Map<
    | Omit<WaterfallField['key'], UiFieldKey>
    | keyof Pick<WaterfallGridData, 'distributableProceeds' | 'gpDistributed' | 'lpDistributed'>,
    string | IFormatterDataModel<unknown>
  >([]);
  static #keyToLabel = new Map<
    | Omit<WaterfallField['key'], UiFieldKey>
    | keyof Pick<WaterfallGridData, 'distributableProceeds' | 'gpDistributed' | 'lpDistributed'>,
    string
  >([]);

  static fundInputs = new Set<keyof FundDataModel>([
    'commitments',
    'enableGPCatchup',
    'enableSuperReturn',
    'gpCatchUpPercentage',
    'isProceedsPercentAdjusted',
    'lpCommitmentSplit',
    'lpGpSplit',
    'lpGpSplitThreshold',
    'superReturnSplit',
  ]);

  static fundMetricsInputs = new Set<keyof FundMetrics>([
    'contributions',
    'distributions',
    'escrowReceivable',
    'fmv',
    'gpContributions',
    'gpDistributions',
    'lpContributions',
    'lpDistributions',
    'lpNavFromLpGpSplit',
    'navFromLpGpSplitLP',
    'navFromSuperReturnLP',
    'netAssets',
  ]);

  static funcMetricsByFundInputs = new Set<keyof FundMetricsByFund>([
    'mostRecentContributedSecurities',
    'mostRecentDeemedContributions',
  ]);

  static fundMetricsCalcFields = new Set<keyof FundMetrics>([
    'amountAvailableForLpGpSplit',
    'lpGpSlitAmount',
    'maxGpCatchup',
  ]);

  static tier0Fields = new Set<WfKey>(['0-lpDistributed', '0-gpDistributed', '0-totalDistributed']);

  static tier1Fields = new Set<WfKey>([
    '1-distributableProceeds',
    'amountAvailableForLpGpSplit',
    'lpGpSlitAmount',
    '1-lpDistributed',
    '1-gpDistributed',
    '1-totalDistributed',
  ]);

  static tier2Fields = new Set<WfKey>([
    '2-distributableProceeds',
    'maxGpCatchup',
    '2-gpDistributed',
    '2-totalDistributed',
  ]);

  static tier3Fields = new Set<WfKey>([
    '3-distributableProceeds',
    '3-lpDistributed',
    '3-gpDistributed',
    '3-totalDistributed',
  ]);

  static addBackFields = new Set<WfKey>([
    'Add Back Distributions-lpDistributed',
    'Add Back Distributions-gpDistributed',
  ]);

  static outputFields = new Set<WfKey>(['Output-lpDistributed', 'Output-gpDistributed']);

  #fields: Map<WfKey, WaterfallField>;
  constructor(fund: FundDataModel, fundMetrics: FundMetricsByFund, wfGridData: WaterfallGridData[]) {
    this.#fund = { ...fund };
    this.#fundMetrics = { ...fundMetrics.metrics };
    this.#fundMetricsByFund = {
      mostRecentContributedSecurities: fundMetrics.mostRecentContributedSecurities,
      mostRecentDeemedContributions: fundMetrics.mostRecentDeemedContributions,
    };
    this.#wfGridData = new Map<WfGridDataKey, WaterfallGridData>();
    wfGridData.forEach((tierData) => {
      this.#wfGridData.set(tierData.key, { ...tierData });
    });

    [
      ...schemaToFormFields(fundFormSchema()),
      ...schemaToFormFields(fundMetricsCalcsSchema()),
      ...schemaToFormFields(waterfallGridDataSchema()),
      ...schemaToFormFields(fundMetricsByFundFormSchema()),
    ].forEach((field) => {
      WFG.#keyToLabel.set(field.key as WaterfallField['key'], field.label!);
      WFG.#keyToFormatter.set(field.key as WaterfallField['key'], field.formatter ?? 'string');
    });

    this.#fields = new Map<WfKey, WaterfallField>();
    WFG.fundInputs.forEach((key) => {
      this.#fields.set(key, this.#keyToWaterfallField('fund', key));
    });
    [...WFG.fundMetricsInputs, ...WFG.fundMetricsCalcFields].forEach((key) => {
      this.#fields.set(key, this.#keyToWaterfallField('fundMetrics', key));
    });
    WFG.funcMetricsByFundInputs.forEach((key) => {
      this.#fields.set(key, this.#keyToWaterfallField('fundMetricsByFund', key));
    });
    (['initial', '0', '1', '2', '3', 'Add Back Distributions', 'Output'] as WfGridDataKey[]).forEach(
      (tier) => {
        (['distributableProceeds', 'lpDistributed', 'gpDistributed', 'totalDistributed'] as const).forEach(
          (key) => {
            this.#fields.set(
              `${tier}-${key}` as WfKey,
              this.#keyToWaterfallField('wfGridData', `${tier}-${key}`)
            );
          }
        );
      }
    );

    this.#setInputCalcs();
    this.#setInitialTierCalcs();
    this.#setTier0Calcs();
    this.#setTier1Calcs();
    this.#setTier2Calcs();
    this.#setTier3Calcs();
    this.#setAddBackCalcs();
    this.#setOutputCalcs();
  }

  getFundInputs() {
    return Array.from(this.#fields.values()).filter((field) =>
      WFG.fundInputs.has(field.key as keyof FundDataModel)
    );
  }
  getFundMetricsInputs() {
    return Array.from(this.#fields.values()).filter((field) =>
      WFG.fundMetricsInputs.has(field.key as keyof FundMetrics)
    );
  }
  getFundMetricsByFundInputs() {
    return Array.from(this.#fields.values()).filter((field) =>
      WFG.funcMetricsByFundInputs.has(field.key as keyof FundMetricsByFund)
    );
  }

  getInitialTierFields() {
    return [this.#fields.get('initial-distributableProceeds')!];
  }
  getTier0Fields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.tier0Fields.has(field.key as WfKey));
  }
  getTier1Fields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.tier1Fields.has(field.key as WfKey));
  }
  getTier2Fields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.tier2Fields.has(field.key as WfKey));
  }
  getTier3Fields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.tier3Fields.has(field.key as WfKey));
  }

  getAddBackFields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.addBackFields.has(field.key as WfKey));
  }

  getOutputFields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.outputFields.has(field.key as WfKey));
  }

  #setInputCalcs() {
    this.#fields.set('contributions', {
      ...this.#fields.get('contributions')!,
      inputs: new Set(['lpContributions', 'gpContributions', 'mostRecentDeemedContributions']),
      formula: `${WFG.#keyToLabel.get('lpContributions')} + ${WFG.#keyToLabel.get('gpContributions')} + ${WFG.#keyToLabel.get('mostRecentDeemedContributions')}`,
    });
    this.#fields.set('distributions', {
      ...this.#fields.get('distributions')!,
      inputs: new Set(['lpDistributions', 'gpDistributions']),
      formula: `${WFG.#keyToLabel.get('lpDistributions')} + ${WFG.#keyToLabel.get('gpDistributions')}`,
    });
  }
  #setInitialTierCalcs() {
    const distributionsField =
      this.#fund.calculationType === CalculationType.lpOnly ? 'lpDistributions' : 'distributions';
    const inputs = new Set<WfKey>([
      'calculationType',
      'escrowReceivable',
      'fmv',
      'mostRecentContributedSecurities',
      'netAssets',
      distributionsField,
    ]);
    if (this.#fund.isProceedsPercentAdjusted) inputs.add('lpCommitmentSplit');

    const nonAdjusted = `${WFG.#keyToLabel.get('fmv')} + ${WFG.#keyToLabel.get(distributionsField)} + ${WFG.#keyToLabel.get('netAssets')} + ${WFG.#keyToLabel.get('escrowReceivable')} - ${WFG.#keyToLabel.get('mostRecentContributedSecurities')}`;
    const adjusted = `(${nonAdjusted}) * ${WFG.#keyToLabel.get('lpCommitmentSplit')}`;

    this.#fields.set('initial-distributableProceeds', {
      ...this.#fields.get('initial-distributableProceeds')!,
      inputs,
      formula: this.#fund.isProceedsPercentAdjusted ? adjusted : nonAdjusted,
    });
  }
  #setTier0Calcs() {
    const lpContributions = (this.#fields.get('lpContributions')?.value ?? 0) as number;
    const gpContributions = (this.#fields.get('gpContributions')?.value ?? 0) as number;
    const initialDistributableProceeds =
      (this.#fields.get('initial-distributableProceeds')?.value as number) ?? 0;

    if (initialDistributableProceeds! > lpContributions!) {
      this.#fields.set('0-lpDistributed', {
        ...this.#fields.get('0-lpDistributed')!,
        inputs: new Set<WfKey>(['lpContributions']),
        formula: `${WFG.#keyToLabel.get('lpContributions')}`,
      });
    } else {
      this.#fields.set('0-lpDistributed', {
        ...this.#fields.get('0-lpDistributed')!,
        inputs: new Set<WfKey>(['initial-distributableProceeds', 'lpCommitmentSplit']),
        formula: `${WFG.#keyToLabel.get('distributableProceeds')} * ${WFG.#keyToLabel.get('lpCommitmentSplit')}`,
      });
    }

    if (((initialDistributableProceeds ?? 0) as number) > gpContributions + lpContributions) {
      this.#fields.set('0-gpDistributed', {
        ...this.#fields.get('0-gpDistributed')!,
        inputs: new Set<WfKey>(['gpContributions']),
        formula: `${WFG.#keyToLabel.get('gpContributions')}`,
      });
    } else {
      this.#fields.set('0-gpDistributed', {
        ...this.#fields.get('0-gpDistributed')!,
        inputs: new Set<WfKey>(['initial-distributableProceeds', 'lpCommitmentSplit']),
        formula: `${WFG.#keyToLabel.get('distributableProceeds')} * (1 - ${WFG.#keyToLabel.get('lpCommitmentSplit')})`,
      });
    }
    this.#fields.set('0-totalDistributed', {
      ...this.#fields.get('0-totalDistributed')!,
      inputs: new Set(['0-lpDistributed', '0-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('lpDistributed')} + ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
  }
  #setTier1Calcs() {
    this.#fields.set('1-distributableProceeds', {
      ...this.#fields.get('1-distributableProceeds')!,
      inputs: new Set(['initial-distributableProceeds', '0-totalDistributed']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} - ${WFG.#keyToLabel.get('totalDistributed')}`,
    });
    this.#fields.set('amountAvailableForLpGpSplit', {
      ...this.#fields.get('amountAvailableForLpGpSplit')!,
      inputs: new Set([
        'commitments',
        'lpGpSplitThreshold',
        'lpContributions',
        'lpCommitmentSplit',
        'lpGpSplit',
      ]),
      formula: `((${WFG.#keyToLabel.get('commitments')} * ${WFG.#keyToLabel.get('lpGpSplitThreshold')}) - ${WFG.#keyToLabel.get('lpContributions')}) / (${WFG.#keyToLabel.get('lpGpSplit')} *  ${WFG.#keyToLabel.get('lpCommitmentSplit')})`,
    });
    this.#fields.set('lpGpSlitAmount', {
      ...this.#fields.get('lpGpSlitAmount')!,
      inputs: new Set(['amountAvailableForLpGpSplit', '1-distributableProceeds']),
      formula: `MIN(${WFG.#keyToLabel.get('amountAvailableForLpGpSplit')}, ${WFG.#keyToLabel.get('distributableProceeds')})`,
    });
    this.#fields.set('1-lpDistributed', {
      ...this.#fields.get('1-lpDistributed')!,
      inputs: new Set(['lpGpSlitAmount', 'lpGpSplit']),
      formula: `${WFG.#keyToLabel.get('lpGpSlitAmount')} * ${WFG.#keyToLabel.get('lpGpSplit')}}`,
    });
    this.#fields.set('1-gpDistributed', {
      ...this.#fields.get('1-gpDistributed')!,
      inputs: new Set(['lpGpSlitAmount', 'lpGpSplit']),
      formula: `${WFG.#keyToLabel.get('lpGpSlitAmount')} * (1 - ${WFG.#keyToLabel.get('lpGpSplit')})`,
    });
    this.#fields.set('1-totalDistributed', {
      ...this.#fields.get('1-totalDistributed')!,
      inputs: new Set(['1-lpDistributed', '1-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('lpDistributed')} + ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
  }
  #setTier2Calcs() {
    this.#fields.set('2-distributableProceeds', {
      ...this.#fields.get('2-distributableProceeds')!,
      inputs: new Set(['1-distributableProceeds', '1-totalDistributed']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} - ${WFG.#keyToLabel.get('totalDistributed')}`,
    });
    this.#fields.set('maxGpCatchup', {
      ...this.#fields.get('maxGpCatchup')!,
      inputs: new Set(['lpNavFromLpGpSplit', 'gpCatchUpPercentage', '1-gpDistributed']),
      formula: `(${WFG.#keyToLabel.get('lpNavFromLpGpSplit')} * ${WFG.#keyToLabel.get('gpCatchUpPercentage')}) / (1 - ${WFG.#keyToLabel.get('gpCatchUpPercentage')}) - ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
    this.#fields.set('2-gpDistributed', {
      ...this.#fields.get('2-gpDistributed')!,
      inputs: new Set(['maxGpCatchup', '2-distributableProceeds']),
      formula: `MIN(${WFG.#keyToLabel.get('maxGpCatchup')}, ${WFG.#keyToLabel.get('distributableProceeds')})`,
    });
    this.#fields.set('2-totalDistributed', {
      ...this.#fields.get('2-totalDistributed')!,
      inputs: new Set(['2-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('gpDistributed')}`,
    });
  }
  #setTier3Calcs() {
    this.#fields.set('3-distributableProceeds', {
      ...this.#fields.get('3-distributableProceeds')!,
      inputs: new Set(['2-distributableProceeds', '2-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} - ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
    this.#fields.set('3-lpDistributed', {
      ...this.#fields.get('3-lpDistributed')!,
      inputs: new Set(['3-distributableProceeds', 'superReturnSplit']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} * ${WFG.#keyToLabel.get('superReturnSplit')}`,
    });
    this.#fields.set('3-gpDistributed', {
      ...this.#fields.get('3-gpDistributed')!,
      inputs: new Set(['3-distributableProceeds', 'superReturnSplit']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} * (1 - ${WFG.#keyToLabel.get('superReturnSplit')})`,
    });
    this.#fields.set('3-totalDistributed', {
      ...this.#fields.get('3-totalDistributed')!,
      inputs: new Set(['3-lpDistributed', '3-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('lpDistributed')} + ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
  }

  #setAddBackCalcs() {
    this.#fields.set('Add Back Distributions-lpDistributed', {
      ...this.#fields.get('Add Back Distributions-lpDistributed')!,
      inputs: new Set(['lpDistributions']),
      formula: `-${WFG.#keyToLabel.get('lpDistributions')}`,
    });
    this.#fields.set('Add Back Distributions-gpDistributed', {
      ...this.#fields.get('Add Back Distributions-gpDistributed')!,
      inputs: new Set(['gpDistributions']),
      formula: `-${WFG.#keyToLabel.get('gpDistributions')}`,
    });
  }

  #setOutputCalcs() {
    const [lpDistributed, gpDistributed] = schemaToFormFields(waterfallModellingSummaryDataSchema(), [
      'lpDistributed',
      'gpDistributed',
    ]);
    this.#fields.set('Output-lpDistributed', {
      ...this.#fields.get('Output-lpDistributed')!,
      label: lpDistributed.label!,
      inputs: new Set(['navFromLpGpSplitLP', 'navFromSuperReturnLP', '0-lpDistributed', 'lpDistributions']),
      formula: `${WFG.#keyToLabel.get('navFromLpGpSplitLP')} + ${WFG.#keyToLabel.get('navFromSuperReturnLP')} + ${WFG.#keyToLabel.get('lpDistributed')} (Tier 0) - ${WFG.#keyToLabel.get('lpDistributions')}`,
    });
    this.#fields.set('Output-gpDistributed', {
      ...this.#fields.get('Output-gpDistributed')!,
      label: gpDistributed.label!,
      inputs: new Set(['1-gpDistributed', '2-gpDistributed', '3-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('gpDistributed')} (Tier 1) + ${WFG.#keyToLabel.get('gpDistributed')} (Tier 2) + ${WFG.#keyToLabel.get('gpDistributed')} (Tier 3)`,
    });
  }

  #keyToWaterfallField(
    entity: 'fund' | 'fundMetrics' | 'wfGridData' | 'fundMetricsByFund',
    key: keyof FundDataModel | keyof FundMetrics | keyof FundMetricsByFundCalcFields | WfKey
  ): WaterfallField {
    const tier = entity === 'wfGridData' ? (key.split('-').at(0) as WfGridDataKey) : undefined;
    const _key = entity === 'wfGridData' ? key.split('-').at(1)! : key;
    let value;
    switch (entity) {
      case 'fund':
        value = this.#fund[key as keyof FundDataModel];
        break;
      case 'fundMetrics':
        value = this.#fundMetrics[key as keyof FundMetrics];
        break;
      case 'wfGridData':
        value = this.#wfGridData.get(tier!)![_key as keyof WaterfallGridData];
        break;
      case 'fundMetricsByFund':
        value = this.#fundMetricsByFund[key as keyof FundMetricsByFundCalcFields];
        break;
    }
    return {
      entity,
      formatter: WFG.#keyToFormatter.get(_key) ?? 'string',
      key,
      label: WFG.#keyToLabel.get(_key)!,
      value,
    };
  }
}
export const WaterfallFieldsGenerator = WFG;
