import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { pick } from 'lodash-es';
import { PropsWithChildren, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFactoryNoLayout } from '../../../../components/Form/FormFactory';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import {
  fromDealDMToMissedVM,
  fromDealDMToPassVM,
  fromMissVMToDealDM,
  fromPassVMToDealDM,
  MissDealFormViewModel,
  PassDealFormViewModel,
  TrackDealFormViewModel,
  trackDealVMSchema,
  useMissDealFormVMSchema,
  usePassDealFormVMSchema,
} from '../../../../schemas/deal.schema';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { colors } from '../../../../theme/colors';
import { FMT } from '../../../../util/formatter-service';
import { DateFormattersId } from '../../../../util/formatters/DateFormatters';
import { schemaToFormFields } from '../../../../util/schema-utils';
import { createFormFromFieldsArray, IForm } from '../../../../view-models/form.view-model';
import { UnstyledFieldset } from '../../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { useDebouncedUpdateDeal } from '../../hooks/useDealActions';
import { dealStagesByNameMapState } from '../../state/DealboardDataState';

const stageFieldsetStyles = css`
  background: ${colors.primary[5]};
  padding: 1rem 1rem 0.2rem;
`;

const fieldsContainer = css`
  width: 50%;
`;

export function DealStatusBox({ deal }: { deal: IDealDataModel }) {
  const stagesMap = useAtomValue(dealStagesByNameMapState);
  const editable = PermissionService.get().hasPermission(PermissionKey.canEditDeal);
  let stage = null;
  if (!stagesMap) return null;
  else if (deal.stageId === stagesMap.get('Track')!.id) {
    stage = 'Track';
  } else if (deal.stageId === stagesMap.get('Pass')!.id) {
    stage = 'Pass';
  } else if (deal.stageId === stagesMap.get('Missed')!.id) {
    stage = 'Missed';
  }
  if (!stage) {
    return null;
  }
  return (
    <DealStatusBoxContents deal={deal} editable={editable} stage={stage as 'Track' | 'Pass' | 'Missed'} />
  );
}
export function DealStatusBoxContents({
  deal,
  editable,
  stage,
}: {
  deal: IDealDataModel;
  editable: boolean;
  stage: 'Track' | 'Pass' | 'Missed';
}) {
  const updateDeal = useDebouncedUpdateDeal();

  const onUpdateDeal = useCallback(
    async (data: Partial<IDealDataModel>) => {
      const { companyId, dealTypeId } = deal;
      updateDeal(deal, { companyId, dealTypeId, ...data } as Partial<IDealDataModel>, false);
    },
    [deal, updateDeal]
  );

  let title = null;
  switch (stage) {
    case 'Track':
      title = (
        <StageTitle>
          <HourglassBottomOutlinedIcon fontSize={'small'} />
          {`This deal was tracked on ${FMT.format(DateFormattersId.dateLocal, deal.stageUpdateDate)}`}
        </StageTitle>
      );
      break;
    case 'Pass':
      title = (
        <StageTitle>
          <ThumbDownAltOutlinedIcon fontSize={'small'} />
          {`This deal was passed on ${FMT.format(DateFormattersId.dateLocal, deal.stageUpdateDate)}`}
        </StageTitle>
      );
      break;
    case 'Missed':
      title = (
        <StageTitle>
          <CallMissedOutgoingIcon fontSize={'small'} />
          {` This deal was missed on ${FMT.format(DateFormattersId.dateLocal, deal.stageUpdateDate)}`}
        </StageTitle>
      );
      break;
  }

  return (
    <UnstyledFieldset disabled={!editable} css={stageFieldsetStyles}>
      {title}
      <div css={fieldsContainer}>
        {stage === 'Track' && <TrackedDealForm deal={deal} onChange={onUpdateDeal} />}
        {stage === 'Pass' && <PassedDealForm deal={deal} onChange={onUpdateDeal} />}
        {stage === 'Missed' && <MissedDealForm deal={deal} onChange={onUpdateDeal} />}
      </div>
    </UnstyledFieldset>
  );
}

function StageTitle({ children }: PropsWithChildren) {
  return (
    <Typography display={'inline-flex'} alignItems={'center'} gap={'0.5rem'} mb='0.5rem'>
      {children}
    </Typography>
  );
}

function TrackedDealForm({
  deal,
  onChange,
}: {
  deal: IDealDataModel;
  onChange: (data: Partial<IDealDataModel>) => void;
}) {
  const schema = trackDealVMSchema();

  const methods = useForm<TrackDealFormViewModel>({
    resolver: yupResolver(schema),
    defaultValues: pick(deal, ['trackComments', 'snoozeDate']) as TrackDealFormViewModel,
  });

  const form = createFormFromFieldsArray(
    schemaToFormFields(schema).map((f) => ({
      ...f,
      required: false,
    })),
    {
      variant: 'form-inline',
    }
  ) as IForm<TrackDealFormViewModel>;

  const _onChange = useCallback(
    (data: Partial<IDealDataModel>) => {
      try {
        Object.keys(data).forEach((key) => {
          schema.validateSyncAt(key, data);
        });
        onChange(data);
      } catch (err) {
        console.warn(err);
      }
    },
    [onChange, schema]
  );

  return (
    <FormProvider {...methods}>
      <FormFactoryNoLayout form={form as IForm<IDealDataModel>} onChange={_onChange} />
    </FormProvider>
  );
}

function MissedDealForm({
  deal,
  onChange,
}: {
  deal: IDealDataModel;
  onChange: (data: Partial<IDealDataModel>) => void;
}) {
  const schema = useMissDealFormVMSchema();
  const methods = useForm<MissDealFormViewModel>({
    resolver: yupResolver(schema),
    defaultValues: fromDealDMToMissedVM(deal),
  });

  const form = createFormFromFieldsArray(
    schemaToFormFields(schema).map((f) => ({
      ...f,
      required: false,
    })),
    {
      variant: 'form-inline',
    }
  ) as IForm<MissDealFormViewModel>;

  const _onChange = useCallback(
    (data: Partial<MissDealFormViewModel>) => {
      try {
        Object.keys(data).forEach((key) => {
          schema.validateSyncAt(key, data);
        });
        const payload = fromMissVMToDealDM(data);
        onChange(payload);
      } catch (err) {
        console.warn(err);
      }
    },
    [onChange, schema]
  );

  return (
    <FormProvider {...methods}>
      <FormFactoryNoLayout form={form} onChange={_onChange} />
    </FormProvider>
  );
}

function PassedDealForm({
  deal,
  onChange,
}: {
  deal: IDealDataModel;
  onChange: (data: Partial<IDealDataModel>) => void;
}) {
  const schema = usePassDealFormVMSchema();
  const methods = useForm<PassDealFormViewModel>({
    resolver: yupResolver(schema),
    defaultValues: fromDealDMToPassVM(deal),
  });

  const _onChange = useCallback(
    (data: Partial<PassDealFormViewModel>) => {
      try {
        Object.keys(data).forEach((key) => {
          schema.validateSyncAt(key, data);
        });
        const payload = fromPassVMToDealDM(data);
        onChange(payload);
      } catch (err) {
        console.warn(err);
      }
    },
    [onChange, schema]
  );

  const form = createFormFromFieldsArray(
    schemaToFormFields(schema).map((f) => ({
      ...f,
      required: false,
    })),
    {
      variant: 'form-inline',
    }
  ) as IForm<PassDealFormViewModel>;

  return (
    <FormProvider {...methods}>
      <FormFactoryNoLayout form={form} onChange={_onChange} />
    </FormProvider>
  );
}
