import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router';
import { useLoadingBarState } from '../../../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../../../components/ToastMessage/ToastMessageProvider';
import {
  IKPIRequestDataModel,
  IKPIResponseActionPayload,
} from '../../../../../../data-models/kpi-requests.data-model';
import {
  acceptKPIRequestResponse,
  bulkAcceptResponses,
  createKPIRequestResponse,
  fetchKPIRequestS3FileUploadUrl,
  moveRequestBackToReview,
  rejectKPIRequestResponse,
  updateKPIRequestResponse,
} from '../../../../../../services/queries/KPIRequestsQueries';
import { getErrorMessage } from '../../../../../../services/queryHelpers';
import { LoadingId } from '../../../../../../types';
import { uploadToS3 } from '../../../../../CompanyComparison/components/UploadGaCSV/hooks/useUploadToS3';
import {
  selectedRequestState,
  showModalStateKPI,
  KpiAction,
  kpisRequestsMapByIdState,
} from '../../../../../../services/state/KPI/KPIRequestsState';
import { ROUTES } from '../../../../../../constants/RouteNameMapping';
import { useShouldSaveMsEnteredFinancials } from '../managed-services-utils';
import { useSaveMsEnteredFinancials } from '../msFinancialsGridActions';
import { useInvalidateKPIRequests } from './useInvalidateKPIRequests';

type KPIRequestResponsePayload = {
  id?: number;
  requestId: string;
  responseFormData: IKPIResponseActionPayload;
  autosave?: boolean;
};

export function useKPIRequestResponseActions() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast } = useToastMessageState();
  const invalidateKpiRequests = useInvalidateKPIRequests();
  const shouldSaveMsEnteredFinancials = useShouldSaveMsEnteredFinancials();
  const saveMsEnteredFinancials = useSaveMsEnteredFinancials();
  const requestsById = useRecoilValue(kpisRequestsMapByIdState);

  const handleBulkAccept = useCallback(
    async (requestIds: number[]) => {
      try {
        await bulkAcceptResponses(requestIds);
        await invalidateKpiRequests();
      } catch (err) {
        const message = getErrorMessage(err, 'Bulk accept failed');
        pushErrorToast({ message });
      }
    },
    [invalidateKpiRequests, pushErrorToast]
  );

  const createRequestResponse = useCallback(
    async (payload: KPIRequestResponsePayload) => {
      const { requestId, responseFormData: respondFormData, autosave } = payload;

      actions.startLoading(LoadingId.createKPIRequestResponse);

      try {
        return await createKPIRequestResponse(requestId, respondFormData, autosave);
      } catch (err) {
        const message = getErrorMessage(err, `Failed to submit`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.createKPIRequestResponse);
      }
    },
    [actions, pushErrorToast]
  );

  const updateRequestResponse = useCallback(
    async (payload: KPIRequestResponsePayload) => {
      const { requestId, responseFormData: respondFormData, autosave } = payload;

      actions.startLoading(LoadingId.updateKPIRequestResponse);

      try {
        return await updateKPIRequestResponse(requestId, respondFormData, autosave);
      } catch (err) {
        const message = getErrorMessage(err, `Failed to update response`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.updateKPIRequestResponse);
      }
    },
    [actions, pushErrorToast]
  );

  const acceptRequestResponse = useCallback(
    async (requestId: string, acceptMessage?: string) => {
      actions.startLoading(LoadingId.acceptKPIRequestResponse);

      try {
        const currentRequest = requestsById.get(parseInt(requestId));
        if (currentRequest && shouldSaveMsEnteredFinancials(currentRequest.status)) {
          await saveMsEnteredFinancials(currentRequest);
        }
        const res = await acceptKPIRequestResponse(requestId, acceptMessage);
        invalidateKpiRequests();
        return res;
      } catch (err) {
        const message = getErrorMessage(err, `Failed to accept the request`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.acceptKPIRequestResponse);
      }
    },
    [
      actions,
      invalidateKpiRequests,
      pushErrorToast,
      requestsById,
      saveMsEnteredFinancials,
      shouldSaveMsEnteredFinancials,
    ]
  );

  const rejectRequestResponse = useCallback(
    async (requestId: string, observation?: string) => {
      actions.startLoading(LoadingId.rejectKPIRequestResponse);

      try {
        const res = await rejectKPIRequestResponse(requestId, observation);
        invalidateKpiRequests();
        return res;
      } catch (err) {
        const message = getErrorMessage(err, `Failed to reject the request`);

        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.rejectKPIRequestResponse);
      }
    },
    [actions, invalidateKpiRequests, pushErrorToast]
  );

  const uploadFileRequestResponse = useCallback(
    async ({ companyId, file }: { companyId: number; file: File }) => {
      try {
        // Get AWS S3 signed url
        const responseFileUpload = await fetchKPIRequestS3FileUploadUrl({
          contentType: file.type,
          companyId,
        });

        // Upload file to S3 using signed url
        await uploadToS3(file, responseFileUpload.signedUrl);

        return {
          contentType: file.type,
          fileId: responseFileUpload.fileId,
        };
      } catch (err) {
        const message = getErrorMessage(err, `Failed to upload ${file.name}`);
        pushErrorToast({ message });

        return false;
      }
    },
    [pushErrorToast]
  );

  return {
    uploadFileRequestResponse,
    createRequestResponse,
    acceptRequestResponse,
    updateRequestResponse,
    rejectRequestResponse,
    handleBulkAccept,
  };
}

export function useMoveBackToReview() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateKpiRequests = useInvalidateKPIRequests();
  const navigate = useNavigate();

  return useCallback(
    async (requestId: number) => {
      try {
        await moveRequestBackToReview(requestId);
        await invalidateKpiRequests();
        // if we don't want to navigate away, we'll need to turn this into a recoil callback and invalidate kpiRequestByIdState(requestId)
        navigate(`/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`);
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, `Failed to move back to review`) });
      }
    },
    [invalidateKpiRequests, navigate, pushErrorToast]
  );
}

export function useRequestActions() {
  const [selectedRequest, setSelectedRequest] = useRecoilState(selectedRequestState);
  const [modalAction, setModalAction] = useRecoilState(showModalStateKPI);
  const resetState = useResetKpiState();

  const onSelectAction = useCallback(
    (request: IKPIRequestDataModel, action: KpiAction) => {
      setSelectedRequest(request);
      setModalAction(action);
    },
    [setSelectedRequest, setModalAction]
  );

  const onCloseModal = useCallback(() => {
    resetState();
  }, [resetState]);

  return { onSelectAction, onCloseModal, modalAction, selectedRequest };
}

export function useResetKpiState() {
  const resetModalState = useResetRecoilState(showModalStateKPI);
  const resetSelectedRequest = useResetRecoilState(selectedRequestState);

  return useCallback(() => {
    resetModalState();
    resetSelectedRequest();
  }, [resetModalState, resetSelectedRequest]);
}
