import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useAtomValue, useSetAtom } from 'jotai';
import { MouseEvent, useCallback, useState } from 'react';
import { useDealActions2 } from '../../hooks/useDealActions';
import {
  DealAction,
  dealBoardSettingsState,
  isOpenDrawerState,
  selectedDealActionAtom,
  selectedDealIdState,
  selectedDealState,
  useShowNearestDealOrClose,
} from '../../state/DealboardUIState';
import { StringFormatterId } from '../../../../util/formatters/StringFormatters';
import { FMT } from '../../../../util/formatter-service';
import { getNextDeal, getPrevDeal } from './dealNavigationUtils';

export function ActiveDealActionsButton() {
  const setIsOpenDrawer = useSetAtom(isOpenDrawerState);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const setSelectedDeaId = useSetAtom(selectedDealIdState);
  const deal = useAtomValue(selectedDealState);

  const dealBoardSettings = useAtomValue(dealBoardSettingsState);
  const { handleClose } = useDealActions2();
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const setSelectedDealAction = useSetAtom(selectedDealActionAtom);

  const showNearestDealOrClose = useShowNearestDealOrClose();

  const onClose = useCallback(async () => {
    if (!deal) return;
    let nearestDeal = getNextDeal(dealBoardSettings, deal);
    if (!nearestDeal) nearestDeal = getPrevDeal(dealBoardSettings, deal);
    await handleClose(deal);
    if (!nearestDeal) setIsOpenDrawer(false);
    setSelectedDeaId(nearestDeal?.id ?? null);
  }, [deal, dealBoardSettings, handleClose, setIsOpenDrawer, setSelectedDeaId]);

  const handleSelectOption = useCallback(
    (optionId: DealAction | 'close') => {
      if (!deal) return;
      switch (optionId) {
        case 'close':
          onClose();
          setAnchorEl(null);
          return;
        case 'track':
          setSelectedDealAction({
            action: 'track',
            dealId: deal.id,
            next: () => showNearestDealOrClose(deal),
          });
          setAnchorEl(null);
          return;
        case 'pass':
          setSelectedDealAction({
            action: 'pass',
            dealId: deal.id,
            next: () => showNearestDealOrClose(deal),
          });
          setAnchorEl(null);
          return;
        case 'missed':
          setSelectedDealAction({
            action: 'missed',
            dealId: deal.id,
            next: () => showNearestDealOrClose(deal),
          });
          setAnchorEl(null);
          return;
        case 'delete':
          setSelectedDealAction({
            action: 'delete',
            dealId: deal.id,
            next: () => showNearestDealOrClose(deal),
          });
          setAnchorEl(null);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [deal, onClose, setSelectedDealAction, showNearestDealOrClose]
  );

  if (!deal) return null;

  return (
    <div>
      <ButtonGroup
        variant='outlined'
        color={'secondary'}
        size={'medium'}
        aria-label='split button'
        style={{ position: 'relative' }}
      >
        <Button
          color={'secondary'}
          startIcon={<ThumbDownAltOutlinedIcon />}
          onClick={() => handleSelectOption('pass')}
        >
          Pass
        </Button>

        <Button
          size='small'
          aria-controls={'split-button-menu'}
          aria-expanded={'true'}
          aria-label='select deal action'
          aria-haspopup='menu'
          onClick={handleClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {[
          { icon: <CheckCircleOutlineIcon fontSize='small' />, action: 'close' },
          { icon: <HourglassEmptyIcon fontSize='small' />, action: 'track' },
          { icon: <CallMissedOutgoingIcon fontSize='small' />, action: 'missed' },
          { icon: <DeleteOutlineIcon fontSize='small' />, action: 'delete' },
        ].map(({ icon, action }) => (
          <div key={action} title={action}>
            <MenuItem
              onClick={() => handleSelectOption(action as DealAction | 'close')}
              style={{ width: '8rem' }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText>{FMT.format(StringFormatterId.capitalizedString, action)}</ListItemText>
            </MenuItem>
          </div>
        ))}
      </Menu>
    </div>
  );
}
