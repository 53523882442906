import { CellValueChangedEvent } from 'ag-grid-community';
import { useAtomCallback } from 'jotai/utils';
import { pick } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { ProjectedFeesAndExpensesTransaction } from '../../../schemas/FundCapitalAllocation.schema';
import {
  createProjectedFeesAndExpensesTransaction,
  deleteProjectedFeesAndExpensesTransaction,
  updateProjectedFeesAndExpensesTransaction,
} from '../../../services/queries/MaggieFundQueries';
import { getErrorMessage } from '../../../services/queryHelpers';
import { refetchCapitalAllocationState } from '../state/FPState';
import { FeesAndExpensesFormData } from './feesAndExpensesFormData';

export function useInvalidateCapitalAllocation() {
  return useAtomCallback(
    useCallback((get, set) => {
      set(refetchCapitalAllocationState, (prev) => prev + 1);
    }, [])
  );
}

export function useFeesAndExpensesActions() {
  const invalidateData = useInvalidateCapitalAllocation();
  const { pushErrorToast } = useToastMessageState();

  const addProjectedFeesAndExpenses = useCallback(
    async (transaction: Partial<ProjectedFeesAndExpensesTransaction>) => {
      try {
        const res = await createProjectedFeesAndExpensesTransaction(transaction);
        invalidateData();
        return res;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to add projected fees and expenses') });
      }
    },
    [invalidateData, pushErrorToast]
  );

  const editProjectedFeesAndExpenses = useCallback(
    async (transaction: Partial<ProjectedFeesAndExpensesTransaction>) => {
      try {
        const res = await updateProjectedFeesAndExpensesTransaction(transaction);
        invalidateData();
        return res;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to edit projected fees and expenses') });
      }
    },
    [invalidateData, pushErrorToast]
  );

  const deleteProjectedFeesAndExpenses = useCallback(
    async (transactionId: number) => {
      try {
        await deleteProjectedFeesAndExpensesTransaction(transactionId);
        invalidateData();
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to delete projected fees and expenses') });
      }
    },
    [invalidateData, pushErrorToast]
  );

  return {
    addProjectedFeesAndExpenses,
    editProjectedFeesAndExpenses,
    deleteProjectedFeesAndExpenses,
  };
}

export function useFeesAndExpensesFormData() {
  const { addProjectedFeesAndExpenses, editProjectedFeesAndExpenses, deleteProjectedFeesAndExpenses } =
    useFeesAndExpensesActions();
  const [loading, setLoading] = useState(false);
  const onCellValueChange = useCallback(
    async (event: CellValueChangedEvent<FeesAndExpensesFormData>) => {
      const updatedData = event.data;
      if (!validateData(updatedData)) return;
      setLoading(true);
      if (updatedData.id) {
        if (updatedData.amount == null) {
          await deleteProjectedFeesAndExpenses(updatedData.id);
        } else {
          await editProjectedFeesAndExpenses(pick(updatedData, ['id', 'amount', 'date', 'fundId']));
        }
      } else if (updatedData.amount != null && updatedData.date) {
        await addProjectedFeesAndExpenses(pick(updatedData, ['amount', 'date', 'fundId']));
      }
      setLoading(false);
    },
    [addProjectedFeesAndExpenses, deleteProjectedFeesAndExpenses, editProjectedFeesAndExpenses]
  );

  return { onCellValueChange, loading };
}

function validateData(data: FeesAndExpensesFormData) {
  return data && data.date && data.fundId;
}
