import { FMT } from '../../../util/formatter-service';
import { StringFormatterId } from '../../../util/formatters/StringFormatters';
import { MAX_BARS_FOR_GROUP_BY_LOCATION } from '../hooks/useDealsBreakdownData';
import { GroupByOptions } from '../state/DealConversionFilterState';

export function getGroupByOptionName(name: GroupByOptions) {
  if (name === GroupByOptions.INVESTMENT_STATUS) {
    return 'Deal Status';
  }
  if (name === GroupByOptions.LOCATION) {
    return `Location (top ${MAX_BARS_FOR_GROUP_BY_LOCATION})`;
  }
  return FMT.format(StringFormatterId.camelCaseToCapitalizedString, name);
}
