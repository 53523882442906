import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { get as lodashGet } from 'lodash-es';
import { StringListCellRenderer } from '../../../../../../components/AgTable/cell-renderers/StringListCellRenderer';
import { ColumnMeta } from '../../../../../../types';
import { getIsoDateColDef } from '../../../../../../util/ag-grid-utils';
import { getExcelFormatterId } from '../../../../../../util/excel-formatter-service';
import { StandardFormatterId } from '../../../../../../util/formatter-service';
import { TooltipWrapperComponent } from '../../../../../../util/TooltipWrapperComponent';
import { ImageCellRenderer } from './TypeCellRenderers/ImageCellRenderer';
import { LinkRenderer } from './TypeCellRenderers/LinkRenderer';

export function getTypeBasedConfigs(column: ColumnMeta): Partial<ColDef | ColGroupDef> | null {
  if (column.type === 'number') {
    const excelFormatterId = getExcelFormatterId(column.formatting as StandardFormatterId);
    const commonNumberCellClasses = excelFormatterId
      ? ['monospace', 'text-align-right', excelFormatterId]
      : ['monospace', 'text-align-right'];

    const numberColumnSettings: Partial<ColDef> = {
      headerClass: 'ag-left-aligned-header',
      type: 'rightAligned',
      filter: 'agNumberColumnFilter',
      sortingOrder: ['desc', 'asc', null],
      cellClass: [...commonNumberCellClasses],
    };

    if (column.path === 'irr')
      return {
        ...numberColumnSettings,
        type: ['rightAligned', 'percent'],
      };

    if (column.formatting === 'percent')
      return {
        ...numberColumnSettings,
        enableValue: true,
        type: ['rightAligned', 'percent'],
      };

    if (column.formatting === 'currency' || column.formatting === 'usd')
      return {
        ...numberColumnSettings,
        enableValue: true,
        type: ['rightAligned'],
        filterValueGetter: (params: ValueGetterParams) => {
          const value = lodashGet(params?.data, column.path);
          return typeof value === 'number' ? Math.round(value * 100) / 100 : value;
        },
      };

    if (
      column.formatting === 'currencyAsInteger' ||
      column.formatting === 'currencyRounded' ||
      column.formatting === 'usdPositive' ||
      column.formatting === 'usdRound'
    )
      return {
        filterValueGetter: (params: ValueGetterParams) => {
          const value = lodashGet(params?.data, column.path);
          return typeof value === 'number' ? Math.round(value) : value;
        },
        ...numberColumnSettings,
      };

    return numberColumnSettings;
  }

  if (column.type === 'stringArray') {
    return {
      cellRenderer: StringListCellRenderer,
      cellRendererParams: {
        title: column.displayName,
      },
    };
  }

  if (column.formatting === 'longText') {
    return {
      cellRenderer: TooltipWrapperComponent,
    };
  }

  if (column.type === 'link' && column.formatting === 'url') {
    return {
      cellRenderer: LinkRenderer,
    };
  }

  if (column.type === 'link' && column.formatting === 'image') {
    return {
      cellRenderer: ImageCellRenderer,
    };
  }

  if (column.type === 'date' || column.formatting === 'date') {
    return getIsoDateColDef({
      headerClass: 'ag-left-aligned-header',
      type: ['rightAligned'],
    });
  }

  return null;
}
