import { GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { useEffect, useMemo, useState } from 'react';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import { Stack } from '@mui/material';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../../components/grid-renderers/FadeInGridWrapper';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { useSchemaToGrid } from '../../../../../util/schema-utils';
import { defaultColDef } from '../../KPICompanies/AssignedCompaniesColDefs';
import NotSentActionsCellRenderer from '../components/NotSentActionsCellRenderer';
import ResponsesActionsCellRenderer from '../components/ResponsesActionsCellRenderer';
import { SentRequestActions } from '../components/SentRequestActions';
import { withOverrides } from '../../../../../util/ag-grid-utils';
import { StringFormatterId } from '../../../../../util/formatters/StringFormatters';
import { AgFormatterService } from '../../../../../util/ag-formatter-service';
import { getFieldsForStatus, kpiAllRequestGridSchema } from './kpi-manage-grids.view-model';
import { KpiRequestManageViewModel } from './kpi-manage.view-model';

export type KpiManageGridProps = {
  requests: KpiRequestManageViewModel[];
  selectedStatus: KPIRequestStatus | string;
};

export function KpiManageGrid(props: KpiManageGridProps) {
  const { requests, selectedStatus } = props;
  const [gridApi, setGridApi] = useState<GridApi>();
  const schemaToGrid = useSchemaToGrid();
  const colDefs = useMemo(() => {
    return withOverrides(schemaToGrid(kpiAllRequestGridSchema(), getFieldsForStatus(selectedStatus)), {
      ['actions']: {
        pinned: 'right',
      },
      ['status']: {
        filterParams: {
          valueFormatter: AgFormatterService.get().getFormatterForId(
            StringFormatterId.camelCaseToCapitalizedString
          ),
        },
      },
    });
  }, [schemaToGrid, selectedStatus]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  useEffect(() => {
    if (!gridApi) return;

    const filteredData =
      selectedStatus === 'All' ? requests : requests.filter((request) => request.status === selectedStatus);

    gridApi.setFilterModel({});
    gridApi.setGridOption('rowData', filteredData);
  }, [gridApi, selectedStatus, requests]);

  return (
    <FadeInGridWrapper>
      <AgTable
        rowData={requests}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowGroupPanelShow='always'
        groupDefaultExpanded={-1}
        suppressMovableColumns={true}
        suppressRowDrag={true}
        statusBar={{
          statusPanels: [
            { statusPanel: 'agTotalRowCountComponent' },
            { statusPanel: 'agFilteredRowCountComponent' },
          ],
        }}
      />
    </FadeInGridWrapper>
  );
}

export function KpiManageActionCellRenderer(params: ICellRendererParams<KpiRequestManageViewModel>) {
  switch (params.data?.status) {
    case KPIRequestStatus.Rejected:
    case KPIRequestStatus.Accepted:
    case KPIRequestStatus.Submitted:
      return <ResponsesActionsCellRenderer {...params} />;
    case KPIRequestStatus.NotSent:
      return (
        <NotSentActionsCellRenderer {...(params as unknown as ICellRendererParams<IKPIRequestDataModel>)} />
      );
    case KPIRequestStatus.Sent:
      return <SentRequestActions {...(params as unknown as ICellRendererParams<IKPIRequestDataModel>)} />;
    case KPIRequestStatus.Archived:
    default:
      return null;
  }
}

export function KpiManageStatusCellRenderer(params: ICellRendererParams<KpiRequestManageViewModel>) {
  const { data } = params;
  const overdue = useMemo(() => data && isOverdue(data as IKPIRequestDataModel), [data]);
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      title={overdue ? 'Request is overdue' : ''}
    >
      {params.valueFormatted ?? params.value}
      {overdue && <AssignmentLateOutlinedIcon color={'warning'} />}
    </Stack>
  );
}

function isOverdue(request?: Pick<IKPIRequestDataModel, 'dueDate' | 'status'>) {
  const { dueDate, status } = request ?? {};
  return status === KPIRequestStatus.Sent && dueDate && new Date(dueDate).getTime() < new Date().getTime();
}
