import * as yup from 'yup';
import { percentField, usdField } from './common-schema-defs';
import { FundDataModel, fundFields, fundProfilesFormSchema } from './Fund.schema';

export enum FundViewModelCalcType {
  total = 'Total',
  lpOnly = 'LP Only',
}

export function fundViewModelFields() {
  return {
    tier1: yup.boolean().nullable().label('Tier 1: Preferred Return').default(true),
    tier2: yup.boolean().nullable().label('Tier 2: Catch-Up').default(true),
    tier3: yup.boolean().nullable().label('Tier 3: Super Return Split').default(true),
    totalAllocationAmount: usdField().nullable().default(null).label(''),
    totalAllocationPercentage: percentField().nullable().default(null).label('Total Allocation'),
  };
}

function fundViewModelBase() {
  return yup.object(fundViewModelFields());
}

export function fundViewModelSchema() {
  const { lpGpSplit, lpGpSplitThreshold, gpCatchUpPercentage, superReturnSplit } = fundFields();
  return fundProfilesFormSchema().shape({
    _viewModel: fundViewModelBase(),
    lpGpSplit: lpGpSplit.when('_viewModel.tier1', {
      is: true,
      then: (schema) => schema.required(),
    }),
    lpGpSplitThreshold: lpGpSplitThreshold.when('_viewModel.tier1', {
      is: true,
      then: (schema) => schema.required(),
    }),
    gpCatchUpPercentage: gpCatchUpPercentage.when('_viewModel.tier2', {
      is: true,
      then: (schema) => schema.required(),
    }),
    superReturnSplit: superReturnSplit.when('_viewModel.tier3', {
      is: true,
      then: (schema) => schema.required(),
    }),
  });
}

export type FundViewModel = Omit<
  yup.InferType<ReturnType<typeof fundViewModelSchema>>,
  | 'followOnInvestingWindowCloseDate'
  | 'initialInvestingWindowCloseDate'
  | 'investingWindowInceptionDate'
  | 'managementFeeTerminationDate'
> & {
  followOnInvestingWindowCloseDate?: string | null;
  initialInvestingWindowCloseDate?: string | null;
  investingWindowInceptionDate?: string | null;
  managementFeeTerminationDate?: string | null;
};

export function fromFundViewModel(fundViewModel: FundViewModel): FundDataModel {
  const { _viewModel, ...rest } = fundViewModel;
  const result = { ...rest };

  if (!_viewModel.tier1) {
    result.lpGpSplit = null;
    result.lpGpSplitThreshold = null;
  }
  if (!_viewModel.tier2) {
    result.gpCatchUpPercentage = null;
    result.enableGPCatchup = false;
  } else {
    result.enableGPCatchup = true;
  }
  if (!_viewModel.tier3) {
    result.superReturnSplit = null;
    result.enableSuperReturn = false;
  } else {
    result.enableSuperReturn = true;
  }

  return result;
}

export function toFundViewModel(fund: FundDataModel): FundViewModel {
  const totalAllocationPercentage =
    (fund.initialInvestmentAllocationPercentage ?? 0) +
    (fund.feesAndExpensesAllocationPercentage ?? 0) +
    (fund.reservesAllocationPercentage ?? 0);

  return {
    ...fund,
    _viewModel: {
      tier1: fund.lpGpSplit != null || fund.lpGpSplitThreshold != null,
      tier2: fund.enableGPCatchup || fund.gpCatchUpPercentage != null,
      tier3: fund.enableSuperReturn || fund.superReturnSplit != null,
      totalAllocationAmount: (totalAllocationPercentage / 100) * (fund.committedCapital ?? 0),
      totalAllocationPercentage,
    },
  };
}

export function fundCapitalAllocationSchemaStep1() {
  const {
    committedCapital,
    feesAndExpensesAllocationPercentage,
    feesAndExpensesAllocationAmount,
    initialInvestmentAllocationPercentage,
    initialInvestmentAllocationAmount,
    recyclingLimitPercentage,
    recyclingLimitAmount,
    reservesAllocationPercentage,
    reservesAllocationAmount,
  } = fundFields();
  const { totalAllocationAmount, totalAllocationPercentage } = fundViewModelFields();

  return yup.object({
    committedCapital: committedCapital.required().min(10_000_000).max(100_000_000_000),
    feesAndExpensesAllocationPercentage: feesAndExpensesAllocationPercentage.required().min(0),
    feesAndExpensesAllocationAmount: feesAndExpensesAllocationAmount,
    initialInvestmentAllocationPercentage: initialInvestmentAllocationPercentage.required().min(0),
    initialInvestmentAllocationAmount,
    recyclingLimitPercentage: recyclingLimitPercentage.required().min(0),
    recyclingLimitAmount,
    reservesAllocationPercentage: reservesAllocationPercentage.required().min(0),
    reservesAllocationAmount,
    _viewModel: yup.object({
      totalAllocationAmount,
      totalAllocationPercentage,
    }),
  });
}

export function fundCapitalAllocationSchemaStep2() {
  const {
    investingWindowInceptionDate,
    initialInvestingWindowCloseDate,
    followOnInvestingWindowCloseDate,
    managementFeeTerminationDate,
  } = fundFields();

  return yup.object({
    investingWindowInceptionDate: investingWindowInceptionDate.required(),
    initialInvestingWindowCloseDate,
    followOnInvestingWindowCloseDate,
    managementFeeTerminationDate: managementFeeTerminationDate.required(),
  });
}
