import { styled } from '@mui/system';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { FieldPath, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { companyState } from '../../../services/state/CompanyState';
import { FDMap } from '../../../util/data-structure/FDMap';
import { createForm, IFormField } from '../../../view-models/form.view-model';
import { selectedDetailsFieldsOrgState } from '../../AdminPanel/DataDisplay/DataDisplayState';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import {
  CompanyDetailsPanelViewModel,
  fromCompanyDetailsVM,
  toCompanyDetailsVM,
} from './CompanyDetailsPanel.view-model';
import { Panel } from './Panel';
import { useCompanyDetailsFields } from './useCompanyDetailsFields';
import { useDebounceCompanyUpdates } from './useDebounceCompanyUpdates';
import { useCompanyEditPerms } from './useEditPerms';

export const CompanyDetailsPanelBreakpoint = '380px';

export const FieldsWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(4rem, auto));
  gap: 0 2rem;
  min-height: 320px;
  @container fd-panel (width < ${CompanyDetailsPanelBreakpoint}) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
    min-height: 0;
  }
`;

export const FieldsPerPage = 8;

export interface IProfilePanelProps<T extends FieldValues> {
  companyId: number;
  fieldsToShow?: FieldPath<T>[];
  editable?: boolean;
  defaultPage?: number;
}

export function CompanyDetailsPanel({
  companyId,
  fieldsToShow,
  editable = true,
  defaultPage,
}: IProfilePanelProps<ICompanyDataModel>) {
  const selectedFields = useAtomValue(selectedDetailsFieldsOrgState);
  const setEditableFields = useCompanyEditPerms(companyId);

  const companyDetailsFields = useCompanyDetailsFields(fieldsToShow ?? selectedFields);
  const fields = useMemo(
    () => setEditableFields(companyDetailsFields),
    [companyDetailsFields, setEditableFields]
  );
  const { paginationConfig, visibleFields } = usePanelPagination({
    fields,
    initialPage: defaultPage,
  });

  return (
    <Panel
      title={'Company Details'}
      paginationConfig={paginationConfig}
      showContentOverflow={true}
      defaultPage={defaultPage}
    >
      <UnstyledFieldset disabled={!editable}>
        <CompanyDetailsPage fields={visibleFields} companyId={companyId} />
      </UnstyledFieldset>
    </Panel>
  );
}

interface ICompanyDetailsProps {
  fields: IFormField<unknown>[];
  companyId: number;
}
function CompanyDetailsPage(props: ICompanyDetailsProps) {
  const { fields, companyId } = props;
  const company = useRecoilValue(companyState(companyId))!;
  const companyMetrics = useRecoilValue(companyMetricsByIdState(companyId));

  const methods = useForm({
    values: toCompanyDetailsVM(company, companyMetrics),
  });

  const updateCompany = useDebounceCompanyUpdates(companyId);
  const onChange = (data: Partial<CompanyDetailsPanelViewModel>) => {
    return updateCompany(fromCompanyDetailsVM(data));
  };

  const form = createForm<CompanyDetailsPanelViewModel>({
    fields: FDMap.fromArray(fields, 'key'),
    variant: 'form-inline',
  });

  return (
    <FormProvider {...methods}>
      <FieldsWrapper>
        <FormFactoryNoLayout form={form} onChange={onChange} />
      </FieldsWrapper>
    </FormProvider>
  );
}

interface IPaginationConfig {
  paginationConfig: {
    onChange: React.Dispatch<React.SetStateAction<number>>;
    pageCount: number;
  };
}

export function usePanelPagination<T>({
  fields,
  itemsPerPage = FieldsPerPage,
  initialPage = 1,
}: {
  fields: T[];
  itemsPerPage?: number;
  initialPage?: number;
}): IPaginationConfig & { visibleFields: T[] } {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const visibleFields = fields.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return {
    paginationConfig: {
      onChange: setCurrentPage,
      pageCount: Math.ceil(fields.length / itemsPerPage),
    },
    visibleFields,
  };
}
