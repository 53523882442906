import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { BaseModel } from '../../../schemas/BaseModel.schema';
import { FundProjectedInvestmentAudit } from '../../../schemas/FundProjectedInvestmentAudit.schema';
import { FundInvestment, FundInvestmentTransaction } from '../../../schemas/FundReserves.schema';
import { selectedFundInvestmentsByCompanyNameState } from '../state/FPState';

export interface IProjectedTransactionAudit
  extends FundInvestmentTransaction,
    Pick<BaseModel, 'updatedAt' | 'updatedBy'> {}
export interface IProjectedTransactionAuditDiff extends IProjectedTransactionAudit {
  prevValues: Map<keyof FundInvestmentTransaction, unknown>;
}

export function useDiffReservesHistory(historyByCompany: Map<string, FundProjectedInvestmentAudit[]>) {
  const companyNameToInvestment = useAtomValue(selectedFundInvestmentsByCompanyNameState);

  return useCallback(
    (companyName: string) => {
      if (!historyByCompany) return [];
      const history = historyByCompany.get(companyName);
      const reserves = companyNameToInvestment.get(companyName);
      if (!history || !reserves) return [];

      return diffReservesAudit(history, reserves);
    },
    [historyByCompany, companyNameToInvestment]
  );
}

export function diffReservesAudit(history: FundProjectedInvestmentAudit[], reserves: FundInvestment) {
  const currentProjectedByUuid = new Map<
    string,
    FundInvestmentTransaction & Pick<BaseModel, 'updatedAt' | 'updatedBy'>
  >(
    reserves.transactions?.reduce(
      (acc, t) => {
        if (!t.hypothetical || !t.uuid) return acc;
        acc.push([
          t.uuid,
          {
            ...t,
            updatedAt: reserves.updatedAt || reserves.createdAt || '',
            updatedBy: reserves.updatedBy || reserves.createdBy || '',
          },
        ]);
        return acc;
      },
      [] as [string, FundInvestmentTransaction & Pick<BaseModel, 'updatedAt' | 'updatedBy'>][]
    )
  );

  const auditTransactionsByUuid = history.reduce((map, audit) => {
    const updatedAt = audit.updatedAt ?? audit.createdAt ?? '';
    const updatedBy = audit.updatedBy ?? audit.createdBy ?? '';
    audit.transactions.forEach((t) => {
      if (!t.uuid || !currentProjectedByUuid.has(t.uuid!)) return;
      const curr = map.get(t.uuid ?? '');
      if (curr) {
        curr.push({ ...t, updatedAt, updatedBy });
      } else {
        map.set(t.uuid, [{ ...t, updatedAt, updatedBy }]);
      }
    });
    return map;
  }, new Map<string, IProjectedTransactionAudit[]>());

  const allTransactionsAsc = sortAndDedupe(auditTransactionsByUuid);

  currentProjectedByUuid.forEach((t, uuid) => {
    const auditForTransaction = allTransactionsAsc.get(uuid);
    const currentState = {
      ...t,
    };
    if (auditForTransaction) {
      allTransactionsAsc.set(uuid, [...auditForTransaction, currentState]);
    } else {
      allTransactionsAsc.set(uuid, [currentState]);
    }
  });

  return diffTransactionAudits(allTransactionsAsc);
}

function auditComparator<T extends { updatedAt?: string | null; prevValues?: Map<string, unknown> }>(
  a: T,
  b: T
) {
  //TODO: check this w/ Emiliano - we can currently have identical updatedAt for a single transaction.uuid with changed values
  if (a.updatedAt === b.updatedAt && (a.prevValues?.size || b.prevValues?.size)) {
    return !a.prevValues?.size ? -1 : !b.prevValues?.size ? 1 : 0;
  }
  // only current transactions might not have updatedAt
  if (!a.updatedAt) return 1;
  if (!b.updatedAt) return -1;
  return a.updatedAt.localeCompare(b.updatedAt);
}

function sortAndDedupe(transactionMap: Map<string, IProjectedTransactionAudit[]>) {
  const dedupedAsc = new Map<string, IProjectedTransactionAudit[]>();
  transactionMap.forEach((audit, uuid) => {
    const sorted = [...audit].sort(auditComparator);
    const deduped: IProjectedTransactionAudit[] = [sorted.at(0)!];
    const uniqueMap = sorted.slice(1).reduce((acc, t) => {
      const key = `${t.amount}-${t.date}`;
      if (!acc.has(key)) {
        acc.set(key, t);
      }
      return acc;
    }, new Map<string, IProjectedTransactionAudit>());
    deduped.push(...uniqueMap.values());
    dedupedAsc.set(uuid, deduped);
  });
  return dedupedAsc;
}

/**
 *
 * @param auditTransactionMap: deduped transactions, sorted by updatedAt date and grouped by uuid
 * @returns diff sorted by updatedAt date, descending
 */
function diffTransactionAudits(auditTransactionMap: Map<string, IProjectedTransactionAudit[]>) {
  const diff: IProjectedTransactionAuditDiff[] = [];
  auditTransactionMap.forEach((transactions) => {
    for (let i = 0; i < transactions.length; i++) {
      const t = transactions[i];
      const prevValues = new Map<keyof FundInvestmentTransaction, unknown>();

      let include = i === 0;
      if (i > 0) {
        const previousT = transactions[i - 1];
        if (t.amount !== previousT.amount) {
          prevValues.set('amount', previousT.amount);
          include = true;
        }
        if (t.date !== previousT.date) {
          prevValues.set('date', previousT.date);
          include = true;
        }
      }

      if (include) {
        diff.push({
          ...t,
          prevValues,
        });
      }
    }
  });

  return diff.sort((a, b) => auditComparator(b, a));
}
