import { ColDef, KeyCreatorParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { RoundCellRenderer } from '../../../components/AgTable/cell-renderers/RoundCellRenderer';
import { StringListCellRenderer } from '../../../components/AgTable/cell-renderers/StringListCellRenderer';
import { UNKNOWN_GROUP_NAME } from '../../../components/AgTable/tableConstants';
import { field2ToField3, FieldEntity } from '../../../data-models/field2.data-model';
import { field3ToColumnDef } from '../../../data-models/field3.data-model';
import { IFinanceRoundDataModel, RoundSource } from '../../../data-models/finance-round.data-model';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { roundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { AgFormatterService } from '../../../util/ag-formatter-service';
import { getIsoDateColDef } from '../../../util/ag-grid-utils';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { getCurrencyColDefs, getIntegerColDefs } from '../common-grid-defs/commonColDefs';
import { InvestmentMenuCell } from '../common-grid-defs/InvestmentMenuCell';
import { RoundSourceRenderer } from '../common-grid-defs/RoundSourceRenderer';

export function useRoundTableColDefs(): ColDef<IFinanceRoundDataModel>[] {
  const roundsByIdMap = useAtomValue(roundsByIdMapAtom);
  const customFields = useRecoilValue(customFieldsByEntity);
  const roundFields = useMemo(() => customFields.get(FieldEntity.round) ?? [], [customFields]);
  const { showRoundSource } = useFlags<MaggieFeatureFlags>();

  return useMemo(() => {
    const defs: ColDef<IFinanceRoundDataModel>[] = [
      getIsoDateColDef({
        headerName: 'Round Date',
        field: 'roundDate',
        valueFormatter: AgFormatterService.get().getFormatterForId('date'),
        sortable: true,
        initialSort: 'desc',
      }),
      {
        headerName: 'Stage',
        enableRowGroup: true,
        cellRenderer: RoundCellRenderer,
        keyCreator: (params) => {
          return roundsByIdMap.get(params.data?.roundId ?? -1)?.displayName ?? UNKNOWN_GROUP_NAME;
        },
        valueGetter: (params: ValueGetterParams<IFinanceRoundDataModel>) =>
          roundsByIdMap.get(params.data?.roundId ?? -1)?.displayName ?? '',
      },
      {
        headerName: 'Round',
        field: 'name',
      },
      {
        headerName: 'Post Money Valuation',
        field: 'postMoneyValuation',
        ...getCurrencyColDefs(),
      },
      {
        headerName: 'Raise Amount',
        field: 'raiseAmount',
        ...getCurrencyColDefs(),
        aggFunc: 'sum',
      },
      {
        headerName: 'Lead Investor(s)',
        field: 'leadInvestors',
        cellRenderer: StringListCellRenderer,
        cellRendererParams: { title: 'Investors' },
      },
      {
        headerName: 'Co-Investors',
        field: 'coInvestors',
        suppressSizeToFit: true,
        minWidth: 200,
        cellRenderer: StringListCellRenderer,
        cellRendererParams: { title: 'Co-Investors' },
      },
      {
        headerName: 'Fully Diluted Shares',
        field: 'fullyDilutedShares',
        ...getIntegerColDefs(),
      },
      {
        headerName: '',
        maxWidth: 70,
        flex: 0,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: InvestmentMenuCell,
        pinned: 'right',
      },
    ];

    roundFields.forEach((field) => {
      const colDef = field3ToColumnDef<IFinanceRoundDataModel>(field2ToField3(field));

      defs.push(colDef);
    });
    if (showRoundSource) {
      defs.unshift({
        headerName: 'Source',
        field: 'source',
        keyCreator: RoundSourceFormatter,
        valueFormatter: RoundSourceFormatter,
        enableRowGroup: true,
        cellRenderer: RoundSourceRenderer,
        maxWidth: 110,
      });
    }

    return defs;
  }, [roundFields, roundsByIdMap, showRoundSource]);
}

export const SourceFormatMap: Record<RoundSource, string> = {
  [RoundSource.aumni]: 'Aumni',
  [RoundSource.foresight]: 'Foresight',
  [RoundSource.systemGenerated]: 'System Generated',
};
export function RoundSourceFormatter(params: KeyCreatorParams | ValueFormatterParams) {
  if (!params.value) return '';
  return SourceFormatMap[params.value as RoundSource];
}
