import { CSSProperties, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useAtomValue } from 'jotai/index';
import { Collapse, IconButton, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UnknownUser } from '../../data-models/user.data-model';
import { ICommentDataModel } from '../../pages/DealFlow2/data-models/comment.data-model';
import { usersByEmailMapAtom } from '../../services/state/AppConfigStateJ';
import { Comment } from './Comment';

const CommentsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface ICommentListProps {
  comments: ICommentDataModel[];
  handleDelete: (commentId: number) => void;
  handleUpdate: (comment: string, commentId: number) => Promise<void>;
  isUpdatingComment?: boolean;
  style?: CSSProperties;
}

export function CommentList({
  comments,
  handleDelete,
  handleUpdate,
  isUpdatingComment = false,
  style,
}: ICommentListProps) {
  const usersByEmail = useAtomValue(usersByEmailMapAtom);

  const sortedComments = useMemo(() => {
    return (comments ?? []).toSorted(
      ({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime()
    );
  }, [comments]);

  const [expanded, setExpanded] = useState(false);
  if (!sortedComments?.length) return null;
  const [latestComment, ...rest] = sortedComments;
  const latestCommentAuthorData = usersByEmail.get(latestComment.createdBy) ?? UnknownUser;

  return (
    <div>
      <Comment
        authorFirstName={latestCommentAuthorData?.firstName ?? ''}
        authorLastName={latestCommentAuthorData?.lastName ?? ''}
        authorEmail={latestCommentAuthorData?.email ?? ''}
        comment={sortedComments[0].comment}
        createdAt={new Date(sortedComments[0].createdAt)}
        key={sortedComments[0].id}
        id={sortedComments[0].id}
        isLoading={isUpdatingComment}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
      <Collapse in={expanded}>
        <div css={CommentsContainer} style={style}>
          {rest.map(({ createdBy, comment, createdAt, id, isLoading }) => {
            const user = usersByEmail.get(createdBy) ?? UnknownUser;
            return (
              <Comment
                authorFirstName={user.firstName}
                authorLastName={user.lastName}
                authorEmail={user?.email ?? ''}
                comment={comment}
                createdAt={new Date(createdAt)}
                key={id}
                id={id}
                isLoading={isLoading || isUpdatingComment}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
            );
          })}
        </div>
      </Collapse>

      {rest.length > 0 && (
        <Stack justifyContent={'center'} alignItems={'center'}>
          <IconButton
            onClick={() => setExpanded((prev) => !prev)}
            aria-expanded={expanded}
            title={expanded ? 'show less' : 'show more'}
          >
            <ExpandMoreIcon
              className={expanded ? 'rotate-180' : ''}
              sx={{
                transition: 'transform 0.3s ease-in-out',
                '&.rotate-180': { transform: 'rotate(180deg)' },
              }}
            />
          </IconButton>
        </Stack>
      )}
    </div>
  );
}
