import { css } from '@emotion/react';
import { TearSheetViewModel } from '@foresightdata/tear-sheet';
import React from 'react';

const tableStyle = css`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 0.85rem;

  @media print {
    font-size: 0.55rem;
    margin-top: 0.15rem;
  }
`;

const thStyle = css`
  text-align: left;
  padding: 0.75rem;
  background: #f7fafc;
  border-bottom: 1px solid #e2e8f0;
  color: #4a5568;
  font-weight: 600;

  @media print {
    padding: 0.15rem;
  }
`;

const tdStyle = css`
  padding: 0.75rem;
  border-bottom: 1px solid #e2e8f0;
  color: #2d3748;

  @media print {
    padding: 0.15rem;
  }
`;

const sectionTitleStyle = css`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #4a5568;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: left;
  padding: 0;
  display: block;
  width: 100%;

  @media print {
    font-size: 0.65rem;
    margin-bottom: 0.15rem;
  }
`;

interface FinancingHistoryProps {
  title: string;
  data: TearSheetViewModel['financingHistory'];
}

export const FinancingHistory: React.FC<FinancingHistoryProps> = ({ title, data }) => {
  return (
    <section>
      <h2 css={sectionTitleStyle}>{title}</h2>
      <table css={tableStyle}>
        <thead>
          <tr>
            <th css={thStyle}>Round</th>
            <th css={thStyle}>Stage</th>
            <th css={thStyle}>Invested Amount</th>
            <th css={thStyle}>Lead Investor</th>
            <th css={thStyle}>Post Money Valuation</th>
          </tr>
        </thead>
        <tbody>
          {data.map((round, index) => (
            <tr key={index}>
              <td css={tdStyle}>{round.round}</td>
              <td css={tdStyle}>{round.stage}</td>
              <td css={tdStyle}>{round.investedAmount}</td>
              <td css={tdStyle}>{round.leadInvestor}</td>
              <td css={tdStyle}>{round.postMoneyValuation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};
