import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { isDealFormOpenState } from '../../../components/Layout/Navbar/AddModal';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { customFieldsByEntityAtom } from '../../../services/state/AdminPanel/CustomFieldsStateJ';
import { AddDealForm } from './AddDealForm';
import { useResetAddDealFormState } from './AddDealFormState';

export function AddDealFormModal() {
  const [isDealFormOpen, setIsDealFormOpen] = useAtom(isDealFormOpenState);
  useAtomValue(customFieldsByEntityAtom);
  const resetFormState = useResetAddDealFormState();

  const onClose = useCallback(() => {
    setIsDealFormOpen(false);
    resetFormState();
  }, [setIsDealFormOpen, resetFormState]);

  if (!isDealFormOpen) return null;

  return (
    <GenericFallbacksWrapper>
      <BasicDialog open={isDealFormOpen} onClose={onClose} title={'Add New Deal'}>
        <AddDealForm onClose={() => setIsDealFormOpen(false)} />
      </BasicDialog>
    </GenericFallbacksWrapper>
  );
}
