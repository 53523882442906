import { styled } from '@mui/material/styles';

import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../../services/PermissionService';

import { AdditionalInfo2 } from './AdditionalInfo2';
import { FinancialInfo2 } from './FinancialInfo2';
import { GeneralInfo2 } from './GeneralInfo2';
import { DealCustomDataSection } from './DealCustomFields';

export const DATA_SECTION_WIDTH = '28.125rem';

const Container = styled('div')`
  display: grid;
  width: ${DATA_SECTION_WIDTH};
  gap: 0.75rem;
`;
const Section = styled('section')`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neutral[2]};
  padding: 1rem;
`;

const DrawerSection = styled(Section)`
  display: grid;
  grid-template-columns: 8rem 1fr;
  gap: 0.5rem;
  align-items: center;
  & label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface IDataSectionProps {
  deal: IDealDataModel;
  company: ICompanyDataModel;
}

export const DataSection = ({ deal, company }: IDataSectionProps) => {
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  return (
    <Container key={deal.id}>
      <DrawerSection>
        <GeneralInfo2 deal={deal} company={company} disableEdit={!canEditDeal} />
      </DrawerSection>
      <DrawerSection>
        <FinancialInfo2 deal={deal} disableEdit={!canEditDeal} />
      </DrawerSection>
      <DrawerSection>
        <AdditionalInfo2 deal={deal} />
      </DrawerSection>
      <DrawerSection>
        <DealCustomDataSection deal={deal} disableEdit={!canEditDeal} />
      </DrawerSection>
    </Container>
  );
};
