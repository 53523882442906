import { css } from '@emotion/react';
import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { IField } from '../../../../../data-models/field2.data-model';
import {
  IKPIRequestDataModel,
  IKPIRequestResponse,
  IKPIResponseFormSectionData,
} from '../../../../../data-models/kpi-requests.data-model';
import { IKPITableDataModel, IKPITemplate } from '../../../../../data-models/kpi-template.data-model';
import { SectionTitle } from '../../../../CompanyProfiles/Summary/components/SubSection';
import { KPIFieldFactory, KPIFieldFactoryProps } from '../../Sections/FormItem/KPIFieldFactory';
import { IKPIGridSectionProps, KPIAgGrid } from './KPIAgGrid';

const kpiWrapperCss = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem 0;
  row-gap: 1rem;
`;

const kpiGridTitleCss = css`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: max-content min-content;
  align-items: center;
`;

interface IKPIRenderedTemplateProps {
  onKPIGridDataUpdated: IKPIGridSectionProps['onKPIGridDataUpdated'];
  onSectionDataUpdated: KPIFieldFactoryProps['onChange'];
  request: IKPIRequestDataModel;
  response: IKPIRequestResponse;
  template: IKPITemplate;
  disabled?: boolean;
}

export function KPIRenderedTemplate(props: IKPIRenderedTemplateProps) {
  const { disabled, onKPIGridDataUpdated, onSectionDataUpdated, request, response, template } = props;

  const sections = useMemo(() => {
    if (template) {
      return (
        template.sections
          ?.toSorted((section1, section2) => {
            return (section1.meta.sortOrder ?? -1) - (section2.meta.sortOrder ?? -1);
          })
          .map((section, index) => {
            if (section.type === 'grid') {
              const { frequency, fye, granularity, period } = request;
              const gridSectionMeta = section.meta as IKPITableDataModel;

              return (
                <div key={index} css={kpiWrapperCss}>
                  <div css={kpiGridTitleCss}>
                    <SectionTitle title={`${index + 1}. ${gridSectionMeta.displayName}`} />
                    <Chip label={gridSectionMeta.section} color={'secondary'} />
                  </div>
                  <KPIAgGrid
                    companyFYEDateString={fye}
                    disabled={disabled}
                    kpiRequestFrequency={frequency}
                    kpiRequestGranularity={granularity}
                    kpiRequestPeriod={period}
                    kpiResponseData={response.kpiData.values}
                    onKPIGridDataUpdated={onKPIGridDataUpdated}
                    section={section}
                  />
                </div>
              );
            } else {
              const sectionData: IKPIResponseFormSectionData = response.sectionData.find(
                (data) => data.sectionId === section.id
              ) ?? {
                sectionId: section.id ?? -1,
                value: null,
              };

              return (
                <div key={index} css={kpiWrapperCss}>
                  <SectionTitle title={`${index + 1}. ${(section.meta as IField<unknown>).entityField}`} />
                  <KPIFieldFactory
                    companyId={request.companyId}
                    onChange={onSectionDataUpdated}
                    section={section}
                    sectionData={sectionData}
                    disabled={disabled}
                  />
                </div>
              );
            }
          }) ?? []
      );
    }
    return [];
  }, [
    template,
    request,
    response.kpiData.values,
    response.sectionData,
    onKPIGridDataUpdated,
    onSectionDataUpdated,
    disabled,
  ]);

  return <>{sections}</>;
}
