import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { getErrorMessage } from '../../../services/queryHelpers';
import { getJotaiSnapshot, goToJotaiSnapshot } from '../../../util/jotai-store';
import { DealComment } from '../data-models/comment.data-model';
import { allDealsState } from '../state/DealboardDataState';

export const deleteComment = ({ commentId }: { commentId: number }) => {
  return MaggieHttp.maggie.delete<DealComment>(`/dealComment/${commentId}`);
};

interface IDeleteCommentParams {
  dealId: number;
  commentId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteComment = () => {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useAtomCallback(
    useCallback(
      async (get, set, { commentId, dealId, onSuccess, onError }: IDeleteCommentParams) => {
        const snapshot = getJotaiSnapshot([allDealsState]);

        set(allDealsState, (current) => {
          if (!current) return current;
          return current.map((d) => {
            if (d.id === dealId) {
              const filteredComments = d.comments.filter(({ id }) => id !== commentId);
              return { ...d, comments: filteredComments };
            }
            return d;
          });
        });

        try {
          await deleteComment({ commentId });
          pushSuccessToast({ message: 'Comment deleted successfully' });
          onSuccess?.();
        } catch (err) {
          console.error(err);
          goToJotaiSnapshot(snapshot);
          const message = getErrorMessage(err, 'Failed to delete comment');
          pushErrorToast({ message });
          onError?.();
        }
      },
      [pushErrorToast, pushSuccessToast]
    )
  );
};
