import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { percentField, usdField } from './common-schema-defs';
import { FundDataModel, fundFields, fundProfilesFormSchema } from './Fund.schema';

export enum FundViewModelCalcType {
  total = 'Total',
  lpOnly = 'LP Only',
}

export const TimeWeightedType = {
  threshold: 'Threshold',
  hurdle: 'Hurdle',
} as const;
export type TimeWeightedType = (typeof TimeWeightedType)[keyof typeof TimeWeightedType];

export function fundViewModelFields() {
  return {
    tier1: yup
      .string()
      .oneOf(Object.values(TimeWeightedType))
      .nullable()
      .label('Type')
      .default(TimeWeightedType.threshold)
      .required()
      .formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.values(TimeWeightedType).map((value) => ({
            displayName: value,
            value,
          })),
        },
        disableClearable: true,
      }),
    // tier2: yup.boolean().nullable().label('Tier 2: Catch-Up').default(true),
    // tier3: yup.boolean().nullable().label('Tier 3: Super Return Split').default(true),
    totalAllocationAmount: usdField().nullable().default(null).label(''),
    totalAllocationPercentage: percentField().nullable().default(null).label('Total Allocation'),
  };
}

function fundViewModelBase() {
  return yup.object(fundViewModelFields());
}

export function fundViewModelSchema() {
  const {
    lpGpSplit,
    lpGpSplitThreshold,
    secondaryReturnHurdle,
    secondaryReturnSplit,
    gpCatchUpPercentage,
    preferredReturnHurdle,
    superReturnSplit,
  } = fundFields();
  return fundProfilesFormSchema().shape({
    _viewModel: fundViewModelBase(),
    lpGpSplit: lpGpSplit.when('_viewModel.tier1', {
      is: TimeWeightedType.threshold,
      then: (schema) => schema.required(),
    }),
    lpGpSplitThreshold: lpGpSplitThreshold.when('_viewModel.tier1', {
      is: TimeWeightedType.threshold,
      then: (schema) => schema.required(),
    }),
    preferredReturnHurdle: preferredReturnHurdle.when('_viewModel.tier1', {
      is: TimeWeightedType.hurdle,
      then: (schema) => schema.required(),
    }),
    secondaryReturnHurdle: secondaryReturnHurdle.when('isSecondaryReturnEnabled', {
      is: true,
      then: (schema) => schema.required(),
    }),
    secondaryReturnSplit: secondaryReturnSplit.when('isSecondaryReturnEnabled', {
      is: true,
      then: (schema) => schema.required(),
    }),
    gpCatchUpPercentage: gpCatchUpPercentage.when('enableGPCatchup', {
      is: true,
      then: (schema) => schema.required(),
    }),
    superReturnSplit: superReturnSplit.when('enableSuperReturn', {
      is: true,
      then: (schema) => schema.required(),
    }),
  });
}

export type FundViewModel = Omit<
  yup.InferType<ReturnType<typeof fundViewModelSchema>>,
  | 'followOnInvestingWindowCloseDate'
  | 'initialInvestingWindowCloseDate'
  | 'investingWindowInceptionDate'
  | 'managementFeeTerminationDate'
> & {
  followOnInvestingWindowCloseDate?: string | null;
  initialInvestingWindowCloseDate?: string | null;
  investingWindowInceptionDate?: string | null;
  managementFeeTerminationDate?: string | null;
};

export function fromFundViewModel(fundViewModel: FundViewModel): FundDataModel {
  const { _viewModel, ...rest } = fundViewModel;
  const result = { ...rest };

  if (_viewModel.tier1 === TimeWeightedType.hurdle) {
    result.isTimeWeighted = true;
    result.lpGpSplit = null;
    result.lpGpSplitThreshold = null;
  } else {
    // (_viewModel.tier1 === TimeWeightedType.threshold)
    result.isTimeWeighted = false;
    result.preferredReturnHurdle = null;
  }
  if (!fundViewModel.isSecondaryReturnEnabled) {
    result.secondaryReturnHurdle = null;
    result.secondaryReturnSplit = null;
  }
  if (!fundViewModel.enableGPCatchup) {
    result.gpCatchUpPercentage = null;
  }
  if (!fundViewModel.enableSuperReturn) {
    result.superReturnSplit = null;
  }

  return result;
}

export function toFundViewModel(fund: FundDataModel): FundViewModel {
  const totalAllocationPercentage =
    (fund.initialInvestmentAllocationPercentage ?? 0) +
    (fund.feesAndExpensesAllocationPercentage ?? 0) +
    (fund.reservesAllocationPercentage ?? 0);

  return {
    ...fund,
    _viewModel: {
      tier1: fund.isTimeWeighted ? TimeWeightedType.hurdle : TimeWeightedType.threshold,
      totalAllocationAmount: (totalAllocationPercentage / 100) * (fund.committedCapital ?? 0),
      totalAllocationPercentage,
    },
  };
}

export function fundCapitalAllocationSchemaStep1() {
  const {
    committedCapital,
    feesAndExpensesAllocationPercentage,
    feesAndExpensesAllocationAmount,
    initialInvestmentAllocationPercentage,
    initialInvestmentAllocationAmount,
    recyclingLimitPercentage,
    recyclingLimitAmount,
    reservesAllocationPercentage,
    reservesAllocationAmount,
  } = fundFields();
  const { totalAllocationAmount, totalAllocationPercentage } = fundViewModelFields();

  return yup.object({
    committedCapital: committedCapital.required().min(10_000_000).max(100_000_000_000),
    feesAndExpensesAllocationPercentage: feesAndExpensesAllocationPercentage.required().min(0),
    feesAndExpensesAllocationAmount: feesAndExpensesAllocationAmount,
    initialInvestmentAllocationPercentage: initialInvestmentAllocationPercentage.required().min(0),
    initialInvestmentAllocationAmount,
    recyclingLimitPercentage: recyclingLimitPercentage.required().min(0),
    recyclingLimitAmount,
    reservesAllocationPercentage: reservesAllocationPercentage.required().min(0),
    reservesAllocationAmount,
    _viewModel: yup.object({
      totalAllocationAmount,
      totalAllocationPercentage,
    }),
  });
}

export function fundCapitalAllocationSchemaStep2() {
  const {
    investingWindowInceptionDate,
    initialInvestingWindowCloseDate,
    followOnInvestingWindowCloseDate,
    managementFeeTerminationDate,
  } = fundFields();

  return yup.object({
    investingWindowInceptionDate: investingWindowInceptionDate.required(),
    initialInvestingWindowCloseDate,
    followOnInvestingWindowCloseDate,
    managementFeeTerminationDate: managementFeeTerminationDate.required(),
  });
}
