import { ColDef, KeyCreatorParams, ValueGetterParams } from 'ag-grid-community';
import { RoundRenderer } from '../../../../../components/AgTable/cell-renderers/RoundRenderer';
import { CompanyCellRenderer } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IDealStatsDataModel } from '../../../../../data-models/deal-stats.data-model';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { IRoundDataModel } from '../../../../../data-models/round.data-model';
import { getIsoDateColDef } from '../../../../../util/ag-grid-utils';
import { FMT } from '../../../../../util/formatter-service';
import { IDealStageDataModel } from '../../../data-models/dealStage.data-model';

export interface IDealAnalyticsGriData extends IDealDataModel {
  company: ICompanyDataModel;
  round: IRoundDataModel;
}

export function getColDefs(stages: IDealStageDataModel[]): ColDef[] {
  const colDefs: ColDef[] = [
    {
      cellRenderer: CompanyCellRenderer,
      comparator: (company1: ICompanyDataModel, company2: ICompanyDataModel) => {
        return company1.name.localeCompare(company2.name);
      },
      field: 'company',
      keyCreator: (params: KeyCreatorParams<IDealAnalyticsGriData>) => {
        return params.data?.company.name ?? '';
      },
      filterParams: {
        comparator: (name1: string, name2: string) => {
          return name1.localeCompare(name2);
        },
        valueGetter: (params: ValueGetterParams<IDealAnalyticsGriData>) => {
          return params.data?.company.name ?? '';
        },
      },
      headerName: 'Company',
      width: 200,
    },
    {
      cellRenderer: RoundRenderer,
      field: 'round',
      filterParams: {
        comparator: (name1: string, name2: string) => {
          return name1.localeCompare(name2);
        },
        valueGetter: (params: ValueGetterParams<IDealAnalyticsGriData>) => {
          return params.data?.round.displayName ?? '';
        },
      },
      headerName: 'Round',
      width: 170,
    },
    getIsoDateColDef({
      headerName: 'Date Added',
      field: 'createdAt',
      valueFormatter: (params) => {
        return FMT.format('date', params.data.createdAt);
      },
    }),
  ];

  for (const stage of stages) {
    colDefs.push({
      field: stage.name,
      headerName: stage.displayName,
      cellClass: 'ag-right-aligned-cell',
      filter: 'agNumberColumnFilter',
    });
  }

  return colDefs;
}

export function getDefaultColDef(): ColDef {
  return {
    resizable: true,
    filter: true,
    width: 150,
    flex: 1,
  };
}

export function dealStatsToRowData(
  data: IDealStatsDataModel[],
  roundsById: Map<number, IRoundDataModel>
): unknown[] {
  return data.map((item) => {
    const { deal, stages } = item;
    const stagesAsCol = stages.reduce(
      (res, stage) => {
        res[stage.name] = stage.duration;
        return res;
      },
      {} as Record<string, number>
    );

    return {
      ...deal,
      ...stagesAsCol,
      round: roundsById.get(deal.roundId) ?? '',
    };
  });
}
