import { Box } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { IForm } from '../../view-models/form.view-model';
import { FormTitle } from './FormComponents';
import { FormFieldAndLabelFactory } from './FormFieldAndLabelFactory';

export interface IFormFactoryProps<T> {
  form: IForm<T>;
}

export function FormFactoryWithStandardLayout<T>(props: IFormFactoryProps<T>) {
  const { form } = props;

  return (
    <Box sx={{ gridTemplateRows: 'min-content 1fr' }}>
      {form.title && <FormTitle title={form.title} />}
      <Box display={'grid'} gap='0.75rem' alignContent={'start'}>
        <FormItemsOnlyFactory form={form} />
      </Box>
    </Box>
  );
}

export interface IInlineFormFactoryProps<T> extends IFormFactoryProps<T> {
  changeDebounce?: number;
  onChange: (data: Partial<T>) => void;
}
export function FormFactoryNoLayout<T>(props: IInlineFormFactoryProps<T>) {
  const { onChange, changeDebounce, form } = props;
  useListenToFormChanges(onChange, changeDebounce);

  return <FormItemsOnlyFactory form={form} />;
}

function FormItemsOnlyFactory<T>(props: IFormFactoryProps<T>) {
  const { form } = props;
  const fieldsArray = Array.from(form.fields.values());

  if (fieldsArray.length === 0) {
    return <></>;
  }

  return (
    <>
      {fieldsArray.map((formField) => {
        if (form.variant) {
          formField.variant = form.variant;
        }

        return <FormFieldAndLabelFactory formField={formField} key={formField.key} />;
      })}
    </>
  );
}

export function useListenToFormChanges<T>(onChange: (data: Partial<T>) => void, changeDebounceTime = 1000) {
  const { formState, watch } = useFormContext();
  const lastUpdate = useRef('');
  const values = watch();

  const debouncedUpdate = useMemo(() => {
    return debounce(onChange, changeDebounceTime);
  }, [changeDebounceTime, onChange]);

  useEffect(() => {
    if (formState.isDirty) {
      const dirtyFieldIds = Object.keys(formState.dirtyFields);

      if (dirtyFieldIds.length === 0) {
        // Check to avoid MAGGIE-6583
        return;
      }

      const changeData = dirtyFieldIds.reduce((res, fieldId) => {
        res[fieldId as keyof T] = values[fieldId];

        return res;
      }, {} as Partial<T>);
      const changeDataStr = JSON.stringify(changeData);

      if (lastUpdate.current !== changeDataStr) {
        // FIXME MAGGIE-6583 - check needed before onChange triggers twice
        lastUpdate.current = changeDataStr;
        debouncedUpdate(changeData);
      }
    }
  }, [onChange, debouncedUpdate, formState.dirtyFields, formState.isDirty, values]);
}
