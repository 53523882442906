import { atom, atomFamily, selectorFamily } from 'recoil';
import { IPersonDataModel } from '../../../data-models/person.data-model';
import { fetchCompanyPeople } from '../../../services/queries/MaggieCompanyQueries';
import { getBoardMembersForCompany, getEmployeesForCompany } from '../utils/peopleHelpers';

export const peopleByCompanyState = atomFamily<IPersonDataModel[] | null, number>({
  key: 'peopleByCompanyState',
  default: selectorFamily({
    key: 'peopleByCompanyState/default',
    get: (companyId) => () => fetchCompanyPeople(companyId),
  }),
});

export const showHiddenPeopleState = atom({
  key: 'showHiddenPeopleState',
  default: false,
});

export const peopleSortedAndFilteredByVisibility = selectorFamily<IPersonDataModel[] | null, number>({
  key: 'sortedPeopleByCompanyState',
  get:
    (companyId) =>
    ({ get }) => {
      const people = get(peopleByCompanyState(companyId));
      const includeHidden = get(showHiddenPeopleState);
      if (!people) return null;
      const hiddenPeople = people.filter((p) => p.hidden);
      const visiblePeople = people.filter((p) => !p.hidden);
      return includeHidden ? [...visiblePeople, ...hiddenPeople] : visiblePeople;
    },
});

export const boardMembersByCompanyState = selectorFamily<IPersonDataModel[] | null, number>({
  key: 'boardMembersByCompanyState',
  get:
    (companyId) =>
    ({ get }) => {
      const people = get(peopleSortedAndFilteredByVisibility(companyId));
      if (!people) return null;
      return getBoardMembersForCompany(people);
    },
});

export const allEmployeesByCompanyState = selectorFamily<IPersonDataModel[] | null, number>({
  key: 'boardMembersByCompanyState',
  get:
    (companyId) =>
    ({ get }) => {
      const people = get(peopleSortedAndFilteredByVisibility(companyId));
      if (!people) return null;
      return getEmployeesForCompany(people);
    },
});
