import {
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  KeyCreatorParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { UNKNOWN_GROUP_NAME } from '../../../components/AgTable/tableConstants';
import { IPersonDataModel } from '../../../data-models/person.data-model';
import { selectedCompanyIdProfile } from '../state/UIState';
import { getLocation, getTitlesForCompany } from '../utils/peopleHelpers';
import { ConnectionsRenderer } from './CellRenderers/ConnectionsRenderer';
import { PersonLink } from './CellRenderers/PersonLink';
import { PersonRenderer } from './CellRenderers/PersonRenderer';
import { SocialMediaRenderer } from './CellRenderers/SocialMediaRenderer';
import { WorkHistoryRenderer } from './CellRenderers/WorkHistoryRenderer';

export enum NonExportableColumns {
  hide = 'hide',
  contacts = 'contacts',
}

export enum ExportableColumns {
  name = 'Name',
  title = 'Title',
  department = 'Department',
  workHistory = 'Work History',
  location = 'Location',
  relationships = 'Relationships',
  primaryEmail = 'Primary Email',
  secondaryEmails = 'Secondary Emails',
  linkedIn = 'LinkedIn',
  twitter = 'Twitter',
  relationshipStrengthScore = 'Relationship Strength',
  connections = 'Connections',
}

export function usePeopleColDefs() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  return useMemo(() => {
    const colDefs: ColDef<IPersonDataModel>[] = [
      {
        cellRenderer: PersonRenderer,
        headerName: 'Name',
        colId: ExportableColumns.name,
        keyCreator: (params: KeyCreatorParams) => {
          return params.data?.name ?? UNKNOWN_GROUP_NAME;
        },
        valueGetter: (params: ValueGetterParams<IPersonDataModel>) => {
          const person = params.data;
          return person ? `${person.firstName} ${person.lastName}` : '';
        },
      },
      {
        colId: ExportableColumns.title,
        headerName: 'Titles',
        valueGetter: (params: ValueGetterParams<IPersonDataModel>) => {
          const person = params.data;
          return person ? getTitlesForCompany(person, companyId) : '';
        },
      },
      {
        cellRenderer: SocialMediaRenderer,
        headerName: 'Contacts',
        colId: NonExportableColumns.contacts,
        filter: false,
        maxWidth: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<IPersonDataModel>) => {
          return params.data;
        },
      },

      {
        colId: ExportableColumns.department,
        headerName: 'Department',
        field: 'department',
      },
      {
        cellRenderer: WorkHistoryRenderer,
        colId: ExportableColumns.workHistory,
        headerName: 'Work History',
      },

      {
        valueGetter: (params: ValueGetterParams<IPersonDataModel>) => {
          const person = params.data;
          return person ? getLocation(person) : '';
        },
        filter: 'agTextColumnFilter',
        headerName: 'Location',
        colId: ExportableColumns.location,
      },
      {
        headerName: '',
        field: 'hidden',
        cellRenderer: PersonLink,
        colId: NonExportableColumns.hide,
        minWidth: 60,
        maxWidth: 60,
      },
      ...hiddenColumns,
    ];

    //// comment out for now, we'll probably need to add it back in
    // colDefs.splice(4, 0, {
    //   cellRenderer: RelationshipScoreRenderer,
    //   field: 'relationshipStrengthScore',
    //   headerName: 'Relationship Strength',
    //   colId: ExportableColumns.relationshipStrengthScore,
    // });

    colDefs.splice(5, 0, {
      cellRenderer: ConnectionsRenderer,
      field: 'relationships',
      headerName: 'Connections',
      colId: ExportableColumns.connections,
      minWidth: 170,
    });

    return colDefs;
  }, [companyId]);
}

const hiddenColumns: ColDef<IPersonDataModel>[] = [
  { field: 'primaryEmail', colId: ExportableColumns.primaryEmail, headerName: 'Primary Email', hide: true },
  {
    field: 'secondaryEmails',
    colId: ExportableColumns.secondaryEmails,
    headerName: 'Secondary Emails',
    hide: true,
  },
  {
    field: 'socials.linkedin',
    colId: ExportableColumns.linkedIn,
    headerName: 'LinkedIn',
    hide: true,
  },
  {
    field: 'socials.twitter',
    colId: ExportableColumns.twitter,
    headerName: 'Twitter',
    hide: true,
  },
];

export const exportParams: ExcelExportParams & CsvExportParams = {
  columnKeys: Object.values(ExportableColumns),
};
