import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { field2ToFormField, IField } from '../../data-models/field2.data-model';
import { field3ToFormField, IField3, isField3 } from '../../data-models/field3.data-model';
import { IFormField } from '../../view-models/form.view-model';
import { FormField as LegacyFormField } from '../FormField/FormField';
import { FieldWithOverride } from './Display/FieldWithOverride/FieldWithOverride';
import { ErrorText, FieldLabel, FormField, Label } from './FormComponents';
import { FormFieldFactory, IFormItemFactoryProps } from './FormFieldFactory';
import { FieldWithTimeSeriesButton } from './Display/FieldWithTimeSeries/FieldWithTimeSeriesButton';

export interface IFormFieldAndLabelFactoryProps<T> {
  formField: IFormField<unknown>;
  /** @deprecated use FormContext instead */
  initialValue?: T;
}

export function FormFieldAndLabelFactory<T>(props: IFormFieldAndLabelFactoryProps<T>) {
  const { formField, initialValue } = props;
  const { key, variant } = formField;

  if (formField.isCalculated) {
    return <FieldWithOverride formField={formField} />;
  }
  if (variant === 'form-inline') {
    return <FormFieldInlineWithLabelFactory key={key} formField={formField} initialValue={initialValue} />;
  }
  if (variant === 'form') {
    return <FormFieldWithLabelFactory key={key} formField={formField} initialValue={initialValue} />;
  }
  return <FormFieldWithLegacyLabelFactory key={key} formField={formField} initialValue={initialValue} />;
}

interface TempFormItemWithLabelProps<ValueType> extends IFormItemFactoryProps<ValueType> {
  item?: IField<unknown> | IField3<unknown>;
}

export function FormFieldWithLabelFactory<ValueType>(props: TempFormItemWithLabelProps<ValueType>) {
  const {
    formState: { errors },
  } = useFormContext();
  const { formField, initialValue, item } = props;
  const _formField = item ? getFormField(item, formField) : formField;
  const showErrorText = !item;
  const errorText = get(errors, _formField.key)?.message?.toString();

  return (
    <FormField key={_formField.key} data-fieldkey={formField.key}>
      <FormFieldLabelFactory formField={_formField} />
      <FormFieldFactory formField={_formField} initialValue={initialValue} />
      {showErrorText && <ErrorText>{errorText}</ErrorText>}
    </FormField>
  );
}

export function FormFieldInlineWithLabelFactory<ValueType>(props: TempFormItemWithLabelProps<ValueType>) {
  const { formField, initialValue, item } = props;
  const _formField = item ? getFormField(item, formField) : formField;

  return (
    <FormField key={_formField.key} data-fieldkey={formField.key}>
      <FormFieldLabelFactory formField={_formField} />
      <FormFieldFactory formField={_formField} initialValue={initialValue} />
    </FormField>
  );
}

export function FormFieldWithLegacyLabelFactory<ValueType>(props: TempFormItemWithLabelProps<ValueType>) {
  const { initialValue, item, formField } = props;
  const _formField = item ? getFormField(item, formField) : formField;
  const { description, label, required } = _formField;

  return (
    <LegacyFormField description={description} label={label} required={required} labelWidth='12rem'>
      <FormFieldFactory initialValue={initialValue} formField={_formField} />
    </LegacyFormField>
  );
}

export interface IFormFieldLabelFactoryProps<T> {
  formField: IFormField<T>;
}

export function FormFieldLabelFactory<ValueType>(props: IFormFieldLabelFactoryProps<ValueType>) {
  const { description, key, label, required, timeSeries } = props.formField;

  if (timeSeries) {
    return (
      <FieldLabel
        key={key}
        field={{
          ...props.formField,
          endAdornment: <FieldWithTimeSeriesButton formField={props.formField} key={key} />,
        }}
      />
    );
  }

  return (
    <Label key={key} required={required} description={description}>
      {label}
    </Label>
  );
}

function getFormField(item: IField<unknown> | IField3<unknown>, formField: IFormField<unknown>) {
  return isField3(item) ? field3ToFormField(item, formField) : field2ToFormField(item, formField);
}
