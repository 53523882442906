import { useAtomCallback } from 'jotai/utils';
import { useCallback, useState } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { getErrorMessage } from '../../../services/queryHelpers';
import { getJotaiSnapshot, goToJotaiSnapshot } from '../../../util/jotai-store';
import { DealComment, ICommentUpdate, ICommentUpdatePayload } from '../data-models/comment.data-model';
import { allDealsState } from '../state/DealboardDataState';

export interface IDealCommentUpdate extends ICommentUpdate {
  dealId: number;
}

export interface IUpdateDealCommentParams extends IDealCommentUpdate {
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ICommentDealUpdatePayload extends ICommentUpdatePayload {
  dealId: number;
}

const updateComment = ({ dealId, comment, commentId }: IDealCommentUpdate) => {
  return MaggieHttp.maggie.put<ICommentDealUpdatePayload, DealComment>(`/dealComment/${commentId}`, {
    dealId,
    comment,
  });
};

export const useUpdateComment = () => {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingId, setLoadingId] = useState<number | null>(null);

  const updateDealComment = useAtomCallback(
    useCallback(
      async (get, set, { dealId, comment, commentId, onSuccess, onError }: IUpdateDealCommentParams) => {
        setIsLoading(true);
        setLoadingId(commentId);

        const snapshot = getJotaiSnapshot([allDealsState]);

        set(allDealsState, (current) => {
          if (!current) return current;
          return current.map((d) => {
            if (d.id === dealId) {
              const updatedComments = d.comments.map((c) => {
                if (c.id === commentId) {
                  return { ...c, comment: comment };
                } else {
                  return c;
                }
              });
              return { ...d, comments: updatedComments };
            } else {
              return d;
            }
          });
        });
        try {
          const updatedComment = await updateComment({ dealId, comment, commentId });
          pushSuccessToast({ message: 'Comment successfully updated' });
          onSuccess?.();
          return updatedComment;
        } catch (err) {
          const message = getErrorMessage(err, 'An error occurred while trying to update comment');
          pushErrorToast({ message });
          goToJotaiSnapshot(snapshot);
          onError?.();
        } finally {
          setIsLoading(false);
          setLoadingId(null);
        }
      },
      [pushErrorToast, pushSuccessToast]
    )
  );

  return { updateDealComment, isLoading, loadingId };
};
