import { useAtomValue } from 'jotai';
import { Fragment, useMemo } from 'react';
import { SchemaDescription } from 'yup';
import { FMT } from '../../../util/formatter-service';
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderRow,
  StyledTableRow,
} from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { selectedFundStateFP } from '../state/FPState';
import { useFundMetricsFP } from '../useFundMetricsFP';
import { DistributionBar } from './DistributionBar2';
import { getWaterfallData, WaterfallGridData, waterfallGridDataSchema } from './FPWaterfallData2';
import { WaterfallModellingSummaryRow } from './WaterfallModellingSummaryRow2';

export function WaterfallModellingGrid() {
  const fund = useAtomValue(selectedFundStateFP);
  const { metrics: fundMetrics } = useFundMetricsFP();
  const { gridData, output } = useMemo(() => {
    if (!fundMetrics || !fund) return { gridData: [], output: {} as WaterfallGridData };
    const data = getWaterfallData(fundMetrics, fund);
    return data.reduce(
      (acc, tier) => {
        if (tier.key == 'Output') {
          return { ...acc, output: tier };
        } else {
          acc.gridData.push(tier);
          return acc;
        }
      },
      { gridData: [] as WaterfallGridData[], output: {} as WaterfallGridData }
    );
  }, [fundMetrics, fund]);
  const fieldDescriptions = useMemo(() => {
    return Object.entries(waterfallGridDataSchema().omit(['key']).fields).map(([fieldKey, value]) => ({
      key: fieldKey as keyof WaterfallGridData,
      desc: value.describe() as SchemaDescription,
    }));
  }, []);
  const rows = useMemo(() => {
    if (!gridData) return null;
    return gridData.map((row: WaterfallGridData) => (
      <StyledTableRow key={row.key}>
        {fieldDescriptions.map((f) => {
          const value = row[f.key];
          let type: 'text' | 'numeric' = 'text';
          let formattedValue = String(value ?? '');
          if (f.desc.type === 'number') {
            type = 'numeric';
            formattedValue = value != null ? FMT.format(f.desc.meta?.formatter || 'string', value) : '-';
          }
          if (f.key === 'visualization' && row) {
            return value ? <DistributionBar key={f.key} data={row} /> : <Fragment key={f.key}></Fragment>;
          }

          return (
            <StyledTableCell key={f.key} type={type!}>
              {formattedValue}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    ));
  }, [gridData, fieldDescriptions]);

  if (!fundMetrics || !fund || !output) return null;

  return (
    <>
      <StyledTable style={{ gridTemplateColumns: '5fr repeat(5, 4fr) 5fr' }}>
        <StyledTableHeaderRow>
          {fieldDescriptions.map((f, i) => (
            <StyledTableCell
              key={f.key}
              style={i === 0 ? { paddingLeft: '1rem' } : undefined}
              type={f.desc.type === 'number' ? 'numeric' : 'text'}
              header
            >
              {f.desc.label}
            </StyledTableCell>
          ))}
        </StyledTableHeaderRow>
        {rows}

        {output && <WaterfallModellingSummaryRow output={output} />}
      </StyledTable>
    </>
  );
}
