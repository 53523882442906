import { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { FundDataModel } from '../../../schemas/Fund.schema';
import { FDMap } from '../../../util/data-structure/FDMap';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import { createForm } from '../../../view-models/form.view-model';
import { FieldsWrapper, usePanelPagination } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { Panel } from '../../CompanyProfiles2/Summary/Panel';
import { useUpdateFund } from '../state/useUpdateFund';
import {
  digestViewModelToFundDataModel,
  FPDigestSchema,
  fundDataModelToDigestViewModel,
  useFPDigestSchema,
} from './useFPDigestSchema';

export function FPDigestPanel({ fund }: { fund: FundDataModel }) {
  const schemaToFormFields = useSchemaToFormFields();
  const updateFund = useUpdateFund();
  const schema = useFPDigestSchema(fund!);
  const fields = schemaToFormFields(schema);
  const { paginationConfig, visibleFields } = usePanelPagination({ fields, itemsPerPage: 10 });

  const methods = useForm<FPDigestSchema>({
    values: fundDataModelToDigestViewModel(fund),
  });

  const onChange = useCallback(
    async (data: Partial<FPDigestSchema>) => {
      const formUpdate = { ...data };
      const payload = digestViewModelToFundDataModel(fund.id, formUpdate);
      updateFund(payload);
    },
    [fund.id, updateFund]
  );

  const [lpCommitmentSplit, slpCommitmentSplit] = useWatch({
    control: methods.control,
    name: ['lpCommitmentSplit', 'slpCommitmentSplit'],
  });

  const { setValue } = methods;
  useEffect(() => {
    if (lpCommitmentSplit != null || slpCommitmentSplit != null) {
      setValue('gpCommitmentPercentage', 100 - (lpCommitmentSplit ?? 0) - (slpCommitmentSplit ?? 0));
    }
  }, [lpCommitmentSplit, slpCommitmentSplit, setValue]);

  const form = createForm<FPDigestSchema>({
    fields: FDMap.fromArray(visibleFields, 'key'),
    variant: 'form-inline',
  });

  return (
    <Panel title={'Digest'} paginationConfig={paginationConfig} showContentOverflow={true}>
      <FormProvider {...methods}>
        <FieldsWrapper style={{ minHeight: '22rem' }}>
          <FormFactoryNoLayout form={form} onChange={onChange} />
        </FieldsWrapper>
      </FormProvider>
    </Panel>
  );
}
