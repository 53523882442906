import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { IconButton, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import { FC, MouseEventHandler, useCallback } from 'react';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { dealSort } from '../../../../services/queries/MaggieDealQueries';
import { useUpdateDeal2 } from '../../hooks/useDealActions';
import { allDealsState } from '../../state/DealboardDataState';

interface IPriorityStarProps {
  deal: IDealDataModel;
}

export const PriorityStar: FC<IPriorityStarProps> = ({ deal }) => {
  const { priority } = deal;
  const { colors } = useTheme();
  const updateDeal = useUpdateDeal2();
  const setAllDealsState = useSetAtom(allDealsState);
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  const handleClick = useCallback(
    async (e: MouseEvent) => {
      if (!deal) return;
      e.stopPropagation();
      const newPriority = deal.priority === 1 ? 2 : 1;
      await updateDeal(deal, {
        priority: newPriority,
      });
      setTimeout(() => {
        setAllDealsState((currentDeals) => [...currentDeals].sort(dealSort));
      }, 500);
    },
    [deal, setAllDealsState, updateDeal]
  );

  // TODO
  return (
    <IconButton
      data-testid='priority-star'
      onClick={handleClick as unknown as MouseEventHandler<HTMLButtonElement>}
      sx={{ padding: '0.1rem' }}
      disabled={!canEditDeal}
    >
      {priority === 1 ? (
        <StarRoundedIcon htmlColor={colors.warning[50]} data-testid='yellow-star' />
      ) : (
        <StarOutlineRoundedIcon htmlColor={colors.neutral[50]} data-testid='neutral-star' />
      )}
    </IconButton>
  );
};
