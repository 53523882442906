import { useCallback, useState } from 'react';

import { getPageForIndex, getPageWithChange } from './data-display-utils';
import { IDisplayConfigStateParams } from './DisplayConfig';
import { IDraggableField } from './FieldOrderConfig';

export type ConfigStatus = 'saved' | 'modified' | 'initial';

export function useDisplayConfigState({
  selectedFields,
  setSelectedFields,
  handleSaveChanges,
  handleResetConfig,
  fieldsPerPage,
}: IDisplayConfigStateParams) {
  const [editStatus, setEditStatus] = useState<ConfigStatus>('initial');
  const [activePage, setActivePage] = useState(1);

  const onSave = useCallback(async () => {
    await handleSaveChanges();
    setEditStatus('saved');
  }, [handleSaveChanges]);

  const onAnyChange = useCallback(() => {
    setEditStatus('modified');
  }, []);

  const onReset = useCallback(async () => {
    await handleResetConfig();
    setEditStatus('saved');
    setActivePage(1);
  }, [handleResetConfig]);

  const onRemoveField = useCallback(
    (field: IDraggableField) => {
      if (!selectedFields) return;
      const { index, key } = field;
      setActivePage(getPageForIndex(index === selectedFields!.length - 1 ? index - 1 : index, fieldsPerPage));
      setSelectedFields(selectedFields.filter((f) => f !== key));
      onAnyChange();
    },
    [fieldsPerPage, onAnyChange, selectedFields, setSelectedFields]
  );

  const onReorder = useCallback(
    (groupedItems: Record<string, IDraggableField[]>) => {
      onAnyChange();
      const staleOrder = selectedFields ?? [];
      const newOrder = Object.values(groupedItems)
        .flat()
        .map((item) => item.key);

      setActivePage(getPageWithChange(staleOrder, newOrder, fieldsPerPage));
      setSelectedFields(newOrder);
    },
    [onAnyChange, selectedFields, fieldsPerPage, setSelectedFields]
  );

  const onAddField = useCallback(
    (value: string) => {
      if (!selectedFields?.length) return;
      setActivePage(getPageForIndex(selectedFields.length, fieldsPerPage));
      setSelectedFields([...(selectedFields ?? []), value]);
      onAnyChange();
    },
    [fieldsPerPage, onAnyChange, selectedFields, setSelectedFields]
  );

  return { editStatus, activePage, onSave, onReset, onRemoveField, onReorder, onAddField };
}
