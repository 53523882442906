import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  CompanySearchResponse,
  createCompanySearchResponse,
} from '../../../schemas/CompanySearchResponse.schema';
import { portCosByNameMapState } from '../../../services/state/PortCosState';
import { companyToSearchResult } from '../../../util/companyUtils';
import {
  fundReservesTransactionUuidToInvestmentState,
  selectedFundCompanyNameState,
  selectedFundInvestmentsByCompanyNameState,
  selectedInvestmentTransactionUuidState,
  showReservesFormState,
  useResetReservesFormState,
} from '../state/FPState';

export function useReservesFormState() {
  const showForm = useAtomValue(showReservesFormState);
  const selectedCompanyName = useAtomValue(selectedFundCompanyNameState);
  const portcosByName = useRecoilValue(portCosByNameMapState);

  const selectedCompany = useMemo(() => {
    if (!selectedCompanyName) return;
    const portco = portcosByName.get(selectedCompanyName ?? '');
    if (portco) return companyToSearchResult(portco);

    return createCompanySearchResponse({
      fields: { name: selectedCompanyName ?? '' },
    } as CompanySearchResponse);
  }, [portcosByName, selectedCompanyName]);

  const selectedTransactionUuid = useAtomValue(selectedInvestmentTransactionUuidState);
  const transactionsByUuid = useAtomValue(fundReservesTransactionUuidToInvestmentState);
  const selectedTransaction = useMemo(() => {
    return transactionsByUuid
      .get(selectedTransactionUuid ?? '')
      ?.transactions?.find((t) => t.uuid === selectedTransactionUuid);
  }, [transactionsByUuid, selectedTransactionUuid]);
  const companyNameToInvestment = useAtomValue(selectedFundInvestmentsByCompanyNameState);
  const selectedInvestment =
    companyNameToInvestment.get(selectedCompanyName ?? '') ??
    transactionsByUuid.get(selectedTransactionUuid ?? '');
  const resetFormState = useResetReservesFormState();

  return {
    resetFormState,
    selectedCompany,
    selectedCompanyName,
    selectedInvestment,
    selectedTransaction,
    showForm,
  };
}
