import * as yup from 'yup';
import { merge } from 'lodash-es';
import { FundMetricsByFund } from '../../../schemas/FundMetrics.schema';
import { usdField } from '../../../schemas/common-schema-defs';
import { FundDataModel } from '../../../schemas/Fund.schema';

export function distributionSegmentFields() {
  return {
    highlight: yup.boolean().nullable().default(false),
    percentValue: yup.number().required().default(0),
  };
}

export function distributionSegmentSchema() {
  return yup.object().shape(distributionSegmentFields());
}

export type DistributionSegment = yup.InferType<ReturnType<typeof distributionSegmentSchema>>;

export function createDistributionSegment(overrides: Partial<DistributionSegment> = {}): DistributionSegment {
  return merge({}, distributionSegmentSchema().getDefault(), overrides);
}
export const WfGridDataKey = {
  Initial: 'initial',
  Tier0: '0',
  isProfitLossIncludedInSLPCapital: 'isProfitLossIncludedInSLPCapital',
  isIdleFundsIncomeIncluded: 'isIdleFundsIncomeIncluded',
  isExpensesRestored: 'isExpensesRestored',
  isProfitLossIncludedInGPCapital: 'isProfitLossIncludedInGPCapital',
  isManagementFeeRestored: 'isManagementFeeRestored',
  Tier1: 'tier1',
  isSecondaryReturnEnabled: 'isSecondaryReturnEnabled',
  enableGPCatchup: 'enableGPCatchup',
  enableSuperReturn: 'enableSuperReturn',
  AddBackDistributions: 'addBackDistributions',
  Output: 'Output',
} as const;
export type WfGridDataKey = (typeof WfGridDataKey)[keyof typeof WfGridDataKey];

export const TierName: Record<WfGridDataKey, string> = {
  [WfGridDataKey.Initial]: 'Initial',
  [WfGridDataKey.Tier0]: 'Return of Capital',
  [WfGridDataKey.isProfitLossIncludedInSLPCapital]: 'Profit (Loss) at SLP Capital %',
  [WfGridDataKey.isIdleFundsIncomeIncluded]: 'Idle Funds',
  [WfGridDataKey.isExpensesRestored]: 'Management Fees to LPs',
  [WfGridDataKey.isProfitLossIncludedInGPCapital]: 'Profit (Loss) at GP Capital %',
  [WfGridDataKey.isManagementFeeRestored]: 'Restore Management Fees',
  [WfGridDataKey.Tier1]: 'Preferred Return',
  [WfGridDataKey.isSecondaryReturnEnabled]: 'Preferred Return - Secondary',
  [WfGridDataKey.enableGPCatchup]: 'Catch Up',
  [WfGridDataKey.enableSuperReturn]: 'Super Return',
  [WfGridDataKey.AddBackDistributions]: 'Add Back Distributions',
  [WfGridDataKey.Output]: '',
} as const;
export type TierName = (typeof TierName)[keyof typeof TierName];

export function waterfallGridDataFields() {
  return {
    key: yup.string().oneOf(Object.values(WfGridDataKey)).required(),
    tier: yup.string().oneOf(Object.values(TierName)).nullable().label('Tier').default(null),
    distributableProceeds: usdField().nullable().label('Distributable Proceeds').default(null),
    totalDistributed: usdField().nullable().label('Total Distributed').default(null),
    lpDistributed: usdField().nullable().label('LP Distributed').default(null),
    gpDistributed: usdField().nullable().label('GP Distributed').default(null),
    gpCarry: usdField().nullable().label('GP Carry').default(null),
    visualization: yup.array().of(distributionSegmentSchema()).nullable().label('Visualization').default([]),
  };
}

export function waterfallGridDataSchema() {
  return yup.object().shape(waterfallGridDataFields());
}

export type WaterfallGridData = yup.InferType<ReturnType<typeof waterfallGridDataSchema>>;

export function createWaterfallGridData(overrides: Partial<WaterfallGridData> = {}): WaterfallGridData {
  return merge({ key: '' }, waterfallGridDataSchema().getDefault(), overrides);
}

export function getWaterfallData(metricsByFund: FundMetricsByFund, fund: FundDataModel): WaterfallGridData[] {
  const initialDistributableProceeds = metricsByFund.metrics.distributableProceedsUnadjusted;
  return Object.values(WfGridDataKey)
    .reduce((acc, tier) => {
      const tierData = getWaterFallDataForTier(tier, fund, metricsByFund);
      if (tierData) {
        return [...acc, tierData];
      }
      return acc;
    }, [] as WaterfallGridData[])
    .reduce((acc, curr) => {
      const prev = acc.at(-1);
      const visualization = getDistributions(
        curr,
        initialDistributableProceeds ?? 0,
        prev?.visualization ?? []
      );
      return [...acc, { ...curr, visualization }];
    }, [] as WaterfallGridData[]);
}

export function getDistributions(
  data: WaterfallGridData,
  initialDistributableProceeds: number,
  prevSegments: DistributionSegment[] = []
): DistributionSegment[] | null {
  if (data.key === WfGridDataKey.Output || data.key === WfGridDataKey.AddBackDistributions) {
    return null;
  }
  const { totalDistributed } = data;
  const tierSegment = createDistributionSegment({
    percentValue: (totalDistributed ?? -0) / initialDistributableProceeds,
    highlight: true,
  });

  const result: DistributionSegment[] = prevSegments.map((s) => ({ ...s, highlight: false }));
  result.push(tierSegment);
  return result;
}

function getWaterFallDataForTier(
  tierKey: WfGridDataKey,
  fund: FundDataModel,
  metricsByFund: FundMetricsByFund
): WaterfallGridData | null {
  const metrics = metricsByFund.metrics;
  const initialDistributableProceeds = metrics.distributableProceedsUnadjusted;

  switch (tierKey) {
    case WfGridDataKey.Initial: {
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.initial,
        distributableProceeds: initialDistributableProceeds,
      });
    }
    case WfGridDataKey.Tier0: {
      const lpDistributed = metrics.lpROC ?? 0;
      const gpDistributed = metrics.gpROC ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName[0],
        distributableProceeds: initialDistributableProceeds,
        totalDistributed: lpDistributed + gpDistributed,
        lpDistributed,
        gpDistributed,
        gpCarry: null,
      });
    }

    case WfGridDataKey.isProfitLossIncludedInSLPCapital: {
      if (!fund.isProfitLossIncludedInSLPCapital) return null;
      const lpDistributed = metrics.profitAtSLPCapital ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.isProfitLossIncludedInSLPCapital,
        distributableProceeds: metrics.distributableProceedsPostROC,
        totalDistributed: lpDistributed,
        lpDistributed,
        gpDistributed: null,
        gpCarry: null,
      });
    }
    case WfGridDataKey.isIdleFundsIncomeIncluded: {
      if (!fund.isIdleFundsIncomeIncluded) return null;
      const lpDistributed = metrics.idleFundsIncomeLP ?? 0;
      const gpDistributed = metrics.idleFundsIncomeGP ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.isIdleFundsIncomeIncluded,
        distributableProceeds: metrics.distributableProceedsPostSLPCapital,
        totalDistributed: lpDistributed + gpDistributed,
        lpDistributed,
        gpDistributed,
        gpCarry: null,
      });
    }
    case WfGridDataKey.isExpensesRestored: {
      if (!fund.isExpensesRestored) return null;
      const lpDistributed = metrics.managementFeeLP ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.isExpensesRestored,
        distributableProceeds: metrics.distributableProceedsPostIdleFundsIncome,
        totalDistributed: lpDistributed,
        lpDistributed,
        gpDistributed: null,
        gpCarry: null,
      });
    }
    case WfGridDataKey.isProfitLossIncludedInGPCapital: {
      if (!fund.isProfitLossIncludedInGPCapital) return null;
      const gpDistributed = metrics.gpCapital ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.isProfitLossIncludedInGPCapital,
        distributableProceeds: metrics.distributableProceedsPostManagementFee,
        totalDistributed: gpDistributed,
        lpDistributed: null,
        gpDistributed,
        gpCarry: null,
      });
    }
    case WfGridDataKey.isManagementFeeRestored: {
      if (!fund.isManagementFeeRestored) return null;
      const lpDistributed = metrics.managementFeeRestored ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.isManagementFeeRestored,
        distributableProceeds: metrics.distributableProceedsPostGPCapital,
        totalDistributed: lpDistributed,
        lpDistributed,
        gpDistributed: null,
        gpCarry: null,
      });
    }
    case WfGridDataKey.Tier1: {
      const lpDistributed = metrics.navFromLpGpSplitLP ?? 0;
      const gpDistributed = metrics.navFromLpGpSplitGp ?? 0;
      const gpCarry = metrics.gpIncentiveFromLpGpSplit ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName[WfGridDataKey.Tier1],
        distributableProceeds: metrics.amountAvailableForLpGpSplit,
        totalDistributed: lpDistributed + gpDistributed + gpCarry,
        lpDistributed,
        gpDistributed,
        gpCarry,
      });
    }
    case WfGridDataKey.isSecondaryReturnEnabled: {
      if (!fund.isSecondaryReturnEnabled) return null;
      const lpDistributed = metrics.navFromSecondaryReturnLP ?? 0;
      const gpDistributed = metrics.navFromSecondaryReturnGP ?? 0;
      const gpCarry = metrics.secondaryReturnGPIncentive ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName[WfGridDataKey.isSecondaryReturnEnabled],
        distributableProceeds: metrics.amountAvailableForSecondaryReturn,
        totalDistributed: lpDistributed + gpDistributed + gpCarry,
        lpDistributed,
        gpDistributed,
        gpCarry,
      });
    }
    case WfGridDataKey.enableGPCatchup: {
      if (!fund.enableGPCatchup) return null;
      const gpCarry = metrics.gpIncentiveFromGpCatchup ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName[WfGridDataKey.enableGPCatchup],
        distributableProceeds: metrics.availableForGpCatchup,
        totalDistributed: gpCarry,
        lpDistributed: null,
        gpDistributed: null,
        gpCarry,
      });
    }
    case WfGridDataKey.enableSuperReturn: {
      if (!fund.enableSuperReturn) return null;
      const lpDistributed = metrics.navFromSuperReturnLP ?? 0;
      const gpDistributed = metrics.navFromSuperReturnGP ?? 0;
      const gpCarry = metrics.gpIncentiveFromSuperReturn ?? 0;
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName[WfGridDataKey.enableSuperReturn],
        distributableProceeds: metrics.availableForSuperReturn,
        totalDistributed: lpDistributed + gpDistributed + gpCarry,
        lpDistributed,
        gpDistributed,
        gpCarry,
      });
    }
    case WfGridDataKey.AddBackDistributions: {
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.addBackDistributions,
        lpDistributed: metrics.lpDistributions ? -metrics.lpDistributions : 0,
        gpDistributed: metrics.gpNonTaxDistribution ? -metrics.gpNonTaxDistribution : 0,
        gpCarry: metricsByFund.mostRecentGPTaxDistribution ? -metricsByFund.mostRecentGPTaxDistribution : 0,
      });
    }
    case WfGridDataKey.Output: {
      return createWaterfallGridData({
        key: tierKey,
        tier: TierName.Output,
        lpDistributed: metrics.lpNavTotal,
        gpDistributed: metrics.gpPortionTotal,
        gpCarry: metrics.gpIncentive,
      });
    }
    default: {
      return null;
    }
  }
}
