import { ChangeEvent } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { RendererType } from '../../../../../data-models/field.data-model';
import { field2ToFormField, IField } from '../../../../../data-models/field2.data-model';
import { IKPIResponseFormSectionData } from '../../../../../data-models/kpi-requests.data-model';
import { IKPITemplateSectionDataModel } from '../../../../../data-models/kpi-template.data-model';
import { IFormField, IFormFieldUploadMeta } from '../../../../../view-models/form.view-model';
import { FieldKPIFileUpload2 } from '../../../../../components/Form/Display/FieldKPIFileUpload2';

export type KPIFieldFactoryProps = {
  companyId: number;
  onChange: (data: IKPIResponseFormSectionData) => void;
  section: IKPITemplateSectionDataModel;
  sectionData: IKPIResponseFormSectionData;
  disabled?: boolean;
};

export function KPIFieldFactory(props: KPIFieldFactoryProps) {
  const { formField, formProps } = kpiSectionToFormFieldProps(props);

  return formField.renderer === RendererType.fileUpload ? (
    <FieldKPIFileUpload2 formField={formField as IFormField<IFormFieldUploadMeta>} formProps={formProps} />
  ) : (
    <FieldFactory formField={formField} formProps={formProps} />
  );
}

function kpiSectionToFormFieldProps(config: KPIFieldFactoryProps) {
  const { companyId, disabled, onChange, section, sectionData } = config;
  const formProps = {
    onChange: (newValue: unknown) => {
      onChange({
        sectionId: section.id!,
        value: newValue,
      });
    },
    value: sectionData.value,
  } as ControllerRenderProps;
  const formField = field2ToFormField(section.meta as IField<unknown>);

  if (formField.renderer === RendererType.text) {
    formField.fullWidth = true;
    formField.rendererMeta = {
      multiline: true,
      rows: 4,
    };
    formProps.onChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        sectionId: section.id!,
        value: event.target.value,
      });
    };
  } else if (formField.renderer === RendererType.multiSelect) {
    formProps.onChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        sectionId: section.id!,
        value: event.target.value,
      });
    };
  } else if (formField.renderer === RendererType.fileUpload) {
    formField.rendererMeta = {
      companyId,
      multiple: true,
    } as IFormFieldUploadMeta;
  }

  if (disabled) {
    formProps.disabled = true;
  }

  return { formField, formProps };
}
