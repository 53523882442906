import { atomFamily } from 'jotai/utils';
import { atom } from 'jotai';
import { allDealsState } from '../../pages/DealFlow2/state/DealboardDataState';

export const DealsByCompanyIdState = atomFamily((companyId: number) =>
  atom((get) => {
    const allDeals = get(allDealsState);

    return allDeals.filter((deal) => deal.companyId === companyId);
  })
);

export const DealsForCompanyLoadedState = atomFamily(() => atom(false));
