import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  KPIFileUploadedType,
  IKPIRequestDataModel,
  IKPIRequestResponse,
  KPIRequestStatus,
} from '../../../data-models/kpi-requests.data-model';
import {
  fetchKPIRequests,
  fetchKPIRequestById,
  fetchKPIRequestResponseById,
  fetchKPIRequestUploadedFileFromS3,
} from '../../queries/KPIRequestsQueries';

export const kpisAssignRequestModalState = atom<number>({
  key: 'kpiAssignedRequestModalState',
  default: -1,
});

export const kpisSelectedRequestToDeleteModalState = atom<number>({
  key: 'kpiSelectedRequestToDeleteModalState',
  default: 0,
});

const ALLOWED_KPI_REQUEST_STATUS = new Set<string>([
  KPIRequestStatus.ManagedServiceSubmitted,
  KPIRequestStatus.ManagedServiceInProgress,
  KPIRequestStatus.ManagedServiceFinished,
  KPIRequestStatus.Submitted,
  KPIRequestStatus.Accepted,
  KPIRequestStatus.Rejected,
]);

export const kpisRequestsListState = atom<IKPIRequestDataModel[] | null>({
  key: 'kpiRequestsListState',
  default: selector({
    key: 'kpiRequestsListState/Default',
    get: () => {
      return fetchKPIRequests();
    },
  }),
});

export const kpiRequestsByCompanyIdState = atomFamily<IKPIRequestDataModel[], number>({
  key: 'kpiResponsesByCompanyIdState',
  default: selectorFamily({
    key: 'kpiResponsesByCompanyIdState/Default',
    get: (companyId) => async () => {
      return Promise.all([
        fetchKPIRequests({ companyId, status: KPIRequestStatus.Accepted }),
        fetchKPIRequests({ companyId, status: KPIRequestStatus.Rejected }),
      ]).then(([accepted, rejected]) => {
        return [...(accepted ?? []), ...(rejected ?? [])];
      });
    },
  }),
});

export const kpiRequestsCountState = selector<Record<KPIRequestStatus, number>>({
  key: 'kpiRequestsCountState',
  get: ({ get }) => {
    const requests = get(kpisRequestsListState);
    const kpisRequestsCount: Record<KPIRequestStatus, number> = {
      [KPIRequestStatus.Submitted]: 0,
      [KPIRequestStatus.Accepted]: 0,
      [KPIRequestStatus.Rejected]: 0,
      [KPIRequestStatus.NotSent]: 0,
      [KPIRequestStatus.Sent]: 0,
      [KPIRequestStatus.Archived]: 0,
      [KPIRequestStatus.ManagedServiceSubmitted]: 0,
      [KPIRequestStatus.ManagedServiceInProgress]: 0,
      [KPIRequestStatus.ManagedServiceFinished]: 0,
    };

    requests?.reduce((acc, request) => {
      if (!request.status) return acc;
      acc[request.status as KPIRequestStatus] += 1;
      return acc;
    }, kpisRequestsCount);
    return kpisRequestsCount;
  },
});

export const kpisRequestsMapByIdState = selector({
  key: 'kpiRequestsMapByIdState',
  get: ({ get }) => {
    const kpisRequestsList = get(kpisRequestsListState);
    const kpisRequestsMap = new Map<number, IKPIRequestDataModel>();

    if (!kpisRequestsList) return kpisRequestsMap;

    kpisRequestsList.forEach((request) => {
      kpisRequestsMap.set(request.id, request);
    });

    return kpisRequestsMap;
  },
});

export const filterKPIRequestsByStatuses = selectorFamily<IKPIRequestDataModel[], KPIRequestStatus[]>({
  key: 'filterKPIRequestsByStatuses',
  get:
    (statuses) =>
    ({ get }) => {
      const kpisRequests = get(kpisRequestsListState);

      return (
        kpisRequests?.filter((request) => {
          return (statuses as KPIRequestStatus[]).includes(request.status as KPIRequestStatus);
        }) ?? []
      );
    },
});

export const kpisRequestByIdState = atomFamily<IKPIRequestDataModel | null, number>({
  key: 'kpiRequestByIdState',
  default: selectorFamily({
    key: 'kpiRequestByIdState/Default',
    get: (requestId) => () => {
      try {
        return fetchKPIRequestById(requestId);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  }),
});

/** @deprecated */
export const kpisRequestResponseByIdState = atomFamily<IKPIRequestResponse | null, number>({
  key: 'kpiRequestResponseByIdState',
  default: selectorFamily({
    key: 'kpiRequestResponseByIdState/Default',
    get:
      (requestId) =>
      async ({ get }) => {
        if (requestId === -1) {
          return null;
        }
        const kpiRequest = get(kpisRequestByIdState(requestId));
        if (kpiRequest && ALLOWED_KPI_REQUEST_STATUS.has(kpiRequest?.status)) {
          try {
            const response = await fetchKPIRequestResponseById(kpiRequest.id);
            return response;
          } catch (error) {
            return null;
          }
        }
        return null;
      },
  }),
});

// FIXME: once `enableResponseAutosave` flag is no longer needed,
// this should replace kpiRequestResponseByIdState
export const kpisRequestResponseByIdState2 = atomFamily<IKPIRequestResponse | null, number>({
  key: 'kpiRequestResponseByIdState2',
  default: selectorFamily({
    key: 'kpiRequestResponseByIdState2/Default',
    get:
      (requestId) =>
      async ({ get }) => {
        if (requestId === -1) {
          return null;
        }
        const kpiRequest = get(kpisRequestByIdState(requestId));
        if (
          kpiRequest &&
          ALLOWED_KPI_REQUEST_STATUS.add(KPIRequestStatus.Sent).has(kpiRequest?.status as KPIRequestStatus)
        ) {
          try {
            const response = await fetchKPIRequestResponseById(kpiRequest.id);
            return response;
          } catch (error) {
            return null;
          }
        }
        return null;
      },
  }),
});

type kpisRequestUploadedFileS3UrlParams = KPIFileUploadedType & {
  startDownloadFile?: boolean;
};

export const kpisRequestUploadedFileS3UrlState = atomFamily<
  string | null,
  kpisRequestUploadedFileS3UrlParams
>({
  key: 'kpiRequestUploadedFileS3UrlState',
  default: null,
  effects: (params) => [
    ({ setSelf }) => {
      try {
        const { companyId, fileId, contentType, fileName } = params;

        if (!companyId || !fileId || !contentType) {
          setSelf(null);
          return;
        }

        const downloadableUrl = fetchKPIRequestUploadedFileFromS3({
          companyId,
          fileId,
          contentType,
          fileName,
        });

        setSelf(downloadableUrl);
      } catch (error) {
        console.error(error);
        setSelf(null);
      }
    },
  ],
});

export const kpiRequestsByCompanyState = selector<Map<number, IKPIRequestDataModel[]>>({
  key: 'kpiRequestsByCompanyState',
  get: ({ get }) => {
    const map = new Map<number, IKPIRequestDataModel[]>();
    get(kpisRequestsListState)!.reduce((acc, request) => {
      if (acc.has(request.companyId)) {
        acc.get(request.companyId)!.push(request);
      } else {
        acc.set(request.companyId, [request]);
      }
      return acc;
    }, map);
    return map;
  },
});

export const selectedRequestState = atom<IKPIRequestDataModel | null>({
  key: 'selectedRequestState',
  default: null,
});

export enum KpiAction {
  archive = 'archive',
  sendReminder = 'sendReminder',
  resend = 'resend',
}

export const showModalStateKPI = atom<KpiAction | null>({
  key: 'showModalStateKPI',
  default: null,
});
