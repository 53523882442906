import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import * as Sentry from '@sentry/react';
import {
  IGenericSocketUpdate,
  ISocketErrorData,
  ISocketResponseBaseDataModel,
  ResponseStatus,
  SocketStatus,
} from '../data-models/socket-base.data-model';
import {
  ISocketRequestCompIntelDataModel,
  ISocketResponseCompIntelBase,
  ISocketResponseCompIntelData,
  ISocketResponseCompIntelStatus,
} from '../data-models/socket-comp-intel.data-model';

export interface IWebSocket {
  get socketStatus(): Observable<SocketStatus>;
  get socketStatusSync(): SocketStatus;
  abortQuery: (query: string) => void;
  disconnect: () => void;
  listen<Response extends IGenericSocketUpdate<unknown>>(
    eventName: string,
    Listener: (data: Response) => void,
    getInitialValue?: boolean
  ): Subscription;
  send<
    Request extends ISocketRequestCompIntelDataModel<unknown>,
    Response extends ISocketResponseCompIntelData<unknown>,
  >(
    message: Request
  ): Observable<Response>;
}

export class WebSocketService implements IWebSocket {
  static readonly #MAX_RETRY_COUNT = 5;
  static #instance: IWebSocket;

  readonly #getToken;
  readonly #requestsBuffer: ISocketRequestCompIntelDataModel<unknown>[];
  readonly #url;

  #retryCount = 0;
  #socket: WebSocket | undefined;
  #socketStatus: BehaviorSubject<SocketStatus>;
  #responseChannels: Map<string, ReplaySubject<unknown>>;

  private constructor(getToken: () => Promise<string>, url: string | undefined) {
    this.#getToken = getToken;
    this.#url = url;
    this.#requestsBuffer = [];
    this.#responseChannels = new Map();
    this.#connect();
    this.#socketStatus = new BehaviorSubject<SocketStatus>(SocketStatus.connecting);
  }

  abortQuery(queryId: string) {
    console.log('[WebSocketService] Aborting query', queryId);

    this.send({ action: 'abort', data: null, requestId: queryId });
  }

  disconnect() {
    this.#responseChannels.forEach((value) => {
      value.complete();
      value.unsubscribe();
    });
    this.#responseChannels = new Map<string, ReplaySubject<unknown>>();
    this.#clearListeners();
    this.#socket?.close();
    this.#socketStatus.next(SocketStatus.closed);
  }

  listen<Response extends IGenericSocketUpdate<unknown>>(
    eventName: string,
    listener: (data: Response) => void,
    getInitialValue = true
  ) {
    let channel = this.#responseChannels.get(eventName) as Subject<Response>;
    if (!channel) {
      channel = getInitialValue ? new ReplaySubject<Response>(1) : new Subject();
      this.#responseChannels.set(eventName, channel as ReplaySubject<unknown>);
    }

    return channel.asObservable().subscribe(listener);
  }

  send<
    Request extends ISocketRequestCompIntelDataModel<unknown>,
    Response extends ISocketResponseCompIntelData<unknown>,
  >(message: Request) {
    let channel = this.#responseChannels.get(message.requestId) as ReplaySubject<Response>;
    if (!channel) {
      channel = new ReplaySubject<Response>(1);
      this.#responseChannels.set(message.requestId, channel as ReplaySubject<unknown>);
    }

    if (this.#socketStatus.getValue() !== SocketStatus.ready) {
      this.#requestsBuffer.push(message);
    } else {
      this.#sendMessage(message);
    }

    return channel.asObservable();
  }

  get socketStatus() {
    return this.#socketStatus.asObservable();
  }

  get socketStatusSync() {
    return this.#socketStatus.getValue();
  }

  async #connect() {
    const token = await this.#getToken();
    this.#socket = new WebSocket(this.#url ?? this.#getConnectionUrl(token));
    this.#socket.addEventListener('open', this.#onSocketOpen);
    this.#socket.addEventListener('close', this.#onSocketClose);
    this.#socket.addEventListener('error', this.#onSocketError);
    this.#socket.addEventListener('message', this.#onSocketMessage);
  }

  #retryConnect() {
    if (this.#retryCount < WebSocketService.#MAX_RETRY_COUNT) {
      this.#socketStatus.next(SocketStatus.connecting);
      this.#retryCount++;
      console.log(`[WebSocketService] retrying connection to websocket, count ${this.#retryCount}.`);

      setTimeout(() => {
        this.#connect();
      }, this.#retryCount * 1000);
    } else {
      console.error(
        `[WebSocketService] Failed to establish connection after ${
          WebSocketService.#MAX_RETRY_COUNT
        } attempts.`
      );
      this.#socketStatus.next(SocketStatus.error);
    }
  }

  #clearListeners() {
    if (this.#socket) {
      this.#socket.removeEventListener('open', this.#onSocketOpen);
      this.#socket.removeEventListener('close', this.#onSocketClose);
      this.#socket.removeEventListener('error', this.#onSocketError);
      this.#socket.removeEventListener('message', this.#onSocketMessage);
    }
  }

  #flushBuffer(): void {
    this.#requestsBuffer.forEach((msg) => {
      this.#sendMessage(msg);
    });
  }

  #getConnectionUrl(authToken: string): string {
    if (typeof import.meta.env.VITE_WS_API !== 'string') {
      throw new Error('[WebSocketService]  No baseURL configured for websocket.');
    }
    return `${import.meta.env.VITE_WS_API}?token=${authToken}`;
  }

  #onSocketClose = (closeEvent: CloseEvent) => {
    console.log(
      '[WebSocketService]  Socket closed - disconnected',
      closeEvent.code,
      closeEvent.reason,
      closeEvent.wasClean
    );
    this.#clearListeners();

    // See iana.org/assignments/websocket/websocket.xhtml for details.
    if (closeEvent.code > 1000 || !closeEvent.wasClean) {
      this.#retryConnect();
    } else {
      this.#socketStatus.next(SocketStatus.closed);
    }
  };

  #onSocketError = (errorEvent: Event) => {
    Sentry.captureMessage(`Socket error ${errorEvent.type}`, 'error');
    console.error('[WebSocketService] Socket error', errorEvent);
    this.#clearListeners();
    this.#socketStatus.next(SocketStatus.error);
    this.#retryConnect();
  };

  #onSocketMessage = (event: MessageEvent) => {
    const data: unknown = this.#readMessage(event.data);

    if (!(data as ISocketResponseBaseDataModel).type) {
      console.error('[WebSocketService] unexpected message', data);
      return;
    }

    if ((data as ISocketResponseCompIntelBase).requestId) {
      this.handleCompetitiveIntelligenceResponse(data as ISocketResponseCompIntelBase);
    } else {
      this.handleGenericResponse(data as ISocketResponseBaseDataModel);
    }
  };

  #handleCompIntelStatusResponse(socketStatusResponse: ISocketResponseCompIntelStatus) {
    const channel = this.#responseChannels.get(socketStatusResponse.requestId)!;

    if (socketStatusResponse.status === ResponseStatus.complete) {
      channel.complete();
      this.#responseChannels.delete(socketStatusResponse.requestId);
    } else if (socketStatusResponse.status === ResponseStatus.pending) {
      // Do nothing.
    } else if (socketStatusResponse.status === ResponseStatus.error) {
      channel.error(socketStatusResponse.error!);
    } else if (socketStatusResponse.status === ResponseStatus.aborted) {
      channel.error({
        code: '-100',
        message: 'Request aborted',
      } as ISocketErrorData);
    } else {
      console.error('[WebSocketService] unexpected status response:', socketStatusResponse);
    }
  }

  private handleCompetitiveIntelligenceResponse(data: ISocketResponseCompIntelBase) {
    const messageId = (data as ISocketResponseCompIntelBase).requestId;
    const channel = this.#responseChannels.get(messageId);
    if (!channel) {
      console.error('[WebSocketService] no channels found for data:', data);
      return;
    }

    if ((data as ISocketResponseCompIntelStatus).status) {
      this.#handleCompIntelStatusResponse(data as ISocketResponseCompIntelStatus);
    } else {
      this.handleCompIntelDataResponse(data as ISocketResponseCompIntelData<unknown>);
    }
  }

  private handleCompIntelDataResponse(response: ISocketResponseCompIntelData<unknown>) {
    const channel = this.#responseChannels.get(response.requestId)!;
    channel.next(response);
  }

  private handleGenericResponse(response: ISocketResponseBaseDataModel) {
    const channel = this.#responseChannels.get(response.type);
    channel?.next(response);
  }

  #onSocketOpen = () => {
    console.log('[WebSocketService] Socket opened - connected');
    this.#retryCount = 0;
    this.#socketStatus.next(SocketStatus.ready);
    this.#flushBuffer();
  };

  #readMessage<T>(msg: string) {
    return JSON.parse(msg) as T;
  }

  async #sendMessage<T>(msg: T) {
    const token = await this.#getToken();
    this.#socket!.send(JSON.stringify({ ...msg, token }));
  }

  static async initService(
    getToken: () => Promise<string>,
    url: string | undefined = undefined
  ): Promise<IWebSocket> {
    if (WebSocketService.#instance && WebSocketService.#instance.socketStatusSync !== SocketStatus.closed) {
      console.warn('[WebSocketService] Socket has already been initialized, skipping');
    } else {
      WebSocketService.#instance = new WebSocketService(getToken, url);
    }

    return WebSocketService.#instance;
  }

  static destroyService() {
    if (WebSocketService.#instance) {
      WebSocketService.#instance.disconnect();
    }
  }

  static get() {
    return WebSocketService.#instance;
  }
}
