import { ColDef } from 'ag-grid-community';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { kpiRequestsGridSchema } from '../../../../../data-schemas/KPIRequestSchema';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { useSchemaToGrid } from '../../../../../util/schema-utils';
import { ArchivedRequestsActions } from '../components/ArchivedRequestsActions';
import { SentAtRenderer } from '../components/SentAtRenderer';
import { SentRequestActions } from '../components/SentRequestActions';
import { useCommonKpiColDefs } from './useCommonKpiColDefs';

export default function useKPIRequestsSentColDefs(
  status: typeof KPIRequestStatus.Sent | typeof KPIRequestStatus.Archived
) {
  const {
    respondentColDef,
    periodColDef,
    frequencyColDef,
    granularityColDef,
    templateColDef,
    nonSelectableCompanyColDef,
    fyeColDef,
  } = useCommonKpiColDefs();
  const schemaToGrid = useSchemaToGrid();
  const reqSchema = kpiRequestsGridSchema();
  const { showGranularitySelector } = useFlags<MaggieFeatureFlags>();

  const columnDefs: ColDef<IKPIRequestDataModel>[] = useMemo(() => {
    const res = [nonSelectableCompanyColDef];
    const [dueDate, archivedAt] = schemaToGrid(reqSchema, ['dueDate', 'archivedAt']) as ColDef[];
    if (status === KPIRequestStatus.Sent) {
      res.push(
        {
          headerName: 'Sent At',
          cellRenderer: SentAtRenderer,
          minWidth: 180,
          field: 'sentAt',
        },
        { ...dueDate, filter: false } as ColDef
      );
    } else if (status == KPIRequestStatus.Archived) {
      res.push({ ...archivedAt, filter: false } as ColDef);
    }
    res.push(
      ...([
        templateColDef,
        frequencyColDef,
        fyeColDef,
        periodColDef,
        respondentColDef,
        {
          maxWidth: status === KPIRequestStatus.Sent ? 100 : 60,
          resizable: false,
          sortable: false,
          pinned: 'right',
          cellRenderer: status === KPIRequestStatus.Sent ? SentRequestActions : ArchivedRequestsActions,
        },
      ] as ColDef<IKPIRequestDataModel>[])
    );

    if (showGranularitySelector) {
      res.splice(4, 0, granularityColDef as unknown as ColDef<IKPIRequestDataModel>);
    }

    return res;
  }, [
    frequencyColDef,
    fyeColDef,
    granularityColDef,
    nonSelectableCompanyColDef,
    periodColDef,
    reqSchema,
    respondentColDef,
    schemaToGrid,
    showGranularitySelector,
    status,
    templateColDef,
  ]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
