import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Alert, IconButton, Link, Typography, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { FormFactoryNoLayout } from '../../../../components/Form/FormFactory';
import { FormTextField } from '../../../../components/FormField/FormTextField';
import { useLoadingBarState } from '../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { useUpdateCompanyAndState } from '../../../../services/hooks/useUpdateCompanyAndState';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { roundsAtom } from '../../../../services/state/AppConfigStateJ';
import { companyStateJ } from '../../../../services/state/CompanyStateJ';
import { DealBoardDealCategory, LoadingId } from '../../../../types';
import { FDMap } from '../../../../util/data-structure/FDMap';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { FMT } from '../../../../util/formatter-service';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';
import { schemaToFormFields } from '../../../../util/schema-utils';
import { formatExternalLink, toURLFriendlyStringWithDashes } from '../../../../util/url-utils';
import { ICompanyViewModel } from '../../../../view-models/company.view-model';
import { createForm } from '../../../../view-models/form.view-model';
import { useDebounceCompanyUpdates } from '../../../CompanyProfiles2/Summary/useDebounceCompanyUpdates';
import { companyFormSchema } from '../../../Finance2/Forms/CompanyFormUtils';
import { dealStageIdToCategoryState } from '../../state/DealboardDataState';
import { getDealCategory } from '../../utils/getDealCategory';
import { CheckListWarningIcon } from '../CheckList/CheckList2';
import { colors } from '../../../../theme/colors';
import { ActiveDealActionsButton } from './ActiveDealActionsButton';
import { DrawerNavigation } from './DrawerNavigation';
import { NextStageButton } from './NextStageButton';
import { PassedDealMenu, PassSplitButton } from './PassSplitButton';
import { PriorityStar } from './PriorityStar';
import { SplitButton } from './SplitButton';
import { StageStatusBar } from './StageStatusBar';
import { TrackedDealMenu, TrackSplitButton } from './TrackSplitButton';
import { DealStatusBox } from './DealStatusBox';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  justify-content: space-between;
  margin-top: 0.2rem;
`;

const ButtonWrapper = styled('div')`
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const DealInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.4);
`;

const CompanyNameAndLogoWrapper = styled('div')`
  display: grid;
  grid-template-columns: max-content 1fr repeat(4, auto);
  @supports (field-sizing: content) {
    grid-template-columns: max-content repeat(5, auto);
  }

  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & img {
    height: 27px;
    max-width: 5rem;
    object-fit: contain;
  }
  & input {
    @supports (field-sizing: content) {
      field-sizing: content;
    }
  }
  & .MuiFormControl-root {
    margin-left: 0.2rem;
    margin-top: 0.5rem;
  }
  &&&&& label {
    display: none;
  }
  &&&&& .MuiInputBase-input {
    font-size: 1.4rem;
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const TypographyWrapper = styled('div')`
  margin-bottom: 0.2rem;
  height: 1.5rem; // fix the height whether there is any content or not
`;

const alertDuration = 2000;

export interface IDrawerHeaderProps {
  selectedDeal: IDealDataModel;
  company: ICompanyDataModel;
}

export const DrawerHeader: FC<IDrawerHeaderProps> = (props) => {
  const { selectedDeal, company } = props;
  const updateCompany = useUpdateCompanyAndState();
  const dealStageIdToCategory = useAtomValue(dealStageIdToCategoryState);
  const { colors } = useTheme();
  const rounds = useAtomValue(roundsAtom);
  const [showLoader, setShowLoader] = useState(false);
  const [alert, setAlert] = useState<{ message: string; severity: 'success' | 'error' }>();
  const [showAlert, setShowAlert] = useState(false);
  const { actions } = useLoadingBarState();
  const { pushErrorToast } = useToastMessageState();
  const { showDealDrawer2, showDealForm2 } = useFlags<MaggieFeatureFlags>();

  useEffect(() => {
    if (!alert) return;
    setShowAlert(true);
    const timerId = setTimeout(() => {
      setShowAlert(false);
      setAlert(undefined);
    }, alertDuration);
    return () => clearTimeout(timerId);
  }, [alert]);

  const round = rounds.find((r) => r.id === selectedDeal.roundId);
  const dealCategory = getDealCategory(dealStageIdToCategory, selectedDeal.stageId);
  const [companyNameValue, setCompanyNameValue] = useState(company.name);

  const formattedAmount = useMemo(() => {
    const valuationMin = selectedDeal.gcAmountMin;
    const valuationMax = selectedDeal.gcAmountMax;
    if (!valuationMax || !valuationMin) return '';
    if (valuationMax === valuationMin) return formatUSDShort(valuationMax);
    return `${FMT.format('usdShort', valuationMin)} - ${FMT.format('usdShort', valuationMax)}`;
  }, [selectedDeal.gcAmountMax, selectedDeal.gcAmountMin]);

  const onCompanyEdited = useCallback(
    async (newName: string) => {
      actions.startLoading(LoadingId.updateCompany);
      try {
        await updateCompany(selectedDeal.companyId, { name: newName }, true);
      } catch (error) {
        pushErrorToast({ message: 'Failed to update company' });
      } finally {
        actions.stopLoading(LoadingId.updateCompany);
      }
    },
    [actions, pushErrorToast, selectedDeal, updateCompany]
  );

  return (
    <div>
      {showLoader && <LinearProgress style={{ marginBottom: '2.5rem' }} />}
      {showAlert && (
        <Alert severity={alert?.severity} style={{ position: 'absolute' }}>
          {alert?.message}
        </Alert>
      )}
      {!showLoader && <DrawerNavigation />}
      <DealInfoWrapper>
        <Wrapper>
          {showDealDrawer2 ? (
            <CompanyNameField selectedDeal={selectedDeal} />
          ) : (
            <CompanyNameAndLogoWrapper>
              <MUIAvatar size='medium' src={company.logoUrl ?? ''} nameParts={company.name.split(' ')} />
              <FormTextField
                value={companyNameValue}
                onChange={(e) => setCompanyNameValue(e.target.value)}
                onValueChanged={onCompanyEdited}
                inputProps={{
                  style: { fontSize: 20 },
                }}
                InputLabelProps={{ style: { fontSize: 20 } }}
                disabled={company.crunchbaseId !== null}
              />

              {showDealDrawer2 && <CheckListWarningIcon deal={selectedDeal} />}
              <PriorityStar deal={selectedDeal} />
              <IconButton
                component={Link}
                href={`/${ROUTES.COMPANY_PROFILES}/${toURLFriendlyStringWithDashes(company.name)}-${
                  selectedDeal.companyId
                }/summary`}
                target='_blank'
                rel='noreferrer'
              >
                <OpenInNewIcon htmlColor={colors.primary[60]} fontSize='small' />
              </IconButton>
            </CompanyNameAndLogoWrapper>
          )}
          {PermissionService.get().hasPermission(PermissionKey.canEditDeal) && (
            <ButtonWrapper>
              {dealCategory === DealBoardDealCategory.TRACK &&
                (showDealForm2 ? <TrackedDealMenu /> : <TrackSplitButton />)}
              {dealCategory === DealBoardDealCategory.PASS &&
                (showDealForm2 ? <PassedDealMenu /> : <PassSplitButton />)}
              {dealCategory === DealBoardDealCategory.CURRENT && (
                <>
                  {showDealForm2 ? <ActiveDealActionsButton /> : <SplitButton />}
                  <NextStageButton setShowLoader={setShowLoader} setAlert={setAlert} />
                </>
              )}
            </ButtonWrapper>
          )}
        </Wrapper>

        <TypographyWrapper>
          <Typography component='div' variant='body2' color={colors.neutral[60]}>
            <InnerWrapper>
              <span>{round?.displayName ?? ''}</span>
              <span>{formattedAmount}</span>
            </InnerWrapper>
          </Typography>
        </TypographyWrapper>
        {showDealDrawer2 ? <DealStatusBox deal={selectedDeal} /> : <StageStatusBar />}
      </DealInfoWrapper>
    </div>
  );
};

function CompanyNameField({ selectedDeal }: { selectedDeal: IDealDataModel }) {
  const updateCompany = useDebounceCompanyUpdates(selectedDeal.companyId);
  const schema = companyFormSchema().pick(['name']);
  const company = useAtomValue(companyStateJ(selectedDeal.companyId));

  const methods = useForm<Pick<ICompanyViewModel, 'name'>>({
    defaultValues: {
      name: company?.name,
    },
    mode: 'onBlur',
  });

  const onUpdateCompany = useCallback(
    async (data: Partial<ICompanyViewModel>) => {
      if (data.name) {
        updateCompany(data);
      }
    },
    [updateCompany]
  );

  const companyNameForm = useMemo(() => {
    const [field] = schemaToFormFields(schema);

    return createForm<ICompanyViewModel>({
      fields: FDMap.fromArray([{ ...field, label: '', disabled: company?.crunchbaseId != null }], 'key'),
      variant: 'form-inline',
    });
  }, [company?.crunchbaseId, schema]);

  if (!company) return null;

  return (
    <FormProvider {...methods}>
      <CompanyNameAndLogoWrapper>
        <MUIAvatar size='medium' src={company.logoUrl ?? ''} nameParts={company.name.split(' ')} />
        <FormFactoryNoLayout form={companyNameForm} onChange={onUpdateCompany} />

        <CheckListWarningIcon deal={selectedDeal} />
        <PriorityStar deal={selectedDeal} />
        <IconButton
          component={Link}
          href={`/${ROUTES.COMPANY_PROFILES}/${toURLFriendlyStringWithDashes(company.name)}-${
            selectedDeal.companyId
          }/summary`}
          target='_blank'
          rel='noreferrer'
        >
          <OpenInNewIcon color='secondary' fontSize='small' />
        </IconButton>
        <IconButton href={formatExternalLink(company.website ?? '')} target='_blank'>
          <LinkIcon htmlColor={colors.neutral[60]} fontSize='small' />
        </IconButton>
      </CompanyNameAndLogoWrapper>
    </FormProvider>
  );
}
