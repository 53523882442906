import {
  addQuarters,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  parseISO,
  startOfDay,
  sub,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';
import { KpiPeriod, KpiPeriodExtended } from '../../../data-models/company-financials.data-model';
import { periodToFrequency } from '../../../data-models/kpi-requests.data-model';
import {
  fiscalDateFormatter,
  formatISODateOnly,
  getFiscalQuarter,
  getFiscalYear,
} from '../../../util/formatters/DateFormatters';

// FIXME: MAGGIE-5153
export const getEachDateByPeriodAndFrequency = ({
  totalSelectedPeriods: nSelectedPeriods,
  frequency,
  fye,
  endDate = endOfDay(new Date()),
}: {
  totalSelectedPeriods: number;
  frequency: KpiPeriod;
  fye: number;
  endDate?: Date;
}) => {
  if (fye < 1 || fye > 12) {
    throw new Error('Invalid fye');
  }
  let fromDate = null;
  let dates: Date[] = [];

  switch (frequency) {
    case KpiPeriod.month: {
      const end = endOfMonth(endDate);
      fromDate = subMonths(end, nSelectedPeriods - 1);

      dates = eachMonthOfInterval({
        start: startOfDay(fromDate),
        end,
      }).map((date) => endOfMonth(date));
      break;
    }
    case KpiPeriod.quarter: {
      dates.push(...getLastNQuarters(endDate, fye, nSelectedPeriods));
      break;
    }
    case KpiPeriod.year: {
      dates.push(...getLastNYears(endDate, fye, nSelectedPeriods));
    }
  }
  return { dates, fromDate };
};

function getLastNQuarters(currentDate: Date, fye: number, nQuarters: number) {
  const { fiscalQuarter } = getFiscalQuarter(currentDate, fye);
  const endOfCurrentQuarter = endOfFiscalQuarter(fiscalQuarter, fyeDate(currentDate, fye - 1));
  const start = endOfMonth(subQuarters(endOfCurrentQuarter, nQuarters - 1));
  const dates = [];
  for (let i = 0; i < nQuarters; i++) {
    dates.push(endOfMonth(addQuarters(start, i)));
  }
  return dates;
}

function getLastNYears(currentDate: Date, fye: number, nYears: number) {
  const endYear = currentDate.getFullYear();
  const end = endOfMonth(new Date(endYear, fye - 1, 1));
  const dates = [];
  for (let i = 0; i < nYears; i++) {
    dates.unshift(endOfMonth(subYears(end, i)));
  }
  return dates;
}

/**
 * @param financialsItemDate
 * @param period
 * @param fye fye month, [1-12] reflecting company.fye range
 * @returns
 */
export function getFormattedFiscalDate(financialsItemDate: string, period: KpiPeriod, fye: number) {
  const date = new Date(financialsItemDate);
  const fyeYear = date.getMonth() > fye - 1 ? date.getFullYear() + 1 : date.getFullYear();
  const fyeIso = formatISODateOnly(endOfMonth(new Date(fyeYear, fye - 1, 1)));
  const frequency = periodToFrequency[period];
  return fiscalDateFormatter(frequency, financialsItemDate, fyeIso);
}

/**
 * @param date
 * @param fye fye month, zero based
 * @returns fiscal year end date for given date
 */
export function fyeDate(date: Date, fye: number) {
  const month = date.getMonth();
  const year = month > fye ? date.getFullYear() + 1 : date.getFullYear();
  return endOfMonth(new Date(year, fye, 1, 0, 0, 0));
}

/**
 * @param fiscalQuarter [1-4]
 * @param fye fye month, zero based
 * @returns month for given fiscal quarter, zero based
 */
export function calendarMonthOfFiscalQuarter(fiscalQuarter: number, fye: number) {
  if (fye == 11) {
    return fiscalQuarter * 3 - 1;
  }
  if (fiscalQuarter === 4) {
    return fye;
  }
  return (3 * fiscalQuarter + fye) % 12;
}

export function endOfFiscalQuarter(fiscalQuarter: number, fyeDate: Date) {
  const fyeMonth = fyeDate.getMonth();
  const month = calendarMonthOfFiscalQuarter(fiscalQuarter, fyeMonth);
  const year = month > fyeMonth ? fyeDate.getFullYear() - 1 : fyeDate.getFullYear();
  return startOfDay(endOfMonth(new Date(year, month)));
}

export function fyeDateFor(
  calendarPeriodEnd: Date,
  fye: number,
  frequency: Extract<KpiPeriod, 'Quarter' | 'Year'>
) {
  const freqInMonths = frequency === KpiPeriod.quarter ? 3 : 12;
  return endOfMonth(sub(calendarPeriodEnd, { months: freqInMonths - (fye % freqInMonths) }));
}

/**
 * @param date
 * @param frequency
 * @param fye month of fiscal year end, zero based
 * @returns end of fiscal period for given date
 */
export function endOfFiscalPeriod(date: Date, frequency: KpiPeriod | KpiPeriodExtended, fye = 11) {
  let endOfCurrentPeriod = endOfMonth(new Date(date));
  if (frequency === KpiPeriod.quarter) {
    const { plainDate } = getFiscalQuarter(startOfDay(date), fye + 1);
    endOfCurrentPeriod = parseISO(plainDate.toString());
  } else if (frequency === KpiPeriod.year) {
    const { plainDate } = getFiscalYear(date, fye + 1);
    endOfCurrentPeriod = parseISO(plainDate.toString());
  }
  return endOfCurrentPeriod;
}

/**
 * @param kpiDate date string in format 'YYYY-MM-DD'
 * @param date
 * @returns true if date in same string format equals kpiDate
 */
export function isEqualToKpiDate(kpiDate: string, date: Date) {
  return kpiDate === formatISODateOnly(date);
}
