import { useEffect, useState } from 'react';
import { getErrorMessage } from '../services/queryHelpers';
import { LoadingStatus, ValueOrError } from '../types';

export function useAsync<T>(asyncFn: () => Promise<T>, options?: { id: string }) {
  const [state, setState] = useState<ValueOrError<T, string>>({
    loading: LoadingStatus.loading,
    error: null,
    data: null,
  });

  useEffect(() => {
    // As promise might complete post unmount this boolean prevent sending unexpected setState.
    let didUnmount = false;

    asyncFn()
      .then((data) => {
        if (didUnmount) return;

        setState({ data, loading: LoadingStatus.success, error: null });
      })
      .catch((error) => {
        if (didUnmount) return;

        const errId = options?.id ?? 'Error';
        const errMessage = getErrorMessage(error, `An error occurred\n${error}`);
        setState({ data: null, loading: LoadingStatus.error, error: `${errId} - ${errMessage}` });
      });

    return () => {
      didUnmount = true;
    };
  }, [asyncFn, options?.id]);

  return state;
}
