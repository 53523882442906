import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Link,
  PopperProps,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { CSSProperties, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import omit from 'lodash-es/omit';
import {
  SearchType,
  getRealCompanyId,
  searchCompanies,
} from '../../../services/queries/MaggieCompanyQueries';
import { CompanyAndLogo } from '../../../pages/CompanyComparison/components/UploadGaCSV/components/CompanyAndLogo';
import { ICompanySearchMeta } from '../../../data-models/field3.data-model';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import {
  CompanySearchResponse,
  CompanySearchResponseWithRealId,
} from '../../../schemas/CompanySearchResponse.schema';
import { formSelectInlineClassNames, formTextFieldInlineClassNames } from '../../../theme/component-styles';
import { IOptionsFromSearchConfig, useAsyncAutoComplete } from './useAsyncAutomplete';
import { IBaseFieldProps } from './types';

export const BoxStyle: CSSProperties = {
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: 'max-content minmax(12px, 1fr) 1fr',
  padding: '4px 8px',
};

export const LinkStyle: CSSProperties = {
  justifySelf: 'end',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const LinkWithTypeStyle: CSSProperties = {
  justifySelf: 'start',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const DummyCreateId = 'dummyCreate';

// React requires the "value" to be defined for this to be a controlled component,
// undefined will trigger an error and result in unwanted behaviour, hence this dummy.
// See https://github.com/facebook/react/issues/6222.
export const dummyInitial: CompanySearchResponse = {
  id: 'initial',
  fields: {
    website: '',
    logo: '',
    description: '',
    name: '',
    type: 'portfolio',
  },
};

export function FieldCompanySearch(props: IBaseFieldProps<ICompanySearchMeta>) {
  const { formField, formProps } = props;
  const { onChange, value: initialValue } = formProps;
  const { disabled = false, placeholder } = formField ?? {};
  const {
    showAdd = false,
    searchType = SearchType.Company,
    multiSelect = false,
    createOnSelection = true,
    onAdd,
    addLabel,
  } = formField.rendererMeta ?? {};
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();
  const [isGettingCompanyId, setIsGettingCompanyId] = useState(false);
  const autoCompleteConfig = useMemo(() => {
    const conf: IOptionsFromSearchConfig<CompanySearchResponse, typeof multiSelect> = {
      generateCreateOption: showAdd
        ? (currentText) => {
            return {
              id: DummyCreateId + name,
              fields: {
                website: '',
                logo: '',
                description: `Select to create a new`,
                name: currentText,
                type: '',
              },
            };
          }
        : undefined,
      initialValue: initialValue ?? dummyInitial,
      searchFn: (searchTerm) =>
        searchCompanies(
          searchTerm,
          searchType,
          false,
          showElasticSearchResults && searchType !== SearchType.Investor
        ),
      onChange: async (newValue) => {
        if (newValue !== null && searchType !== SearchType.Investor) {
          const newValues = Array.isArray(newValue) ? newValue : [newValue];
          if (createOnSelection) {
            setIsGettingCompanyId(true);
            const companyIdsRequests = newValues.map(async (value) => {
              return {
                ...value,
                id: (await getRealCompanyId(value!.id)).id,
              } as CompanySearchResponseWithRealId;
            });
            const changedCompanies = await Promise.all(companyIdsRequests);
            setIsGettingCompanyId(false);
            onChange(multiSelect ? changedCompanies : changedCompanies[0]);
          } else {
            onChange(multiSelect ? newValues : newValues[0]);
          }
        } else {
          onChange(newValue);
        }
      },
      onCreateClick: onAdd,
    };

    return conf;
  }, [
    showAdd,
    initialValue,
    onAdd,
    searchType,
    showElasticSearchResults,
    createOnSelection,
    onChange,
    multiSelect,
  ]);
  const { isSearching, onClose, onInputChange, onValueChange, open, options, value } =
    useAsyncAutoComplete(autoCompleteConfig);

  const className =
    formField.variant === 'form-inline'
      ? `${formTextFieldInlineClassNames} ${formSelectInlineClassNames}`
      : '';

  const getOptionLabel = useCallback((e: CompanySearchResponse) => {
    // if (!showSelectedOption) return '';
    return e.fields.name;
  }, []);

  const showCompanyType = searchType === SearchType.Company && showElasticSearchResults;
  const autocompleteProps = showCompanyType
    ? {
        ListboxComponent: StyledListBox,
        componentsProps: {
          popper: {
            sx: { minWidth: '30rem' },
          } as PopperProps,
        },
      }
    : undefined;

  return (
    <Autocomplete
      autoHighlight
      blurOnSelect
      disabled={disabled || isGettingCompanyId}
      filterOptions={(options) => options}
      getOptionLabel={getOptionLabel}
      id='companies-autocomplete'
      isOptionEqualToValue={(option, newValue) => {
        return option.id === newValue.id;
      }}
      noOptionsText={null}
      multiple={multiSelect}
      onChange={onValueChange}
      onClose={onClose}
      onInputChange={onInputChange}
      open={open}
      options={options}
      popupIcon={null}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={
              isGettingCompanyId
                ? 'Fetching company details...'
                : (placeholder ?? 'Search for a company name or website')
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isSearching || isGettingCompanyId ? (
                    <CircularProgress color='secondary' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      renderOption={(props, option: CompanySearchResponse) => {
        const otherProps = omit(props, 'key');
        const addOptionClasses = props.className ? `${props.className} add-option` : 'add-option';
        if (option.id?.startsWith(DummyCreateId)) {
          return (
            <Box {...props} component='li' className={addOptionClasses} key={option.id}>
              <Typography variant={'caption'}>
                {addLabel
                  ? addLabel(option.fields.name)
                  : `Create new item with name '${option.fields.name}'`}
              </Typography>
            </Box>
          );
        }

        return (
          <CompanyOption
            key={option.id}
            company={option}
            showCompanyType={showCompanyType}
            otherProps={otherProps}
          />
        );
      }}
      value={value}
      className={className}
      slotProps={{ popper: { style: { minWidth: '15rem' }, placement: 'bottom-start' } }}
      {...autocompleteProps}
    />
  );
}

export const StyledListBox = styled('ul')`
  @supports (grid-template-columns: subgrid) {
    display: grid;
    grid-template-columns: 4fr 3fr auto;
    justify-content: space-between;
  }
  & > li.add-option {
    grid-column: 1 / -1;
    padding-left: 0.5rem;
  }
  & > li.company-option {
    display: grid;
    align-items: center;
    justify-items: start;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    padding: 4px 8px;
    grid-template-columns: repeat(3, auto);
    @supports (grid-template-columns: subgrid) {
      grid-template-columns: subgrid;
      grid-column: 1 / -1;
    }
  }
`;

const StyledChip = styled(Chip)`
  font-size: 0.75rem;
`;
export interface ICompanyOptionProps extends HTMLAttributes<HTMLLIElement> {
  company: CompanySearchResponse;
  otherProps: HTMLAttributes<HTMLLIElement>;
  showCompanyType?: boolean;
}

const baseURLRegexMatcher = /^(https?)?:?(\/\/)?(www\.)?([\w\-.]+)/;

export function CompanyOption(props: ICompanyOptionProps) {
  const { company, showCompanyType = false, otherProps } = props;
  const className = props.className ? `${props.className} company-option` : 'company-option';
  const { description, logo, name, website, type } = company.fields;
  // FIXME: This is poor man's URL cleanup & normalization until MAGGIE-2180 is addressed.
  const baseURL = website?.match(baseURLRegexMatcher)?.[4] ?? website;
  const websiteFullURL = website?.startsWith('https://www.') ? website : `https://www.${baseURL}`;

  if (showCompanyType) {
    return (
      <li {...otherProps} className={className} title={description ?? ''}>
        <CompanyAndLogo logoUrl={logo ?? ''} name={name} />
        {type ? <StyledChip label={type} size={'small'} /> : <span />}
        <Link
          href={websiteFullURL}
          underline='hover'
          target={'_blank'}
          style={LinkWithTypeStyle}
          variant={'caption'}
          color={'secondary'}
        >
          {baseURL}
        </Link>
      </li>
    );
  }

  return (
    <Box component='li' {...otherProps} style={BoxStyle} title={description ?? ''}>
      <CompanyAndLogo logoUrl={logo ?? ''} name={name} />
      <div />
      {website && (
        <Link
          href={websiteFullURL}
          underline='hover'
          target={'_blank'}
          style={LinkStyle}
          variant={'caption'}
          color={'secondary'}
        >
          {baseURL}
        </Link>
      )}
    </Box>
  );
}
