import { format } from '../../../pages/PortfolioOverview/services/kpi/kpi-formatter';
import { KpiConfig, KPI_COMPONENTS, KpiConfigMeta, KpiCalculationParams, Kpi } from '../../../types';

export class ProfitableExitsConfig implements KpiConfig {
  private id = KPI_COMPONENTS.PROFITABLE_EXITS as const;
  private title = 'Profitable Exits';
  private type = 'integer';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value = data?.metrics?.filter(({ realizedGL }) => realizedGL != null && realizedGL > 1).length || 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
