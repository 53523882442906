import { FC, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Typography } from '@mui/material';

import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';

const InfoWrapper = styled('div')`
  margin-top: 0.5rem;
  display: flex;
  max-width: fit-content;
  align-items: center;
  & svg {
    transform: scale(0.8);
  }
`;

interface Props {
  title: string;
  deals: IDealDataModel[];
}

export const GridHeader: FC<Props> = ({ title, deals }) => {
  const getValuationMaxSum = useCallback(() => {
    if (!deals.length) return '';
    const sum = deals.reduce((acc, curr) => (curr.gcAmountMax ? acc + curr.gcAmountMax : acc), 0);
    return `(${formatUSDShort(sum)})`;
  }, [deals]);

  return (
    <InfoWrapper data-testid='grid-header'>
      <Typography variant='body1'>{title}</Typography>
      <Chip sx={{ marginLeft: '.25rem' }} label={`${deals.length} ${getValuationMaxSum()}`} />
    </InfoWrapper>
  );
};
