import { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField, TextFieldProps } from '@mui/material';
import { NumberFormatValues, NumericFormatProps } from 'react-number-format/types/types';
import * as React from 'react';
import { ICurrencyMeta, INumberMeta } from '../../../data-models/field3.data-model';
import { FormatterService } from '../../../util/formatter-service';
import { ICustomNumberFormatConfig, IFormatterDataModel } from '../../../data-models/formatter.data-model';
import { getCurrencySymbolFor } from '../../../util/currency-util';
import { IFormField } from '../../../view-models/form.view-model';
import { RendererType } from '../../../data-models/field.data-model';
import { formTextFieldInlineClassNames } from '../../../theme/component-styles';
import { IBaseFieldProps } from './types';

/**
 * When editing (in focus) the full number will be displayed based on the passed configuration.
 * When viewing the formatter as per config will be displayed.
 * They will be the same unless "shortNotation" or other custom rendering is used
 */
export const FieldNumeric: FC<IBaseFieldProps<INumberMeta | ICurrencyMeta>> = ({ formProps, formField }) => {
  const { autoFocus = false, disabled = false, variant } = formField;
  const { onBlur, onChange, value } = formProps;
  const formatter = FormatterService.get().getFormatterForFormField(formField);
  const [valueOnBlur, setValueOnBlur] = useState(formatter(value));
  const numericFormatPropsForEditing = formatModelToReactNumberFormatModel(formField);
  const className = variant === 'form-inline' ? formTextFieldInlineClassNames : '';

  function onValueChange(values: NumberFormatValues) {
    setValueOnBlur(formatter(values.floatValue));
    onChange(values.floatValue ?? null);
  }

  return (
    <NumericFormat
      {...numericFormatPropsForEditing}
      autoFocus={autoFocus}
      className={className}
      customInput={TextFieldWithBlurredValue}
      disabled={disabled}
      onBlur={onBlur}
      onValueChange={onValueChange}
      value={value}
      valueOnBlur={valueOnBlur}
    />
  );
};

type NumericProps = Pick<
  NumericFormatProps,
  'thousandSeparator' | 'allowNegative' | 'decimalScale' | 'suffix' | 'prefix' | 'valueIsNumericString'
>;
function formatModelToReactNumberFormatModel(
  formField: IFormField<INumberMeta | ICurrencyMeta>
): NumericProps {
  const model = FormatterService.get().getFormatterModelForFormField<ICustomNumberFormatConfig>(formField);
  const { maximumFractionDigits } = model.config ?? {};
  const { groupingSeparator = ',' } = formField.rendererMeta ?? {};

  return {
    allowNegative: maximumFractionDigits !== 0,
    decimalScale: maximumFractionDigits,
    prefix: getPrefix(formField, model),
    suffix: getSuffix(model),
    thousandSeparator: groupingSeparator,
  };
}

function getPrefix(
  formField: IFormField<INumberMeta | ICurrencyMeta>,
  model: IFormatterDataModel<ICustomNumberFormatConfig>
) {
  if ((formField.rendererMeta as ICurrencyMeta)?.defaultCurrency) {
    return getCurrencySymbolFor((formField.rendererMeta as ICurrencyMeta).defaultCurrency);
  }
  if (model.config?.currency) {
    return getCurrencySymbolFor(model.config?.currency);
  }
  if (formField.renderer === RendererType.currency) {
    return getCurrencySymbolFor('USD');
  }

  return undefined;
}

function getSuffix(model: IFormatterDataModel<ICustomNumberFormatConfig>) {
  if (model.config?.suffix) {
    return model.config.suffix;
  }
  if (model.id.startsWith('percent')) {
    return '%';
  }
  return undefined;
}

type ITextFieldWithBlurredValueProps = TextFieldProps & {
  valueOnBlur: string;
};

function TextFieldWithBlurredValue(props: ITextFieldWithBlurredValueProps) {
  const [isFocused, setIsFocused] = useState(false);
  const { value, valueOnBlur, onBlur, ...otherProps } = props;

  function _onFocus() {
    setIsFocused(true);
  }

  function _onBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
    setIsFocused(false);
    onBlur?.(event);
  }

  return (
    <TextField {...otherProps} value={isFocused ? value : valueOnBlur} onFocus={_onFocus} onBlur={_onBlur} />
  );
}
