import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { get, set } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { CompanyCellRenderer } from '../../../components/grid-renderers/CompanyCellRenderer';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { KpiPeriod, KpiSection } from '../../../data-models/company-financials.data-model';
import { field2ToField3, IField } from '../../../data-models/field2.data-model';
import { field3ToColumnDef } from '../../../data-models/field3.data-model';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { getMultiPeriodReport } from '../../../services/queries/MaggieMultiPeriodReportingQueries';
import {
  getKpiFieldKey,
  kpiConfigByIdMapAtom,
  kpiConfigByKeyMapAtom,
} from '../../../services/state/KPIConfigState';
import { ColumnMeta, LoadingId } from '../../../types';
import { useAsync } from '../../../util/hook-utils';
import { getFormattedFiscalDate } from '../../CompanyProfiles/utils/financialUtils';
import { getTypedFinancials, TypedFinancials } from '../../CompanyProfiles/utils/getTypedFinancials';
import { getValueFormatter } from '../components/OverviewTable/columns/ColumnFormatter';
import { getTypeBasedConfigs } from '../components/OverviewTable/columns/TypeBasedConfigs/TypeBasedConfigs';
import { columnsByPathStateLoadable, selectedViewIdPF } from '../state/ViewState';
import { IMultiPeriodReportingDataPoint, IMultiPeriodReportResponse } from './MultiPeriodReporting.schemas';
import {
  getColumnConfigKey,
  getDateForConfig,
  IMprKpiColumnConfig,
  IMprMetricsColumnConfig,
  isMprKpiColumnConfig,
  isMprMetricsColumnConfig,
  mprKpiColumnConfigToDataPoint,
  mprMetricsColumnConfigsToDataPoints,
} from './multiPeriodReportingUtils';
import { savedMprViewConfigAtom } from './useMprColumnConfigs';

export type MultiPeriodReportingDataByKpiId = Record<
  string,
  Record<string, Record<string, Record<KpiSection, TypedFinancials>>>
>;

export function getMultiPeriodReportingKpiDataMap(
  report: IMultiPeriodReportResponse,
  kpiConfigMap: Map<number, IField<unknown>>
) {
  return getTypedFinancials(report.data, kpiConfigMap).reduce(
    (acc, item) => {
      const kpiKey = getKpiFieldKey(kpiConfigMap.get(item.kpiId));
      if (!kpiKey) return acc;
      return set(acc, [item.companyId, kpiKey, item.date, item.period!, item.section!], item);
    },
    {} as Record<number, MultiPeriodReportingDataByKpiId>
  );
}

export function getMultiPeriodReportingMetricsDataMap(report: IMultiPeriodReportResponse) {
  return report.metricsData.reduce(
    (metricsByCompanyIdAndDate, metrics) => {
      const { companyId, date, ...paths } = metrics;
      Object.entries(paths).forEach(([path, value]) => {
        set(metricsByCompanyIdAndDate, [companyId, date, path], value);
      });
      return metricsByCompanyIdAndDate;
    },
    {} as Record<number, Record<string, Partial<IMetricsDataModel>>>
  );
}

export const MprCompanyColId = 'company';
const companyColDef: ColDef = {
  field: 'company',
  colId: MprCompanyColId,
  cellRenderer: CompanyCellRenderer,
  cellRendererParams: {
    linkToProfile: true,
  },
  valueFormatter: (params: ValueFormatterParams) => {
    return params?.value?.name ?? '';
  },
  filter: 'agSetColumnFilter',
  filterParams: {
    buttons: ['reset'],
  },
  pinned: 'left',
  width: 200,
};

export function useMultiPeriodReportingColumnDefs() {
  const viewId = useRecoilValue(selectedViewIdPF);
  const viewSettings = useAtomValue(savedMprViewConfigAtom(viewId));
  const configToDefs = useMprConfigToColDefs();

  return useMemo(
    () => [companyColDef, ...configToDefs(viewSettings?.columns ?? [])],
    [configToDefs, viewSettings?.columns]
  );
}

interface ColDefWithSortOrder extends ColDef {
  sortOrder: number;
}

export function metricsConfigToColumnDef(
  config: IMprMetricsColumnConfig,
  columnsByPath: Map<string, ColumnMeta>
): ColDefWithSortOrder | null {
  const columnMeta = columnsByPath.get(config.columnPath);
  if (!columnMeta) {
    return null;
  }
  const partialDefs = getTypeBasedConfigs(columnMeta);
  return {
    ...partialDefs,
    colId: getColumnConfigKey(config),
    enableRowGroup: false,
    sortOrder: config.sortOrder ?? Number.MAX_SAFE_INTEGER,
    headerName: formatMetricsHeader(config, columnsByPath.get(config.columnPath)!),
    valueGetter: (params: ValueGetterParams) => {
      return get(params.context.metricsDataMap, [
        params.data.company.id,
        getDateForConfig(config),
        config.columnPath,
      ]);
    },
    valueFormatter: getValueFormatter(columnMeta),
  };
}

export function kpiConfigToColumnDef(
  config: IMprKpiColumnConfig,
  kpiMap: Map<string, IField<unknown>>
): ColDefWithSortOrder | null {
  const kpi = kpiMap.get(config.kpiKey!);
  if (!kpi) return null;
  return {
    ...field3ToColumnDef(field2ToField3(kpi)),
    colId: getColumnConfigKey(config),
    headerName: formatKpiHeader(config, kpiMap.get(config.kpiKey!)!),
    valueGetter: (params: ValueGetterParams) => {
      const dataMap = params.context.kpiRowDataMap as Record<number, MultiPeriodReportingDataByKpiId>;
      return get(dataMap, [
        params.data.company.id,
        config.kpiKey!,
        getDateForConfig(config),
        config.period!,
        config.section!,
      ])?.value;
    },
    sortOrder: config.sortOrder ?? Number.MAX_SAFE_INTEGER,
  };
}

export function useMprConfigToColDefs() {
  const kpiConfigMap = useAtomValue(kpiConfigByKeyMapAtom);
  const columnsByPath = useAtomValue(columnsByPathStateLoadable(COMPANY_VIEW_TYPE.RETURN_FORECAST));
  return useCallback(
    (sortedConfigs: (IMprKpiColumnConfig | IMprMetricsColumnConfig)[], includeStaticColumns = false) => {
      if (columnsByPath.state !== 'hasData') return [];
      const nonCompanyColumns = sortedConfigs.reduce((acc, config) => {
        if (isMprKpiColumnConfig(config)) {
          const kpiDef = kpiConfigToColumnDef(config, kpiConfigMap);
          if (kpiDef) {
            acc.push(kpiDef);
          }
        } else if (isMprMetricsColumnConfig(config)) {
          const metricsDef = metricsConfigToColumnDef(config, columnsByPath.data);
          if (metricsDef) {
            acc.push(metricsDef);
          }
        }
        return acc;
      }, [] as ColDefWithSortOrder[]);
      if (includeStaticColumns) {
        return [companyColDef, ...nonCompanyColumns];
      } else {
        return nonCompanyColumns;
      }
    },
    [columnsByPath, kpiConfigMap]
  );
}

function formatKpiHeader(columnConfig: IMprKpiColumnConfig, kpi: IField<unknown>) {
  return `${kpi.displayName} (${columnConfig.section}) ${getFormattedFiscalDate(getDateForConfig(columnConfig), columnConfig.period as KpiPeriod, 12)}`;
}
function formatMetricsHeader(columnConfig: IMprMetricsColumnConfig, columnMeta: ColumnMeta) {
  return `${columnMeta.displayName} ${getFormattedFiscalDate(getDateForConfig(columnConfig), columnConfig.period as KpiPeriod, 12)}`;
}

// can keep everything local as long as it's called from single component
export function useMprReportData() {
  const viewId = useRecoilValue(selectedViewIdPF);
  const configs = useAtomValue(savedMprViewConfigAtom(viewId));
  const kpiConfigMap = useAtomValue(kpiConfigByIdMapAtom);
  const kpisByKeyMap = useAtomValue(kpiConfigByKeyMapAtom);
  const columnsByPath = useAtomValue(columnsByPathStateLoadable(COMPANY_VIEW_TYPE.RETURN_FORECAST));
  const {
    actions: { startLoading, stopLoading },
  } = useLoadingBarState();

  const columns = configs?.columns;
  const getReport = useCallback(async () => {
    if (!columns?.length || columnsByPath.state !== 'hasData') {
      return;
    }

    startLoading(LoadingId.getMultiPeriodReport);
    const payload = {
      dataPoints: columns.reduce((acc, config) => {
        if (!isMprKpiColumnConfig(config)) return acc;
        const pt = mprKpiColumnConfigToDataPoint(config, kpisByKeyMap);
        if (!pt) return acc;
        acc.push(pt);
        return acc;
      }, [] as IMultiPeriodReportingDataPoint[]),
      isDateAdjusted: true,
      metricsDataPoints: mprMetricsColumnConfigsToDataPoints(
        columns.filter(isMprMetricsColumnConfig),
        columnsByPath.data
      ),
    };
    const reportData = await getMultiPeriodReport(payload);
    stopLoading(LoadingId.getMultiPeriodReport);
    return reportData;
  }, [columnsByPath, columns, kpisByKeyMap, startLoading, stopLoading]);

  const { data, loading } = useAsync(getReport, { id: getRefetchId(columns) });

  const kpiRowDataMap = useMemo(() => {
    if (!data) return null;
    return getMultiPeriodReportingKpiDataMap(data, kpiConfigMap);
  }, [data, kpiConfigMap]);

  const metricsDataMap = useMemo(() => {
    if (!data || columnsByPath.state !== 'hasData') return null;
    return getMultiPeriodReportingMetricsDataMap(data);
  }, [data, columnsByPath]);

  const companyRowData = useMemo(() => {
    return data?.companies.map((company) => {
      return { company };
    });
  }, [data]);

  return { loading, companyRowData, kpiRowDataMap, metricsDataMap };
}

function getRefetchId(configs?: (IMprKpiColumnConfig | IMprMetricsColumnConfig)[] | null) {
  return (
    configs
      ?.map((c) => getColumnConfigKey(c))
      ?.sort()
      .join(',') ?? ''
  );
}
