import { ColDef, CsvExportParams, ExcelExportParams, ProcessCellForExportParams } from 'ag-grid-community';
import { get, merge } from 'lodash-es';
import { useMemo } from 'react';
import { RendererType } from '../../data-models/field.data-model';
import { getPlainDateString } from '../../services/queries/MaggieMetricsQueries';
import {
  ExcelFormatterIdPrefix,
  getExcelAgFormat,
  getExcelFormatterId,
} from '../../util/excel-formatter-service';
import { FMT, StandardFormatterId } from '../../util/formatter-service';
import { DateFormattersId } from '../../util/formatters/DateFormatters';
import { isObject } from '../../util/object-util';
import { sanitizeAsFileName } from '../../util/stringUtils';

export function commonProcessCb(params: ProcessCellForExportParams) {
  const { cellDataType, cellClass } = params.column.getColDef();
  const val = params.node?.group === true && isObject(params.value) ? params.value?.value : params.value;

  // If there is an Excel cell class then excelStyles will be applied to the cell
  if (Array.isArray(cellClass) && cellClass.some((c) => c.startsWith(ExcelFormatterIdPrefix))) {
    return val;
  }

  if (cellDataType === 'date' || cellDataType === 'dateString') {
    // Return the raw date from the BE which will be in ISO date format YYYY-MM-DD
    return get(params.node?.data, (params.column?.getDefinition() as ColDef)?.field ?? '') ?? '';
  }

  // Use Foresight value formatting
  return params.formatValue(val);
}

export function getExcelClassForCurrency(currencyCode: string) {
  return `currency${currencyCode}`;
}
export function getExportToExcelStyles() {
  const allFormatter = FMT.get().getAllFormatterModels();

  return allFormatter
    .filter((f) => f.type === 'number' || f.type === 'date')
    .map((f) => getExcelAgFormat(f))
    .filter((excelStyle) => excelStyle !== null);
}

export function getRendererTypeToExcelStyleId(rendererType: RendererType) {
  switch (rendererType) {
    case RendererType.number:
      return getExcelFormatterId('numeric2DP')!;
    case RendererType.currency:
      return getExcelFormatterId('currency')!;
    case RendererType.text:
      return getExcelFormatterId('string')!;
    default:
      return getExcelFormatterId(rendererType as StandardFormatterId)!;
  }
}

export function getFormatterIdToExcelStyleId(formatterId: StandardFormatterId | string) {
  switch (formatterId) {
    case 'date':
      return getExcelFormatterId('date')!;
    case 'integer':
      return getExcelFormatterId('integer')!;
    case 'multiplier':
      return getExcelFormatterId('multiplier')!;
    case 'naturalNumber':
      return getExcelFormatterId('naturalNumber')!;
    case 'number':
      return getExcelFormatterId('numeric2DP')!;
    case 'percent2dpAsIs':
      return getExcelFormatterId('percent')!;
    case 'percent2dpAsIsPositive':
      return getExcelFormatterId('percent2dpAsIsPositive' as StandardFormatterId)!;
    case 'usd':
    case 'usdShort':
      return getExcelFormatterId('usd')!;
    case 'usdPositive':
      return getExcelFormatterId('usdPositive')!;
    default:
      return getExcelFormatterId('string')!;
  }
}

/**
 *
 * @param colIds set of column ids (`column.colId`) that should be exported with custom formatting,
 * by calling the defined valueFormatter for each column
 * @returns  export params that can be passed as defaultExcelExportParams or defaultCsvExportParams
 */
export function useExtendedExportSettings<T>(colIds: Set<string>, fileName?: string) {
  const exportParams: ExcelExportParams & CsvExportParams = useMemo(() => {
    function processCb(params: ProcessCellForExportParams<T>) {
      const colId = params.column.getColId();
      if (colIds.has(colId)) {
        return params.formatValue(params.value);
      }
      return commonProcessCb(params);
    }

    return {
      processCellCallback: processCb,
      fileName,
    };
  }, [colIds, fileName]);

  return { exportParams };
}

export function getExportParams(params: Partial<ExcelExportParams | CsvExportParams> = {}) {
  let fileName = params?.fileName;
  if (fileName && typeof fileName === 'string') {
    fileName = sanitizeAsFileName(fileName!);
    return merge({}, params, { fileName });
  }
  return params;
}

const dateFormatters = new Set<string>(Object.values(DateFormattersId));
export function processValueForExport(value: unknown, format?: StandardFormatterId | string) {
  switch (true) {
    case dateFormatters.has(format ?? '') && value instanceof Date: {
      return getPlainDateString(value);
    }
    case format?.startsWith('percent2dpAsIs') && typeof value === 'number': {
      return value / 100;
    }

    default:
      return value;
  }
}
