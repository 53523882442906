import { ICustomNumberFormatConfig, IFormatterDataModel } from '../../data-models/formatter.data-model';
import { FMT } from '../formatter-service';

const DefaultCurrency = 'USD';

export enum NumericFormatterId {
  currency = 'currency',
  currencyRounded = 'currencyRounded',
  decimalPercent = 'decimalPercent',
  integer = 'integer',
  multiplier = 'multiplier',
  multiplierHighPrecision = 'multiplierHighPrecision',
  naturalNumber = 'naturalNumber',
  numeric = 'numeric',
  numeric1DP = 'numeric1DP',
  numeric2DP = 'numeric2DP',
  numericShort = 'numericShort',
  percent = 'percent',
  percent2dpAsIs = 'percent2dpAsIs',
  percent2dpAsIsShort = 'percent2dpAsIsShort',
  percent2dpShort = 'percent2dpShort',
  percentHighPrecision = 'percentHighPrecision',
  percentRound = 'percentRound',
  usd = 'usd',
  usdPositive = 'usdPositive',
  usdRound = 'usdRound',
  usdShort = 'usdShort',
}

export const NumericFormattersConfig: Record<
  NumericFormatterId,
  IFormatterDataModel<ICustomNumberFormatConfig>
> = {
  currency: {
    id: 'currency',
    type: 'number',
    config: {
      style: 'currency',
      currency: DefaultCurrency,
    },
  },
  currencyRounded: {
    id: 'currencyRounded',
    type: 'number',
    config: {
      currency: 'USD',
      style: 'currency',
      maximumFractionDigits: 0,
    },
  },
  decimalPercent: {
    id: 'decimalPercent',
    type: 'number',
    config: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
  },
  integer: {
    id: 'integer',
    type: 'number',
    config: {
      maximumFractionDigits: 0,
    },
  },
  multiplier: {
    id: `multiplier`,
    type: 'number',
    extends: 'numeric',
    config: {
      suffix: 'x',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  multiplierHighPrecision: {
    id: `multiplierHighPrecision`,
    type: 'number',
    config: {
      suffix: 'x',
      minimumFractionDigits: 0,
      maximumFractionDigits: 8,
    },
  },
  naturalNumber: {
    id: 'naturalNumber',
    type: 'number',
    config: {
      maximumFractionDigits: 0,
      negativeValue: '0',
    },
  },
  numeric: {
    id: 'numeric',
    type: 'number',
    config: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      signDisplay: 'negative',
    },
  },
  numeric1DP: {
    id: 'numeric1DP',
    type: 'number',
    config: {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    },
  },
  numeric2DP: {
    id: 'numeric2DP',
    type: 'number',
    config: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
  },
  numericShort: {
    id: 'numericShort',
    type: 'number',
    config: {
      compactDisplay: 'short',
      notation: 'compact',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    },
  },
  percent: {
    id: 'percent',
    type: 'number',
    config: {
      suffix: '%',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  percentRound: {
    id: 'percentRound',
    type: 'number',
    config: {
      suffix: '%',
      maximumFractionDigits: 0,
    },
  },
  percentHighPrecision: {
    id: 'percentHighPrecision',
    type: 'number',
    config: {
      suffix: '%',
      minimumFractionDigits: 0,
      maximumFractionDigits: 8,
    },
  },
  percent2dpShort: {
    id: 'percent2dpShort',
    type: 'number',
    config: {
      suffix: '%',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      compactDisplay: 'short',
      notation: 'compact',
    },
  },
  /** @deprecated use {@link NumericFormattersConfig.percent} instead */
  percent2dpAsIs: {
    id: 'percent2dpAsIs',
    type: 'number',
    config: {
      suffix: '%',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  /** @deprecated use {@link NumericFormattersConfig.percent2dpShort} instead */
  percent2dpAsIsShort: {
    id: 'percent2dpAsIsShort',
    type: 'number',
    config: {
      suffix: '%',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      compactDisplay: 'short',
      notation: 'compact',
    },
  },
  usd: {
    id: 'usd',
    type: 'number',
    config: {
      currency: 'USD',
      style: 'currency',
    },
  },
  usdPositive: {
    id: 'usdPositive',
    type: 'number',
    config: {
      negativeValue: '$0.00',
      currency: 'USD',
      style: 'currency',
    },
  },
  usdRound: {
    id: 'usdRound',
    type: 'number',
    config: {
      currency: 'USD',
      style: 'currency',
      maximumFractionDigits: 0,
    },
  },
  usdShort: {
    id: 'usdShort',
    type: 'number',
    config: {
      compactDisplay: 'short',
      currency: 'USD',
      notation: 'compact',
      style: 'currency',
    },
  },
};

export const usdLong2DPModel: IFormatterDataModel<ICustomNumberFormatConfig> = {
  type: 'number',
  id: `USD-long`,
  config: {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

const _formatUSDLong2dp = (value: number) => FMT.format(usdLong2DPModel, value);

export function formatUSDShort(amount: number) {
  if (amount > -1 && amount < 0.5) {
    return '$0';
  } else if (amount >= 0.5 && amount < 1) {
    return '$1';
  }
  return FMT.format('usdShort', amount);
}

function createFormatter(id: keyof typeof NumericFormatterId): (value: number) => string {
  return (value: number) => FMT.format(id, value);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export const formatNumber2dp = createFormatter('numeric');

/** @deprecated use {@link FormatterService.format} directly instead. */
export const formatNumberToFixed2dp = createFormatter('numeric2DP');

/** @deprecated use {@link FormatterService.format} directly instead. */
export const formatInteger = createFormatter('integer');
const _formatUSD = createFormatter('usd');

/** @deprecated use {@link FormatterService.format} directly instead. */
export const formatPercent2dpAsIs = (value: number) => {
  return `${formatNumber2dp(value)}%`;
};

export const formatUSDLong2dp = (value?: number | null): string => {
  if (value != null) {
    return _formatUSDLong2dp(value);
  }
  return '';
};

export const formatUSD = (value?: number | null) => {
  if (value != null) {
    return _formatUSD(value);
  }
  return '';
};

export const formatUSDRound = (value?: number | null) => {
  if (value != null) {
    return FMT.format('usdRound', value);
  }
  return '';
};

export function adjustValueWithAccountingErrorMargin(value: number): number {
  if (value > -1 && value < 0.5) {
    return 0;
  } else if (value >= 0.5 && value < 1) {
    return 1;
  }
  return value;
}
