import { TextField, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { DatePickerInput } from '../../../../components/DatePickerInput/DatePickerInput';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { ToggleChip } from '../../../../components/ToggleChip/ToggleChip';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { SelectItem } from '../../../../types';
import { useDealActions2 } from '../../hooks/useDealActions';
import { isOpenDrawerState } from '../../state/DealboardUIState';
import { ModalActionButtons } from './ModalActionButtons';

const DATE_OPTIONS = [
  { id: 30, value: 'in 30d' },
  { id: 60, value: 'in 60d' },
  { id: 90, value: 'in 90d' },
];

const Wrapper = styled('div')`
  width: 100%;
`;
const DateInputsWrapper = styled('div')`
  display: flex;
  height: 32px;
  justify-content: space-between;
`;
const DatePickerWrapper = styled('div')`
  width: 180px;
`;
const LabelWrapper = styled('div')`
  margin: 20px 0 4px 0;
`;
const ChipWrapper = styled('div')`
  display: flex;
`;

export interface IDealTrackModalContentBase {
  trackHandler: (reasonValue: string, snoozeDate: Date | undefined) => void;
}

export function DealTrackModalContentBase(props: IDealTrackModalContentBase) {
  const { trackHandler } = props;
  const [reasonValue, setReasonValue] = useState<string>('');
  const [datePickerValue, setDatePickerValue] = useState<Date | null>(null);
  const [snoozeDate, setSnoozeDate] = useState<Date | undefined>();
  const [selectedDateOption, setSelectedDateOption] = useState<SelectItem | null>(null);
  const { colors } = useTheme();
  const setIsOpenDrawer = useSetAtom(isOpenDrawerState);
  const { onCloseModal } = useModalState();

  const onSubmit = useCallback(async () => {
    onCloseModal();
    trackHandler(reasonValue, snoozeDate);
    setIsOpenDrawer(false);
  }, [onCloseModal, reasonValue, setIsOpenDrawer, snoozeDate, trackHandler]);

  const getChipStyle = (isChecked: boolean) => ({
    margin: '0 2px',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    border: `thin solid ${colors.neutral[30]}`,
    backgroundColor: isChecked ? colors.primary[10] : 'transparent',
    minWidth: '3.1rem',
  });

  const onChangeDatePickerValue = useCallback((val: Date | null) => {
    setDatePickerValue(val);
    setSelectedDateOption(null);
  }, []);

  const onToogleDateOptionChange = useCallback((item: SelectItem | null) => {
    const date = new Date();
    date.setDate(date.getDate() + (item?.id as number));
    setDatePickerValue(date);
    setSelectedDateOption(item);
  }, []);

  useEffect(() => {
    setSnoozeDate(datePickerValue ?? undefined);
  }, [datePickerValue]);

  return (
    <Wrapper>
      <TextField
        placeholder='Write the Reason'
        onChange={(e) => setReasonValue(e.target.value)}
        value={reasonValue}
        variant='outlined'
        fullWidth
        onFocus={(e) => e.stopPropagation()}
      />
      <LabelWrapper>
        <Typography color={colors.primary[90]} variant='body2'>
          Remind me on:
        </Typography>
      </LabelWrapper>
      <DateInputsWrapper>
        <DatePickerWrapper>
          <DatePickerInput
            value={datePickerValue}
            onChange={(val) => onChangeDatePickerValue(val)}
            placeholder='Select Date'
            height='100%'
          />
        </DatePickerWrapper>
        <ChipWrapper>
          {DATE_OPTIONS.map((option) => (
            <ToggleChip
              key={option.id}
              item={option}
              onToggle={(item) => onToogleDateOptionChange(item)}
              checked={option.id === selectedDateOption?.id}
              style={getChipStyle(option.id === selectedDateOption?.id)}
              typographyVariant='caption'
              typographyColor={option.id === selectedDateOption?.id ? colors.primary[90] : colors.primary[60]}
            />
          ))}
        </ChipWrapper>
      </DateInputsWrapper>
      <ModalActionButtons
        isSubmitDisabled={!snoozeDate}
        onSubmit={onSubmit}
        onCancel={() => onCloseModal()}
        submitLabel='Move to Track Mode'
      />
    </Wrapper>
  );
}

interface IDealTrackModalContent {
  deal: IDealDataModel;
}

export function DealTrackModalContent(props: IDealTrackModalContent) {
  const { deal } = props;
  const { handleTrack } = useDealActions2();

  const onTrackDeal = useCallback(
    (reasonValue: string, snoozeDate: Date | undefined) => {
      handleTrack(deal, reasonValue, snoozeDate);
    },
    [deal, handleTrack]
  );

  return <DealTrackModalContentBase trackHandler={onTrackDeal} />;
}
