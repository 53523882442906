import { atom, useAtomValue } from 'jotai';
import { atomWithDefault, unwrap, useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { IFundDataModel, UnassignedFund } from '../../../data-models/fund.data-model';
import { fundsAtom, roundsAtom, sectorsAtom } from '../../../services/state/AppConfigStateJ';
import { companyStateJ, companyStateLoadable } from '../../../services/state/CompanyStateJ';
import { DealBoardDealCategory, DealBoardViewMode, SelectItem } from '../../../types';
import { getNextDeal, getPrevDeal } from '../components/DealDrawer/dealNavigationUtils';
import { createColumnStage } from '../data-models/columnStage.data-model';
import {
  allDealsByIdState,
  dealsByCategoryState,
  dealStagesByIdMapState,
  dealTypesByIdMapState,
} from './DealboardDataState';

export const TBD_ROUND_OPTION = { id: null, value: 'TBD' };

export const isOpenDrawerState = atom<boolean>(false);

export const isPresentModeState = atom<boolean>(false);
export const DefaultDealCategory = {
  id: DealBoardDealCategory.CURRENT,
  value: 'Current Deals',
};

export const selectedDealCategoryState = atom<SelectItem>(DefaultDealCategory);

export const viewModeState = atom<DealBoardViewMode>(DealBoardViewMode.KANBAN);

export const searchTermState = atom<string>('');

export const showCommentsState = atom<boolean>(false);

export const selectedDealTypeState = atom<number | undefined>(undefined);

export const selectedSectorsState = atomWithDefault<number[]>((get) => {
  return get(sectorsAtom).map((sector) => sector.id);
});

export const dealFiltersFundsState = atom<IFundDataModel[]>((get) => {
  const funds = get(fundsAtom);

  return [...funds, UnassignedFund];
});

export const selectedFundsState = atomWithDefault<number[]>((get) => {
  const funds = get(dealFiltersFundsState);
  return funds.map((fund) => fund.id);
});

export const allRoundsOptionsState = atom<(Option | typeof TBD_ROUND_OPTION)[]>((get) => {
  const roundOptions = get(roundsAtom).map((round) => {
    return {
      id: round.id,
      value: round.displayName,
    };
  });

  return [...roundOptions, TBD_ROUND_OPTION];
});

export const selectedRoundsState = atomWithDefault((get) => {
  const rounds = get(allRoundsOptionsState);

  return rounds.map((round) => round.id) as (number | null)[];
});

export const selectableStagesState = atom((get) => {
  const selectedDealType = get(selectedDealTypeState);
  const dealTypesById = get(dealTypesByIdMapState);
  const dealStagesById = get(dealStagesByIdMapState);
  if (!dealTypesById || !dealStagesById) return [];
  const dealStagesForDealType = dealTypesById.get(selectedDealType ?? -1)?.dealStageIds ?? [];

  return dealStagesForDealType.map((stageId) => dealStagesById.get(stageId)!);
});

export const selectedDealLeadState = atom<number | null>(null);

export const dealBoardSettingsState = atomWithDefault((get) => {
  const visibleDeals = get(visibleDealsState) ?? [];
  const selectableStages = get(selectableStagesState);

  return selectableStages.map((stage) => {
    return createColumnStage({
      stage,
      deals: visibleDeals.filter((deal) => {
        return deal.stageId === stage.id;
      }),
      isCollapsed: false,
    });
  });
});

export const selectedDealIdState = atom<number | null>(null);
export const selectedDealState = atom<IDealDataModel | null>((get) => {
  const allDeals = get(allDealsByIdState);
  const selectedDealId = get(selectedDealIdState);
  return selectedDealId ? (allDeals.get(selectedDealId) ?? null) : null;
});

export const selectedFieldState = atom<string | null>(null);

export const visibleDealsState = atom((get) => {
  const selectedCategory = get(selectedDealCategoryState).id as DealBoardDealCategory;
  const dealsForCategory = get(unwrap(dealsByCategoryState(selectedCategory)));
  const selectedDealLead = get(selectedDealLeadState);
  const selectedDealType = get(selectedDealTypeState);
  const selectedRounds = new Set(get(selectedRoundsState));
  const selectedSectors = new Set(get(selectedSectorsState));
  const searchTerm = (get(searchTermState) ?? '').toLowerCase();
  const selectedFunds = new Set(get(selectedFundsState));

  const filteredData = (dealsForCategory ?? []).filter((deal) => {
    const company = get(unwrap(companyStateJ(deal.companyId)));

    if (!company) {
      return false;
    }

    const hasSelectedDealLead = selectedDealLead === null ? true : deal.dealLeadId === selectedDealLead;
    const hasSelectedDealType =
      selectedCategory !== DealBoardDealCategory.CURRENT || deal.dealTypeId === selectedDealType;

    const fundIds = deal.fundIds ?? [];
    const hasSelectedFund =
      fundIds.some((fundId) => selectedFunds.has(fundId)) ||
      (fundIds.length === 0 && selectedFunds.has(UnassignedFund.id));

    const hasSelectedRound = selectedRounds.has(deal.roundId);
    const hasSelectedText = company.name.toLowerCase().indexOf(searchTerm) >= 0;
    const hasSelectedSector = company.sectorId === null || selectedSectors.has(company.sectorId);
    return (
      hasSelectedDealType &&
      hasSelectedDealLead &&
      hasSelectedSector &&
      hasSelectedRound &&
      hasSelectedText &&
      hasSelectedFund
    );
  });

  return filteredData;
});

export const DealAction = {
  delete: 'delete',
  pass: 'pass',
  track: 'track',
  miss: 'missed',
} as const;
export type DealAction = (typeof DealAction)[keyof typeof DealAction];
export interface ISelectedDealAction {
  action: DealAction;
  dealId: number;
  next?: () => void;
}
export interface ISelectedDealActionState extends Pick<ISelectedDealAction, 'action' | 'next'> {
  deal: IDealDataModel;
  company: ICompanyDataModel;
}

export const selectedDealActionAtom = atom<ISelectedDealAction | null>(null);
export const selectedDealActionState = atom<ISelectedDealActionState | null>((get) => {
  const selectedDealAction = get(selectedDealActionAtom);
  if (!selectedDealAction) return null;

  const deal = get(allDealsByIdState).get(selectedDealAction.dealId);
  if (!deal) {
    console.warn(`Deal not found for dealId: ${selectedDealAction.dealId}`);
    return null;
  }
  const company = get(companyStateLoadable(deal.companyId ?? -1));
  if (company.state != 'hasData' || !company.data) {
    console.warn(`Company not found for dealId: ${selectedDealAction.dealId}`);
    return null;
  }
  return {
    ...selectedDealAction,
    company: company.data,
    deal,
    next: selectedDealAction.next,
  };
});

export function useShowNearestDealOrClose() {
  const dealBoardSettings = useAtomValue(dealBoardSettingsState);
  return useAtomCallback(
    useCallback(
      (get, set, deal: IDealDataModel) => {
        let nearestDeal = getNextDeal(dealBoardSettings, deal);
        if (!nearestDeal) nearestDeal = getPrevDeal(dealBoardSettings, deal);
        if (!nearestDeal) set(isOpenDrawerState, false);
        set(selectedDealIdState, nearestDeal?.id ?? null);
      },
      [dealBoardSettings]
    )
  );
}
