import { Alert, Stack } from '@mui/material';
import { useMemo } from 'react';
import { ColDef, IRowNode } from 'ag-grid-community';
import { usePortCos } from '../Finance2/hooks/usePortCos';
import { companyGridSchema } from '../../schemas/Company.schema';
import { useSchemaToGrid } from '../../util/schema-utils';
import { withOverrides } from '../../util/ag-grid-utils';
import { AgTableWithBaseStyle } from '../../components/AgTable/AgTable2';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { LinkRenderer } from '../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/LinkRenderer';
import { useExtendedExportSettings } from '../../components/AgTable/exportToExcelDefs';

export function CompaniesOverview() {
  const { data, error } = usePortCos();

  if (error) {
    return <Alert>Error loading data.</Alert>;
  }

  return (
    <Stack height='100%' width={'100%'} p='1rem 2rem'>
      <CompaniesOverviewGrid portCos={data} />
    </Stack>
  );
}

interface ICompaniesOverviewGridProps {
  portCos?: ICompanyDataModel[];
}
function CompaniesOverviewGrid({ portCos }: ICompaniesOverviewGridProps) {
  const { columnDefs, defaultColDef, exportParams } = useCompaniesOverviewGridDefs();
  return (
    <AgTableWithBaseStyle
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      rowData={portCos}
      defaultExcelExportParams={exportParams}
      defaultCsvExportParams={exportParams}
    />
  );
}

function useCompaniesOverviewGridDefs() {
  const schema = companyGridSchema();
  const schemaToGrid = useSchemaToGrid();
  const { exportParams } = useExtendedExportSettings(new Set(['id']), 'Companies Overview');

  return useMemo(() => {
    const defaultColDef: ColDef = {
      flex: 1,
      filter: true,
      filterParams: {
        buttons: ['reset'],
      },
    };
    const overrides = {
      id: {
        comparator: (
          valueA: string,
          valueB: string,
          nodeA: IRowNode<ICompanyDataModel>,
          nodeB: IRowNode<ICompanyDataModel>
        ) => {
          return (nodeA?.data?.name ?? '')?.localeCompare(nodeB?.data?.name ?? '') ?? 0;
        },
        sortable: true,
        initialSort: 'asc' as const,
      },
      shortDescription: {
        flex: 2,
        tooltipField: 'shortDescription',
        filter: 'agTextColumnFilter',
      },
      website: {
        cellRenderer: LinkRenderer,
      },
    };
    const columnDefs = withOverrides(schemaToGrid(schema, ['id', 'website', 'shortDescription']), overrides);
    return {
      columnDefs,
      defaultColDef,
      exportParams,
    };
  }, [exportParams, schema, schemaToGrid]);
}
