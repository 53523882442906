import { atom } from 'jotai';
import { atomFamily, atomWithDefault, atomWithLazy, loadable, unwrap } from 'jotai/utils';
import { IDealTypeDataModel } from '../../../data-models/deal-type.data-model';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import {
  dealSort,
  fetchDealBoardConfig,
  fetchDealLabels,
  fetchDealTodos,
} from '../../../services/queries/MaggieDealQueries';
import { DealBoardDealCategory, LoadingStatus } from '../../../types';
import { IDealStageDataModel } from '../data-models/dealStage.data-model';
import { getDealCategory } from '../utils/getDealCategory';

export interface RepeatQueryStatus {
  status: LoadingStatus;
  lastUpdated: Date | undefined;
}

export const allDealsLoadStatusState = atom<RepeatQueryStatus>({
  status: LoadingStatus.idle,
  lastUpdated: undefined,
});

export const allDealsState = atom<IDealDataModel[]>([]);
export const allDealsByIdState = atom((get) => {
  const allDeals = get(allDealsState);
  return allDeals.reduce((map, deal) => {
    return map.set(deal.id, deal);
  }, new Map<number, IDealDataModel>());
});

export const allDealsSortedState = atom<IDealDataModel[]>((get) => {
  const allDeals = get(allDealsState);
  return [...allDeals].sort(dealSort);
});

export const dealsByCategoryState = atomFamily((category: string) =>
  atom((get) => {
    const mapping = get(dealStageIdToCategoryState);
    const allDeals = get(allDealsSortedState);

    return allDeals.filter((deal) => getDealCategory(mapping, deal.stageId) === category);
  })
);

export const dealBoardConfigState = atomWithDefault(() => fetchDealBoardConfig());
export const dealBoardConfigStateUnwrapped = unwrap(dealBoardConfigState);

export const dealStageIdToCategoryState = atom((get) => {
  const dealStages = get(dealStagesByIdMapState);
  const dealStageNameToDealStage: Record<string, number> = {};
  dealStages?.forEach((dealStage) => {
    dealStageNameToDealStage[dealStage.name] = dealStage.id;
  });

  const dealsCatMap = new Map<DealBoardDealCategory, Set<number>>();
  addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.PASS, 'Pass');
  addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.TRACK, 'Track');
  addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.MISSED, 'Missed');
  addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.CLOSED, 'Closed');

  const remainingStages = Object.values(dealStageNameToDealStage);
  dealsCatMap.set(DealBoardDealCategory.CURRENT, new Set(remainingStages));

  return dealsCatMap;
});

export const dealStagesIdsState = atom((get) => {
  const stagesArray = get(dealBoardConfigStateUnwrapped)?.dealStages;

  return stagesArray?.map((stage) => {
    return stage.id;
  });
});

export const dealStagesByIdMapState = atomWithDefault((get) => {
  const stagesArray = get(dealBoardConfigStateUnwrapped)?.dealStages;

  return stagesArray?.reduce((res, stage) => {
    return res.set(stage.id, stage);
  }, new Map<number, IDealStageDataModel>());
});

export const dealStagesByNameMapState = atom(async (get) => {
  const stagesArray = get(dealBoardConfigStateUnwrapped)?.dealStages;

  return stagesArray?.reduce((res, stage) => {
    return res.set(stage.name, stage);
  }, new Map<string, IDealStageDataModel>());
});

export const dealTypesByIdMapState = atom((get) => {
  const dealTypesArray = get(dealBoardConfigStateUnwrapped)?.dealTypes;

  return dealTypesArray?.reduce((res, dealType) => {
    return res.set(dealType.id, dealType);
  }, new Map<number, IDealTypeDataModel>());
});

export const recentlyClosedDealsState = atom((get) => {
  const closedDeals = get(dealsByCategoryState(DealBoardDealCategory.CLOSED));

  return closedDeals.slice(0, 3);
});

export const recentlyPassedDealsState = atom(async (get) => {
  const passedDeals = get(dealsByCategoryState(DealBoardDealCategory.PASS));

  return passedDeals.slice(0, 3);
});

export const _dealLabelsState = atomWithLazy(fetchDealLabels);
export const dealLabelsState = loadable(_dealLabelsState);

export const dealTodosState = atomWithLazy(fetchDealTodos);

function addCategoryToMap(
  dealsCatMap: Map<DealBoardDealCategory, Set<number>>,
  dealStageNameToDealStage: Record<string, number>,
  category: DealBoardDealCategory,
  stageName: string
) {
  dealsCatMap.set(category, new Set([dealStageNameToDealStage[stageName]]));
  delete dealStageNameToDealStage[stageName];
}
