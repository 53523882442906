import { useCallback, useEffect } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useAtomCallback } from 'jotai/utils';
import { DealFormViewModel, TbdStageId } from '../../../schemas/deal.schema';
import { CompanySearchResponseWithRealId } from '../../../schemas/CompanySearchResponse.schema';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { companyStateLoadable } from '../../../services/state/CompanyStateJ';
import { useCreateOrUpdateCompanyHandler } from '../../Finance2/Forms/CompanyFormUtils';
import { currentStepState } from '../../Finance2/Forms/TransactionFormUIState';
import { useCreateDeal } from '../hooks/useDealActions';
import { dealStagesByNameMapState } from '../state/DealboardDataState';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { getDuplicateDeal } from '../utils/getLatestDuplicateDeal';

const dealFormDataState = atom<Partial<DealFormViewModel> | null>(null);
const dealCompanyFormDataState = atom<Partial<ICompanyDataModel> | null>(null);
const selectedCompanyIdState = atom<number | null>(null);
const selectedCompanyState = atom((get) => {
  const selectedCompanyId = get(selectedCompanyIdState);
  if (selectedCompanyId) {
    const companyState = get(companyStateLoadable(selectedCompanyId));
    if (companyState.state != 'hasData') return null;
    return companyState.data;
  }
  return null;
});
const duplicateDealState = atom<IDealDataModel | null>(null);
const byPassDuplicateState = atom<boolean>(false);

export function useResetAddDealFormState() {
  const setCurrentStep = useSetRecoilState(currentStepState);
  return useAtomCallback(
    useCallback(
      (get, set) => {
        set(dealFormDataState, null);
        set(dealCompanyFormDataState, null);
        set(selectedCompanyIdState, null);
        set(duplicateDealState, null);
        set(byPassDuplicateState, false);
        setCurrentStep(0);
      },
      [setCurrentStep]
    )
  );
}
export function useAddDealFormState() {
  const [currentStep, setCurrentStep] = useRecoilState(currentStepState);
  const [selectedCompanyId, setSelectedCompanyId] = useAtom(selectedCompanyIdState);
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [dealFormData, setDealFormData] = useAtom(dealFormDataState);
  const createOrUpdateCompany = useCreateOrUpdateCompanyHandler();
  const createDealAndUpdateState = useCreateDeal();
  const stagesMap = useAtomValue(dealStagesByNameMapState);
  const [duplicateDeal, setDuplicateDeal] = useAtom(duplicateDealState);
  const [byPassDuplicate, setByPassDuplicate] = useAtom(byPassDuplicateState);
  const [dealCompanyFormData, setDealCompanyFormData] = useAtom(dealCompanyFormDataState);

  const onCompanySelect = useCallback(
    async (company: CompanySearchResponseWithRealId) => {
      setSelectedCompanyId(company.id);
      setDealCompanyFormData({ ...company });
      const duplicateDeal = await getDuplicateDeal(company.id);
      if (duplicateDeal) {
        setDuplicateDeal(duplicateDeal);
        setByPassDuplicate(true);
      } else {
        setDuplicateDeal(null);
        setByPassDuplicate(false);
        setCurrentStep((prev) => prev + 1);
      }
    },
    [setByPassDuplicate, setCurrentStep, setDealCompanyFormData, setDuplicateDeal, setSelectedCompanyId]
  );

  useEffect(() => {
    if (!selectedCompanyId) {
      setDuplicateDeal(null);
      setByPassDuplicate(false);
    }
  }, [selectedCompanyId, setByPassDuplicate, setDuplicateDeal]);

  const onCreateDeal = useCallback(
    async (data: DealFormViewModel) => {
      const companyId = selectedCompany?.id;
      if (!companyId || !stagesMap) return;
      const payload = prepareDealPayload({
        dealData: data,
        companyId,
        stageId: stagesMap.get('Prospects')!.id as number,
      });

      const dealCreationResponse = await createDealAndUpdateState(
        payload as Partial<IDealDataModel>,
        byPassDuplicate
      );
      return dealCreationResponse;
    },
    [selectedCompany?.id, stagesMap, createDealAndUpdateState, byPassDuplicate]
  );

  const onClearCompany = useCallback(() => {
    setSelectedCompanyId(null);
    setByPassDuplicate(false);
    setDuplicateDeal(null);
    setDealCompanyFormData(null);
  }, [setByPassDuplicate, setDuplicateDeal, setDealCompanyFormData, setSelectedCompanyId]);

  const onCreateOrUpdateCompany = useCallback(
    async (data: Partial<ICompanyDataModel>) => {
      const res = await createOrUpdateCompany(data);
      if (res) {
        setSelectedCompanyId(res.id);
        return res;
      }
    },
    [createOrUpdateCompany, setSelectedCompanyId]
  );

  const handleValidateCompanyStep = useCallback(async () => {
    return Promise.resolve(selectedCompany != null);
  }, [selectedCompany]);

  return {
    currentStep,
    dealFormData,
    dealCompanyFormData,
    duplicateDeal,
    onCompanySelect,
    onCreateDeal,
    onClearCompany,
    onCreateOrUpdateCompany,
    handleValidateCompanyStep,
    selectedCompany,
    setCurrentStep,
    setDealFormData,
    setDealCompanyFormData,
    setByPassDuplicate,
    setSelectedCompanyId,
  };
}

function prepareDealPayload({
  dealData,
  companyId,
  stageId,
}: {
  dealData: DealFormViewModel;
  companyId: number;
  stageId: number;
}) {
  const roundId = dealData.roundId == TbdStageId ? null : dealData.roundId;
  return {
    ...dealData,
    companyId,
    stageId,
    roundId,
  };
}
