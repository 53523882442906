import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import {
  fromPassVMToDealDM,
  PassDealFormViewModel,
  usePassDealFormVMSchema,
} from '../../../schemas/deal.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { useDealActions2 } from '../hooks/useDealActions';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';

export interface IDealActionFormProps {
  deal: IDealDataModel;
  onClose: () => void;
  next?: () => void;
}

export function PassDealForm({ deal, onClose, next }: IDealActionFormProps) {
  const schema = usePassDealFormVMSchema();
  const { handlePass } = useDealActions2();
  const [loading, setLoading] = useState(false);

  const methods = useForm<PassDealFormViewModel>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: PassDealFormViewModel) => {
      setLoading(true);
      const { passComments, stageComments } = fromPassVMToDealDM(data);
      await handlePass(deal, stageComments || '', passComments || null);
      setLoading(false);
      onClose();
      next?.();
    },
    [deal, handlePass, onClose, next]
  );

  return (
    <FormProvider {...methods}>
      <FormFieldsContainer>
        <PassDealFields schema={schema} />
        <StickyFormButtons
          onSubmit={methods.handleSubmit(onSubmit)}
          onCancel={onClose}
          style={{ padding: '0.5rem 0 2rem' }}
          submitLabel={'Move to Pass'}
          loading={loading}
        />
      </FormFieldsContainer>
    </FormProvider>
  );
}

function PassDealFields({ schema }: { schema: ObjectSchema<PassDealFormViewModel> }) {
  const formFields = schemaToFormFields(schema);
  const form = createFormFromFieldsArray(formFields);
  return <FormFactoryWithStandardLayout form={form} />;
}
