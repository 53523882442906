import { css } from '@emotion/react';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { colors } from '../../../../theme/colors';
import { UnstyledFieldset } from '../../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { Panel } from '../../../CompanyProfiles2/Summary/Panel';
import { getRequiredChecklistItems, getWarningMessage } from '../../helpers/checklistHelpers';
import { useDebouncedUpdateDeal } from '../../hooks/useDealActions';
import { dealBoardConfigState } from '../../state/DealboardDataState';

export interface ICheckListProps {
  deal: IDealDataModel;
  editable: boolean;
}

const StyledFormGroup = css`
  & .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }
  & .MuiFormControlLabel-root:has(input:checked) {
    & .MuiFormControlLabel-label {
      text-decoration: line-through;
      color: ${colors.neutral[60]};
    }
  }
`;

export const CheckList: FC<ICheckListProps> = (props) => {
  const { deal, editable } = props;
  const { checklistState, handleChange } = useCheckListState({ deal });

  const items = useMemo(() => {
    return Object.entries(checklistState).map(([name, checked]) => {
      return (
        <FormControlLabel
          key={name}
          control={<Checkbox checked={checked} onChange={handleChange} name={name} />}
          label={name}
        />
      );
    });
  }, [checklistState, handleChange]);
  if (Object.keys(checklistState).length === 0) {
    return null;
  }
  return (
    <Panel title='Checklist'>
      <UnstyledFieldset disabled={!editable}>
        <FormControl variant='standard'>
          <FormGroup css={StyledFormGroup}>{items}</FormGroup>
        </FormControl>
      </UnstyledFieldset>
    </Panel>
  );
};

export function useCheckListState({ deal }: { deal: IDealDataModel }) {
  const dealBoardConfig = useAtomValue(dealBoardConfigState);
  const updateDeal = useDebouncedUpdateDeal();

  const [checklistState, setChecklistState] = useState<Record<string, boolean>>(() => {
    const checkedItems = new Set(deal.todoList ?? []);
    const required = new Set(getRequiredChecklistItems({ dealBoardConfig, deal }));
    const todoList = [...dealBoardConfig.todoList]
      .filter((item) => required.has(item.name))
      .sort((a, b) => a.sortOrder - b.sortOrder);
    return todoList.reduce(
      (acc, item) => {
        acc[item.name] = checkedItems.has(item.name);
        return acc;
      },
      {} as Record<string, boolean>
    );
  });

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newState = { ...checklistState, [event.target.name]: event.target.checked };
      setChecklistState(newState);
      const newList = Object.entries(newState).reduce((acc, [todoItem, checked]) => {
        if (checked) acc.add(todoItem);
        return acc;
      }, new Set<string>());
      updateDeal(
        deal,
        {
          todoList: Array.from(newList),
        },
        false
      );
    },
    [checklistState, deal, updateDeal]
  );

  return { checklistState, handleChange };
}

export function CheckListWarningIcon({ deal }: { deal: IDealDataModel }) {
  const dealBoardConfig = useAtomValue(dealBoardConfigState);
  const warningMessage = useMemo(() => {
    if (!dealBoardConfig) return null;
    return getWarningMessage({ dealBoardConfig, deal });
  }, [dealBoardConfig, deal]);

  if (!warningMessage) return null;

  return <ReportProblemOutlinedIcon fontSize='small' color='warning' titleAccess={warningMessage} />;
}
