import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class LossRatioConfig implements KpiConfig {
  private id = KPI_COMPONENTS.LOSS_RATIO as const;
  private title = 'Loss Ratio';
  private type = 'percentage';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      description: 'The percentage of realized companies that lost capital',
    };
  }

  calculate(params: KpiCalculationParams): Kpi {
    const totalAmountInvested = params.data?.metrics.reduce((sum, curr) => sum + curr.amountInvested, 0);
    const totalDistributions = params.data?.metrics.reduce((sum, curr) => sum + curr.distributions, 0);
    const value =
      !totalAmountInvested ||
      !totalDistributions ||
      totalAmountInvested === 0 ||
      totalAmountInvested < totalDistributions
        ? 0
        : ((totalAmountInvested - totalDistributions) / totalAmountInvested) * 100;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
