import { useAtomValue } from 'jotai';
import { FC, Fragment, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { pick } from 'lodash-es';
import { useListenToFormChanges } from '../../../../../components/Form/FormFactory';
import { FormFieldAndLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { field2ToField3, FieldEntity } from '../../../../../data-models/field2.data-model';
import { field3ToFormField } from '../../../../../data-models/field3.data-model';
import { DealFormViewModel } from '../../../../../schemas/deal.schema';
import { customFieldsByEntityAtom } from '../../../../../services/state/AdminPanel/CustomFieldsStateJ';
import { LoadingId } from '../../../../../types';
import { useUpdateDeal2 } from '../../../hooks/useDealActions';
import { DrawerLabel } from './commonDrawerComponents';

interface IDealCustomDataSectionProps {
  deal: IDealDataModel;
  disableEdit?: boolean;
}

export const DealCustomDataSection: FC<IDealCustomDataSectionProps> = ({ deal, disableEdit }) => {
  const updateDeal = useUpdateDeal2();
  const { actions } = useLoadingBarState();

  const methods = useForm<Partial<DealFormViewModel>>({
    values: {
      ...(pick(deal, 'customData') ?? {}),
    },
  });

  const onUpdateDeal = useCallback(
    async (payload: Partial<DealFormViewModel>) => {
      const { companyId, dealTypeId } = deal;
      actions.startLoading(LoadingId.updateDeal);
      await updateDeal(deal, { companyId, dealTypeId, ...payload } as Partial<IDealDataModel>);
      actions.stopLoading(LoadingId.updateDeal);
    },
    [actions, deal, updateDeal]
  );

  return (
    <FormProvider {...methods}>
      <DeaCustomFields onChange={onUpdateDeal} disableEdit={disableEdit} />
    </FormProvider>
  );
};

interface IDealCustomFieldsProps {
  onChange: (data: Partial<DealFormViewModel>) => Promise<void>;
  disableEdit?: boolean;
}
function DeaCustomFields({ onChange, disableEdit }: IDealCustomFieldsProps) {
  const dealCustomFields = useAtomValue(customFieldsByEntityAtom).get(FieldEntity.deal);
  const fields = (dealCustomFields ?? []).map((f) => field3ToFormField(field2ToField3(f)));

  useListenToFormChanges(onChange);
  return (
    <>
      {fields.map((f) => {
        const { label, description, ...rest } = f;
        const noLabelField = { ...rest, label: '', disabled: disableEdit, variant: 'form-inline' as const };
        return (
          <Fragment key={f.key}>
            <DrawerLabel>{label}</DrawerLabel>
            <FormFieldAndLabelFactory key={f.key} formField={noLabelField} />
          </Fragment>
        );
      })}
    </>
  );
}
