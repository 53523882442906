import { endOfMonth, endOfQuarter, endOfYear, sub } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { Temporal } from 'temporal-polyfill';
import {
  ICompanyFinancialsDataModel,
  KpiPeriod,
  KpiSection,
} from '../../../data-models/company-financials.data-model';
import { IField } from '../../../data-models/field2.data-model';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { kpiConfigByKeyMapAtom } from '../../../services/state/KPIConfigState';
import { ColumnMeta } from '../../../types';
import { dateToPlainDateString } from '../../../util/date-utilts';
import { MONTHS } from '../../KPI/components/Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { columnsByPathState } from '../state/ViewState';
import { compareBySortOrder } from '../../../util/comparators';
import {
  IMprViewSettings,
  IMultiPeriodReportingDataPoint,
  IMultiPeriodReportingMetricsDataPoint,
  MprColumnSortType,
  MprKpiConfigFormData,
  MprMetricsConfigFormData,
  ReportingPeriodType,
} from './MultiPeriodReporting.schemas';

export function mprKpiColumnConfigToDataPoint(
  column: IMprKpiColumnConfig,
  kpisByKeyMap: Map<string, IField<unknown>>
): IMultiPeriodReportingDataPoint | null {
  const { kpiKey, period, section } = column;
  const date = getDateForConfig(column);
  const kpiId = kpisByKeyMap.get(kpiKey)?.id;
  if (!kpiId) return null;

  return {
    date,
    kpiId: kpiId!,
    period,
    section,
  };
}

export function mprMetricsColumnConfigsToDataPoints(
  columns: IMprMetricsColumnConfig[],
  columnsByPath: Map<string, ColumnMeta>
): IMultiPeriodReportingMetricsDataPoint[] {
  const columnsByDate = columns.reduce((map, col) => {
    const date = getDateForConfig(col);
    if (!map.has(date)) {
      map.set(date, []);
    }
    const colId = columnsByPath.get(col.columnPath)?.id;
    if (!colId) return map;
    map.get(date)!.push(colId);
    return map;
  }, new Map<string, number[]>());

  return Array.from(columnsByDate.entries()).map(([date, colIds]) => ({
    date,
    columnIds: colIds,
  }));
}

export function getDateForConfig(columnConfig: IAbstractMprColumnConfig): string {
  const type = columnConfig.type;

  let date;
  if (type === ReportingPeriodType.relative) {
    date = getDateStrForRelativePeriod(columnConfig.period as KpiPeriod, columnConfig.relativeDistance ?? 0);
  } else if (type === ReportingPeriodType.absolute) {
    date = getDateStrForAbsolutePeriod(columnConfig.year!, columnConfig.month as number | undefined);
  } else {
    throw new Error('Invalid type');
  }
  return date;
}

export function getDateStrForRelativePeriod(period: KpiPeriod, relativeDistance: number): string {
  let date;
  if (period === KpiPeriod.quarter) {
    date = endOfQuarter(sub(new Date(), { months: (relativeDistance! + 1) * 3 }));
  } else if (period === KpiPeriod.year) {
    date = endOfYear(sub(new Date(), { years: (relativeDistance! + 1) * 1 }));
  } else {
    date = endOfMonth(sub(new Date(), { months: (relativeDistance! + 1) * 1 }));
  }
  return dateToPlainDateString(date);
}

export function getDateStrForAbsolutePeriod(year: number, month: number = 12): string {
  const date = Temporal.PlainYearMonth.from(`${year}-${String(month).padStart(2, '0')}`);
  return date.toPlainDate({ day: date.daysInMonth }).toString();
}

export interface IAbstractMprColumnConfig
  extends Pick<ICompanyFinancialsDataModel, 'period'>,
    Pick<MprKpiConfigFormData, 'type'> {
  month?: number | null;
  sortOrder?: number;
  relativeDistance?: number | null;
  year?: number | null;
}

export interface IMprKpiColumnConfig
  extends Pick<ICompanyFinancialsDataModel, 'section'>,
    IAbstractMprColumnConfig {
  kpiKey: string;
}
export function isMprKpiColumnConfig(config: IAbstractMprColumnConfig): config is IMprKpiColumnConfig {
  return 'kpiKey' in config;
}

export interface IMprMetricsColumnConfig extends IAbstractMprColumnConfig {
  columnPath: string;
}
export function isMprMetricsColumnConfig(
  config: IAbstractMprColumnConfig
): config is IMprMetricsColumnConfig {
  return 'columnPath' in config;
}

export function createAbstractMprColumnConfig(
  overrides: Partial<IAbstractMprColumnConfig> = {}
): IAbstractMprColumnConfig {
  return {
    period: KpiPeriod.quarter,
    type: ReportingPeriodType.relative,
    relativeDistance: 0,
    ...overrides,
  };
}
export function createMprKpiColumnConfig(overrides: Partial<IMprKpiColumnConfig> = {}): IMprKpiColumnConfig {
  return {
    ...createAbstractMprColumnConfig(),
    kpiKey: 'kpiKey',
    section: KpiSection.actual,
    ...overrides,
  };
}

export function createMprMetricsColumnConfig(
  overrides: Partial<IMprMetricsColumnConfig> = {}
): IMprMetricsColumnConfig {
  return {
    ...createAbstractMprColumnConfig(),
    columnPath: 'columnPath',
    ...overrides,
  };
}

export function kpiColumnConfigsFromFormData(settings: MprKpiConfigFormData): IMprKpiColumnConfig[] {
  const result: IMprKpiColumnConfig[] = [];

  settings.kpiKey.forEach((key) => {
    settings.section.forEach((section) => {
      if (settings.type === ReportingPeriodType.relative) {
        const { period, relativeDistance } = settings;
        if (!relativeDistance?.length || !period) {
          throw new Error('Missing data');
        }
        relativeDistance.forEach((distance) => {
          result.push({
            kpiKey: key!,
            section: section!,
            period,
            relativeDistance: distance,
            type: settings.type,
          });
        });
      } else if (settings.type === ReportingPeriodType.absolute) {
        (settings.month ?? [12])?.forEach((month) => {
          if (!settings.year?.length) {
            throw new Error('Missing data');
          }
          settings.year?.forEach((year) => {
            result.push({
              kpiKey: key!,
              section: section!,
              period: settings.period!,
              month,
              year,
              type: settings.type,
            });
          });
        });
      }
    });
  });

  return result;
}

export function metricsColumnConfigsFromFormData(
  settings: MprMetricsConfigFormData
): IMprMetricsColumnConfig[] {
  const result: IMprMetricsColumnConfig[] = [];

  settings.columnPaths?.forEach((path) => {
    if (settings.type === ReportingPeriodType.relative) {
      const { period, relativeDistance } = settings;
      if (!relativeDistance?.length || !period) {
        throw new Error('Missing data');
      }
      relativeDistance.forEach((distance) => {
        result.push({
          columnPath: path!,
          period,
          relativeDistance: distance,
          type: settings.type,
        });
      });
    } else if (settings.type === ReportingPeriodType.absolute) {
      (settings.month ?? [12])?.forEach((month) => {
        if (!settings.year?.length) {
          throw new Error('Missing data');
        }
        settings.year?.forEach((year) => {
          result.push({
            columnPath: path!,
            period: settings.period!,
            month,
            year,
            type: settings.type,
          });
        });
      });
    }
  });

  return result;
}

// used for colIds and to compare configs
export function getColumnConfigKey(config: IMprKpiColumnConfig | IMprMetricsColumnConfig): string {
  const { period, type, relativeDistance, year, month } = config;
  if (isMprKpiColumnConfig(config)) {
    const { kpiKey, section } = config;
    return JSON.stringify({ kpiKey, section, period, type, relativeDistance, year, month });
  } else {
    // isMprMetricsColumnConfig
    const { columnPath } = config;
    return JSON.stringify({ columnPath, period, type, relativeDistance, year, month });
  }
}

export const LastFullPeriodPrefix = 'Last Full';
export function getFormattedConfigPeriod(config: IAbstractMprColumnConfig) {
  const { type, period, relativeDistance, year, month } = config;
  if (type == ReportingPeriodType.relative) {
    if (relativeDistance === 0) {
      return `${LastFullPeriodPrefix} ${period}`;
    } else {
      return `${LastFullPeriodPrefix} ${period} - ${relativeDistance}`;
    }
  } else if (type == ReportingPeriodType.absolute) {
    if (period === KpiPeriod.year) {
      return `${year}`;
    } else if (period === KpiPeriod.quarter) {
      return `Q${month! / 3} ${year}`;
    } else if (period === KpiPeriod.month) {
      return `${MONTHS[month! - 1]} ${year}`;
    }
  }
  return '';
}

const PeriodToSortOrder: Record<KpiPeriod, number> = {
  [KpiPeriod.month]: 1,
  [KpiPeriod.quarter]: 2,
  [KpiPeriod.year]: 3,
};

export function multiPeriodDateComparator(a: IAbstractMprColumnConfig, b: IAbstractMprColumnConfig) {
  const aDate = getDateForConfig(a);
  const bDate = getDateForConfig(b);
  const aPeriod = a.period;
  const bPeriod = b.period;
  if (aPeriod === bPeriod) {
    return aDate.localeCompare(bDate);
  } else {
    return PeriodToSortOrder[aPeriod as KpiPeriod] - PeriodToSortOrder[bPeriod as KpiPeriod];
  }
}

function useSortByColumnName() {
  const kpiConfigByKey = useAtomValue(kpiConfigByKeyMapAtom);
  const columnsByPath = useAtomValue(columnsByPathState(COMPANY_VIEW_TYPE.RETURN_FORECAST));

  return useCallback(
    (a: IMprKpiColumnConfig | IMprMetricsColumnConfig, b: IMprKpiColumnConfig | IMprMetricsColumnConfig) => {
      return mprColumnNameComparator(a, b, kpiConfigByKey, columnsByPath);
    },
    [kpiConfigByKey, columnsByPath]
  );
}

export function mprColumnNameComparator(
  a: IMprKpiColumnConfig | IMprMetricsColumnConfig,
  b: IMprKpiColumnConfig | IMprMetricsColumnConfig,
  kpiConfigMap: Map<string, IField<unknown>>,
  columnsByPath: Map<string, ColumnMeta>
) {
  const nameA = isMprKpiColumnConfig(a)
    ? (kpiConfigMap.get(a.kpiKey)?.displayName ?? '')
    : (columnsByPath.get(a.columnPath)?.displayName ?? '');
  const nameB = isMprKpiColumnConfig(b)
    ? (kpiConfigMap.get(b.kpiKey)?.displayName ?? '')
    : (columnsByPath.get(b.columnPath)?.displayName ?? '');
  return nameA.localeCompare(nameB);
}

export function useSortColumnConfigs() {
  const sortByName = useSortByColumnName();

  const sortByDateAndDisplayName = useCallback(
    (configs: (IMprKpiColumnConfig | IMprMetricsColumnConfig)[]) => {
      return configs.toSorted((a, b) => sortByName(a, b)).sort(multiPeriodDateComparator);
    },
    [sortByName]
  );
  const sortByDisplayNameAndDate = useCallback(
    (configs: (IMprKpiColumnConfig | IMprMetricsColumnConfig)[]) => {
      return configs.toSorted(multiPeriodDateComparator).sort(sortByName);
    },
    [sortByName]
  );

  return { sortByDateAndDisplayName, sortByDisplayNameAndDate };
}

export function usePreprocessMprViewSettings() {
  const { sortByDateAndDisplayName, sortByDisplayNameAndDate } = useSortColumnConfigs();

  return useCallback(
    (config: IMprViewSettings) => {
      if (!config.columnSort) {
        return config;
      }
      let sortedColumns: (IMprKpiColumnConfig | IMprMetricsColumnConfig)[];
      if (config.columnSort === MprColumnSortType.dateAsc) {
        sortedColumns = sortByDateAndDisplayName(config.columns);
      } else if (config.columnSort === MprColumnSortType.displayName) {
        sortedColumns = sortByDisplayNameAndDate(config.columns);
      } else {
        sortedColumns = config.columns.toSorted(compareBySortOrder);
      }
      return {
        ...config,
        columns: sortedColumns,
      };
    },
    [sortByDateAndDisplayName, sortByDisplayNameAndDate]
  );
}
