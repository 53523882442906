import { ExcelStyle } from 'ag-grid-community';
import { ICustomNumberFormatConfig, IFormatterDataModel } from '../data-models/formatter.data-model';
import { getCurrencySymbolFor } from './currency-util';
import { FMT, StandardFormatterId } from './formatter-service';

// see:
// - https://support.microsoft.com/en-us/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68 for docs
// - https://www.ablebits.com/office-addins-blog/custom-excel-number-format

export const ExcelFormatterIdPrefix = 'excel_';

export function getExcelAgFormat(model: IFormatterDataModel<unknown>): ExcelStyle | null {
  const id = getExcelFormatterId(model.id as StandardFormatterId);

  if (id === null) {
    return null;
  }

  switch (model.type) {
    case 'number':
      return {
        dataType: 'Number',
        id,
        numberFormat: {
          format: numberFormatConfigToExcelString(model.config as ICustomNumberFormatConfig),
        },
      };
    case 'date':
      return {
        dataType: 'DateTime',
        id,
        numberFormat: { format: 'mm/dd/yyyy;;;' },
      };
    default:
      return null;
  }
}

/**
 * Convert from ICustomNumberFormatConfig to Excel number format string as per https://support.microsoft.com/en-us/office/number-format-codes-in-excel-for-mac-5026bbd6-04bc-48cd-bf33-80f18b4eae68
 * @param options
 */
export function numberFormatConfigToExcelString(options: ICustomNumberFormatConfig = {}): string {
  let formatString = '';

  if (options.style === 'currency') {
    formatString = getCurrencySymbolFor(options.currency ?? '') + formatString;
  }

  if (options.useGrouping !== false) {
    formatString += '#,##0';
  } else {
    formatString += '0';
  }

  if (options.minimumFractionDigits != undefined) {
    formatString +=
      options.minimumFractionDigits > 0 ? '.'.padEnd(options.minimumFractionDigits + 1, '0') : '';
  } else if (options.maximumFractionDigits != undefined) {
    formatString +=
      options.maximumFractionDigits > 0 ? '.'.padEnd(options.maximumFractionDigits + 1, '0') : '';
  } else {
    formatString += '.00';
  }

  if (options.suffix) {
    // escape all characters in suffix, to avoid things like:
    // - if suffix is %, no need to divide raw value by 100
    // - if suffix contains any reserved or special characters it won't error
    formatString += '\\' + options.suffix.split('').join('\\');
  }

  formatString += ';';
  if (options.negativeValue) {
    formatString += `${options.negativeValue}`;
  }

  formatString += ';';
  if (options.zeroValue) {
    formatString += `${options.zeroValue}`;
  }

  return formatString;
}

export function getExcelFormatterId(id: StandardFormatterId) {
  const formatterModel = FMT.get().getFormatterModel(id);

  if (
    formatterModel &&
    (formatterModel.type === 'number' || formatterModel.type === 'currency' || formatterModel.type === 'date')
  ) {
    return `${ExcelFormatterIdPrefix}${id}`;
  }

  return null;
}
